import React, {Component} from 'react'

import {getCurrentAppId, getSelectedIds, getZoom} from "../../../ducks/editor/objects";
import {connect} from "react-redux";
import { getApp } from "ducks/apps/selectors";
import {withRouter} from "react-router-dom";
import Canvas from './Canvas'; // import the component above linking to file we just created.


class Frame extends Component {

    render() {

        //let scale = this.getScale()


        /*let innerStyles = {
          width: Math.ceil(scale * styles.width),
          height: Math.ceil(scale * styles.height),
        }*/

        return (
            <div className="editor-trace-frame-wrapper">
                {/* <DocumentEvents target={window} onResize={this.forceRender}/>*/}
                <Canvas />
            </div>
        )
    }
}

const mapStateToProps = (state, {appId}) => {

    const app = getApp(state, getCurrentAppId(state))
    const components = app ? Object.values(app.components).filter(obj => app.launchComponentId === obj.id) : []

    return {
        app: app,
        objects: components,
        selection: getSelectedIds(state),
        zoom: getZoom(state),
    }
}

export default withRouter(connect(mapStateToProps, {})(Frame))
