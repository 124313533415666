// WrappedTextInput.js
import React from 'react'
import 'styles/WrappedInput.css'

const WrappedTextInput = ({
  label,
  name,
  value,
  onChange,
  placeholder,
}) => {

  const handleChange = (e) => {
    let val = e.target.value;
    onChange(val);
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) { // Enter
      e.currentTarget.blur();
    }
  }

  return (
    <div className={'single-number-control'}>

      {label && (
        <label className="single-number-control-label">{label}</label>
      )}

      <div className={'text-control'}>
        <div className="text-control-sub">
          <input
            type="text"
            name={name}
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
          />
        </div>
      </div>
    </div>
  )

}

export default WrappedTextInput
