import React, {Component} from 'react'
import classNames from 'classnames'

export default class DropTarget extends Component {
  render() {
    let { before, after, inside } = this.props

    return (
      <div className={classNames('drop-target', { before, after, inside })} />
    )
  }
}
