// saving.js

import deepEqual from 'deep-equal'

import {buildIndex, getObject, subPath, uniqueElements} from 'common/utils'
import {traverse} from 'common/utils/operations'

import {loadComponent} from 'ducks/apps/actions'

import { deleteComponent, saveComponents } from './io'
import {unsafeGetStore} from './auth'
import {calculate} from "./calculate";

// Cache for the purpose of diffing
// Ugly, I know :(
let prevList = null

export const saveTouched = (
  appId,
  list,
  map,
  objectIds,
  objectPaths,
  deletes,
  libraryGlobals
) => {

  let paths = objectPaths

  prevList = list

  if (!paths) {
    paths = objectIds.map(id => map[id])
  }

  let componentPaths = uniqueElements(
    paths.map(path => {
      return subPath(path, 1)
    })
  )

  if (deletes) {
    deletes.forEach(id => deleteComponent(appId, id))
  }

  let components = {}

  componentPaths.forEach(path => {

    let component = getObject(list, path)

    if (component) {
      components[component.id] = component
    }

  })

  let allComponentPaths = uniqueElements(
    Object.values(map).map(path => {
      return subPath(path, 1)
    })
  )

  let allComponents = {}

  allComponentPaths.forEach(path => {
    let component = getObject(list, path)

    if (component) {
      allComponents[component.id] = component
    }
  })

  if (Object.keys(components).length > 0) {
    save(appId, components, calculate(allComponents))
  }

}

export const saveDiffed = (appId, newList) => {
  if (!prevList) {
    return
  }

  let newIndex = buildIndex(newList, obj => obj.id)
  let oldIndex = buildIndex(prevList, obj => obj.id)

  let oldIds = prevList.map(component => component.id)
  let newIds = newList.map(component => component.id)

  let added = newIds.filter(id => oldIds.indexOf(id) === -1)
  let removed = oldIds.filter(id => newIds.indexOf(id) === -1)

  let others = newIds.filter(id => added.indexOf(id) === -1)
  let changed = others.filter(id => !deepEqual(newIndex[id], oldIndex[id]))
  changed = changed.concat(added)

  removed.forEach(id => deleteComponent(appId, id))

  let components = {}
  changed.forEach(id => (components[id] = newIndex[id]))
  save(appId, components)

  // This is done with a global - do not remove
  prevList = newList
}

export const setPrevList = list => {
  // This is done with a global - do not remove
  prevList = list
}

/**
 * @param  {...Array} componentObjects
 */
const getActions = (...arrays) => {
  let result = {}

  for (let array of arrays) {
    traverse(array, obj => {
      if (obj.actions) {
        result[obj.id] = obj.actions
      }
    })
  }

  return result
}

// функция которая сохраняет изменения в draft
const save = (appId, components, calc) => {

  if (!appId || !components || Object.keys(components).length === 0) {
    
    console.error('Attempted to save with invalid params:', {
      appId,
      components,
    })

    return
  }

  let outputComponents = {}

  for (let componentId of Object.keys(components)) {
    let component = {
      ...components[componentId],
      objects: components[componentId].children,
    }

    delete component.children

    outputComponents[componentId] = component

    component = {
      ...component,
      actions: getActions(component.objects, [
        { id: component.id, actions: component.componentActions },
      ]),
    }

    window.setTimeout(() => {
      unsafeGetStore().dispatch(loadComponent(appId, componentId, component))
    }, 0)

  }

  saveComponents(appId, outputComponents, calc)

}
