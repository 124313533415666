// ducks/admin/index.js

import { combineReducers } from 'redux'
import apps from './apps'
import users from './users'
import builds from './builds'
import libraries from './libraries'

import template from 'components/Admin/template/store/templateCombineReducers';
import orientation from 'components/Admin/orientations/store/orientationCombineReducers';


export default combineReducers({
  apps,
  users,
  builds,
  libraries,
  template,
  orientation,
})

