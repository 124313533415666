import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ModalActions extends Component {
  render() {
    const { children } = this.props

    return <div className="modal-actions">{children}</div>
  }
}

ModalActions.propTypes = {
  children: PropTypes.any.isRequired,
}

export default ModalActions
