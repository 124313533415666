import React from 'react'
import {imageResize} from 'common/constants'

import {assetURL} from 'utils/assets'
import {scaleValue} from 'utils/zoom'

import Section from '../Section'
import Icon from '../ImageUpload/uploaded-image.svg'
import DynIcon from '../ImageUpload/dynamic-image.svg'
import Potrace from 'utils/trace'
import 'styles/Image.css'

const DynamicPlaceholder = {
  source: DynIcon,
}

const UploadedPlaceholder = {
  source: Icon,
}

export default class Image extends Section {
  constructor(props) {
    super(props)

    this.svgRef = React.createRef()
    this.state = {
      path: null
    }
  }

  getURL = () => {
    let {imageType, imageId, imageBinding, imageURL, filename1x} = this.props

    let order = ['2x', '1x', '3x']

    let filename = null

    // placeholder image
    let placeholderImageEnabled = null
    let placeholderImage = null

    if (
      imageBinding &&
      typeof imageBinding.options !== 'undefined' &&
      !filename1x
    ) {
      const {options} = imageBinding

      if (typeof options.placeholderImageEnabled !== 'undefined') {
        placeholderImageEnabled = options.placeholderImageEnabled
      }

      if (typeof options.placeholderImage !== 'undefined') {
        placeholderImage = options.placeholderImage
      }
    }

    if (imageId) {
      filename = `${imageId}@2x.png`
    } else if (placeholderImageEnabled) {
      filename = placeholderImage
    } else if (!imageBinding && filename1x) {
      for (let size of order) {
        let key = `filename${size}`

        if (this.props[key]) {
          filename = this.props[key]

          break
        }
      }
    }

    if (imageType === 'url' && imageURL) {
      // check if url is magic text
      if (Array.isArray(imageURL)) return null

      return imageURL
    }

    if (!filename) return null

    return assetURL(filename)
  }

  getMaskId() {
    let {id} = this.props

    return `image-mask-${id}`
  }

  renderPlaceholder() {
    let {
      id,
      zoom,
      width,
      height,
      widthScaled,
      heightScaled,
      xScaled,
      yScaled,
      imageType,
    } = this.props

    const placeholder =
      imageType === 'dynamic' || imageType === 'url'
        ? DynamicPlaceholder
        : UploadedPlaceholder

    let minDim = Math.min(width, height)
    let size = Math.max(16, Math.min(80, minDim * 0.7))

    let iconWidth = scaleValue(size, zoom)
    let iconHeight = scaleValue(size, zoom)

    let src = this.getURL()
    let maskId = this.getMaskId()

    let resizeType =
      this.props.imageResize === imageResize.CONTAIN ? 'meet' : 'slice'

    if (src) {


      return (
        <g mask={`url(#${maskId})`}>
          <image
            href={src}
            x={xScaled}
            y={yScaled}
            width={widthScaled}
            height={heightScaled}
            preserveAspectRatio={`xMidYMid ${resizeType}`}
          />
          {svg}
        </g>
      )
    }

    return (
      <g
        transform={`translate(${xScaled}, ${yScaled})`}
        className="image-upload-object"
      >
        <image
          href={placeholder.source}
          width={iconWidth}
          height={iconHeight}
          x={widthScaled / 2 - iconWidth / 2}
          y={heightScaled / 2 - iconHeight / 2}
          clipPath={`url(#clip-${id})`}
        />
      </g>
    )
  }
  componentDidUpdate(prevProps) {
    // Популярный пример (не забудьте сравнить пропсы):
    if (this.props !== prevProps) {
      this.trace();
    }
  }

  trace(path, settings = {}) {
    const _this = this
    const {object} = this.props
    let src = this.getURL()
    if (src) {
      Potrace.loadImageFromUrl(src);
      Potrace.setParameter(
        {
          turnpolicy: "minority",
          turdsize:object.turdsize ? object.turdsize : 2,
          optcurve: false,
          alphamax: 1,
          opttolerance: object.tolerance ? object.tolerance / 10 : 0.2
        }
      )
      Potrace.process(function(){
        const  path = Potrace.getPathData(1)
        _this.setState({path: path});
      });

    }
  }

  render() {
    let {
      id,
      width,
      height,
      xScaled,
      yScaled,
      widthScaled,
      heightScaled,
      borderRadius,
      opacity,
      zoom,
      imageType,
    } = this.props
    let {
      path,
    } = this.state
    borderRadius = Math.min(borderRadius, height / 2)
    borderRadius = Math.min(borderRadius, width / 2)
    borderRadius = scaleValue(borderRadius || 0, zoom)

    let borderProps = this.getBorderProps()
    let backgroundColor = this.getBackgroundColor()

    let resizeType =
      this.props.imageResize === imageResize.CONTAIN ? 'meet' : 'slice'

    let src = this.getURL()
    let maskId = this.getMaskId()
    let showPlaceholder = imageType === 'dynamic' || !src
    let showPlaceholderImage = imageType === 'dynamic' && src


    return (
      <g
        className="image"
        onMouseDown={this.handleMouseDown}
        onTouchStart={this.handleMouseDown}
        onDoubleClick={this.handleDoubleClick}
        opacity={opacity}
      >
        <defs>
          <clipPath id={`clip-${id}`}>
            <rect
              x={0}
              y={0}
              width={widthScaled}
              height={heightScaled}
              rx={borderRadius}
              ry={borderRadius}
            />
          </clipPath>
        </defs>
        <mask id={maskId}>
          <rect
            x={xScaled}
            y={yScaled}
            width={widthScaled}
            height={heightScaled}
            fill="#fff"
            stroke="none"
            rx={borderRadius}
            ry={borderRadius}
          />
        </mask>
        <rect
          x={xScaled}
          y={yScaled}
          width={widthScaled}
          height={heightScaled}
          stroke="none"
          fill={backgroundColor}
          rx={borderRadius}
          ry={borderRadius}
        />
        <rect
          {...borderProps}
          x={xScaled}
          y={yScaled}
          width={widthScaled}
          height={heightScaled}
          fill={!showPlaceholder || showPlaceholderImage ? 'none' : '#eaeaea'}
          rx={borderRadius}
          ry={borderRadius}
        />

        {showPlaceholder ? (
          this.renderPlaceholder()
        ) : (

          <g>
            <g mask={`url(#${maskId})`}>
              <image
                href={src}
                x={xScaled}
                y={yScaled}
                width={widthScaled}
                height={heightScaled}
                preserveAspectRatio={`xMidYMid ${resizeType}`}
              />


            </g>
          </g>
        )}
        {path && <path className="link-path" d={path} />}
      </g>
    )
  }

}
