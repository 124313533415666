import React, {Component} from 'react'
import {connect} from 'react-redux'

import {defaultBranding} from 'utils/colors'
import {IconButton} from "components/Shared/Icon";
import {resetZoom} from "ducks/editor/objects";

class Zoom extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeZoom: null,
      trackVertHeight: 0,
      downY: false,
      downX: false,
      //yogaReady: false,
    }

  }

  setZoom = (e) => {
    const { resetZoom} = this.props
    resetZoom()
  }

  render() {
    let {
      component,
      zoom,
    } = this.props

    return (

        <div className={'zoom-panel'}>
          <div className={'item'}>

              <IconButton white type="autofit" onClick={this.setZoom} />

          </div>
        </div>


    )
  }

}

const mapStateToProps = state => ({
  branding: defaultBranding,
})

export default connect(mapStateToProps, {
  resetZoom,
})(Zoom)

