import React from 'react'
import classNames from 'classnames'
import {ALIGN_CENTER, ALIGN_RIGHT} from 'common/constants'

import {isFirefox} from 'utils/browsers'
import {getLines} from 'utils/text'
import {scaleValue} from 'utils/zoom'
import {getFontFamily} from 'utils/type'

import BaseObject from '../BaseObject'
import 'styles/Label.css'

export default class Label extends BaseObject {
  doubleClickHandler() {
    if (this.isSelected()) {
      return this.props.onEdit()
    }

    return super.doubleClickHandler()
  }

  getBorderProps() {
    const { id } = this.props

    // if no id is set then this label is not yet part of the app, render a
    // border to help the user see the size of the component
    if (!id) {
      return {
        strokeWidth: 2,
        stroke: '#e0e0e0',
        rx: 4,
        ry: 4,
      }
    }
  }

  handleFontFamily = () => {
    const { branding, fontFamily } = this.props

    return getFontFamily(fontFamily, branding)
  }

  render() {
    let {
      branding,
      text,
      layoutText,
      x,
      y,
      width,
      height,
      xScaled,
      yScaled,
      widthScaled,
      heightScaled,
      selected,
      editing,
      fontSize,
      textAlignment,

      elementRef,
      onChange,
      onSelect,
      zoom,

      ...styles
    } = this.props

    fontSize = scaleValue(fontSize, zoom)

    let textAnchor = 'start'

    let xAdjusted = xScaled

    layoutText = getLines(this.props).map(line => line.trim())

    if (editing) {
      styles.opacity = 0
    }

    if (textAlignment === ALIGN_CENTER) {
      textAnchor = 'middle'
      xAdjusted += widthScaled / 2
    } else if (textAlignment === ALIGN_RIGHT) {
      textAnchor = 'end'
      xAdjusted += widthScaled
    }

    styles.fontFamily = this.handleFontFamily()
    const color = this.getColor(styles.color || '@text')

    return (
      <g
        className={classNames('label', {
          'label-editing': editing,
        })}
        onMouseDown={this.handleMouseDown}
        onDoubleClick={this.handleDoubleClick}
        onTouchStart={this.handleMouseDown}
        style={{
          ...styles,
          fontSize: `${fontSize}px`,
        }}
      >
        {/* Click target for selection */}
        <rect
          x={xScaled}
          y={yScaled}
          width={widthScaled}
          height={heightScaled}
          fill="transparent"
          {...this.getBorderProps()}
        />
        <text
          className="label-text"
          y={yScaled}
          x={xAdjusted}
          fill={color}
          textAnchor={textAnchor}
          style={{ ...styles }}
        >
          {layoutText.map((line, i) => (
            <tspan
              key={i} // eslint-disable-line
              alignmentBaseline="text-before-edge"
              x={xAdjusted}
              y={
                yScaled + i * fontSize * 1.15 + (isFirefox() ? fontSize : 0) ||
                0
              }
            >
              {line}
            </tspan>
          ))}
        </text>
      </g>
    )
  }
}

Label.defaultProps = {
  yScaled: 0,
  y: 0,
}
