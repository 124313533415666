import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { notify } from 'ducks/notifications';
import { withRouter } from 'react-router-dom';

import { setOuterRule, setTemplateSetting } from 'components/Admin/template/store/templateActions';

class RemovePoint extends Component {

    setActivePoint = (pointIndex) => {
        this.props.setTemplateSetting({ activePointIndex: pointIndex });
    };

    onRemovePoint = (pointIndex) => {
        const { outer_rule, setting, setOuterRule } = this.props;
        const activeRuleIndex = setting.activeRuleIndex;
        const points = [...outer_rule[activeRuleIndex].points];

        if (!points || points.length <= 2 || pointIndex < 0 || pointIndex >= points.length) {
            return;
        }

        points.splice(pointIndex, 1);

        const updatedOuterRule = outer_rule.map((rule, index) => {
            if (index === activeRuleIndex) {
                return { ...rule, points: points };
            }
            return rule;
        });

        setOuterRule(updatedOuterRule);

        // Update active point
        if (setting.activePointIndex === pointIndex) {
            this.setActivePoint(null);
        } else if (setting.activePointIndex > pointIndex) {
            this.setActivePoint(setting.activePointIndex - 1);
        }
    };

    render() {
        const { pointIndex } = this.props;

        return (
            <button
                type="button"
                onClick={() => this.onRemovePoint(pointIndex)}
                className="px-2 py-2 text-xs bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
                del
            </button>
        );
    }
}

RemovePoint.propTypes = {
    pointIndex: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
    setting: state.admin.template.setting,
    outer_rule: state.admin.template.outer_rule,
});

const mapDispatchToProps = (dispatch) => ({
    notify: (message) => dispatch(notify(message)),
    setTemplateSetting: (setting) => dispatch(setTemplateSetting(setting)),
    setOuterRule: (outer_rule) => dispatch(setOuterRule(outer_rule)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RemovePoint));
