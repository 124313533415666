import React from 'react'
import PropTypes from 'prop-types'

import InspectPanel from 'components/Editor/Inspect'
import {PanelItem} from 'components/Shared/Panel'
import Layers from 'components/Editor/Layers'


import ScreenTemplateCollapsible from './ScreenTemplateCollapsible'

import 'styles/ScreenControl.css'
import Scrollbar from "../../../Shared/Scrollbar";

const ScreenControl = props => {
  const {
    screenTemplateExpanded,
    appId,
    object,
    onCollapseScreenTemplate,
    onShowScreenTemplates,
  } = props

  const { id } = object
  const GROUP = `${id}-screen`

  return (
    <>

      <ScreenTemplateCollapsible
        expanded={screenTemplateExpanded}
        appId={appId}
        object={object}
        onClose={onCollapseScreenTemplate}
        onExpand={onShowScreenTemplates}
      />

        <div className={'ssssssssaaas'}><InspectPanel /></div>

        <PanelItem title="Объекты" group={GROUP} fluid defaultExpanded>
        <Layers objectId={id} />
      </PanelItem>


    </>
  )
}

ScreenControl.propTypes = {
  screenTemplateExpanded: PropTypes.bool.isRequired,
  appId: PropTypes.string.isRequired,
  object: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onCollapseScreenTemplate: PropTypes.func.isRequired,
  onShowScreenTemplates: PropTypes.func.isRequired,
}

export default ScreenControl
