import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'

import 'styles/AddObject.css'
import {
    ensureTemplatesAreLoaded,
    loadComponent,
    loadTemplate,
    updateApp,
    updateDraft,
    loadDraft
} from "../../../ducks/apps/actions";

import { getApp } from "ducks/apps/selectors";

import {getCurrentUser} from "../../../ducks/users";
import {connect} from "react-redux";
import {setTool} from "../../../ducks/editor/tools";

import PanelBox from "./PanelBox";
import {DEFAULT_PER_PAGE, fetchTemplates} from 'utils/io'
import {resetObjects, resetZoom, setData, updateObject} from "../../../ducks/editor/objects";
import {pxToUnit} from "../../../utils/unit";


class PanelBoxWrapper extends Component {
  
  shouldComponentUpdate(nextProps) {
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.match.params.appId !== nextProps.match.params.appId
  }

  componentDidMount() {
    /*    let {requestLibraries} = this.props
        requestLibraries()*/
  }

  loadChildrenPaginated = async (node, page = 0, pageLimit = DEFAULT_PER_PAGE) => {

    let { loaded } = this.props
    const data = await fetchTemplates(node.id, {page: page, perPage: pageLimit})

    let children = [];
    if (data) {
      children = data.map((item) => (
        {
          id: item.id,
          name: `${item.name}`,
          description: '',
          children: [],
          counter: 0,
          type: item.value,
          size: `${pxToUnit(item.width)} x ${pxToUnit(item.height)} x ${pxToUnit(item.depth)} mm`,
          page: node.page || 0,
          thumb: item.thumb || "",
          expanded: false,
          selected: false,
        }
      ))
    }

    return children;
  }

  handleSelect = async (e, node, parent) => {

    if (!node) {
      return
    }
  
    const { match, updateDraft, loadDraft, resetZoom, resetObjects, loadTemplate, setData, app } = this.props
  
    try {

      // Загружаем шаблон
      const action = await loadTemplate(node.id)
      const { value: { data } } = action
      const { name, ...other } = data
  
      // Формируем изменения для приложения
      const changes = {
        ...other,
        name: app.name,
        id: app.id,
        template_id: data.id,
      };
  
      // Обновляем компоненты приложения с новыми размерами
      const components = Object.values(app.components).reduce((acc, obj) => {
        acc[obj.id] = {
          ...obj,
          width: changes.width,
          height: changes.height,
          depth: changes.depth,
        };
        return acc;
      }, {});
  
      // Обновляем черновик с новыми данными
      await updateDraft(app.id, { ...changes, components });
  
      // Загружаем черновик и обновляем данные приложения
      const { appId } = match.params
      const draft = await loadDraft(appId)
      const appData = draft.value.data

      setData(appData.id, appData.components, appData)
  
    } catch (error) {

      console.error('Error loading template or draft:', error);

    } finally {
      resetZoom();
    }
  }
  
  // изначальная функция
  // handleSelect = async (e, node, parent) => {


  //   if (!node) {
  //     return
  //   }

  //   const { match, updateDraft, loadDraft, resetZoom,  resetObjects, loadTemplate, setData, app } = this.props

  //   loadTemplate(node.id).then((action) => {


  //     const { value: {data} } = action
  //     const {name, ...other}  = data

  //     let changes = {
  //       ...other,
  //       name: app.name,
  //       id: app.id,
  //       template_id: data.id
  //     }

  //     let components = {}

  //     // let baseObject = {
  //     //   width: data.width,
  //     //   height: data.height,
  //     //   depth: data.depth,
  //     //   border_radius: data.border_radius,
  //     //   background_color: '@background',
  //     // }

  //     // resetObjects(
  //     //   {
  //     //     ...baseObject,
  //     //     name: data.name,
  //     //     type: 'COMPONENT',
  //     //     x: undefined,
  //     //     y: undefined,
  //     //   },
  //     //   null,
  //     //   true
  //     // )

  //     Object.values(app.components).forEach(obj => {
  //       components[obj.id] = {
  //         ...obj,
  //         width: changes.width,
  //         height: changes.height,
  //         depth: changes.depth,
  //       }
  //     })

  //     changes = {
  //       ...changes,
  //       components
  //     }

  //     updateDraft(app.id, changes)
      
      
  //     // setData(app.id, app.components, {...app, ...changes})


  //     let { appId } = match.params

  //     loadDraft(appId).then(({value}) => {
  //       const app = value.data
  //       setData(app.id, app.components, app)
  //     })


  //     //setData(app.id, app.components, app);


  //   })

  //   resetZoom()

  // }

  render() {

    const {
      match: {
        params: { appId },
      },
    } = this.props

    return (
      <PanelBox

        searchPlaceholder="Поиск кейса..."
        emptyMessage={
          <>
            Пусто

          </>
        }
        loadChildrenPaginated={this.loadChildrenPaginated}
        selectCallback={this.handleSelect}

      />
    )

  }

}

const mapStateToProps = (state, {appId}) => {

  const app = getApp(state, appId)
  const user = getCurrentUser(state)

  return {
    app,
    user,
  }
}

export default withRouter(connect(mapStateToProps, {
  setTool,
  updateObject,
  updateApp,
  updateDraft,
  loadDraft,
  resetZoom,
  resetObjects,
  loadComponent,
  loadTemplate,
  setData,
  ensureTemplatesAreLoaded
})(PanelBoxWrapper))
//export default withRouter(AddPanel)
