import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

class ModalContainer extends Component {
  render() {
    const { children, size } = this.props

    return (
      <div
        className={classnames('modal-container', {
          [`modal-width-${size}`]: size,
        })}
      >
        {children}
      </div>
    )
  }
}

ModalContainer.propTypes = {
  children: PropTypes.any.isRequired,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'fluid']),
}

ModalContainer.defaultProps = {
  size: 'fluid',
}

export default ModalContainer
