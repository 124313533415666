import {connect} from 'react-redux'

import {createObject} from '../../../ducks/editor/objects'
import CreateGenericRect, * as generic from './CreateGenericRect'
import 'styles/CreateImage.css'

class CreateImage extends CreateGenericRect {

  createObject(objectAttributes, rect) {

    console.log(6)

    if (rect && (rect.width > 0 || rect.height > 0)) {
      const { createObject } = this.props

      createObject({
        ...objectAttributes,
        ...rect,
      })
    }
  }
}

export default connect(generic.mapStateToProps, {
  ...generic.mapDispatchToProps,
  createObject,
})(CreateImage)
