import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Redirect, withRouter} from 'react-router-dom'
import QS from 'qs'

import {getAuthVisible} from '../../ducks/users'

class AuthRedirect extends Component {
  render() {
    let { authVisible } = this.props

    let alreadyAuthing = window.location.pathname.match(
      /^\/(login)|(signup)|(accept-invite)|(verify)|(forgot-password)|(recover-password)/
    )

    if (authVisible && !alreadyAuthing) {
      let query = QS.parse(window.location.search, { ignoreQueryPrefix: true })
      let { login_type: loginType, ...params } = query
      let queryString = QS.stringify(params, { addQueryPrefix: true })
      let redirect = `${window.location.pathname}${queryString}`

      if (!['login', 'signup'].includes(loginType)) {
        loginType = 'login'
      }

      let url = `/${loginType}?${QS.stringify({ redirect })}`

      return <Redirect to={url} />
    }

    return null
  }
}

const mapStateToProps = state => ({
  authVisible: getAuthVisible(state),
})

export default withRouter(connect(mapStateToProps)(AuthRedirect))
