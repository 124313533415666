

export const getBoundingBoxV2 = (objects) => {

  if (objects.length === 0) {
    return null
  }

  let x1 = Infinity, x2 = -Infinity, y1 = Infinity, y2 = -Infinity, depth = -Infinity

  objects.forEach((obj) => {
    if (obj.x < x1) x1 = obj.x;
    if (obj.x + obj.width > x2) x2 = obj.x + obj.width;
    if (obj.y < y1) y1 = obj.y;
    if (obj.y + obj.height > y2) y2 = obj.y + obj.height;
    if (obj.depth > depth) depth = obj.depth;
  })

  let width = x2 - x1
  let height = y2 - y1

  return {
    x:        x1      ===   Infinity  ? 0 : x1,
    y:        y1      ===   Infinity  ? 0 : y1,
    width:    width   ===   -Infinity ? 0 : width,
    height:   height  ===   -Infinity ? 0 : height,
    depth:    depth   ===   -Infinity ? 0 : depth,
  }
}






export const getBoundingBox = (objects) => {
  let x1 = Infinity,
    x2 = -Infinity,
    y1 = Infinity,
    y2 = -Infinity,
    depth = -Infinity;

  if (objects.length === 0) {
    return null;
  }

  objects.forEach(function (obj) {
    if (obj.x < x1) {
      x1 = obj.x;
    }

    if (obj.depth > depth) {
      depth = obj.depth;
    }

    if (obj.x + obj.width > x2) {
      x2 = obj.x + obj.width;
    }

    if (obj.y < y1) {
      y1 = obj.y;
    }

    if (obj.y + obj.height > y2) {
      y2 = obj.y + obj.height;
    }
  });

  let width = x2 - x1;
  let height = y2 - y1;

  if (x1 === Infinity) {
    x1 = 0;
  }

  if (y1 === Infinity) {
    y1 = 0;
  }

  if (width === -Infinity) {
    width = 0;
  }

  if (height === -Infinity) {
    height = 0;
  }
  if (depth === -Infinity) {
    depth = 0;
  }
  return {
    x: x1,
    y: y1,
    width: width,
    height: height,
    depth: depth
  };
};
