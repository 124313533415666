import Base from "./base";
import {ItemSelection, Numerical} from "./core";
import {Rectangle} from "./rectangle";
import {Formatter} from "./formatter";

export class Point extends Base {
  constructor(arg0, arg1) {

    super(...arguments)
    this._readIndex = true
  }

  initialize(arg0, arg1) {

    var type = typeof arg0,
      reading = this.__read,
      read = 0;
    if (type === 'number') {
      var hasY = typeof arg1 === 'number';
      this._set(arg0, hasY ? arg1 : arg0);
      if (reading)
        read = hasY ? 2 : 1;
    } else if (type === 'undefined' || arg0 === null) {
      this._set(0, 0);
      if (reading)
        read = arg0 === null ? 1 : 0;
    } else {
      var obj = type === 'string' ? arg0.split(/[\s,]+/) || [] : arg0;
      read = 1;
      if (Array.isArray(obj)) {
        this._set(+obj[0], +(obj.length > 1 ? obj[1] : obj[0]));
      } else if ('x' in obj) {
        this._set(obj.x || 0, obj.y || 0);
      } else if ('width' in obj) {
        this._set(obj.width || 0, obj.height || 0);
      } else if ('angle' in obj) {
        this._set(obj.length || 0, 0);
        this.angle(obj.angle || 0);
      } else {
        this._set(0, 0);
        read = 0;
      }
    }
    if (reading)
      this.__read = read;
    return this;
  }
  toString() {
    var f = Formatter.instance;
    return '{ x: ' + f.number(this.x) + ', y: ' + f.number(this.y) + ' }';
  }
  set(arg0, arg1) {

    this.initialize(arg0, arg1)
  }

  _set(x, y) {
    this.x = x;
    this.y = y;
    return this;
  }


  equals(point) {
    return this === point || point
      && (this.x === point.x && this.y === point.y
        || Array.isArray(point)
        && this.x === point[0] && this.y === point[1])
      || false;
  }

  clone() {
    return new Point(this.x, this.y);
  }

  getLength() {
    return this.length
  }

  get length() {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  }

  set length(length) {
    // Whenever chaining both x & y, use #set() instead of direct
    // assignment, so LinkedPoint does not report changes twice.
    if (this.isZero()) {
      var angle = this._angle || 0;
      this._set(
        Math.cos(angle) * length,
        Math.sin(angle) * length
      );
    } else {
      var scale = length / this.getLength();
      // Force calculation of angle now, so it will be preserved even when
      // x and y are 0
      if (Numerical.isZero(scale))
        this.angle();
      this._set(
        this.x * scale,
        this.y * scale
      );
    }
  }

  getAngle(/* point */) {
    return this.getAngleInRadians.apply(this, arguments) * 180 / Math.PI;
  }

  get angle(/* point */) {
    return this.getAngleInRadians.apply(this, arguments) * 180 / Math.PI;
  }

  set angle(angle) {
    this.setAngleInRadians.call(this, angle * Math.PI / 180);
  }


  getAngleInRadians(point) {
    if (!point) {
      return this.isZero()
        // Return the preserved angle in case the vector has no
        // length, and update the internal _angle in case the
        // vector has a length. See #setAngle() for more
        // explanations.
        ? this._angle || 0
        : this._angle = Math.atan2(this.y, this.x);
    } else {
      var div = this.getLength() * point.getLength();
      if (Numerical.isZero(div)) {
        return NaN;
      } else {
        var a = this.dot(point) / div;
        return Math.acos(a < -1 ? -1 : a > 1 ? 1 : a);
      }
    }
  }

  setAngleInRadians(angle) {
    // We store a reference to _angle internally so we still preserve it
    // when the vector's length is set to zero, and then anything else.
    // Note that we cannot rely on it if x and y are something else than 0,
    // since updating x / y does not automatically change _angle!
    this._angle = angle;
    if (!this.isZero()) {
      var length = this.getLength();
      // Use #set() instead of direct assignment of x/y, so LinkedPoint
      // does not report changes twice.
      this._set(
        Math.cos(angle) * length,
        Math.sin(angle) * length
      );
    }
  }


  getQuadrant() {
    return this.x >= 0 ? this.y >= 0 ? 1 : 4 : this.y >= 0 ? 2 : 3;
  }


  getDirectedAngle(point) {

    return Math.atan2(this.cross(point), this.dot(point)) * 180 / Math.PI;
  }


  getDistance(point, squared) {

    var x = point.x - this.x,
      y = point.y - this.y,
      d = x * x + y * y;
    return squared ? d : Math.sqrt(d);
  }


  normalize(length) {
    if (length === undefined)
      length = 1;
    var current = this.getLength(),
      scale = current !== 0 ? length / current : 0,
      point = new Point(this.x * scale, this.y * scale);
    // Preserve angle.
    if (scale >= 0)
      point._angle = this._angle;
    return point;
  }


  rotate(angle, center) {
    if (angle === 0)
      return this.clone();
    angle = angle * Math.PI / 180;
    var point = center ? this.subtract(center) : this,
      sin = Math.sin(angle),
      cos = Math.cos(angle);
    point = new Point(
      point.x * cos - point.y * sin,
      point.x * sin + point.y * cos
    );
    return center ? point.add(center) : point;
  }


  transform(matrix) {
    return matrix ? matrix._transformPoint(this) : this;
  }


  add(point) {
    var point = Point.read(arguments);
    return new Point(this.x + point.x, this.y + point.y);
  }


  subtract(point) {
    var point = Point.read(arguments);
    return new Point(this.x - point.x, this.y - point.y);
  }


  multiply(point) {
    var point = Point.read(arguments);
    return new Point(this.x * point.x, this.y * point.y);
  }

  divide(point) {
    var point = Point.read(arguments);
    return new Point(this.x / point.x, this.y / point.y);
  }


  modulo(point) {
    var point = Point.read(arguments);
    return new Point(this.x % point.x, this.y % point.y);
  }

  negate() {
    return new Point(-this.x, -this.y);
  }


  isInside(/* rect */) {
    return Rectangle.read(arguments).contains(this);
  }


  isClose(point, tolerance) {

    return this.getDistance(point) <= tolerance;
  }


  isCollinear(point) {

    return Point.isCollinear(this.x, this.y, point.x, point.y);
  }


  /**
   * Checks if the vector represented by this point is orthogonal
   * (perpendicular) to another vector.
   *
   * @param {Point} point the vector to check against
   * @return {Boolean} {@true it is orthogonal}
   */
  isOrthogonal(point) {

    return Point.isOrthogonal(this.x, this.y, point.x, point.y);
  }

  /**
   * Checks if this point has both the x and y coordinate set to 0.
   *
   * @return {Boolean} {@true if both x and y are 0}
   */
  isZero() {
    var isZero = Numerical.isZero;
    return isZero(this.x) && isZero(this.y);
  }

  /**
   * Checks if this point has an undefined value for at least one of its
   * coordinates.
   *
   * @return {Boolean} {@true if either x or y are not a number}
   */
  isNaN() {
    return isNaN(this.x) || isNaN(this.y);
  }

  /**
   * Checks if the vector is within the specified quadrant. Note that if the
   * vector lies on the boundary between two quadrants, `true` will be
   * returned for both quadrants.
   *
   * @param {Number} quadrant the quadrant to check against
   * @return {Boolean} {@true if either x or y are not a number}
   * @see #getQuadrant
   */
  isInQuadrant(q) {
    // Map quadrant to x & y coordinate pairs and multiply with coordinates,
    // then check sign:
    // 1: [ 1,  1]
    // 2: [-1,  1]
    // 3: [-1, -1]
    // 4: [ 1, -1]
    return this.x * (q > 1 && q < 4 ? -1 : 1) >= 0
      && this.y * (q > 2 ? -1 : 1) >= 0;
  }

  /**
   * {@grouptitle Vector Math Functions}
   * Returns the dot product of the point and another point.
   *
   * @param {Point} point
   * @return {Number} the dot product of the two points
   */
  dot(point) {

    return this.x * point.x + this.y * point.y;
  }

  /**
   * Returns the cross product of the point and another point.
   *
   * @param {Point} point
   * @return {Number} the cross product of the two points
   */
  cross(point) {

    return this.x * point.y - this.y * point.x;
  }

  /**
   * Returns the projection of the point onto another point.
   * Both points are interpreted as vectors.
   *
   * @param {Point} point
   * @return {Point} the projection of the point onto another point
   */
  project(point) {
    var scale = point.isZero() ? 0 : this.dot(point) / point.dot(point);
    return new Point(
      point.x * scale,
      point.y * scale
    );
  }


  static min(point1, point2) {

    return new Point(
      Math.min(point1.x, point2.x),
      Math.min(point1.y, point2.y)
    );
  }

  static max(point1, point2) {

    return new Point(
      Math.max(point1.x, point2.x),
      Math.max(point1.y, point2.y)
    );
  }


  /**
   * Returns a point object with random {@link #x} and {@link #y} values
   * between `0` and `1`.
   *
   * @return {Point} the newly created point object
   * @static
   *
   * @example
   * var maxPoint = new Point(100, 100);
   * var randomPoint = Point.random();
   *
   * // A point between {x:0, y:0} and {x:100, y:100}:
   * var point = maxPoint * randomPoint;
   */
  static random() {
    return new Point(Math.random(), Math.random());
  }


  static isCollinear(x1, y1, x2, y2) {
    // NOTE: We use normalized vectors so that the epsilon comparison is
    // reliable. We could instead scale the epsilon based on the vector
    // length. But instead of normalizing the vectors before calculating
    // the cross product, we can scale the epsilon accordingly.
    return Math.abs(x1 * y2 - y1 * x2)  <= Math.sqrt((x1 * x1 + y1 * y1) * (x2 * x2 + y2 * y2))
      * /*#=*/Numerical.TRIGONOMETRIC_EPSILON;
  }

  static isOrthogonal(x1, y1, x2, y2) {
    // See Point.isCollinear()
    return Math.abs(x1 * x2 + y1 * y2)
      <= Math.sqrt((x1 * x1 + y1 * y1) * (x2 * x2 + y2 * y2))
      * /*#=*/Numerical.TRIGONOMETRIC_EPSILON;
  }

  static round(x1, y1, x2, y2) {
    // See Point.isCollinear()
    var op = Math.round;
    return new Point(op(this.x), op(this.y))
  }

  static ceil(x1, y1, x2, y2) {
    // See Point.isCollinear()
    var op = Math.ceil;
    return new Point(op(this.x), op(this.y))
  }

  static floor(x1, y1, x2, y2) {
    // See Point.isCollinear()
    var op = Math.floor;
    return new Point(op(this.x), op(this.y))
  }

  static abs(x1, y1, x2, y2) {
    // See Point.isCollinear()
    var op = Math.abs;
    return new Point(op(this.x), op(this.y))
  }
}

export class LinkedPoint extends Point {
  // Have LinkedPoint appear as a normal Point in debugging
  constructor(x, y, owner, setter) {
    super(...arguments)
  }

  initialize(x, y, owner, setter) {


    this._x = x;
    this._y = y;
    this._owner = owner;
    this._setter = setter;
  }


  // See Point#_set() for an explanation of #_set():
  _set(x, y, _dontNotify) {
    this._x = x;
    this._y = y;
    if (!_dontNotify)
      this._owner[this._setter](this);
    return this;
  }

  get x() {
    return this._x;
  }

  set x(x) {
    this._x = x;
    this._owner[this._setter](this);
  }

  get y() {
    return this._y;
  }

  set y(y) {
    this._y = y;
    this._owner[this._setter](this);
  }

  isSelected() {
    return !!(this._owner._selection & this._getSelection());
  }

  setSelected(selected) {
    this._owner._changeSelection(this._getSelection(), selected);
  }

  _getSelection() {
    return this._setter === 'setPosition' ? /*#=*/ItemSelection.POSITION : 0;
  }
}
