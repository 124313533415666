import axios from 'axios'
import {apiURL} from '../../utils/io'


const FETCH_LIST_REQUEST = Symbol('FETCH_LIST_REQUEST')
const FETCH_LIST_SUCCESS = Symbol('FETCH_LIST_SUCCESS')
const FETCH_COMPONENT_SUCCESS = Symbol('FETCH_COMPONENT_SUCCESS')
const SEARCH_SUCCESS = Symbol('SEARCH_SUCCESS')
const FETCH_COMPONENTS_SUCCESS= Symbol('FETCH_COMPONENTS_SUCCESS')
const INITIAL_STATE = {
  libraries: [],
  byId: {},
  selected: null,
  loading: false,
}

export default (state = INITIAL_STATE, action) => {
  if (action.type === FETCH_LIST_REQUEST) {
    return {
      ...state,
      loading: true,
    }
  }

  if (action.type === FETCH_LIST_SUCCESS) {

    //let byId = buildIndex(action.result, itm => itm.id)

    return {
      ...state,
      loading: false,
      libraries: action.result,
      //byId: { ...state.byId, ...byId },
    }
  }
  if (action.type === FETCH_COMPONENT_SUCCESS) {
    let { result } = action

    return {
      ...state,
      selected: result.data
    }
  }
  if (action.type === FETCH_COMPONENTS_SUCCESS) {
    let { result } = action

    return {
      ...state,
      loading: true,
      byId: {
        ...state.byId,
        [result.id]: result.components,
      },
    }
  }

  return state
}

// Actions

export const requestLibraries = () => dispatch => {
  dispatch({ type: FETCH_LIST_REQUEST })

  axios
    .get(`${apiURL}/libraries`)
    .then(response => {
      dispatch({
        type: FETCH_LIST_SUCCESS,
        result: response.data,
      })
    })
    .catch(err => {
      console.error('ADDONS FETCH FAILED!', err)
    })
}

export const requestComponents = (libraryId, pagination = {}) => dispatch => {

  return axios
    .get(`${apiURL}/components`, {params: { page: pagination.page || 0, per_page: pagination.perPage || 60 , library_id : libraryId }})
    .then(response => {
      return dispatch({
        type: FETCH_COMPONENTS_SUCCESS,
        result: response.data,
      })
    })
    .catch(err => {
      console.error('GET COMPONENT FAILED!', err)
    })
}
export const requestComponent = componentId => dispatch => {
  axios
    .get(`${apiURL}/components/${componentId}`)
    .then(response => {
      dispatch({
        type: FETCH_COMPONENT_SUCCESS,
        result: response.data,
      })
    })
    .catch(err => {
      console.error('GET COMPONENT FAILED!', err)
    })
}

export const searchComponents = (term) => dispatch => {
  return axios
    .get(`${apiURL}/components?q=${term}`)
    .then(response => {
      return dispatch({
        type: SEARCH_SUCCESS,
        result: response.data,
      })
    })
    .catch(err => {
      console.error('GET COMPONENT FAILED!', err)
    })
}

// Selectors

export const getLibraries = state => {
  if (state.components.libraries.length === 0 && state.components.loading) {
    return []
  }

  return state.components.libraries
}

export const getComponents = (state, libraryId) => {
  if (!libraryId) {
    return []
  }

  return state.components.byId[libraryId]
}

export const getComponent = (state) => {
  return state.components.selected
}
