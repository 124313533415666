import React, {useEffect, useRef, useState} from 'react'
import {getRuleTransform} from "../../../utils/zoom";

const CanvasRuler = props => {

  const canvasRef = useRef(null)
  const value = props.value
  const isVertical = props.isVertical
  const zoom = props.zoom
  const [position, setPosition] = useState({x: 0, y: 0});

  let transform = getRuleTransform(zoom, true, props.x, props.y)

  const offsetX = 362
  const offsetY = 60

  const drawRuler = (ctx, width, height, offset, unit, scale,  x, y) => {
    const decimal = 2;
    let markersDistance = unit * (scale = scale || 1)
      , o = 1
      , divisors = 10;
    const round = Math.round
      , floor = Math.floor;
    if (markersDistance < 30) {
      o = (floor(30 / markersDistance / divisors) + 1) * divisors;
      let t = floor((markersDistance = unit * scale * o) / 30);
      t > 1 && (markersDistance = unit * scale * (o = (round(30 / markersDistance / (divisors = t > 5 ? 2 : 5)) + 1) * divisors))
    }
    let context = ctx.canvas.getContext("2d");

    context.setTransform(1, 0, 0, 1, 0, 0),
      context.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height),
      context.lineWidth = 1,
      context.strokeStyle = "#afafaf",
      context.fillStyle = "#000",
      context.font = "10px 'Source Sans Pro', sans-serif";
    const origin = (offset) - Math.ceil((offset    ) / markersDistance) * markersDistance    ;
    let f, h, m, g, p = origin, v = markersDistance / divisors;


    if (context.beginPath(), isVertical)
      for (g = height * scale - Math.abs(offset)  ,
             context.translate(24, 0),
             context.rotate(.5 * Math.PI); p < g;) {
        for (f = .5 + ~~p,
               context.moveTo(f, 0),
               context.lineTo(f, 18),
               context.fillText(round((p - offset) / markersDistance * o), f + 4, 18),
               m = 1; m < divisors; m++)
          h = ~~p + v * m + .5,
            context.moveTo(h, 0),
            m % 2 ? context.lineTo(h, 4) : context.lineTo(h, 6),
          v > 30 && context.fillText(((p - offset + v * m) / markersDistance * o).toFixed(decimal), h + 4, 9);
        p += markersDistance
      }
    else

      for (g = ((width )) * scale ; p < g;) {
        for (f = .5 + ~~p,
               context.moveTo(f, 24),
               context.lineTo(f, 6),
               context.fillText(round((p ) / markersDistance * o), f + 4, 12),
               m = 1; m < divisors; m++) {
          h = ~~p + v * m + .5
          context.moveTo(h, 24)
          m % 2 ? context.lineTo(h, 20) : context.lineTo(h, 18)
          v > 30 && context.fillText(((p  + v * m) / markersDistance * o).toFixed(decimal), h + 4, 9);
        }

        p += markersDistance
      }
    return context.stroke(),
      {
        origin: origin,
        markersDistance: markersDistance,
        divisors: divisors
      }
  }
  useEffect(() => {


    const canvas = canvasRef.current
    const context = canvas.getContext('2d')

    let animationFrameId



    //Our draw came here
    const render = () => {

      drawRuler(context, value, value, isVertical ? 0 :  0, 1 / 0.2645833333, zoom.scale ,props.x, props.y)
      //animationFrameId = window.requestAnimationFrame(render)
    }
    render()

    return () => {
      //window.cancelAnimationFrame(animationFrameId)
    }
  }, [drawRuler])


  useEffect(() => {

    const handleMouseMove = e => {
      setPosition({x: e.clientX - Math.abs(transform[0]), y: e.clientY - Math.abs(transform[1])})

    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return <div className={`canvas-ruler ${isVertical ? "vertical" : "horizontal"}`} style={{
    transform: `translate(${transform[0]}px, ${Math.max(isVertical ? transform[1] : transform[1] - 50, offsetY)}px)`,
    width: props.width *zoom.scale
  }}>
    <canvas ref={canvasRef} {...props} width={props.width*zoom.scale}/>
    {/*    <div className={'pointer-location'} style={isVertical ? {top: position.y} : {left: position.x}}/>*/}

  </div>
}

export default CanvasRuler
