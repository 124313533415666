import React, {Component} from 'react'
import {connect} from 'react-redux'

import {selectObject} from '../../../../ducks/editor/objects'
import CanvasObject from '../CanvasObject'

class ComponentPreview extends Component {
  renderObjects() {
    let { component, width, height } = this.props

    if (!component) {
      return null
    }

    let objects = component.children
    let scale = Math.min(width / component.width, height / component.height)
    let offsetX = (width - component.width * scale) / 2
    let offsetY = (height - component.height * scale) / 2
    let zoom = { scale, offset: [offsetX, offsetY] }

    return <ComponentPreviewSub objects={objects} zoom={zoom} />
  }

  render() {
    let { width, height } = this.props

    return (
      <svg width={width} height={height} className="component-preview">
        {this.renderObjects()}
      </svg>
    )
  }
}

export class ComponentPreviewSub extends Component {
  render() {
    let { objects, zoom } = this.props

    return (
      <g className="preview-objects">
        {objects.map(obj => (
          <CanvasObject
            key={obj.id}
            object={obj}
            zoom={zoom}
            transformZoom={zoom}
            hideShadows={false}
          />
        ))}
      </g>
    )
  }
}

const mapStateToProps = (state, { componentId }) => ({
  component: selectObject(state, componentId),
})

export default connect(mapStateToProps)(ComponentPreview)
