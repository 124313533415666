import { SET_DRAFT_INIT_TEMPLATE_SIZE } from './actions'

const initialState = {
    "sizes": "template",
    "name": "",
    "template_id": ""
}

const draftInitTemplateSizesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DRAFT_INIT_TEMPLATE_SIZE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

export default draftInitTemplateSizesReducer
