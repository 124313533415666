import React, { Component } from 'react';
import Page from 'components/Shared/Page';
import GridTable from '@nadavshaar/react-grid-table';
import Menu from './Menu';
import { apiURL } from '../../utils/io';
import './Admin.scss';
import moment from "moment";
import { getAuthToken } from '../../utils/auth'



class AdminUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          id: 'CreateAt',
          field: 'create_at',
          label: 'Дата регистрации',
          getValue: ({value, column}) => moment(value, 'x').format("DD.MM.YYYY HH:mm")
        },

        {
          id: 'Email',
          field: 'email',
          label: 'Эл. адрес',
        },

        {
          id: 'Phone',
          field: 'phone',
          label: 'Телефон',
        },

        {
          id: 'Nickname',
          field: 'nickname',
          label: 'Имя пользователя',
        },

        {
          id: 'export_in_dxf',
          field: 'export_in_dxf',
          label: 'Экспортировать в формат dxf',
          cellRenderer: ({ data }) => (
            <input
              type="checkbox"
              name="export_in_dxf"
              value={data.export_in_dxf}
              onChange={(e) => this.Change(e, data)}
              checked={data.export_in_dxf || false}
            />
          ),
          editable: true,
          width: "200px"
        },
      ],
      rows: [],
      totalRows: 0,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.All();
  }

  All = async () => {
    this.setState({ isLoading: true });

    try {

      let token = getAuthToken()

      const response = await fetch(`${apiURL}/users`, {
        method: 'GET',
        headers: {
            'Authorization': `BEARER ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        this.setState({
          rows: data,
          totalRows: data.length,
          isLoading: false,
        });
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Error fetching:', error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  Change = (e, data) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let row_update = null;

    this.setState(
      (prevState) => ({
        rows: prevState.rows.map((row) => {
          if (row.id === data.id) {
            row_update = { ...row, [name]: value };
            return row_update;
          }
          return row;
        }),
      }),
      async () => await this.Update(row_update)
    );
  };

  Update = async (data) => {

    try {
      
      let token = getAuthToken()

      const response = await fetch(`${apiURL}/users/${data.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `BEARER ${token}`
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Failed to update');
      }
    } catch (error) {
      console.error('Error updating:', error);
    }
  };

  render() {
    const { columns, rows, totalRows, isLoading } = this.state;

    return (
      <Page className="admin-users-page" title="Пользователи">

        <Menu />

        <section className="users-header">
          <h1>Пользователи</h1>
        </section>

        <div className="grid-content">
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <GridTable
              showSearch={true}
              showColumnVisibilityManager={true}
              columns={columns}
              rows={rows}
              totalRows={totalRows}
              texts={{
                search: 'Поиск:',
                totalRows: 'Общее кол-во:',
                rows: 'Показано:',
                selected: 'Выбрано',
                rowsPerPage: 'Элементов на странице:',
                page: 'Страница:',
                of: 'из',
                prev: 'Пред.',
                next: 'След.',
                columnVisibility: 'Колонки',
              }}
            />
          )}
        </div>
      </Page>
    );
  }
}

export default AdminUsers;













// import React, {Component} from 'react'
// import {Link} from 'react-router-dom'
// import {connect} from 'react-redux'

// import {getUsers, requestUsers} from 'ducks/users'

// import Page from 'components/Shared/Page'

// import './Admin.scss'
// import GridTable from "@nadavshaar/react-grid-table";
// import moment from "moment";
// import Menu from "./Menu";
// import { apiURL } from '../../utils/io'



// class AdminUsers extends Component {
//   constructor(props) {
//     super(props)

//     this.state = {
//       value: '',
//       columns: [

//         {
//           id: 'CreateAt',
//           field: 'create_at',
//           label: 'Дата регистрации',
//           getValue: ({value, column}) => moment(value, 'x').format("DD.MM.YYYY HH:mm")
//         },

//         {
//           id: 'Email',
//           field: 'email',
//           label: 'Эл. адрес',
//         },

//         {
//           id: 'Phone',
//           field: 'phone',
//           label: 'Телефон',
//         },

//         {
//           id: 'Nickname',
//           field: 'nickname',
//           label: 'Имя пользователя',
//         },

//         {
//           id: 'export_in_dxf',
//           field: 'export_in_dxf',
//           label: 'Экспортировать в формат dxf',
//           cellRenderer: ({ data }) => (
//             <input
//               type="checkbox"
//               name="export_in_dxf"
//               value={data.export_in_dxf}
//               onChange={(e) => this.handleChange(e, data)}
//               checked={data.export_in_dxf || false}
//             />
//           ),
//           editable: true,
//           width: "200px"
//         },

//       ]
//     }

//     //this.handleQuery = debounce(this.handleQuery, 200)
//   }


//   handleChange = (e, data) => {

//     e.preventDefault()

//     const target = e.target
//     const type = target.type
//     let value = type === 'checkbox' ? target.checked : target.value
//     const name = target.name
    
//     let row_update = null

//     this.setState(prevState => ({

//         users: prevState.users.map(row => {

//           if(row.id === data.id){

//               if(type === 'number'){
//                   value = parseFloat(value)
//               }

//               row_update = { ...row, [name]: value }

//               return row_update
//           }

//           return row
//         })

//     }), async () => await this.Update(row_update))

//   }

//   Update = async (data) => {

//     try {

//       const response = await fetch(`${apiURL}/components`, {
//           method: 'PUT',
//           headers: {
//               'Content-Type': 'application/json'
//           },
//           body: JSON.stringify(data)
//       })

//       if (!response.ok) {
//           throw new Error('Failed to update');
//       }

//     } catch (error) {
//         console.error('Error updating:', error);
//     }

//   }


//   // handleChange = e => {
//   //   e.preventDefault()
//   //   this.setState({ value: e.target.value })
//   // }

//   handleQuery = () => {
//     const { requestUsers } = this.props
//     const { value } = this.state

//     if (!value) return requestUsers()

//     return requestUsers({ query: value })
//   }

//   onRowsRequest = async (requestData, tableManager) => {

//     const { requestUsers } = this.props
//     const { value } = this.state

//     const response = await requestUsers({
//       page: tableManager.paginationApi.page - 1,
//       per_page: tableManager.paginationApi.pageSize,
//       sort: requestData.sort.colId,
//       is_asc: requestData.sort.isAsc,
//       term: requestData.searchText,
//     }).then(({value}) => {
//       return value
//     })

//     if(!response?.data) return;

//     return {
//       rows: response.data.items,
//       totalRows: response.data.total_count
//     };
//   }


//   render() {
    
//     let { users } = this.props
//     const { value, columns } = this.state

//     return (
//       <Page className="admin-users-page" title="Users">

//         <Menu/>
//         <section className="users-header">
//           <h1>Пользователи</h1>
//         </section>
//         <div className="grid-content">
//           <GridTable
//               showSearch={true}
//               showColumnVisibilityManager ={true}
//               texts ={{ search: 'Поиск:', totalRows: 'Общее кол-во:', rows: 'Показано:', selected: 'Выбрано', rowsPerPage: 'Элементов на странице:', page: 'Страница:', of: 'из', prev: 'Пред.', next: 'След.', columnVisibility: 'Колонки' }}

//               columns={columns}
//               onRowsRequest={this.onRowsRequest}
//           />
//         </div>

//       </Page>
//     )
//   }
// }

// const mapStateToProps = state => ({
//   users: getUsers(state),
// })

// export default connect(mapStateToProps, { requestUsers })(AdminUsers)