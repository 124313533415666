import {COMPONENT, IMAGE, LABEL} from 'common/constants'

import {deepMap, getBoundingBox, getInsertPath, getObject, pathLength, subPath,} from 'common/utils'

import {getAbsoluteBbox, getInsertPosition, getPasteParent, toRelativeCoords, translate,} from '../../utils/geometry'

//import { uploadImage } from '../../utils/io'
import {unScaleRect} from '../../utils/zoom'
import {resetLibraryBindingIds} from '../../utils/libraries'

import {changeChildIds} from '../../utils/copying'
import {sortSelection} from '../../utils/selection'

import {performCreate, performDelete} from './objects'

const CUT = Symbol('CUT')
const COPY = Symbol('COPY')
export const PASTE = Symbol('PASTE')

const MIMETYPE = 'application/x-proton-data'

export default (state, action) => {

  if (action.type === COPY || action.type === CUT) {

    let { evt } = action
    let { selection, map, list } = state

    selection = sortSelection(selection, list, map)

    // Prevent default behavior
    evt.preventDefault()

    // If there are components being copied don't copy anything else
    let components = selection.filter(id => pathLength(map[id]) === 1)

    if (components.length > 0) {
      selection = components
    }

    if (selection.length === 0) {
      return
    }

    let data = selection.map(id => getObject(list, map[id]))

    let parentIds = []

    selection.forEach(id => {
      
      let path = state.map[id]
      let parentPath = subPath(path, 1)
      let parentId = getObject(state.list, parentPath).id

      if (!parentIds.includes(parentId)) {
        parentIds.push(parentId)
      }

    })

    let parents = parentIds.map(id => getObject(state.list, state.map[id]))
    let bbox = getBoundingBox(parents)

    data = deepMap(
      data,
      object => ({
        ...object,
        ...translate(getAbsoluteBbox(object, list, map), -bbox.x, -bbox.y),
      }),
      obj => {
        return obj.type !== COMPONENT
      }
    )

    evt.preventDefault()
    evt.clipboardData.setData(MIMETYPE, JSON.stringify(data))

    if (action.type === CUT) {
      return performDelete(state, selection)
    }
  }

  if (action.type === PASTE) {


    let { evt, app } = action
    let { list, map, zoom } = state

    let objects = null

    if (action.objects) {
      objects = action.objects
    } else {
      let data = evt.clipboardData.getData(MIMETYPE)

      try {
        objects = JSON.parse(data)
      } catch (err) {
        console.error('Attempted to paste invalid object')
      }
    }

    if (!objects || objects.length === 0) {
      return state
    }

    let bbox = getBoundingBox(objects)

    let screenBbox = unScaleRect(
      {
        x: 220, // Width of left sidebar
        y: 60, // Height of top bar
        width: window.innerWidth - 220,
        height: window.innerHeight - 60,
      },
      zoom
    )

    let isComponent = false

    objects.forEach(obj => {
      if (obj.type === COMPONENT) {
        isComponent = true
      }
    })

    let parentId = state.activeComponent || getPasteParent(bbox, screenBbox, list, map)
    if (!parentId && !isComponent) {
      return state
    }

    let newObjects = objects

    // Relative coords centers the objects in the target screen + window
    // Disabling because this is not producing the desired result.
    if (parentId) {
      newObjects = toRelativeCoords(objects, parentId, screenBbox, list, map)
    }

    // Reset IDs
    newObjects = changeChildIds({ children: newObjects }).children

    // Reset binding IDs on libraryComponents
    newObjects = deepMap(newObjects, obj => resetLibraryBindingIds(obj))

    let insertPath = getInsertPath(list, null, map[parentId])

    if (state.selection.length > 0) {
      let path = map[state.selection[0]]

      if (pathLength(path) > 1) {
        insertPath = getInsertPath(list, null, path, false)
      }
    }

    let insertPosition = getInsertPosition(list)
    let offsetX = insertPosition.x - bbox.x
    let offsetY = insertPosition.y - bbox.y

    newObjects.forEach(obj => {
      if (obj.type === COMPONENT) {
        isComponent = true
        obj.x += offsetX
        obj.y += offsetY
      }
    })

    let newState = performCreate(
      state,
      insertPath,
      newObjects,
      null,
      isComponent ? null : parentId,
      !isComponent,
      true,
      true,
      false
    )[0]

    list = newState.list
    map = newState.map
    let typeIndex = newState.typeIndex
    let selection = newState.selection
    let activeComponent = newState.activeComponent

    return {
      ...state,
      list,
      map,
      selection,
      typeIndex,
      activeComponent,
      textEditing: false,
      shapeEditing: false,
      hoverSelection: [],
    }
  }
}

export const copy = evt => ({
  type: COPY,
  evt,
})

export const cut = evt => ({
  type: CUT,
  evt,
})

export const paste = (evt, app) => dispatch => {
  let data = evt.clipboardData.getData(MIMETYPE)

  if (data) {
    return dispatch({ type: PASTE, evt, app })
  }

  let items = Array.from(evt.clipboardData.items)

  // Check for image data
  let imageItem = items.filter(itm =>
    itm.type.match(/^image\/(png|jpg|jpeg|gif)/)
  )[0]

  if (imageItem) {
    let file = imageItem.getAsFile()
    let filename = file.name
    let reader = new FileReader()

    reader.onload = async () => {
      let dataURL = reader.result
      let file = dataURL.split(',')[1]
      let url = ""// await uploadImage(app, file, filename)

      let img = new Image()

      img.onload = () => {
        let { width, height } = img

        let object = {
          x: -50000,
          y: -50000,
          width,
          height,
          type: IMAGE,
          name: file.name,
          filename1x: url,
        }

        dispatch({
          type: PASTE,
          app,
          objects: [object],
        })
      }

      img.src = dataURL
    }

    reader.readAsDataURL(file)
  } else {
    // Check for text data
    let text = evt.clipboardData.getData('text/plain')

    if (text) {
      let object = {
        type: LABEL,
        text,
      }

      dispatch({
        type: PASTE,
        app,
        objects: [object],
      })
    }
  }
}
