
// actionTypes.js
export const UPDATE_APP_PARTS           = 'UPDATE_APP_PARTS';
export const UPDATE_APP_OUTER_RULE      = 'UPDATE_APP_OUTER_RULE';
export const UPDATE_APP_NAME            = 'UPDATE_APP_NAME';
export const UPDATE_APP_WIDTH           = 'UPDATE_APP_WIDTH';
export const UPDATE_APP_HEIGHT          = 'UPDATE_APP_HEIGHT';
export const UPDATE_APP_DEPTH           = 'UPDATE_APP_DEPTH';
export const UPDATE_APP_LOWER_WIDTH     = 'UPDATE_APP_LOWER_WIDTH';
export const UPDATE_APP_LOWER_HEIGHT    = 'UPDATE_APP_LOWER_HEIGHT';
export const UPDATE_APP_BORDER_WIDTH    = 'UPDATE_APP_BORDER_WIDTH';
export const UPDATE_APP_BORDER_RADIUS   = 'UPDATE_APP_BORDER_RADIUS';

export const UPDATE_COMPONENT_POSITION  = 'UPDATE_COMPONENT_POSITION';
export const UPDATE_COMPONENT_NAME      = 'UPDATE_COMPONENT_NAME';
export const UPDATE_COMPONENT_WIDTH     = 'UPDATE_COMPONENT_WIDTH';
export const UPDATE_COMPONENT_HEIGHT    = 'UPDATE_COMPONENT_HEIGHT';
export const UPDATE_COMPONENT_DEPTH     = 'UPDATE_COMPONENT_DEPTH';
export const INITIALIZE_STATE           = 'INITIALIZE_STATE';



export const CREATE_DRAFT           = 'CREATE_DRAFT';
export const DELETE_DRAFT           = 'DELETE_DRAFT';
export const LOAD_DRAFTS            = 'LOAD_DRAFTS';
export const LOAD_ALL_DRAFTS        = 'LOAD_ALL_DRAFTS';
export const LOAD_DRAFT             = 'LOAD_DRAFT';
export const UPDATE_DRAFT           = 'UPDATE_DRAFT';
export const PATCH_DRAFT            = 'PATCH_DRAFT';
export const CHECKOUT_DRAFT         = 'CHECKOUT_DRAFT';
export const COPY_DRAFT             = 'COPY_DRAFT';
export const LOAD_TEMPLATES_LIST    = 'LOAD_TEMPLATES_LIST';
export const LOAD_TEMPLATE          = 'LOAD_TEMPLATE';
export const SET_DRAFT              = 'SET_DRAFT';
export const LOAD_APP               = 'LOAD_APP';
export const DELETE_APP             = 'DELETE_APP';
export const SET_APP_LIBRARIES      = 'SET_APP_LIBRARIES';
export const LOAD_COMPONENT         = 'LOAD_COMPONENT';
export const LOAD_APPS_LIST         = 'LOAD_APPS_LIST';
export const UNSET_LAUNCH_COMPONENT = 'UNSET_LAUNCH_COMPONENT';
export const REQUEST_APP            = 'REQUEST_APP';
export const REQUEST_ALL            = 'REQUEST_ALL';
export const UPDATE_APP             = 'UPDATE_APP';
export const SET_LOADING_TEMPLATES  = 'SET_LOADING_TEMPLATES';
export const CREATE_INVITE          = 'CREATE_INVITE';
