import React, {Component} from 'react'
import classNames from 'classnames'

export default class Point extends Component {
  handleMouseDown = e => {
    let { onSelect } = this.props

    e.stopPropagation()
    onSelect()
  }

  renderControlPoint = (point, controlKey) => {
    let { scalePoint, setDraggingInControl, setDraggingOutControl } = this.props

    if (!point || !point[controlKey]) {
      return null
    }

    let startPoint = scalePoint(point.point)
    let endPoint = scalePoint([point[controlKey][0] + point.point[0], point[controlKey][1] + point.point[1]])

    let action =
      controlKey === 'handleIn' ? setDraggingInControl : setDraggingOutControl

    return (
      <g>
        <line
          className="shape-editor-line shape-editor-line-control-point"
          x1={startPoint[0]}
          y1={startPoint[1]}
          x2={endPoint[0]}
          y2={endPoint[1]}
        />
        <PointControl point={endPoint} onMouseDown={action} />
      </g>
    )
  }

  render() {
    let { point, isClosePoint, selected, scalePoint } = this.props


    return (
      <g className="shape-editor-point-wrapper ">
        {selected ? (
          <React.Fragment>
            <g className={'control-wrapper'}>
              {this.renderControlPoint(point, 'handleIn')}
              {this.renderControlPoint(point, 'handleOut')}
            </g>
          </React.Fragment>
        ) : null}
        <PointControl
          point={scalePoint(point.point)}
          selected={selected}
          isClosePoint={isClosePoint}
          onMouseDown={this.handleMouseDown}
        />
      </g>
    )
  }
}

class PointControl extends Component {
  handleMouseDown = e => {
    let { onMouseDown } = this.props

    e.preventDefault()
    e.stopPropagation()

    if (onMouseDown) {
      onMouseDown(e)
    }
  }

  render() {
    let { point, selected, isClosePoint } = this.props

    return (
      <g onMouseDown={this.handleMouseDown}>
        <ellipse
          className={classNames('shape-editor-point', { selected })}
          cx={point[0]}
          cy={point[1]}
          rx={5}
          ry={5}
        />
        <ellipse
          className={classNames('shape-editor-point-target', {
            'cursor-pen-close-path': isClosePoint,
          })}
          cx={point[0]}
          cy={point[1]}
          rx={8}
          ry={8}
        />
      </g>
    )
  }
}
