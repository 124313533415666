import React, {Component} from 'react'
import classNames from 'classnames'
import {connect} from 'react-redux'
import {dataTypes, formatterOptions} from 'common/constants'
import {MultiMenuTrigger} from 'react-multi-menu'

import {buildFormula} from '../../../utils/formulas'

import {getComponent, getCurrentAppId, selectObject,} from '../../../ducks/editor/objects'

import EntityTextarea from '../Forms/EntityTextarea'
import {IconButton} from '../Icon'
import BindingEntity from './BindingEntity'
import SymbolEntity from './SymbolEntity'
import 'styles/FormulaControl.css'

class FormulaControl extends Component {
  static defaultProps = {
    dataType: dataTypes.NUMBER,
  }

  handleChange = newValue => {
    let { name, onChange, dataType } = this.props

    let transformedValue = buildFormula(newValue, dataType)

    onChange({ [name]: transformedValue })
  }

  renderEntity = (entity, { onDelete, onUpdate }) => {
    let { appId, componentId, objectId, role, reference } = this.props
    reference = role === 'listItem' ? reference : null

    if (!entity) {
      return null
    }

    if (entity.type === 'symbol' || entity.type === 'expression') {
      return <SymbolEntity entity={entity} />
    }

    return (
      <BindingEntity
        hideFormatting
        entity={entity}
        appId={appId}
        componentId={componentId}
        objectId={objectId}
        reference={reference}
        onDelete={onDelete}
        onUpdate={onUpdate}
      />
    )
  }

  handleAddEntity = binding => {
    if (binding.type === 'expression') {
      return this.textarea.addEntity(binding)
      // return this.textarea.addChild(math.expression('round()'))
    }

    let { dataType } = binding.source
    let options = formatterOptions[dataType] || []
    let option = options[0]
    let format = option ? { type: option.value } : undefined

    this.textarea.addEntity({
      ...binding,
      format,
    })
  }

  textareaRef = textarea => {
    this.textarea = textarea
  }

  handleMouseDown = e => {
    e.preventDefault()
  }

  getOptions = () => () => {

    let options = []

    if (options.length > 0) {
      options.push(null)
    }

    options.push({
      label: 'Functions',
      children: [
        { label: 'ROUND', value: { type: 'expression', symbol: 'ROUND(' } },
        { label: 'INT', value: { type: 'expression', symbol: 'INT(' } },
        { label: 'ABS', value: { type: 'expression', symbol: 'ABS(' } },
        { label: 'SQRT', value: { type: 'expression', symbol: 'SQRT(' } },
        {
          label: 'EXP(base, exp)',
          value: { type: 'expression', symbol: 'EXP(' },
        },
        { label: 'RAND(0, 1)', value: { type: 'expression', symbol: 'RAND(' } },
        { label: 'LOG', value: { type: 'expression', symbol: 'LOG(' } },
      ],
    })

    return options
  }

  render() {
    let {
      contained,
      displayName,
      placeholder,
      // bindingOptions,
      value,
    } = this.props

    let valid = true

    if (value && value.type === 'formula') {
      valid = value.valid
      value = value.formula
    }

    if (typeof value === 'number') {
      value = [String(value)]
    }

    value = value || []

    if (!Array.isArray(value)) {
      value = ['', value, '']
    }

    return (
      <div
        className={classNames('bindable-text-control', 'formula-control', {
          'formula-control-contained': contained,
          'formula-control-invalid': !valid,
        })}
      >
        <div className="bindable-text-control-header">
          <p className="bindable-text-control-label">{displayName}</p>
          <div className="bindable-text-control-trigger-wrapper">
            <MultiMenuTrigger
              menu={this.getOptions()}
              onSelect={this.handleAddEntity}
              className="bindable-text-control-trigger"
              menuClassName="bindable-text-control-menu"
              rowHeight={40}
              width={260}
            >
              <IconButton
                orange
                type="magic-number"
                onMouseDown={this.handleMouseDown}
                className="bindable-text-control-trigger-icon"
              />
            </MultiMenuTrigger>
          </div>
        </div>
        <EntityTextarea
          value={value}
          onChange={this.handleChange}
          placeholder={placeholder}
          renderEntity={this.renderEntity}
          childRef={this.textareaRef}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  let { objectId, role, reference, actionId } = props
  let appId = getCurrentAppId(state)
  let componentId = getComponent(state, objectId).id
  let allowedDataTypes = [dataTypes.NUMBER, dataTypes.DATE, dataTypes.DATE_ONLY]

  const object = selectObject(state, objectId)

  return {
    appId,
    componentId,
    objectId,
  }
}

export default connect(mapStateToProps)(FormulaControl)
