import React, {Component} from 'react'
import axios from 'axios'
import {withRouter} from 'react-router-dom'

import {getAuthToken} from 'utils/auth'
import {template} from 'utils/templating'
import 'styles/SettingsPanel.css'

const DEFAULT_HEIGHT = 120

class SettingsPanel extends Component {
  static defaultProps = {
    height: DEFAULT_HEIGHT,
  }

  state = {
    libraryToken: null,
  }

  getURL = () => {
    let { match, settingsURL, object } = this.props
    let { libraryToken } = this.state
    let { appId } = match.params
    let objectId = object && object.id

    let url = template(settingsURL, { appId, objectId })

    let connector = url.match(/\?/) ? '&' : '?'

    return `${url}${connector}authToken=${libraryToken}`
  }

  loadToken = async () => {
    let { library, match } = this.props
    let { appId } = match.params
    let libraryName = window.encodeURIComponent(library)
    let baseURL = process.env.REACT_APP_BACKEND_URL

    let result = await axios.get(
      `${baseURL}/library-tokens/${appId}?library=${libraryName}`,
      { headers: { 'x-proton-auth': getAuthToken() } }
    )

    let { token } = result.data

    this.setState({ libraryToken: token })
  }

  componentDidMount() {
    this.loadToken()
  }

  render() {
    let { height } = this.props
    let { libraryToken } = this.state
    let url = this.getURL()



    return (
      <div className="library-inspect-settings-panel">
        <p>Settings</p>
        <div className="library-inspect-settings-panel-inner">
          {libraryToken ? (
            <iframe
              src={url}
              height={height}
              className="library-inspect-settings-panel-frame"
              title="Component Settings"
            />
          ) : (
            <div style={{ height }} />
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(SettingsPanel)
