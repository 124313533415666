import React, {Component} from 'react'
import {COMPONENT} from 'common/constants'
import classnames from 'classnames'

import {scale, scaleValue} from 'utils/zoom'

export default class Box extends Component {
  render() {
    let { object, zoom, yOffset, isHover } = this.props

    if (!object) {
      return null
    }

    if (object.type === COMPONENT && yOffset) {
      object = {
        ...object,
        y: object.y + yOffset,
        height: object.height - yOffset,
      }
    }

    let [xScaled, yScaled] = scale([object.x, object.y], zoom)
    let widthScaled = scaleValue(object.width, zoom) || 0
    let heightScaled = scaleValue(object.height, zoom) || 0

    let left = Math.round(xScaled + 0.5) - 0.5
    let top = Math.round(yScaled + 0.5) - 0.5
    let right = Math.round(xScaled + widthScaled + 0.5) - 0.5
    let bottom = Math.round(yScaled + heightScaled + 0.5) - 0.5

    if (isHover && object.type === COMPONENT) {
      left = left - 3.5
      top = top - 3.5
      right = right + 3.5
      bottom = bottom + 3.5
    }

    let width = right - left
    let height = bottom - top
    let { isNaN } = Number

    if (isNaN(left) || isNaN(top) || isNaN(width) || isNaN(height)) {
      return null
    }

    const transform = /*object.angle ? `translate(${left}, ${top}) rotate(${object.angle} ${widthScaled / 2} ${heightScaled / 2})` :*/ `translate(${left}, ${top})`


    return (
      <g className="hover-selection" transform={transform}>
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          className={classnames('hover-selection-box', {
            thicc: isHover && object.type === COMPONENT,
          })}
        />
      </g>
    )
  }
}
