import React, {Component} from 'react'
import PropTypes from 'prop-types'
import MultiMenu from 'react-multi-menu'
import {traverse} from 'common/utils'
import classNames from 'classnames'

import 'react-multi-menu/styles.css'
import './WrappedSelect.scss'
import 'styles/InlineForm.css'

export default class WrappedSelect extends Component {
  handleChange = newValue => {
    let { onChangeValue, input } = this.props
    let { onChange, value } = input || this.props

    if (value === newValue) {
      return
    }

    onChange(newValue)

    if (onChangeValue) {
      onChangeValue(newValue)
    }
  }

  setValue = props => {
    let { autoSelect, options, comparator, input } = props

    let { value, onChange } = input || this.props

    if (!autoSelect) {
      return
    }

    comparator = comparator || ((a, b) => a === b)

    if (typeof options === 'function') {
      options = options()
    }

    let currentOption

    traverse(options, opt => {
      if (opt && comparator(opt.value, value)) {
        currentOption = opt
      }
    })

    if (options.length > 0 && !currentOption) {
      if (options[0] === null) {
        onChange(null)
      } else {
        onChange(options[0].value)
      }
    } else if (options.length === 0 && value) {
      onChange(null)
    }
  }

  shouldComponentUpdate(nextProps) {
    this.setValue(nextProps)

    return true
  }

  componentDidMount() {
    this.setValue(this.props)
  }

  render() {
    let { className, disabled, boxed, input, rowHeight, meta } = this.props
    let { value, ...selectProps } = input || this.props
    let { touched, error } = meta || {}

    return (
      <>
        <MultiMenu
          {...this.props}
          {...selectProps}
          value={value}
          onChange={this.handleChange}
          menuClassName={className}
          className={classNames('wrapped-select', className, {
            disabled,
            boxed,
            'wrapped-select-error': touched && error,
          })}
          rowHeight={rowHeight}
        />
        {touched && error ? (
          <div className="wrapped-select-error-message">{error}</div>
        ) : null}
      </>
    )
  }
}

WrappedSelect.propTypes = {
  rowHeight: PropTypes.number,
}

WrappedSelect.defaultProps = {
  rowHeight: 32,
}
