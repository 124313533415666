import React, {Component} from 'react'
import {connect} from 'react-redux'
import DocumentEvents from 'react-document-events'
import {scale, unScale} from '../../../../utils/zoom'
import {ESC} from '../../../../utils/keyboard'
import {PathItem, Point as PathPoint} from '../../../../utils/vector'
import {
    createObject,
    getComponentId,
    getCurrentAppId,
    selectObject,
    selectObjects,
    updateObject,
} from '../../../../ducks/editor/objects'

import {
    endDrag,
    getDraggingInControl,
    getDraggingOutControl,
    getDraggingSelectedPoint,
    getEditingPath,
    getEditingShape,
    getSelectedPoint,
    setDraggingInControl,
    setDraggingOutControl,
    setDraggingSelectedPoint,
    setEditingPath,
    setEditingShape,
    setSelectedPoint
} from '../../../../ducks/editor/shapeEditing'

import {resetTool} from '../../../../ducks/editor/tools'
import 'styles/ShapeEditor.css'
import {CompoundPath} from "../../../../utils/vector/classes";
import PathEditor from "../PathEditor";
import { getApp } from "ducks/apps/selectors";


class CompoundShapeEditor extends Component {

  constructor(props) {
    super(props)

    this.state = {
      lastCursorPosition: null,
      hitPoint: {x: 0, y: 0},
    }

    this.path = PathItem.create()

    let {object, zoom, updateObject} = props


    if (object) {

      const {points, compound, isClosed} = object

      this.path = PathItem.create()

      if (compound && compound.length > 0) {
        this.path = PathItem.create(compound)
      } else if (points && points.length > 0) {
        this.path = PathItem.create(points)
      }

      const bounds = this.path.bounds
      this.path.setClosed(isClosed)
      const newObj = {
        ...object,
        width: bounds.width,
        height: bounds.height,
     /*   x: bounds.left,
        y: bounds.top*/
      }

      updateObject(object.id, newObj, 'zoom')

      props.setEditingPath(0)
    }

  }

  handleKeyDown = e => {

    let { setEditingShape, resetTool } = this.props

    if (e.which === ESC) {
      setEditingShape(null); resetTool()
    }

  }


  handleMouseMove = e => {

    let lastCursorPosition = this.getRealPoint([e.clientX, e.clientY])
    const p = new PathPoint(...lastCursorPosition);

    let hit = this.path.hitTest(p)

    if (hit && (hit.type === 'curve' || hit.type === 'segment')) {
      this.setState({
        lastCursorPosition,
        selectedIndex: hit.item.index
      })
      return
    }

    this.setState({
      lastCursorPosition,
      selectedIndex: null
    })

  }


  handleDoubleClick = (e) => {

    const {selectedIndex} = this.state
    const {setEditingPath} = this.props

    setEditingPath(selectedIndex);


    //e.stopPropagation()

  }

  convertPoint = point => {
    let {zoom, component, object} = this.props

    if (!point) {
      return point
    }

    let [x, y] = point
    let absolutePoint = point

    if (component) {
      absolutePoint = [x + component.x, y + component.y]
    }

    return scale(absolutePoint, zoom)
  }
  getRealPoint = point => {
    let {object, component, zoom} = this.props

    let [x, y] = unScale(point, zoom)

    if (component) {
      x = x - component.x
      y = y - component.y
    }

    x = Math.round(x)
    y = Math.round(y)

    /*if (object) {
      x = (x - object.x) / object.width
      y = (y - object.y) / object.height
    }*/

    return [x, y]
  }

  render() {
    let {lastCursorPosition, selectedIndex} = this.state

    let {
      selectedPoint,
      object,
      setDraggingInControl,
      setDraggingOutControl,
      zoom,
      editingPath
    } = this.props



    let cursorClass = null

    let path = ''
    let renderPath = this.path
    if (object) {

      const {points, compound, isClosed} = object

      if (compound && compound.length > 0) {
        this.path = PathItem.create(compound)
      } else if (points && points.length > 0) {
        this.path.setSegments(points)
      }

      this.path.setClosed(isClosed)
      renderPath = this.path.clone()
      renderPath.scale(zoom.scale)
      const point = this.convertPoint([object.x, object.y])
      renderPath.adjust(...point)

    }

    let content = null

    if (renderPath && renderPath instanceof CompoundPath) {
      content = (
        <g
          key={'compound-editor'}
          onMouseMove={this.handleMouseMove}
          className={cursorClass}
          onDoubleClick={this.handleDoubleClick}
          onTouchStart={this.handleMouseDown}
          pointerEvents="all"
        >
          <DocumentEvents onKeyDown={this.handleKeyDown}/>
          <rect
            x={0}
            y={0}
            width={window.innerWidth}
            height={window.innerHeight}
            style={{fill: 'none'}}
          />

         {/* {renderPath && renderPath instanceof CompoundPath && (
            editingPath != null ? <PathEditor zoom={zoom}/> : renderPath.children.map((path, index) => (
              <path id="editorLine"
                    className={`shape-editor-line ${selectedIndex === index ? "shape-editor-line-selected" : ""}`}
                    d={path.getPathData()}/>))
          )}*/}


          {renderPath.children.map((path, index) => {
            if (editingPath === index) {
              return <PathEditor key={`path-editor-${index}`} zoom={zoom}/>
            } else
              return <path key={`path-${index}`} id="editorLine"
                           className={`shape-editor-line ${selectedIndex === index ? "shape-editor-line-selected" : ""}`}
                           d={path.getPathData()}/>
          })}

        </g>)
    } else {
      content = (<PathEditor  key={`path-editor`} zoom={zoom}/>)
    }

    return (
      <g key={`wrapper-editor`}>
        {content}
      </g>

    )
  }
}

const mapStateToProps = state => ({
  app: getApp(state, getCurrentAppId(state)),
  screens: selectObjects(state),
  object: selectObject(state, getEditingShape(state)),
  editingPath: getEditingPath(state),
  component: selectObject(state, getComponentId(state, getEditingShape(state))),
  draggingOutControl: getDraggingOutControl(state),
  draggingInControl: getDraggingInControl(state),
  selectedPoint: getSelectedPoint(state),
  draggingSelectedPoint: getDraggingSelectedPoint(state),
})

export default connect(mapStateToProps, {
  resetTool,
  createObject,
  updateObject,
  setEditingShape,
  setEditingPath,
  setDraggingOutControl,
  setDraggingInControl,
  endDrag,
  setSelectedPoint,
  setDraggingSelectedPoint,
})(CompoundShapeEditor)
