import axios from "axios";
import {apiURL} from "./io";
import {getMe, setCurrentUserToken} from "../ducks/users";

let reduxStore = null

export const unsafeGetStore = () => reduxStore


export const connectApp = async store => {

  let token = getAuthToken()

  reduxStore = store

  if (token) {
    store.dispatch(setCurrentUserToken(token))
    await store.dispatch(getMe(token))
  }

}

export const getAuthToken = (skipCheck = false) => {
  if (window.localStorage) {

    let token = window.localStorage.getItem('protonSession')

    if (!skipCheck) {
      return token
    }


  }
}

export const parseJWT = token => {
  try {
    let pieces = token.split('.')
    let body = JSON.parse(atob(pieces[1]))

    let { exp } = body
    let now = new Date().getTime() / 1000

    if (!exp || exp - now >= 12 * 60 * 60) {
      return body
    }
  } catch (err) {
    console.error('ERROR PARSING JWT:', err)
  }

  return null
}

export const getUserEmail = () => {
  let token = getAuthToken()

  if (!token) {
    return null
  }

  let values = parseJWT(token)

  return values.email
}

export function setCSRFFromCookie() {
  if (typeof document !== 'undefined' && typeof document.cookie !== 'undefined') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith('MMCSRF=')) {
        //Client4.setCSRF(cookie.replace('MMCSRF=', ''));
        break;
      }
    }
  }
}


export const signup = async (data, tid = "") => {

  let endpointURL = `${apiURL}/users/signup`
  return axios.post(endpointURL,  data , {headers : {'X-Requested-With': 'XMLHttpRequest'}, params: {
      t: tid
    } })

}
export const signupVerify = async (data) => {
  
  let endpointURL = `${apiURL}/users/verify`
  return axios.post(endpointURL,  data , {headers : {'X-Requested-With': 'XMLHttpRequest'} })

}
export const verify = async (data) => {

  let endpointURL = `${apiURL}/users/email/verify`
  return axios.post(endpointURL,  data , {headers : {'X-Requested-With': 'XMLHttpRequest'} })

}
export const forgotPassword = async (data) => {

  let endpointURL = `${apiURL}/users/password/reset/send`
  return axios.post(endpointURL,  data , {headers : {'X-Requested-With': 'XMLHttpRequest'} })

}
export const recoveryPassword = async (data) => {

  let endpointURL = `${apiURL}/users/password/reset`
  return axios.post(endpointURL,  data , {headers : {'X-Requested-With': 'XMLHttpRequest'} })

}
