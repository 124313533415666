import React from 'react'
import classNames from 'classnames'
import {borderPosition, borderStyle} from 'common/constants'

import BaseShape from '../BaseShape'
import Shadow from '../Shadow'

export default class Section extends BaseShape {
  getRenderOffset() {
    if (this.props.borderStyle !== borderStyle.NONE) {
      switch (this.props.borderPosition) {
        case borderPosition.INSIDE:
          return 0.5 * this.getBorderWidth()
        case borderPosition.OUTSIDE:
          return -0.5 * this.getBorderWidth()
        default:
      }
    }

    return 0
  }

  getBorderRadiusProps() {
    let { width, height, borderRadius, zoom } = this.props

    borderRadius = Math.min(borderRadius, height / 2)
    borderRadius = Math.min(borderRadius, width / 2)

    let radius = (borderRadius || 0) * zoom.scale

    return {
      rx: radius,
      ry: radius,
    }
  }

  render() {
    let {
      xScaled,
      yScaled,
      widthScaled,
      heightScaled,
      selected,
      borderRadius,
      shadow,
      zoom,
      width,
      height,
      hideShadows,
      children,
      ...styles
    } = this.props

    let borderProps = this.getBorderProps()
    let borderRadiusProps = this.getBorderRadiusProps()
    let backgroundColor = this.getBackgroundColor()
    let renderOffset = this.getRenderOffset()

    xScaled += renderOffset
    yScaled += renderOffset
    widthScaled -= 2 * renderOffset
    heightScaled -= 2 * renderOffset

    return (
      <g
        className={classNames('section', { 'section-selected': selected })}
        onMouseDown={this.handleMouseDown}
        onDoubleClick={this.handleDoubleClick}
        transform={`translate(${xScaled}, ${yScaled})`}
        style={styles}
      >
        {shadow && shadow.enabled && !hideShadows ? (
          <Shadow {...this.getShadowProps()}>
            <rect
              {...borderProps}
              {...borderRadiusProps}
              x={0}
              y={0}
              width={widthScaled}
              height={heightScaled}
              fill={backgroundColor}
            />
          </Shadow>
        ) : null}
        <rect
          {...borderProps}
          {...borderRadiusProps}
          x={0}
          y={0}
          width={widthScaled}
          height={heightScaled}
          fill={backgroundColor}
        />
        {children}
      </g>
    )
  }
}
