import axios from 'axios'

import qs from 'qs'

import {apiURL} from 'utils/io'

const REQUEST_DEPARTMENTS = 'REQUEST_DEPARTMENTS'


const INITIAL_STATE = {
    departments: [],
}

// Reducer

export default (state = INITIAL_STATE, action) => {

    if (action.type === `${REQUEST_DEPARTMENTS}_FULFILLED`) {
        return {
            ...state,
            departments: action.payload.data,
        }
    }

    return state
}


export const requestDepartments = opts => ({
    type: REQUEST_DEPARTMENTS,
    payload: axios.get(`${apiURL}/departments/all?${qs.stringify(opts)}`),
})


// Selectors

export const getDepartments = state => {
    return state.departments.departments
}

