export default class Base {

  constructor() {
    this._readIndex = true
    this.initialize(...arguments)
  }

  initialize() {
  }

  set(props, exclude) {
    if (props)
      Base.filter(this, props, exclude, this._prioritize);
    return this;
  }
  inject(/* src, ... */) {
    for (var i = 0, l = arguments.length; i < l; i++) {
      var src = arguments[i];
      if (src) {
        Base._inject(this, src, src.enumerable, src.beans, src.preserve);
      }
    }
    return this;
  }
  // Keep track of all named classes for serialization and exporting.
  static _inject(dest, src, enumerable, beans, preserve) {
    var beansNames = {};
    var create = Object.create,
      describe = Object.getOwnPropertyDescriptor,
      define = Object.defineProperty
    /**
     * Private function that injects one field with given name and checks if
     * the field is a function with a previous definition that we need to
     * link to through Function#base.
     */
    function field(name, val) {
      // This does even work for prop: 0, as it will just be looked up
      // again through describe.

      val = val || (val = describe(src, name))
        && (val.get ? val : val.value);
      // Allow aliases to properties with different names, by having
      // string values starting with '#'
      if (typeof val === 'string' && val[0] === '#')
        val = dest[val.substring(1)] || val;
      var isFunc = typeof val === 'function',
        res = val,
        // Only lookup previous value if we preserve existing entries or
        // define a function that might need it for Function#base. If
        // a getter is defined, don't lookup previous value, but look if
        // the property exists (name in dest) and store result in prev.
        // Also check if the function doesn't already have #base defined
        // in which case it shall not be overridden. This occurs when
        // injecting statics from one constructor function to the next
        // for inheritance. e.g. in Mootools' Function#extend would
        // falsely be preserved up the inheritance chain through #base.
        prev = preserve || isFunc && !val.base
          ? (val && val.get ? name in dest : dest[name])
          : null,
        bean;
      if (!preserve || !prev) {
        // Expose the 'super' function (meaning the one this function is
        // overriding) through Function#base:
        if (isFunc && prev)
          val.base = prev;
        // Produce bean properties if getters or setters are specified.
        // Just collect potential beans for now, and look them up in
        // dest at the end of fields injection. This ensures base works
        // for beans too, and inherits setters for redefined getters in
        // subclasses.
        if (isFunc && beans !== false
          && (bean = name.match(/^([gs]et|is)(([A-Z])(.*))$/)))
          beansNames[bean[3].toLowerCase() + bean[4]] = bean[2];
        // No need to create accessor description if it already is one.
        // It is considered a description if it is a plain object with a
        // get function.
        if (!res || isFunc || !res.get || typeof res.get !== 'function'
          || !Base.isPlainObject(res)) {
          res = { value: res, writable: true };
        }
        // Only set/change configurable and enumerable if this field is
        // configurable
        if ((describe(dest, name)
          || { configurable: true }).configurable) {
          res.configurable = true;
          // If no value is provided for enumerable, the default is to
          // allow any property to be enumerable except the functions
          // that create bean properties.
          res.enumerable = enumerable != null ? enumerable : !bean;
        }
        define(dest, name, res);
      }
    }
    // Iterate through all definitions in src now and call field() for each.
    if (src) {
      for (var name in src) {
        if (src.hasOwnProperty(name) && !/^(statics|enumerable|beans|preserve)$/.test(name))
          field(name);
      }
      // Now process the beans as well.
      for (var name in beansNames) {
        // Simple Beans Convention:
        // - If `beans: false` is specified, no beans are injected.
        // - `isName()` is only considered a getter of a  bean accessor
        //   if there is also a setter for it.
        // - If a potential getter has no parameters, it forms a bean
        //   accessor.
        // - If `beans: true` is specified, the parameter count of a
        //   potential getter is ignored and the bean is always created.
        var part = beansNames[name],
          set = dest['set' + part],
          get = dest['get' + part] || set && dest['is' + part];
        if (get && (beans === true || get.length === 0))
          field(name, { get: get, set: set });
      }
    }
    return dest;
  }

  static extend() {
    // Override Base.extend() to register named classes in Base.exports,
    // for deserialization and injection into PaperScope.
    var res = super.apply(this, arguments),
      name = res.prototype._class;
    if (name && !Base.exports[name])
      Base.exports[name] = res;
    return res;
  }

  /**
   * Checks if two values or objects are equals to each other, by using their
   * equals() methods if available, and also comparing elements of arrays and
   * properties of objects.
   */
  static equals(obj1, obj2) {
    if (obj1 === obj2)
      return true;
    // Call #equals() on both obj1 and obj2
    if (obj1 && obj1.equals)
      return obj1.equals(obj2);
    if (obj2 && obj2.equals)
      return obj2.equals(obj1);
    // Deep compare objects or arrays
    if (obj1 && obj2
      && typeof obj1 === 'object' && typeof obj2 === 'object') {
      // Compare arrays
      if (Array.isArray(obj1) && Array.isArray(obj2)) {
        var length = obj1.length;
        if (length !== obj2.length)
          return false;
        while (length--) {
          if (!Base.equals(obj1[length], obj2[length]))
            return false;
        }
      } else {
        // Deep compare objects.
        var keys = Object.keys(obj1),
          length = keys.length;
        // Ensure that both objects contain the same number of
        // properties before comparing deep equality.
        if (length !== Object.keys(obj2).length)
          return false;
        while (length--) {
          // Deep compare each member
          var key = keys[length];
          if (!(obj2.hasOwnProperty(key)
            && Base.equals(obj1[key], obj2[key])))
            return false;
        }
      }
      return true;
    }
    return false;
  }

  /**
   * When called on a subclass of Base, it reads arguments of the type of the
   * subclass from the passed arguments list or array, at the given index, up
   * to the specified length. When called directly on Base, it reads any value
   * without conversion from the passed arguments list or array. This is used
   * in argument conversion, e.g. by all basic types (Point, Size, Rectangle)
   * and also higher classes such as Color and Segment.
   *
   * @param {Array} list the list to read from, either an arguments object or
   *     a normal array
   * @param {Number} start the index at which to start reading in the list
   * @param {Object} options `options.readNull` controls whether null is
   *     returned or converted. `options.clone` controls whether passed
   *     objects should be cloned if they are already provided in the required
   *     type
   * @param {Number} length the amount of elements that can be read
   */

  static read(list, start, options, amount) {
    // See if it's called directly on Base, and if so, read value and return
    // without object conversion.
    if (this.name == 'Base') {
      var value = this.peek(list, start);
      list.__index++;
      return value;
    }
    var proto = this.prototype,
      readIndex = proto._readIndex,
      begin = start || readIndex && list.__index || 0,
      length = list.length,
      obj = list[begin];
    amount = amount || length - begin;
    // When read() is called on a sub-class of which the object is already
    // an instance, or when there is only one value in the list and it's
    // null or undefined, return the obj.
    if (obj instanceof this
      || options && options.readNull && obj == null && amount <= 1) {
      if (readIndex)
        list.__index = begin + 1;
      return obj && options && options.clone ? obj.clone() : obj;
    }
    // Otherwise, create a new object and read through its initialize
    // function.
    obj = Object.create(proto);
    if (readIndex)
      obj.__read = true;
    obj = obj.initialize.apply(obj, begin > 0 || begin + amount < length
      ? Base.slice(list, begin, begin + amount)
      : list) || obj;
    if (readIndex) {
      list.__index = begin + obj.__read;
      // This is only in use in Rectangle so far: Nested calls to
      // `Base.readNamed()` would loose __filtered if it wasn't returned
      // on the object.
      var filtered = obj.__filtered;
      if (filtered) {
        list.__filtered = filtered;
        obj.__filtered = undefined;
      }
      obj.__read = undefined;
    }
    return obj;
  }

  /**
   * Allows peeking ahead in reading of values and objects from arguments
   * list through Base.read().
   *
   * @param {Array} list the list to read from, either an arguments object
   * or a normal array
   * @param {Number} start the index at which to start reading in the list
   */
  static peek(list, start) {
    return list[list.__index = start || list.__index || 0];
  }

  /**
   * Returns how many arguments remain to be read in the argument list.
   */
  static remain(list) {
    return list.length - (list.__index || 0);
  }

  /**
   * Reads all readable arguments from the list, handling nested arrays
   * separately.
   *
   * @param {Array} list the list to read from, either an arguments object
   *     or a normal array
   * @param {Number} start the index at which to start reading in the list
   * @param {Object} options `options.readNull` controls whether null is
   *     returned or converted. `options.clone` controls whether passed
   *     objects should be cloned if they are already provided in the
   *     required type
   * @param {Number} amount the amount of elements that should be read
   */
  static readList(list, start, options, amount) {
    var res = [],
      entry,
      begin = start || 0,
      end = amount ? begin + amount : list.length;
    for (var i = begin; i < end; i++) {
      res.push(Array.isArray(entry = list[i])
        ? this.read(entry, 0, options)
        : this.read(list, i, options, 1));
    }
    return res;
  }

  /**
   * Allows using of `Base.read()` mechanism in combination with reading named
   * arguments form a passed property object literal. Calling
   * `Base.readNamed()` can read both from such named properties and normal
   * unnamed arguments through `Base.read()`. In use for example for
   * the various `Path` constructors in `Path.Constructors.js`.
   *
   * @param {Array} list the list to read from, either an arguments object or
   *     a normal array
   * @param {String} name the property name to read from
   * @param {Number} start the index at which to start reading in the list
   * @param {Object} options `options.readNull` controls whether null is
   *     returned or converted. `options.clone` controls whether passed
   *     objects should be cloned if they are already provided in the required
   *     type
   * @param {Number} amount the amount of elements that can be read
   */
  static readNamed(list, name, start, options, amount) {
    var value = this.getNamed(list, name),
      hasValue = value !== undefined;
    if (hasValue) {
      // Create a _filtered object that inherits from `source`, and
      // override all fields that were already read with undefined.
      var filtered = list.__filtered;
      if (!filtered) {
        var source = this.getSource(list);
        filtered = list.__filtered = Object.create(source);
        // Point __unfiltered to the original, so `Base.filter()` can
        // use it to get all keys to iterate over.
        filtered.__unfiltered = source;
      }
      // delete wouldn't work since the masked parent's value would
      // shine through.
      filtered[name] = undefined;
    }
    return this.read(hasValue ? [value] : list, start, options, amount);
  }

  /**
   * If `list[0]` is a source object, calls `Base.readNamed()` for each key in
   * it that is supported on `dest`, consuming these values.
   *
   * @param {Array} list the list to read from, either an arguments object or
   *     a normal array
   * @param {Object} dest the object on which to set the supported properties
   * @return {Boolean} {@true if any property was read from the source object}
   */
  static readSupported(list, dest) {
    var source = this.getSource(list),
      that = this,
      read = false;
    if (source) {
      // If `source` is a filtered object, we get the keys from the the
      // original object (it's parent / prototype). See _filtered
      // inheritance trick in the argument reading code.
      Object.keys(source).forEach(function (key) {
        if (key in dest) {
          var value = that.readNamed(list, key);
          // Due to the _filtered inheritance trick, undefined is used
          // to mask already consumed named arguments.
          if (value !== undefined) {
            dest[key] = value;
          }
          read = true;
        }
      });
    }
    return read;
  }

  /**
   * @return the arguments object if the list provides one at `list[0]`
   */
  static getSource(list) {
    var source = list.__source;
    if (source === undefined) {
      var arg = list.length === 1 && list[0];
      source = list.__source = arg && Base.isPlainObject(arg)
        ? arg : null;
    }
    return source;
  }

  /**
   * @return the named value if the list provides an arguments object,
   *     `null` if the named value is `null` or `undefined`, and
   *     `undefined` if there is no arguments object If no name is
   *     provided, it returns the whole arguments object
   */
  static getNamed(list, name) {
    var source = this.getSource(list);
    if (source) {
      // Return the whole arguments object if no name is provided.
      return name ? source[name] : list.__filtered || source;
    }
  }

  /**
   * Checks if the argument list has a named argument with the given name. If
   * name is `null`, it returns `true` if there are any named arguments.
   */
  static hasNamed(list, name) {
    return !!this.getNamed(list, name);
  }

  /**
   * Copies all properties from `source` over to `dest`, supporting
   * `_filtered` handling as required by {@link Base.readNamed()} mechanism,
   * as well as a way to exclude and prioritize properties.
   *
   * @param {Object} dest the destination that is to receive the properties
   * @param {Object} source the source from where to retrieve the properties
   *     to be copied
   * @param {Object} [exclude] an object that can define any properties as
   *     `true` that should be excluded when copying
   * @param {String[]} [prioritize] a list of keys that should be prioritized
   *     when copying, if they are defined in `source`, processed in the order
   *     of appearance
   */
  static filter(dest, source, exclude, prioritize) {
    var processed;

    function handleKey(key) {
      if (!(exclude && key in exclude) &&
        !(processed && key in processed)) {
        // Due to the _filtered inheritance trick, undefined is used
        // to mask already consumed named arguments.
        var value = source[key];
        if (value !== undefined)
          dest[key] = value;
      }
    }

    // If there are prioritized keys, process them first.
    if (prioritize) {
      var keys = {};
      for (var i = 0, key, l = prioritize.length; i < l; i++) {
        if ((key = prioritize[i]) in source) {
          handleKey(key);
          keys[key] = true;
        }
      }
      // Now reference the processed keys as processed, so that
      // handleKey() will not set them again below.
      processed = keys;
    }

    // If source is a filtered object, we get the keys from the the original
    // object (it's parent / prototype). See _filtered inheritance trick in
    // the argument reading code.
    Object.keys(source.__unfiltered || source).forEach(handleKey);
    return dest;
  }

  /**
   * Returns true if obj is either a plain object or an array, as used by many
   * argument reading methods.
   */
  static isPlainValue(obj, asString) {
    return Base.isPlainObject(obj) || Array.isArray(obj)
      || asString && typeof obj === 'string';
  }

  static pick(a, b) {
    return a !== undefined ? a : b;
  }

  static slice(list, begin, end) {
    const array = [],
      slice = array.slice


    return slice.call(list, begin, end);
  }

  static forIn(iter, bind) {
    // Do not use Object.keys for iteration as iterators might modify
    // the object we're iterating over, making the hasOwnProperty still
    // necessary.
    for (var i in this) {
      if (this.hasOwnProperty(i))
        iter.call(bind, this[i], i, this);
    }
  }

  static each(obj, iter, bind) {

    const forEach = [].forEach || function (iter, bind) {
      // Poly-fill for forEach
      for (var i = 0, l = this.length; i < l; i++) {
        iter.call(bind, this[i], i, this);
      }
    }

    // Convention: Enumerate over the object using forEach if it defines
    // a value property named 'length' that contains an number value.
    // Everything else is enumerated using forIn.
    if (obj) {
      var desc = Object.getOwnPropertyDescriptor(obj, 'length');
      (desc && typeof desc.value === 'number' ? forEach : Base.forIn)
        .call(obj, iter, bind = bind || obj);
    }
    return bind;
  }

  /**
   * Utility function for pushing a large amount of items to an array.
   */
  static push(list, items) {
    var itemsLength = items.length;
    // It seems for "small" amounts of items, this performs better,
    // but once it reaches a certain amount, some browsers start crashing:
    if (itemsLength < 4096) {
      list.push.apply(list, items);
    } else {
      // Use a loop as the best way to handle big arrays (see #1493).
      // Set new array length once before the loop for better performance.
      var startLength = list.length;
      list.length += itemsLength;
      for (var i = 0; i < itemsLength; i++) {
        list[startLength + i] = items[i];
      }
    }
    return list;
  }

  /**
   * Utility function for adding and removing items from a list of which each
   * entry keeps a reference to its index in the list in the private _index
   * property. Used for PaperScope#projects and Item#children.
   */
  static splice(list, items, index, remove) {
    var amount = items && items.length,
      append = index === undefined;
    index = append ? list.length : index;
    if (index > list.length)
      index = list.length;
    // Update _index on the items to be added first.
    for (var i = 0; i < amount; i++)
      items[i]._index = index + i;
    if (append) {
      // Append them all at the end by using push
      Base.push(list, items);
      // Nothing removed, and nothing to adjust above
      return [];
    } else {
      // Insert somewhere else and/or remove
      var args = [index, remove];
      if (items)
        Base.push(args, items);
      var removed = list.splice.apply(list, args);
      // Erase the indices of the removed items
      for (var i = 0, l = removed.length; i < l; i++)
        removed[i]._index = undefined;
      // Adjust the indices of the items above.
      for (var i = index + amount, l = list.length; i < l; i++)
        list[i]._index = i;
      return removed;
    }
  }

  /**
   * Capitalizes the passed string: hello world -> Hello World
   */
  static capitalize(str) {
    return str.replace(/\b[a-z]/g, function (match) {
      return match.toUpperCase();
    });
  }

  /**
   * Camelizes the passed hyphenated string: caps-lock -> capsLock
   */
  static camelize(str) {
    return str.replace(/-(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
  }

  /**
   * Converts camelized strings to hyphenated ones: CapsLock -> caps-lock
   */
  static hyphenate(str) {
    return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  }

  static isPlainObject(obj) {
    var ctor = obj != null && obj.constructor;
    // We also need to check for ctor.name === 'Object', in case
    // this is an object from another global scope (e.g. an iframe,
    // or another vm context in node.js).
    return ctor && (ctor === Object || ctor === Base
      || ctor.name === 'Object');
  }

  /**
   * General purpose clone function that delegates cloning to the
   * constructor that receives the object to be cloned as the first
   * argument.
   * NOTE: #clone() needs to be overridden in any class that requires
   * other cloning behavior.
   */
  clone() {
    return new this.constructor(this);
  }

}
