import React, {Component} from 'react'
import {connect} from 'react-redux'
import {SubmissionError} from 'redux-form'
import QS from 'qs'

import {parseJWT, signup, signupVerify} from 'utils/auth'

import AuthPage from './AuthPage'
import SignupForm from './SignupForm'

class Signup extends Component {
    constructor(props) {
        super(props)

        let {location} = props

        let {token, redirect, t} = QS.parse(location.search, {
            ignoreQueryPrefix: true,
        })

        let initialValues = {}
        let error = null


        if (token) {
            let tokenBody = parseJWT(token)

            if (tokenBody) {
                initialValues = {email: tokenBody.email}
            } else {
                error = 'Your invitation has expired. Invitations expire after 7 days.'
            }
        }

        this.state = {
            initialValues,
            error,
            inviteToken: token,
            t,
            redirect,
        }
    }

    handleSubmit = data => {

        let {history} = this.props
        let {t, redirect, inviteToken} = this.state


        if (inviteToken) {
            return signup(data, t).then((response, a, b) => {
                history.push('/')
            }).catch((err) => {

                const data = err.response?.data
                if (data) {
                    throw new SubmissionError({_error: data.message})
                }

                throw new SubmissionError({_error: 'error'})
            })
        } else {
            return signupVerify(data).then((response, a, b) => {

                history.push(`/verify?token=${response?.data?.token}`)
            }).catch((err) => {

                const data = err.response?.data
                if (data) {
                    throw new SubmissionError({_error: data.message})
                }

                throw new SubmissionError({_error: 'error'})
            })
        }

    }

    render() {
        let {children, location} = this.props
        let {redirect} = this.state
        let isInvite = location.pathname === '/accept-invite'

        let {initialValues, error} = this.state

        return (
            <AuthPage name="signup-page" error={error}>
                <SignupForm
                    isInvite={isInvite}
                    initialValues={initialValues}
                    onSubmit={this.handleSubmit}
                    redirect={redirect}
                />
                {/*{children}*/}
            </AuthPage>
        )
    }
}

export default connect(null, {})(Signup)
