import React, {Component} from 'react'
import classNames from 'classnames'
import {connect} from 'react-redux'

import {getComponent, getCurrentAppId} from 'ducks/editor/objects'

import Icon from 'components/Shared/Icon'
import ToggleButton from 'components/Shared/Forms/ToggleButton'

import './BooleanControl.scss'

class BooleanControl extends Component {
  handleChange = value => {
    let { onChange, name } = this.props

    onChange({ [name]: value })
  }

  handleToggle = () => {
    let { value } = this.props

    return this.handleChange(!value)
  }

  handleAddBinding = newValue => {
    let { onChange, name } = this.props

    if (newValue) {
      onChange({ [name]: newValue })
    }
  }

  handleRemoveBinding = () => {
    let { onChange, name } = this.props

    onChange({ [name]: false })
  }

  render() {
    let { boxed, displayName, label, value } = this.props

    if (typeof value === 'object' && label) {
      return (
        <div className="library-inspect-boolean-binding">
          {label}
          <Icon type="close" onClick={this.handleRemoveBinding} />
        </div>
      )
    }

    return (
      <div
        className={classNames(
          'boolean-control',
          'boolean-control-standalone',
          'library-inspect-boolean-control',
          { 'boolean-control-boxed': boxed }
        )}
        onClick={this.handleToggle}
      >
        {displayName ? <p>{displayName}</p> : null}
        <ToggleButton value={!!value} onChange={this.handleChange} />
      </div>
    )
  }
}

const mapStateToProps = (state, { objectId, value, role, reference }) => {
  let appId = getCurrentAppId(state)
  let componentId = getComponent(state, objectId)?.id
  let label

  if (typeof value === 'object') {
    label = getShortLabel(state, value.source, appId, componentId)
  }

  return {
    label,

  }
}

export default connect(mapStateToProps)(BooleanControl)
