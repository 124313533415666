import React, {Component} from 'react'
import DocumentEvents from 'react-document-events'

import {getIconsList} from '../../../utils/material'
import {ESC, RETURN} from '../../../utils/keyboard'
import {filterByQuery} from '../../../utils/search'
import Icon, {IconButton} from '../Icon'
import 'styles/IconMenu.css'
import 'styles/MaterialIcons.css'

const getIconName = value => {
  value = String(value || '')

  return value.replace(/\-/g, '_')
}

export default class IconMenu extends Component {
  state = {
    isOpen: false,
    searchText: '',
  }

  handleOpen = () => {
    this.setState({ isOpen: true })
  }

  handleClose = () => {
    this.setState({ isOpen: false, searchText: '' })
  }

  handleSearch = e => {
    this.setState({ searchText: e.currentTarget.value.trim() })
  }

  handleKeyDown = e => {
    if (e.which === ESC || e.which === RETURN) {
      this.handleClose()
    }
  }

  handleChange = val => {
    let { onChange, input } = this.props

    if (input && input.onChange) {
      onChange = input.onChange
    }

    onChange(val)

    this.handleClose()
  }

  handleClear = e => {
    e.preventDefault()
    e.stopPropagation()

    this.handleChange(null)
  }

  renderValue() {
    let { value } = this.props

    if (!value) {
      return <div className="material-icon-menu-placeholder">None</div>
    }

    let name = getIconName(value)

    return (
      <div className="material-icon-menu-title">
        <i className="material-icons">{name}</i> {name}
      </div>
    )
  }

  renderMenu() {
    let options = getIconsList()
    let { searchText } = this.state

    if (searchText) {
      options = filterByQuery(options, searchText)
    }

    return (
      <React.Fragment>
        <div
          className="material-icon-menu-backdrop"
          onMouseDown={this.handleClose}
        />
        <div className="material-icon-menu-options">
          <div className="material-icon-menu-search">
            <input
              autoFocus
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={this.handleSearch}
            />
          </div>
          <div className="material-icon-menu-options-sub">
            {options.map(opt => (
              <MenuOption key={opt} value={opt} onSelect={this.handleChange} />
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }

  render() {
    let { isOpen } = this.state
    let { value, input } = this.props

    // Redux-form compatibility
    if (input) {
      value = input.value
    }

    return (
      <div className="material-icon-menu">
        <DocumentEvents onKeyDown={this.handleKeyDown} />
        <div className="material-icon-menu-value" onClick={this.handleOpen}>
          {this.renderValue()}
          {value ? (
            <IconButton
              type="close"
              onClick={this.handleClear}
              className="material-icon-menu-clear"
            />
          ) : null}
          <Icon type="expand-vertical" className="material-icon-menu-expand" />
        </div>
        {isOpen ? this.renderMenu() : null}
      </div>
    )
  }
}

class MenuOption extends Component {
  handleClick = () => {
    let { onSelect, value } = this.props

    onSelect(value)
  }

  render() {
    let { value } = this.props
    let iconName = getIconName(value)

    return (
      <div
        className="material-icon-menu-option"
        onClick={this.handleClick}
        title={value}
      >
        <i className="material-icons">{iconName}</i>
      </div>
    )
  }
}
