import React, {Component} from 'react'
import classNames from 'classnames'

import 'styles/WrappedInput.css'
import TextControl from "../../../Editor/Inspect/TextControl";


export default class WrappedNumberInput extends Component {

  state = {
    focussed: false,
  }

  handleChange = (value, name) => {

    let { input, onChangeValue } = this.props
    let { onChange } = input
    let val = value

    if (typeof value === 'object' && value !== null) {
      val = Object.values(value)[0]
    }


    onChange(val)

    if (onChangeValue) {
      onChangeValue(val)
    }
  }


  stopPropagation = e => {
    e.stopPropagation()
  }



  componentDidMount() {

  }

  inputRef = input => {
    this.input = input
  }

  render() {
    let { focussed } = this.state

    let {
      meta,
      label,
      large,
      input,
      className,
      prefix,
      ...props
    } = this.props

    let { touched, error } = meta

    return (
      <div
        onMouseDown={this.forceFocus}
        onMouseUp={this.forceFocus}
        className={classNames('single-number-control', className, {        'input-error': touched && error})}
      >
        {label ? (
            <label className="single-number-control-label">{label}</label>
        ) : null}
        <TextControl
            {...props}
            error={error}
            value = {input.value}
            name = {input.name}
            autoComplete="off"
            onChange={this.handleChange}
            ref={this.inputRef}
        />
       {/* {touched && error ? (
            <div className="wrapped-input-error-message">{error}</div>
        ) : null}*/}
      </div>
    )
  }
}
