import React, {Component} from 'react'

import EmptyState from '../EmptyState'
import 'styles/ErrorBoundary.css'

export default class ErrorBoundary extends Component {
  state = {
    error: null,
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })

    Sentry.configureScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
    })

  }

  renderError() {
    return (
      <EmptyState greedy className="error-boundary-wrapper">
        <h2>An error occurred.</h2>
        <p>Please try again.</p>
      </EmptyState>
    )
  }

  render() {
    let { error } = this.state
    let { children } = this.props

    if (error) {
      return this.renderError()
    }

    return <>{children}</>
  }
}
