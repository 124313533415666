import PropTypes from 'prop-types'
import React, {Component} from 'react'

import CommonInspectRow from '../GenericRow'
import Slider from '../Slider'
import TextControl from '../TextControl'

export function getMaxBorderRadius(object) {
  const { width = 100, height = 100 } = object

  return Math.floor(Math.min(width, height) / 2)
}

export default class SliderControl extends Component {
  static propTypes = {
    defaultValue: PropTypes.number,
    max: PropTypes.number.isRequired,
    min: PropTypes.number,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    title: PropTypes.string,
    value: PropTypes.number,
  }

  static defaultProps = {
    min: 0,
    defaultValue: 0,
    title: 'Rounding',
  }

  render() {
    const { max, min, defaultValue, name, onChange, title, value } = this.props

    return (
      <CommonInspectRow className="radius-control" title={title}>
        <Slider
          value={value}
          name={name}
          onChange={onChange}
          defaultValue={defaultValue}
          min={min}
          max={max}
        />
        <TextControl
          gray
          type="number"
          name={name}
          value={value}
          onChange={onChange}
        />
      </CommonInspectRow>
    )
  }
}
