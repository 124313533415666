import {LIBRARY_COMPONENT} from 'common/constants'

export const getDefaults = props => {
  let result = {}

  if (props) {
    for (let prop of props) {
      if (prop.global && prop.default) {
        result[prop.name] = prop.default
      }
    }
  }

  return result
}

export const setGlobalDefaults = (libraryGlobals, newObjects) => {
  for (let obj of newObjects) {
    if (obj.type !== LIBRARY_COMPONENT) {
      continue
    }

    let { libraryName, componentName, libraryComponentManifest: library } = obj

    let prevDefault = libraryGlobals?.[libraryName]?.[componentName]
    if (prevDefault) continue

    let result = getDefaults(library?.props)

    if (Array.isArray(library?.childComponents)) {
      for (let child of library?.childComponents) {
        let childResult = getDefaults(child?.props)

        if (Object.keys(childResult).length > 0) {
          result[child.name] = childResult
        }
      }
    }

    libraryGlobals = {
      ...libraryGlobals,
      [libraryName]: {
        ...libraryGlobals[libraryName],
        [componentName]: result,
      },
    }
  }

  return libraryGlobals
}
