import React, {Component} from 'react'
import EntityTextarea from 'react-entity-textarea'
import classNames from 'classnames'

import {convertToBlocks, convertToSimple} from '../../../utils/entities'

import 'react-entity-textarea/styles.css'
import 'styles/EntityTextarea.css'

export default class WrappedEntityTextarea extends Component {
  handleChange = value => {
    let { onChange } = this.props

    onChange(convertToSimple(value))
  }

  getValue = () => {
    let { value } = this.props

    return convertToBlocks(value)
  }

  render() {
    let { childRef, className } = this.props

    return (
      <EntityTextarea
        {...this.props}
        ref={childRef}
        className={classNames('wrapped-entity-textarea', className)}
        value={this.getValue()}
        onChange={this.handleChange}
      />
    )
  }
}
