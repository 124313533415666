import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {COMPONENT, GROUP} from 'common/constants'

import {createName} from 'utils/naming'

import {checkoutDraft} from 'ducks/apps/actions'
import { getApp } from "ducks/apps/selectors";
import {createObject, selectObjects, updateObjects} from 'ducks/editor/objects'

import Form from './Form'
import {PathItem} from "../../../utils/vector";
import './CheckoutModal.scss'
import Button from "../../Shared/Button";

/*
import Shapes from "../../../../output_lib.json";
*/

class CheckoutModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            successBanner: false
        }
    }

    handleSubmit = async values => {

        debugger
        let {checkoutDraft, updateObjects, onSuccess, opts, app} = this.props

        /* let baseObject

         const name = values.name || this.getDefaultName()

         let depth = app.depth
         const recalculate = []


         Object.values(app.components).forEach(screen => {
                 if (screen.depth !== app.depth) {
                     depth -= screen.depth
                 } else {
                     if (screen.objects.length > 0) {
                         let maxX = 0
                         screen.objects.forEach(obj => {
                             if (obj.depth > maxX) {
                                 maxX = obj.depth
                             }
                         })
                         recalculate.push({

                             depth: maxX,
                             id: screen.id,
                         })
                         depth -= maxX
                     } else {
                         const d = app.depth / 2
                         recalculate.push({

                             depth: d,
                             id: screen.id,
                         })
                         depth = d
                     }
                 }
             }
         )

         baseObject = {
             width: app.width,
             height: app.height,
             depth: depth,
             backgroundColor: '@background',
         }


         let newObject = createObject(
             {
                 ...baseObject,
                 name,
                 type: COMPONENT,
                 x: undefined,
                 y: undefined,
             },
             null,
             !opts.zoom
         )
         recalculate.length && updateObjects(recalculate)*/
        debugger
        await checkoutDraft(app.id, values).then((f) => {
            debugger
            this.setState({successBanner: true})//onSuccess(app.id)
        })

    }

    getDefaultName = () => {
        let {list} = this.props
        return createName(COMPONENT, null, list)
    }

    goToHome = () => {
        window.location = '/'
    }

    recalculate = () => {

        const {app: {components}} = this.props

        let calc = {}
        const draw = (layer, shapeObject, parent) => {

            if (shapeObject.children) {
                shapeObject.children.forEach((children) => {
                    draw(layer, children, shapeObject)
                })
            }

            if (shapeObject.type === GROUP) {
                return
            }


            const {width, height, depth, x, y, points, compound, id, angle} = shapeObject

            let path = PathItem.create()

            if (compound && compound.length > 0) {
                path = PathItem.create(compound)
            } else if (points && points.length > 0) {
                path = PathItem.create(points)
            }
            path.setClosed(true)
            path.flatten()
            path.adjust(0, 0)

            const coff = 0.264583333

            layer[shapeObject.id] = {
                area: Math.round(path.getArea() * Math.pow(coff, 2)),
                length: Math.round(path.getLength() * coff)
            }
        }

        Object.values(components).forEach((layer) => {
            layer.objects && Array.isArray(layer.objects) && layer.objects.forEach((el) => {
                let calcel = {
                    id: el.id,
                    name: el.name,
                    length: 0,
                    area: 0,
                }

                draw(calc, el)


                /*calc = {
                    ...calc,
                    [el.id]: {...calcel}
                }*/


            })
        })


        //objects.map((obj) => {

    }

    render() {
        let {app, onCancel, opts} = this.props
        let {successBanner} = this.state
        let name = this.getDefaultName()


        return (
            <>
                {successBanner && (
                    <div className="save-success">
                        <h2>Ваш заказ принят</h2>
                        <div className={"save-success-text"}>
                            В ближайшее время с Вами свяжется наш менеджер.
                        </div>
                        <div className="save-success-button-wrapper">
                            <Button onClick={this.goToHome} className="save-success-button">
                                Перейти на главную
                            </Button>
                        </div>
                    </div>
                )}
                {!successBanner && (<Form
                    onSubmit={this.handleSubmit}
                    onCancel={onCancel}
                    defaultName={name}
                />)}
            </>
        )
    }
}

const mapStateToProps = (state, {match}) => ({
    list: selectObjects(state),
    app: getApp(state, match.params.appId),
})

export default withRouter(
    connect(mapStateToProps, {createObject, updateObjects, checkoutDraft})(CheckoutModal)
)
