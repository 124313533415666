import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import qs from 'qs'

import {PREVIEW_URL} from 'common/constants'

// utils
import {getAuthToken} from 'utils/auth'

// ducks
import { getApp } from "ducks/apps/selectors";
import {getCurrentUser} from 'ducks/users'

// shared components
import Button from 'components/Shared/Button'
import Icon from 'components/Shared/Icon'

import 'styles/PreviewButton.css'

class PreviewButton extends Component {
  
  getButtonProps = () => {
    let { app, user } = this.props

    if (!app) {
      return {}
    }

    if (app.primaryPlatform === 'web') {
      const sessionToken = getAuthToken()
      const params = { sessionToken }

      if (!user?.developer) {
        delete params.sessionToken
      }

      let url = `${PREVIEW_URL}/${app.id}?${qs.stringify(params)}`

      return {
        to: url,
        target: '_blank',
      }
    }

    let previewRoute = `/apps/${app.id}/screens/preview`

    return { to: previewRoute }
  }

  render() {
    return (
      <div className="editor-preview-button-wrapper">
        <Button {...this.getButtonProps()} className="editor-preview-button">
          <Icon type="play" /> Предпросмотр в 3D
        </Button>
      </div>
    )
  }
}

const mapStateToProps = (state, { match }) => ({
  app: getApp(state, match.params.appId),
  user: getCurrentUser(state),
})

export default withRouter(connect(mapStateToProps)(PreviewButton))
