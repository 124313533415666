import React, { Component } from 'react'
import Page from 'components/Shared/Page'
import GridTable from "@nadavshaar/react-grid-table"
import Menu from "./Menu";
import { apiURL, BASE_URL } from '../../utils/io'



class ComponentsNull extends Component {

    constructor(props) {

        super(props)

        this.state = {
            
            libraries: [],

            columns: [

                {
                    id: 'library_id',
                    field: 'library_id',
                    label: 'Категория',
                    cellRenderer: ({ data }) => (
                        <select
                            name="library_id"
                            value={data.library_id || ''}
                            onChange={(e) => this.handleChange(e, data)}
                            style={{ width: "100%" }}
                        >
                            <option value="">
                                Без категории
                            </option>

                            {this.state.libraries.map((o) => (
                                <option key={o.id} value={o.id}>
                                    {o.name}
                                </option>
                            ))}
                        </select>
                    ),
                    width: "200px"
                },

                {
                    id: 'thumb',
                    field: 'thumb',
                    label: 'Изображение',
                    cellRenderer: ({ data }) => (
                        <div>
                            <img src={`${BASE_URL + '/media' + data.thumb}`} width='100' height='100'/>
                            <input type="file" onChange={(e) => this.handleImageChange(e, data.id)} style={{ display: 'block', marginTop: '10px' }} />
                        </div>

                    ),
                    width: "200px"
                },

                {
                    id: 'Name',
                    field: 'name',
                    label: 'Название',
                    cellRenderer: ({ data }) => (
                        <input
                            type="text"
                            name="name"
                            value={data.name}
                            onChange={(e) => this.handleChange(e, data)}
                            style={{ width: "100%" }}
                        />
                    ),
                    editable: true,
                    width: "200px"
                },

                {
                    id: 'counter',
                    field: 'counter',
                    label: 'counter',
                    cellRenderer: ({ data }) => (
                        <input
                            type="number"
                            step="any"
                            name="counter"
                            value={data.counter}
                            onChange={(e) => this.handleChange(e, data)}
                            style={{ width: "100%" }}
                        />
                    ),
                    editable: false,
                    width: "100px"
                },

                {
                    id: 'value',
                    field: 'value',
                    label: 'value',
                    cellRenderer: ({ data }) => (
                        <input
                            type="text"
                            step="any"
                            name="value"
                            value={data.value}
                            onChange={(e) => this.handleChange(e, data)}
                            style={{ width: "100%" }}
                        />
                    ),
                    editable: false,
                    width: "150px"
                },

                {
                    id: 'Delete',
                    label: '',
                    width: "100px",
                    cellRenderer: ({ data }) => (
                        <button onClick={() => this.Delete(data.id)}>Удалить</button>
                    )
                }

            ],

            rows: [],

            totalRows: 0,

            isLoading: true,

        }

    }

    LibrariesAll = async () => {

        this.setState({ isLoading: true })
    
        try {
            const response = await fetch(`${apiURL}/libraries`)
            if (response.ok) {
                const data = await response.json()
                this.setState({
                    libraries: data,
                    isLoading: false
                })
            } else {
                throw new Error('Network response was not ok.')
            }
        } catch (error) {
            console.error('Error fetching:', error)
        }finally{
            this.setState({ isLoading: false })
        }

    }

    handleImageChange = async (e, id) => {

        this.setState({ isLoading: true })

        const file = e.target.files[0]

        const formData = new FormData()

        formData.append('image', file)

        try {

            const response = await fetch(`${apiURL}/components/${id}/image`, {
                method: 'PUT',
                body: formData
            })

            if (response.ok) {
                await this.All(this.props.match.params.id)
            } else {
                throw new Error('Network response was not ok.')
            }

        } catch (error) {
            console.error('Ошибка при загрузке изображения:', error)
        }

    }

    handleChange = (e, data) => {

        const target = e.target
        const type = target.type
        let value = type === 'checkbox' ? target.checked : target.value
        const name = target.name

        let row_update = null

        this.setState(prevState => ({

            rows: prevState.rows.map(row => {

                if(row.id === data.id){

                    if(type === 'number'){
                        value = parseFloat(value)
                    }

                    row_update = { ...row, [name]: value }

                    return row_update
                }

                return row
            })

        }), async () => await this.Update(row_update))

    }

    Update = async (data) => {

        try {

            const response = await fetch(`${apiURL}/components`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })

            if (!response.ok) {
                throw new Error('Failed to update');
            }

        } catch (error) {
            console.error('Error updating:', error);
        }
    }

    componentDidMount() {
        this.All(); this.LibrariesAll()
    }

    All = async () => {

        this.setState({ isLoading: true })
    
        try {
            const response = await fetch(`${apiURL}/libraries/null/components`)
            if (response.ok) {
                const data = await response.json()
                this.setState({
                    rows: data,
                    totalRows: data.length,
                    isLoading: false
                })
            } else {
                throw new Error('Network response was not ok.')
            }
        } catch (error) {
            console.error('Error fetching:', error)
        }finally{
            this.setState({ isLoading: false })
        }

    }
    
    New = async (e) => {

        e.preventDefault()

        const { form } = this.state

    
        this.setState({ isLoading: true })
    
        try {

            const response = await fetch(`${apiURL}/components`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(form)
            })
    
            if (response.ok) {
                const addedCategory = await response.json();
                this.setState(prevState => ({
                    rows: [...prevState.rows, addedCategory],
                    totalRows: prevState.totalRows + 1,
                }))
            } else {
                throw new Error('Failed to create')
            }
        } catch (error) {
            console.error('Error adding:', error)
        } finally {
            this.setState({ isLoading: false })
        }

    }

    Delete = async (id) => {

        this.setState({ isLoading: true })

        try {
            const response = await fetch(`${apiURL}/components/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                this.setState(prevState => ({
                    rows: prevState.rows.filter(o => o.id !== id),
                    totalRows: prevState.totalRows - 1
                }));
            } else {
                throw new Error('Failed to delete')
            }
        } catch (error) {
            console.error('Error deleting:', error)
        } finally {
            this.setState({ isLoading: false })
        }
    }

    render() {

        const { columns, rows, totalRows, isLoading } = this.state

        return (
            <Page className="admin-users-page" title="Шаблоны">

                <Menu />

                <section className="users-header">
                    <h1>Компоненты - без категорий</h1>
                </section>

                <div className="grid-content">
                    {isLoading ? (
                        <div>Loading...</div>  // Индикатор загрузки
                    ) : (
                        <GridTable
                            showSearch={true}
                            showColumnVisibilityManager={true}
                            columns={columns}
                            rows={rows}
                            totalRows={totalRows}
                            texts={{
                                search: 'Поиск:',
                                totalRows: 'Общее кол-во:',
                                rows: 'Показано:',
                                selected: 'Выбрано',
                                rowsPerPage: 'Элементов на странице:',
                                page: 'Страница:',
                                of: 'из',
                                prev: 'Пред.',
                                next: 'След.',
                                columnVisibility: 'Колонки'
                            }}
                        />
                    )}
                </div>
            </Page>
        )
    }

}


export default ComponentsNull