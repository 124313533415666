import * as inflection from 'inflection'

export const singularize = (string, ...args) => {
  if (!string) {
    return '[Untitled]'
  }

  return inflection.singularize(string, ...args)
}

export const capitalize = str => {
  if (!str) {
    return str
  }

  return str
    .split(/\s+/)
    .map(word => {
      let first = word.substring(0, 1)
      let rest = word.substring(1)

      return `${first.toUpperCase()}${rest}`
    })
    .join(' ')
}

