export const DEFAULT_ZOOM = {
  scale: 1,
  offset: [0, 0],
}

export const DEFAULT_ZOOM_2X = {
  scale: 0.5,
  offset: [0, 0],
}

export const scale = (point, zoom) => {
  if (!point) {
    return point
  }

  if (!zoom) {
    throw new Error('Zoom cannot be undefined')
  }

  let { scale, offset } = zoom

  let [offsetX, offsetY] = offset
  let [x, y] = point

  return [x * scale + offsetX, y * scale + offsetY]
}

export const unScale = (point, zoom) => {
  if (!point) {
    return point
  }

  let { scale, offset } = zoom

  let [offsetX, offsetY] = offset
  let [x, y] = point

  return [(x - offsetX) / scale, (y - offsetY) / scale]
}

export const scaleValue = (value, zoom) => {
  if (!value) {
    return value
  }

  if (!zoom) {
    throw new Error('Zoom cannot be undefined')
  }

  let { scale } = zoom

  return value * scale
}

export const unScaleValue = (value, zoom) => {
  if (!value) {
    return value
  }

  let { scale } = zoom

  return value / scale
}

export const scaleRect = (rect, zoom) => {
  if (!rect) {
    return rect
  }

  let { x, y, width, height } = rect

  let [newX, newY] = scale([x, y], zoom)
  let newWidth = scaleValue(width, zoom)
  let newHeight = scaleValue(height, zoom)

  return {
    x: newX,
    y: newY,
    width: newWidth,
    height: newHeight,
  }
}

export const unScaleRect = (rect, zoom) => {
  if (!rect) {
    return rect
  }

  let { x, y, width, height } = rect

  let [newX, newY] = unScale([x, y], zoom)
  let newWidth = unScaleValue(width, zoom)
  let newHeight = unScaleValue(height, zoom)

  return {
    x: newX,
    y: newY,
    width: newWidth,
    height: newHeight,
  }
}



export const calculateZoomV2 = (bbox) => {

  if (!bbox) {
    return DEFAULT_ZOOM
  }

  let { width, height } = bbox

  if (width === 0 || height === 0) {
    return DEFAULT_ZOOM
  }

  let margin = 100
  let leftPanel = 64
  let rightPanel = 0
  let topPanel = 64

  let canvasWidth = window.innerWidth - leftPanel - rightPanel
  let canvasHeight = window.innerHeight - topPanel / 2

  // Вычисляем масштаб
  let xScale = Math.max(0, (canvasWidth - 2 * margin) / width)
  let yScale = Math.max(0, (canvasHeight - 2 * margin) / height)
  let scale = Math.min(xScale, yScale)

  // Вычисляем смещение
  let xOffset =
    (window.innerWidth - leftPanel) / 2 - (width / 2 + bbox.x) * scale

  let yOffset =
    window.innerHeight / 2 - (height / 2 + bbox.y) * scale + topPanel / 2

  // Ограничиваем масштаб
  scale = Math.min(scale, 16);

  return {
    scale,
    offset: [xOffset, yOffset],
  }

}

export const calculateZoom = bbox => {

  if (!bbox) {
    return DEFAULT_ZOOM
  }

  let { width, height, depth } = bbox

  let margin        = 100
  let leftPanel     = 64
  let rightPanel    = 0
  let topPanel      = 64
  let canvasWidth   = window.innerWidth - leftPanel - rightPanel
  let canvasHeight  = window.innerHeight - topPanel / 2
  let xScale        = Math.max(0,(canvasWidth - 2 * margin) / width)
  let yScale        = Math.max(0,(canvasHeight - 2 * margin) / height)
  let scale         = Math.min(xScale, yScale)

  let xOffset =
    (window.innerWidth - leftPanel) / 2 -
    (width / 2 + bbox.x) * scale

  let yOffset =
    window.innerHeight / 2 - (height / 2 + bbox.y) * scale + topPanel / 2

  if (scale > 16) {
    scale = 16
  }

  return {
    scale,
    offset: [xOffset, yOffset],
  }

}


export const adjustZoom = bbox => {
  if (!bbox) {
    return DEFAULT_ZOOM
  }

  let { width, height } = bbox


  let margin = 100
  let leftPanel = 0
  let rightPanel = 0
  let topPanel = 0
  let canvasWidth = window.innerWidth
  let canvasHeight = window.innerHeight
  let xScale = (canvasWidth - 2 * margin) / width
  let yScale = (canvasHeight - margin) / height
  let scale = Math.min(xScale, yScale)

  let xOffset = window.innerWidth / 2 - (width / 2 + bbox.y) * scale

  let yOffset = window.innerHeight / 2 - (height / 2 + bbox.y) * scale

  if (scale > 16) {
    scale = 16
  }

  return {
    scale,
    offset: [xOffset, yOffset],
  }
}
export const getProjectionTransform = (zoom, svgNamespace = false, x = 0, y = 0) => {
  let unit = svgNamespace && window.devicePixelRatio === 2 ? 2 : 1
  let rect = scaleRect({ x, y, width: unit, height: unit }, zoom)

  return `translate(${rect.x}px, 10px) scale(${rect.width})`
}
export const getTransform = (zoom, svgNamespace = false, x = 0, y = 0) => {
  let unit = svgNamespace && window.devicePixelRatio === 2 ? 2 : 1
  
  let rect = scaleRect({ x, y, width: unit, height: unit }, zoom)

  return `translate(${rect.x}px, ${rect.y}px) scale(${rect.width})`
}
export const getTransformObject = (zoom, svgNamespace = false, x = 0, y = 0) => {
  let unit = svgNamespace && window.devicePixelRatio === 2 ? 2 : 1
  let rect = scaleRect({ x, y, width: unit, height: unit }, zoom)

  return {left :~~rect.x, top: ~~rect.y}
}
export const getScaleRect = (zoom, svgNamespace = false, x = 0, y = 0) => {
  let unit = svgNamespace && window.devicePixelRatio === 2 ? 2 : 1
  let rect = scaleRect({ x, y, width: unit, height: unit }, zoom)

  return {x:~~rect.x, y: ~~rect.y}
}
export const getRuleTransform = (zoom, svgNamespace = false, x = 0, y = 0) => {
  let unit = svgNamespace && window.devicePixelRatio === 2 ? 2 : 1
  let rect = scaleRect({ x, y, width: unit, height: unit }, zoom)

  return [~~rect.x, ~~rect.y]
}

export const getSVGTransform = (zoom, x = 0, y = 0) => {
  // let unit = (svgNamespace && window.devicePixelRatio === 2) ? 2 : 1
  let unit = 1
  let rect = scaleRect({ x, y, width: unit, height: unit }, zoom)

  return `translate(${rect.x} ${rect.y}) scale(${rect.width})`
}

export const getBaseZoom = () => {
  return window.devicePixelRatio === 2 ? DEFAULT_ZOOM_2X : DEFAULT_ZOOM
}





export const getOffset = (scale, center, prevScale, prevOffset) => {
  let [offsetX, offsetY] = prevOffset

  return [
    (1 - scale / prevScale) * (center[0] - offsetX) + offsetX,
    (1 - scale / prevScale) * (center[1] - offsetY) + offsetY,
  ]

}