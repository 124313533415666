import React, {Component} from 'react'
import classNames from 'classnames'
import './ToggleButton.scss'

export default class ToggleButton extends Component {
  handleChange = e => {
    let { disabled, input, onChange } = this.props
    let value = this.getValue()

    if (disabled) return null

    let newValue = !value

    if (input?.onChange && !onChange) {
      return input.onChange(newValue)
    }

    return onChange(newValue)
  }

  getValue() {
    let { input, value } = this.props

    if (input?.value && !value) {
      return input.value
    }

    return value
  }

  render() {
    let { disabled, color, inverted } = this.props
    let value = this.getValue()

    if (inverted) {
      value = !value
    }

    return (
      <div
        className={classNames('toggle-button', {
          'toggle-button-enabled': value,
          'toggle-button-disabled': disabled,
          [`toggle-button-${color}`]: color,
        })}
        onClick={this.handleChange}
      >
        <div className="toggle-button-track" />
        <div className="toggle-button-ball" />
      </div>
    )
  }
}
