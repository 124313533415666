import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notify } from 'ducks/notifications';
import { withRouter } from 'react-router-dom';
import * as BABYLON from 'babylonjs';
import BabylonScene from './Scene';
import * as earcut from "earcut";
import "@babylonjs/core/Debug/debugLayer";
import "@babylonjs/inspector";
import { getOverflowsUtils, drawLayerUtils } from './layerUtils';

window.earcut = earcut;

class Template3dViews extends Component {

    state = {
        activeLayer: 0,
        scene: null,
        width: 0,
        height: 0,
    };

    camera = null;
    engine = null;
    infoDivRef = React.createRef();


    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        if (this.engine) {
            this.engine.dispose();
        }
    }


    updateDimensions = () => {
        if (this.infoDivRef.current) {
            this.setState({
                width: this.infoDivRef.current.offsetWidth,
                height: this.infoDivRef.current.offsetHeight,
            });
        }
    };


    // Установка целевого объекта камеры
    cameraSetTarget = (vector) => {
        if (this.camera) {
            this.camera.setTarget(vector);
        }
    }

    // Инициализация сцены и камеры
    onSceneMount = (e) => {
        const { canvas, scene, engine } = e;
        const { app } = this.props;

        this.engine = engine;
        scene.useRightHandedSystem = true;

        this.camera = new BABYLON.ArcRotateCamera(
            "Camera",
            Math.PI / 3,
            Math.PI / 3,
            50,
            BABYLON.Vector3(app.width / 100 / 2, app.height / 100 / 2, 0),
            scene
        );
        this.camera.setTarget(BABYLON.Vector3.Zero());
        this.camera.attachControl(canvas, true);

        this.setState({ scene }, () => {
            engine.displayLoadingUI();
            this.drawLayer();
            engine.hideLoadingUI();
        });

        this.engine.runRenderLoop(() => {
            if (scene) {
                scene.render();
            }
        });
    }

    // Отрисовка слоя
    drawLayer = () => {
        const { app } = this.props;
        const { scene, activeLayer } = this.state;
        const components = app.components || {};

        drawLayerUtils(scene, app, components, activeLayer, this.cameraSetTarget);
    }

    // Получение переполнений
    getOverflows = (componentId) => {
        const { app } = this.props;

        if (!app) {
            return []
        }

        return getOverflowsUtils(app.components, componentId);
    }

    render() {

        const { app, setting } = this.props;

        const { width, height } = this.state;

        if (!app) return null;

        return (
            <div className="editor-preview-frame-wrapper" ref={this.infoDivRef}>
                <BabylonScene
                    key={this.state.activeLayer}
                    engineOptions={{
                        antialias: true,
                        audioEngine: false,
                        disableWebGL2Support: false,
                        powerPreference: "high-performance",
                        failIfMajorPerformanceCaveat: false,
                        useHighPrecisionFloats: true,
                        preserveDrawingBuffer: true,
                        stencil: true,
                    }}
                    width={width}
                    height={height}
                    onSceneMount={this.onSceneMount}

                    activeLayer={this.state.activeLayer}
                    canvasId="root-canvas"
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { details, outer_rule, parts, in_case_shapes } = state.admin.template;

    return {
        app: {
            method_cut: "",
            outer_rule: outer_rule,
            parts: parts,
            in_case_shapes: in_case_shapes,
            name: details.name,
            width: details.width,
            height: details.height,
            lower_width: details.lower_width,
            lower_height: details.lower_height,
            depth: details.depth,
            border_width: details.border_width,
            border_radius: details.border_radius,
            launch_component_id: "",
            calc: {
                "": []
            },
            components: {
                "9634txzzkid4jn3d9i1hj4cqww": {
                    id: "9634txzzkid4jn3d9i1hj4cqww",
                    draft_id: "su7zpdisfi8gmy7ns15c73y37y",
                    order: 1,
                    x: 0,
                    y: 0,
                    name: "asd",
                    type: "component",
                    width: details.width,
                    height: details.height,
                    depth: details.depth,
                    hidden: false,
                    objects: [],
                    opacity: 1,
                    positioning: null,
                    background_color: "@background"
                }
            },
            status: "draft",
        },
        setting: state.admin.template.setting,
    };
};

const mapDispatchToProps = (dispatch) => ({
    notify: (message) => dispatch(notify(message)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Template3dViews));
