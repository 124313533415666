import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { notify } from 'ducks/notifications';
import { withRouter } from 'react-router-dom';

import TemplatePoint from './TemplatePoint';

class TemplatePointsXY extends Component {
    render() {
        const { pointIndex, point_xy } = this.props;

        return (
            <div className="col-span-2 grid grid-cols-2 w-full">
                <TemplatePoint pointIndex={pointIndex} point={point_xy[0]} axis="x" />
                <TemplatePoint pointIndex={pointIndex} point={point_xy[1]} axis="y" />
            </div>
        );
    }
}

TemplatePointsXY.propTypes = {
    pointIndex: PropTypes.number.isRequired,
    point_xy: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    points: state.admin.template.points,
    setting: state.admin.template.setting,
});

const mapDispatchToProps = (dispatch) => ({
    notify: (message) => dispatch(notify(message)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TemplatePointsXY));
