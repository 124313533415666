import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'

import history from '../../utils/history'
import Page from '../Shared/Page'
import Modal from '../Shared/Modal'
import {IconButton} from '../Shared/Icon'

import 'styles/Settings.css'

export default class Settings extends Component {
  closeModal = () => {
    let { match } = this.props

    history.push(`/apps/${match.params.appId}/screens`)
  }

  getBaseURL = () => {
    let { match } = this.props

    if (match && match.params && match.params.appId) {
      return `/apps/${match.params.appId}/screens/settings`
    }

    return '/settings'
  }

  renderContent() {
    let { children } = this.props
    let baseURL = this.getBaseURL()

    return (
      <Page form className="accounts-page" title="Settings">
        <div className="settings-page-close-button">
          <IconButton type="close-big" onClick={this.closeModal} />
        </div>
        <div className="settings-page-tabs">
          <NavLink
            className="settings-page-tab"
            activeClassName="active"
            to={`${baseURL}/account`}
          >
            Profile
          </NavLink>

        </div>
        <div className="settings-page-content">{children}</div>
      </Page>
    )
  }

  render() {
    let { modal } = this.props

    if (modal) {
      return (
        <Modal onClose={this.closeModal} className="settings-modal">
          {this.renderContent()}
        </Modal>
      )
    }

    return this.renderContent()
  }
}

export { default as Account } from './Account'

