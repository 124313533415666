import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {MultiMenuTrigger} from 'react-multi-menu'

import Stepper from 'components/Shared/Stepper'
import {IconButton} from 'components/Shared/Icon'

class ModalHeader extends Component {
  renderMenu = () => {
    const { menu } = this.props

    return (
      <MultiMenuTrigger className="modal-header-menu" menu={menu}>
        <IconButton type="more-vert" />
      </MultiMenuTrigger>
    )
  }

  renderBack = () => {
    const { goBack } = this.props

    return <IconButton type="arrow-back" onClick={goBack} />
  }

  renderDelete = () => {
    const { handleDelete } = this.props

    return <IconButton type="trash-small" onClick={handleDelete} />
  }

  render() {
    const {
      children,
      color,
      handleDelete,
      goBack,
      menu,
      title,
      content,
      stepper,
      steps,
      currentStep,
    } = this.props

    const classes = classnames('modal-header', {
      [`modal-header-color-${color}`]: !stepper && color,
      'modal-header-stepper': stepper,
    })

    const stepperProps = { steps, currentStep, color }

    // if there are any children, only render theme with header wrapper
    if (children) return <header className={classes}>{children}</header>

    if (stepper) {
      return (
        <header className={classes}>
          <Stepper {...stepperProps} />
        </header>
      )
    }

    return (
      <header className={classes}>
        {goBack ? this.renderBack() : null}
        {title ? <h1>{title}</h1> : null}
        {content && content()}
        {menu ? this.renderMenu() : null}
        {handleDelete ? this.renderDelete() : null}
      </header>
    )
  }
}

ModalHeader.propTypes = {
  children: PropTypes.any,
  color: PropTypes.oneOf([
    'orange',
    'yellow',
    'green',
    'teal',
    'darkPink',
    'purple',
    'red',
  ]),
  handleDelete: PropTypes.func,
  menu: PropTypes.array,
  title: PropTypes.string,
}

ModalHeader.defaultProps = {
  color: null,
  menu: null,
}

export default ModalHeader
