import React, { Component } from 'react';
import Page from 'components/Shared/Page';
import GridTable from "@nadavshaar/react-grid-table";
import Menu from "./Menu";
import { apiURL } from '../../utils/io'
import { Link } from 'react-router-dom';


class Library extends Component {

    constructor(props) {

        super(props)

        this.state = {
            columns: [
                {
                    id: 'Name',
                    field: 'name',
                    label: 'Название',
                    cellRenderer: ({ data }) => (
                        <input
                            type="text"
                            name="name"
                            value={data.name}
                            onChange={(e) => this.Change(e, data)}
                            style={{ width: "100%" }}
                        />
                    ),
                    editable: true,
                    width: "200px"
                },
                {
                    id: 'Counter',
                    field: 'counter',
                    label: 'Кол-во шаблонов',
                    editable: false,
                    width: "200px"
                },
                {
                    id: 'Delete',
                    label: 'Удалить',
                    width: "100px",
                    cellRenderer: ({ data }) => (
                        <button onClick={() => this.Delete(data.id)}>Удалить</button>
                    )
                },

                {
                    id: 'Route',
                    label: 'Перейти',
                    width: "100px",
                    cellRenderer: ({ data }) => (
                        <Link to={`/admin/libraries/${data.id}/components`}>перейти</Link>
                    )
                }
            ],
            rows: [],
            totalRows: 0,
            isLoading: true,
            name: ""
        }
    }

    componentDidMount() {
        this.All()
    }

    All = async () => {

        this.setState({ isLoading: true })
    
        try {
            const response = await fetch(`${apiURL}/libraries`)
            if (response.ok) {
                const data = await response.json()
                this.setState({
                    rows: data,
                    totalRows: data.length,
                    isLoading: false
                })
            } else {
                throw new Error('Network response was not ok.')
            }
        } catch (error) {
            console.error('Error fetching:', error)
        }finally{
            this.setState({ isLoading: false })
        }

    }
    
    New = async (e) => {

        e.preventDefault()

        const { name } = this.state

        if (!name) {
            return
        }
    
        this.setState({ isLoading: true });
    
        try {

            const response = await fetch(`${apiURL}/libraries`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name: name })
            })
    
            if (response.ok) {
                const data = await response.json();
                this.setState(prevState => ({
                    rows: [...prevState.rows, data],
                    totalRows: prevState.totalRows + 1,
                    name: ""
                }))
            } else {
                throw new Error('Failed to create')
            }
        } catch (error) {
            console.error('Error adding:', error)
        } finally {
            this.setState({ isLoading: false })
        }

    }

    Delete = async (id) => {

        this.setState({ isLoading: true })

        try {
            const response = await fetch(`${apiURL}/libraries/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                this.setState(prevState => ({
                    rows: prevState.rows.filter(o => o.id !== id),
                    totalRows: prevState.totalRows - 1
                }));
            } else {
                throw new Error('Failed to delete')
            }
        } catch (error) {
            console.error('Error deleting:', error)
        } finally {
            this.setState({ isLoading: false })
        }
    }

    Change = (e, data) => {

        const target = e.target
        const type = target.type
        let value = type === 'checkbox' ? target.checked : target.value
        const name = target.name
        


        let row_update = null

        this.setState(prevState => ({

            rows: prevState.rows.map(row => {

                if(row.id === data.id){

                    if(type === 'number'){
                        value = parseFloat(value)
                    }

                    row_update = { ...row, [name]: value }

                    return row_update
                }

                return row
            })

        }), async () => await this.Update(row_update))

    }

    Update = async (data) => {
        try {

            const response = await fetch(`${apiURL}/libraries`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })

            if (!response.ok) {
                throw new Error('Failed to update');
            }

        } catch (error) {
            console.error('Error updating:', error);
        }
    }

    render() {

        const { columns, rows, totalRows, isLoading } = this.state

        return (
            <Page className="admin-users-page" title="Категории библиотек">

                <Menu />

                <section className="users-header">
                    <h1>Категории библиотек</h1>
                </section>

                <form onSubmit={this.New}>
                    <input
                        type="text"
                        value={this.state.name}
                        onChange={e => this.setState({ name: e.target.value })}
                        placeholder="Введите название новой библиотеки"
                    />
                    <button type="submit">Добавить библиотеку</button>
                </form>

                <div className="grid-content">
                    {isLoading ? (
                        <div>Loading...</div>  // Индикатор загрузки
                    ) : (
                        <GridTable
                            showSearch={true}
                            showColumnVisibilityManager={true}
                            columns={columns}
                            rows={rows}
                            totalRows={totalRows}
                            texts={{
                                search: 'Поиск:',
                                totalRows: 'Общее кол-во:',
                                rows: 'Показано:',
                                selected: 'Выбрано',
                                rowsPerPage: 'Элементов на странице:',
                                page: 'Страница:',
                                of: 'из',
                                prev: 'Пред.',
                                next: 'След.',
                                columnVisibility: 'Колонки'
                            }}
                        />
                    )}
                </div>
            </Page>
        )
    }
}

export default Library