import {getGroupPath, getObject, optimize, pathLength, sort, subPath,} from 'common/utils'

import {getAbsoluteBbox} from './geometry'


// Easier format for reducers
export const getSnapGridV2 = (state, selection) => {

  const getGroupPath = (paths) => {

    const pathLength = (path) => {
        if (!path) {
            return 0
        }
    
        return path.split('.').length
    }

    const subPath = (path, length) => {
        return (path || '').split('.').slice(0, length).join('.')
    }

    const sortPaths = (paths) => {
        paths = [].concat(_toConsumableArray(paths))
        paths.sort(function (a, b) {
            return comparePaths(a, b)
        })
        return paths
    }
      
    if (paths.length === 0) {
        return null
    }

    let commonPath = paths[0]

    paths.forEach(function (path) {
        for (let l = pathLength(commonPath); l >= 0; l -= 1) {
            if (subPath(path, l) === subPath(commonPath, l)) {
                commonPath = subPath(commonPath, l)
                break
            }
        }
    });

    paths = sortPaths(paths)

    return paths[0].split('.').slice(0, commonPath.length + 1).join('.')
  }

  let selectionPath = getGroupPath(selection.map(id => state.map[id]))

  const createSnapGrid = (list, selectionPath = '', guides = false) => {

    const pathLength = (path) => {
  
      if (!path) {
        return 0;
      }
  
      return path.split('.').length
    }
  
    const subPath = (path, length) => {
      return (path || '').split('.').slice(0, length).join('.')
    }
  
    const getObject = (objects, path) => {
  
      if (!objects || !path || path.length === 0) {
        return null
      }
  
      if (typeof path === 'string') {
        path = path.split('.')
      }
  
      if (path.length === 1) {
        return objects[path[0]]
      }
  
      const newObject = objects[path[0]]
  
      return newObject && getObject(newObject.children, path.slice(1)) || null
    }
  
    const getAbsoluteBbox = (object, list, map) => {
    
      let path = map?.[object?.id]
    
      if (!path) {
        return object
      }
    
      let componentPath = subPath(path, 1)
    
      if (componentPath === path) {
        return object
      }
    
      let component = getObject(list, componentPath)
    
      return {
        id: object.id,
        x: object.x + component.x,
        y: object.y + component.y,
        width: object.width,
        height: object.height,
      }
    }
  
    const addPoints = (pointsMap, coords, obj, center = false) => {
      for (let i = 0; i < coords.length; i += 1) {
        let coord = coords[i]
    
        if (!pointsMap[coord]) {
          pointsMap[coord] = { objects: [] }
        }
    
        if (center) {
          pointsMap[coord].center = true
        } else {
          pointsMap[coord].edge = true
        }
    
        pointsMap[coord].objects.push(bbox(obj))
      }
    }
  
    const sort = function (arr) {
  
      const keyFunc = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (itm) {
        return itm;
      }
  
      return arr.slice(0).sort(function (a, b) {
        const aKey = keyFunc(a)
        const bKey = keyFunc(b)
    
        if (aKey < bKey) {
          return -1
        } else if (bKey < aKey) {
          return 1
        } else {
          return 0
        }
      })
  
    } // Single-level array-flattening function
  
    let xPoints = {}
    let yPoints = {}
  
    let objects = list.slice()
  
    if (guides) {
      let guides = []
  
      for (let obj of objects) {
        if (obj.width > 32 && obj.height > 36) {
          guides.push({
            id: obj.id,
            x: obj.x + 16,
            y: obj.y + 20,
            width: obj.width - 32,
            height: obj.height - 36,
          })
        }
      }
  
      objects = objects.concat(guides)
    }
  
    // Iterate through depths of selection
    for (let i = 1; i < pathLength(selectionPath); i += 1) {
      let parentPath = subPath(selectionPath, i)
      let parent = getObject(list, parentPath)
  
      // Iterate through siblings at this depth
      if (parent?.children) {
        for (let j = 0; j < parent.children.length; j += 1) {
          let child = parent.children[j]
          let map = { [child.id]: `${parentPath}.${j}` }
          let absoluteChild = getAbsoluteBbox(child, list, map)
          objects.push(absoluteChild)
        }
      }
    }
  
    for (let i = 0; i < objects.length; i += 1) {
      let obj = objects[i]
  
      let xCoords = [Math.round(obj.x), Math.round(obj.x + obj.width)]
      let yCoords = [Math.round(obj.y), Math.round(obj.y + obj.height)]
  
      addPoints(xPoints, xCoords, obj)
      addPoints(yPoints, yCoords, obj)
  
      let centerX = Math.round(obj.x) + Math.round(obj.width) / 2
      let centerY = Math.round(obj.y) + Math.round(obj.height) / 2
  
      // Centers
      addPoints(xPoints, [centerX], obj, true)
      addPoints(yPoints, [centerY], obj, true)
    }
  
    let xGrid = Object.keys(xPoints).map(x => ({
      ...xPoints[x],
      point: +x,
    }))
  
    let yGrid = Object.keys(yPoints).map(y => ({
      ...yPoints[y],
      point: +y,
    }))
  
    return {
      xGrid: sort(xGrid, ({ point }) => point),
      yGrid: sort(yGrid, ({ point }) => point),
    }
    
  }

  return createSnapGrid(state.list, selectionPath || '', true)
}



// Easier format for reducers
export const getSnapGrid = (state, selection) => {

  let selectionPath = getGroupPath(selection.map(id => state.map[id]))

  return createSnapGrid(state.list, selectionPath || '', true)
}





// Snap grid format:
//
// {
//   x: [{ x: 20, objectIds: [...] }],
//   y: [{ y: 42, objectIds: [...] }],
// }
//
// Coordinates are global and in zoom format

export const createSnapGridV2 = (list, selectionPath = '', guides = false) => {

  const pathLength = (path) => {

    if (!path) {
      return 0;
    }

    return path.split('.').length
  }

  const subPath = (path, length) => {
    return (path || '').split('.').slice(0, length).join('.')
  }

  const getObject = (objects, path) => {

    if (!objects || !path || path.length === 0) {
      return null
    }

    if (typeof path === 'string') {
      path = path.split('.')
    }

    if (path.length === 1) {
      return objects[path[0]]
    }

    const newObject = objects[path[0]]

    return newObject && getObject(newObject.children, path.slice(1)) || null
  }

  const getAbsoluteBbox = (object, list, map) => {
  
    let path = map?.[object?.id]
  
    if (!path) {
      return object
    }
  
    let componentPath = subPath(path, 1)
  
    if (componentPath === path) {
      return object
    }
  
    let component = getObject(list, componentPath)
  
    return {
      id: object.id,
      x: object.x + component.x,
      y: object.y + component.y,
      width: object.width,
      height: object.height,
    }
  }

  const addPoints = (pointsMap, coords, obj, center = false) => {
    for (let i = 0; i < coords.length; i += 1) {
      let coord = coords[i]
  
      if (!pointsMap[coord]) {
        pointsMap[coord] = { objects: [] }
      }
  
      if (center) {
        pointsMap[coord].center = true
      } else {
        pointsMap[coord].edge = true
      }
  
      pointsMap[coord].objects.push(bbox(obj))
    }
  }

  const sort = function (arr) {

    const keyFunc = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (itm) {
      return itm;
    }

    return arr.slice(0).sort(function (a, b) {
      const aKey = keyFunc(a)
      const bKey = keyFunc(b)
  
      if (aKey < bKey) {
        return -1
      } else if (bKey < aKey) {
        return 1
      } else {
        return 0
      }
    })

  } // Single-level array-flattening function

  let xPoints = {}
  let yPoints = {}

  let objects = list.slice()

  if (guides) {
    let guides = []

    for (let obj of objects) {
      if (obj.width > 32 && obj.height > 36) {
        guides.push({
          id: obj.id,
          x: obj.x + 16,
          y: obj.y + 20,
          width: obj.width - 32,
          height: obj.height - 36,
        })
      }
    }

    objects = objects.concat(guides)
  }

  // Iterate through depths of selection
  for (let i = 1; i < pathLength(selectionPath); i += 1) {
    let parentPath = subPath(selectionPath, i)
    let parent = getObject(list, parentPath)

    // Iterate through siblings at this depth
    if (parent?.children) {
      for (let j = 0; j < parent.children.length; j += 1) {
        let child = parent.children[j]
        let map = { [child.id]: `${parentPath}.${j}` }
        let absoluteChild = getAbsoluteBbox(child, list, map)
        objects.push(absoluteChild)
      }
    }
  }

  for (let i = 0; i < objects.length; i += 1) {
    let obj = objects[i]

    let xCoords = [Math.round(obj.x), Math.round(obj.x + obj.width)]
    let yCoords = [Math.round(obj.y), Math.round(obj.y + obj.height)]

    addPoints(xPoints, xCoords, obj)
    addPoints(yPoints, yCoords, obj)

    let centerX = Math.round(obj.x) + Math.round(obj.width) / 2
    let centerY = Math.round(obj.y) + Math.round(obj.height) / 2

    // Centers
    addPoints(xPoints, [centerX], obj, true)
    addPoints(yPoints, [centerY], obj, true)
  }

  let xGrid = Object.keys(xPoints).map(x => ({
    ...xPoints[x],
    point: +x,
  }))

  let yGrid = Object.keys(yPoints).map(y => ({
    ...yPoints[y],
    point: +y,
  }))

  return {
    xGrid: sort(xGrid, ({ point }) => point),
    yGrid: sort(yGrid, ({ point }) => point),
  }

}















// Snap grid format:
//
// {
//   x: [{ x: 20, objectIds: [...] }],
//   y: [{ y: 42, objectIds: [...] }],
// }
//
// Coordinates are global and in zoom format

export const createSnapGrid = (list, selectionPath = '', guides = false) => {
  let xPoints = {}
  let yPoints = {}

  let objects = list.slice()

  if (guides) {
    let guides = []

    for (let obj of objects) {
      if (obj.width > 32 && obj.height > 36) {
        guides.push({
          id: obj.id,
          x: obj.x + 16,
          y: obj.y + 20,
          width: obj.width - 32,
          height: obj.height - 36,
        })
      }
    }

    objects = objects.concat(guides)
  }

  // Iterate through depths of selection
  for (let i = 1; i < pathLength(selectionPath); i += 1) {
    let parentPath = subPath(selectionPath, i)
    let parent = getObject(list, parentPath)

    // Iterate through siblings at this depth
    if (parent?.children) {
      for (let j = 0; j < parent.children.length; j += 1) {
        let child = parent.children[j]
        let map = { [child.id]: `${parentPath}.${j}` }
        let absoluteChild = getAbsoluteBbox(child, list, map)
        objects.push(absoluteChild)
      }
    }
  }

  for (let i = 0; i < objects.length; i += 1) {
    let obj = objects[i]

    let xCoords = [Math.round(obj.x), Math.round(obj.x + obj.width)]
    let yCoords = [Math.round(obj.y), Math.round(obj.y + obj.height)]

    addPoints(xPoints, xCoords, obj)
    addPoints(yPoints, yCoords, obj)

    let centerX = Math.round(obj.x) + Math.round(obj.width) / 2
    let centerY = Math.round(obj.y) + Math.round(obj.height) / 2

    // Centers
    addPoints(xPoints, [centerX], obj, true)
    addPoints(yPoints, [centerY], obj, true)
  }

  let xGrid = Object.keys(xPoints).map(x => ({
    ...xPoints[x],
    point: +x,
  }))

  let yGrid = Object.keys(yPoints).map(y => ({
    ...yPoints[y],
    point: +y,
  }))

  return {
    xGrid: sort(xGrid, ({ point }) => point),
    yGrid: sort(yGrid, ({ point }) => point),
  }
}










export const addPoints = (pointsMap, coords, obj, center = false) => {
  for (let i = 0; i < coords.length; i += 1) {
    let coord = coords[i]

    if (!pointsMap[coord]) {
      pointsMap[coord] = { objects: [] }
    }

    if (center) {
      pointsMap[coord].center = true
    } else {
      pointsMap[coord].edge = true
    }

    pointsMap[coord].objects.push(bbox(obj))
  }
}

export const bbox = obj => {
  let { id, x, y, width, height } = obj

  return { id, x, y, width, height }
}

export const isCenter = key => {
  return key.match(/center$/)
}

export const getSnapValue = (grid, coords, zoom, isResize = false) => {

  let coordValues = Object.keys(coords).map(key => ({
    key,
    value: coords[key],
  }))

  let tolerence = 3.7795275591 / zoom.scale

  let centerGrid = grid.filter(g => g.center)
  let edgeGrid = grid.filter(g => g.edge)

  let candidates = coordValues.map(({ key, value }) => {

      let scaledTolerence = tolerence

      if (isResize && isCenter(key)) {
        scaledTolerence = tolerence / 2
      }

      let grid = isCenter(key) ? centerGrid : edgeGrid

      let snapValue = optimize(grid, ({ point }) => {
        return -Math.abs(point - value)
      })

      if (snapValue && Math.abs(snapValue.point - value) <= scaledTolerence) {
        return { key, value: snapValue.point }
      }

      return undefined
    }).filter(result => result)

  let result = optimize(candidates, ({ key, value }) => {
    let factor = isCenter(key) && isResize ? 2 : 1

    return -Math.abs(value - coords[key]) * factor
  })

  if (result) {
    return {
      [result.key]: result.value,
    }
  }

  return null
}

export const getSnapGridValue = (grid, coords, zoom, isResize = false) => {
  let coordValues = Object.keys(coords).map(key => ({
    key,
    value: coords[key],
  }))



  let tolerence = 10 / zoom.scale

  let centerGrid = grid.filter(g => g.center)
  let edgeGrid = grid.filter(g => g.edge)

  let candidates = coordValues
    .map(({ key, value }) => {
      let scaledTolerence = tolerence

      if (isResize && isCenter(key)) {
        scaledTolerence = tolerence / 2
      }

      if (value % 10 /*snapValue && Math.abs(snapValue.point - value)*/ <= scaledTolerence) {
        return { key, value:  Math.round(value / 10) * 10 }
      }

      return undefined
    })
    .filter(result => result)

  let result = optimize(candidates, ({ key, value }) => {
    let factor = isCenter(key) && isResize ? 2 : 1

    return -Math.abs(value - coords[key]) * factor
  })

  if (result) {

    return {
      [result.key]: result.value,
    }
  }

  return null
}

export const roundObject = object => {
  let { x, y, width, height } = object

  let newLeft = Math.round(x)
  let newRight = Math.round(x + width)

  let newTop = Math.round(y)
  let newBottom = Math.round(y + height)

  return {
    ...object,
    x: newLeft,
    y: newTop,
    width: newRight - newLeft,
    height: newBottom - newTop,
  }
}
