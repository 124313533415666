import React, { Component } from 'react';
import Page from 'components/Shared/Page';
import GridTable from "@nadavshaar/react-grid-table";
import Menu from "./Menu";
import { apiURL } from '../../utils/io';
import { Link } from 'react-router-dom';

class TemplateType extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [
                {
                    id: 'Name',
                    field: 'name',
                    label: 'Название',
                    cellRenderer: ({ data }) => (
                        <input
                            value={data.name}
                            onChange={(e) => this.handleNameChange(e, data.id)}
                            className="w-full"
                        />
                    ),
                    editable: true,
                    width: "200px"
                },
                {
                    id: 'Counter',
                    field: 'counter',
                    label: 'Кол-во шаблонов',
                    editable: false,
                    width: "200px",
                    cellRenderer: ({ data }) => (
                        <div>{data.counter}</div>
                    )
                },
                {
                    id: 'Delete',
                    label: 'Удалить',
                    width: "100px",
                    cellRenderer: ({ data }) => (
                        <button 
                            onClick={() => this.TemplateTypesDelete(data.id)}
                            className="text-red-500 hover:underline"
                        >
                            Удалить
                        </button>
                    )
                },
                {
                    id: 'Route',
                    label: 'Перейти',
                    width: "100px",
                    cellRenderer: ({ data }) => (
                        <Link to={`/admin/template-type/${data.id}/templates`} className="text-blue-500 hover:underline">
                            Перейти
                        </Link>
                    )
                }
            ],
            rows: [],
            totalRows: 0,
            isLoading: true,
            newCategoryName: ""
        };
    }

    componentDidMount() {
        this.TemplateTypesAll();
    }

    TemplateTypesAll = async () => {
        this.setState({ isLoading: true });
    
        try {
            const response = await fetch(`${apiURL}/template_types`);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    rows: data,
                    totalRows: data.length,
                    isLoading: false
                });
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            console.error('Error fetching template types:', error);
        } finally {
            this.setState({ isLoading: false });
        }
    }
    
    TemplateTypesNew = async (e) => {
        e.preventDefault();
        const { newCategoryName } = this.state;

        if (!newCategoryName) {
            alert('Введите название категории!');
            return;
        }
    
        this.setState({ isLoading: true });
    
        try {
            const response = await fetch(`${apiURL}/template_types`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name: newCategoryName })
            });
    
            if (response.ok) {
                const addedCategory = await response.json();
                this.setState(prevState => ({
                    rows: [...prevState.rows, addedCategory],
                    totalRows: prevState.totalRows + 1,
                    newCategoryName: ""
                }));
            } else {
                throw new Error('Failed to create new category');
            }
        } catch (error) {
            console.error('Error adding new template type:', error);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    TemplateTypesDelete = async (categoryId) => {
        this.setState({ isLoading: true });

        try {
            const response = await fetch(`${apiURL}/template_types/${categoryId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                this.setState(prevState => ({
                    rows: prevState.rows.filter(category => category.id !== categoryId),
                    totalRows: prevState.totalRows - 1
                }));
            } else {
                throw new Error('Failed to delete the category');
            }
        } catch (error) {
            console.error('Error deleting template type:', error);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    handleNameChange = (e, id) => {
        const newName = e.target.value;

        this.setState(prevState => ({
            rows: prevState.rows.map(row => 
                row.id === id ? { ...row, name: newName } : row
            )
        }));

        this.updateCategoryName(id, newName);
    }

    updateCategoryName = async (id, newName) => {
        try {
            const response = await fetch(`${apiURL}/template_types`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: id, name: newName })
            });

            if (!response.ok) {
                throw new Error('Failed to update category');
            }
        } catch (error) {
            console.error('Error updating template type:', error);
        }
    }

    render() {
        const { columns, rows, totalRows, isLoading } = this.state;

        return (
            <Page className="admin-users-page" title="Категории шаблонов">
                <Menu />
                <section className="mb-4">
                    <h1 className="text-2xl font-bold">Категории шаблонов</h1>
                </section>

                <form onSubmit={this.TemplateTypesNew} className="mb-4 flex">
                    <input
                        type="text"
                        value={this.state.newCategoryName}
                        onChange={e => this.setState({ newCategoryName: e.target.value })}
                        placeholder="Введите название новой категории"
                        className="w-full p-2 border rounded mr-2"
                    />
                    <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                        Добавить категорию
                    </button>
                </form>

                <div className="grid-content">
                    {isLoading ? (
                        <div className="text-center">Loading...</div>  // Индикатор загрузки
                    ) : (
                        <GridTable
                            showSearch={true}
                            showColumnVisibilityManager={true}
                            columns={columns}
                            rows={rows}
                            totalRows={totalRows}
                            texts={{
                                search: 'Поиск:',
                                totalRows: 'Общее кол-во:',
                                rows: 'Показано:',
                                selected: 'Выбрано',
                                rowsPerPage: 'Элементов на странице:',
                                page: 'Страница:',
                                of: 'из',
                                prev: 'Пред.',
                                next: 'След.',
                                columnVisibility: 'Колонки'
                            }}
                        />
                    )}
                </div>
            </Page>
        );
    }
}

export default TemplateType;
