import React, { Component } from 'react'
import { connect } from 'react-redux'
import { notify } from 'ducks/notifications'
import { withRouter } from 'react-router-dom'

import { setOrientation } from 'components/Admin/orientations/store/orintationActions';

import { getApp } from 'ducks/apps/selectors'
import { updateOrientation } from 'components/Admin/orientations/apiUtils'
import NumberInput from 'components/Admin/orientations/NumberInput';

class OrintationDetail extends Component {

    handleInputChange = (e) => {

        const { name, value, type, checked } = e.target
        const parsedValue = type === 'checkbox' ? checked : type === 'number' ? Number(value) : value

        this.props.setOrientation({
            ...this.props.orientation,
            [name]: parsedValue,
        })

    }

    handleFormSubmit = async (e) => {
        e.preventDefault()

        const { notify, orientation } = this.props

        try {

            await updateOrientation({
                ...orientation,
            })

        } catch (error) {
            notify(`Error: ${error.message}`)
        }

    }

    render() {
        
        const { orientation } = this.props;

        return (
            <form onSubmit={this.handleFormSubmit} className="space-y-6 bg-white p-6 shadow-md rounded-md">

                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Название
                </label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={orientation.name}
                    onChange={this.handleInputChange}
                    placeholder="Название"
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    required
                />

                <NumberInput code={'width'} name={'Ширина'} value={orientation.width} onChange={this.handleInputChange} />

                <NumberInput code={'height'} name={'Высота'} value={orientation.height} onChange={this.handleInputChange} />

                <NumberInput code={'depth'} name={'Глубина'} value={orientation.depth} onChange={this.handleInputChange} />

                <NumberInput code={'unitToPixelRatio'} name={'Отношение единицы Измерения к Пикселю'} value={orientation.unitToPixelRatio} onChange={this.handleInputChange} />

                <button
                    type="submit"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    Обновить
                </button>
            </form>
        );
    }
}

const mapStateToProps = (state) => {

    const app = getApp(state, 'n581kk19ktbw9cctimjy5rykhe');

    return {
        app: app,
        orientation: state.admin.orientation.details,
    }

}

const mapDispatchToProps = (dispatch) => ({
    notify: (message) => dispatch(notify(message)),
    setOrientation: (orientation) => dispatch(setOrientation(orientation)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrintationDetail))
