import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import { getApp } from "ducks/apps/selectors";
import FileSaver from 'file-saver'
import './ShareButton.scss'
import {PathItem, Rectangle as PathRectangle, Segment, Size} from "../../../utils/vector";

import * as makerjs from 'makerjs';
import {getCurrentAppId} from "../../../ducks/editor/objects";
import {GROUP, SHAPE} from "../../../common/constants";
import {getId} from "../../../common/utils";
import {getBaseZoom} from "../../../utils/zoom";
import {element} from "prop-types";
import { export_in_dxf } from "ducks/users";



class ShareButton extends Component {

  componentDidUpdate = prevProps => {
    let {} = this.props
  }

  getOverflows = ( mod) => {

    const {app} = this.props
    
    let baseZoom = getBaseZoom()

    const arr = Object.values(app.components).sort(function (a, b) {
      return a.order - b.order;
    })
    function flattenLayer(objects, flatten) {

      flatten = flatten || {}

      if (!Array.isArray(objects)) {
        objects = [objects]
      }

      objects.forEach((item) => {

        if (item.type === GROUP) {
          item.children?.forEach((obj) => {
            obj = Object.assign({},  obj)
            flattenLayer(obj, flatten)
          })
          return
        }

        if (item.compound && item.compound.length > 0) {
          item.compound?.forEach((obj) => {
            flattenLayer(obj, flatten)
          })
          return
        }

        if (item.points && item.points.length > 0) {
          let p = PathItem.create(item.points)
          const bounds = p.bounds

          item = Object.assign({}, {
            id: getId(),
            isClosed: true,
            //children: curr.children,
            points: item.points,
            x: bounds.x,
            y: bounds.y,
            width: bounds.width,
            height: bounds.height,
            depth: item.depth,
            type: SHAPE
          })
        }


        flatten[item.id] = item
      })


      return Object.values(flatten)
    }

    let overflow = {}





    let flatten =  []
    let cloned = []


    const unit = 1 / 0.2645833333
    const crat = 5 * unit


    arr.forEach((component) => {

      let depths = []


      if (mod < component.depth) {
        const len =  ~~(component.depth / mod);
        depths = new Array(len).fill(mod);

        if (component.depth % mod !== 0) {
          let module = component.depth % mod

          const len2 =  ~~(module / crat);
          depths.push(len2 * crat)
        }
      } else {
        const len2 =  ~~(component.depth / crat);
        depths.push(len2 * crat)
      }

      let ff = flattenLayer(component.objects)
      flatten = [...flatten, ...ff]


      depths.forEach((depth, index) => {
        let elements = []

        cloned = flatten.slice()

        cloned.forEach((flat, index) => {
          const obj = Object.assign({},  flat)

          if ( obj.depth - depth > 0) {
            obj.depth = depth
            flat.depth -= depth
            elements.push(obj)
          } else {
            flatten.splice(index,1)
            elements.push(obj)
          }
        })

        if (!overflow[component.id]) {
          overflow[component.id] = []
        }

        overflow[component.id].push({depth: depth, elements: elements})

      })


    })

    return overflow
  }

  generate = () => {
    const {app, components} = this.props

    const coff = 0.264583333
    var models = {};
    let box = null

    if (app && app.outer_rule && Array.isArray(app.outer_rule)) {
      app.outer_rule.map((outer) => {
        const path = PathItem.create(outer.points)
        path.scale(96)
        path.adjust(0, 0)

        box = makerjs.importer.fromSVGPathData(path.getPathData());
        box = makerjs.model.mirror(box, false, true);

      })
    } else {
      if (app.borderRadius) {
        const rect = new PathRectangle(0, 0, app.width, app.height);
        const kappa = 4 * (Math.sqrt(2) - 1) / 3
        const bl = rect.getBottomLeft(true),
          tl = rect.getTopLeft(true),
          tr = rect.getTopRight(true),
          br = rect.getBottomRight(true)
        ;


        let r = Size.min(new Size(app.borderRadius, app.borderRadius), rect.getSize(true).divide(2));
        const rx = r.width,
          ry = r.height,
          hx = rx * kappa,
          hy = ry * kappa;
        let segments = [
          new Segment(bl.add(rx, 0), null, [-hx, 0]),
          new Segment(bl.subtract(0, ry), [0, hy]),
          new Segment(tl.add(0, ry), null, [0, -hy]),
          new Segment(tl.add(rx, 0), [-hx, 0], null),
          new Segment(tr.subtract(rx, 0), null, [hx, 0]),
          new Segment(tr.add(0, ry), [0, -hy], null),
          new Segment(br.subtract(0, ry), null, [0, hy]),
          new Segment(br.subtract(rx, 0), [hx, 0])
        ];


        const path = PathItem.create(segments.map(({point, handleIn, handleOut}) => {
          return new Segment(point, handleIn, handleOut)
        }))
        path.setClosed(true)
        box = makerjs.importer.fromSVGPathData(path.getPathData());

      } else {
        box = new makerjs.models.Rectangle(app.width /** coff*/, app.height /** coff*/)
      }
    }

    let MODEL = {

      units: makerjs.unitType.Millimeter,
      models: {}

    }


    //components.map((obj) => {


      //let layerId = obj.id

      const unit = 1 / 0.2645833333
      const elements = this.getOverflows(50 * unit)
      const draw = (shapeObject, layerId) => {

        const id = getId()

        const {width, height, depth, x, y, points, compound, type} = shapeObject
        let path = PathItem.create()

        if (shapeObject.children && shapeObject.type === GROUP) {
          shapeObject.children.forEach((children) => {
            draw(children, layerId)
          })
          return
        }

        if (compound && compound.length > 0) {
          path = PathItem.create(compound)
          path.children.forEach((children) => {
            const bounds = children.bounds
            draw({...children, points: children.points, x: bounds.x, y: bounds.y, width: bounds.width, height: bounds.height, depth: children.depth},layerId)
          })

          return
        }



        if (points && points.length > 0) {
          path = PathItem.create(points)
        }

        path.setClosed(true)
        path.scale(coff)
        path.adjust(0, 0)

        const bounds = path.bounds

        let shape = makerjs.importer.fromSVGPathData(path.getPathData());

        //shape = makerjs.model.mirror(shape, false , true);
        //let shape = new makerjs.models.Rectangle(width /** coff*/, height /** coff*/)

        makerjs.model.move(shape, [x * coff, -y * coff]);
        shape.caption = {
          text: `${Math.round(depth * coff)} `,
          anchor: new makerjs.paths.Line([x * coff + bounds.width / 2 + bounds.x, -y * coff - 10 - bounds.top], [x * coff + bounds.width / 2 + bounds.x, -y * coff -  10 -bounds.top])
        }
        shape.layer= layerId
        MODEL.models[id] = shape;

        // return mesh
      }

      let i = 0
      for (const [key, base] of Object.entries(elements)) {

        i++
        base.forEach((layer, index) => {
          const idx = i + "." + index
          let modelBox = makerjs.cloneObject(box)
          modelBox.layer = idx

          modelBox = makerjs.model.mirror(modelBox, false, true);
          modelBox = makerjs.model.scale(modelBox, coff)

          MODEL.models['modelBox_' + key + "_" + layer.depth ] = modelBox
          const obj = components.find(o => o.id === key);
          let frameBox = new makerjs.models.Rectangle(obj.width * 1.1, obj.height * 1.1)

          //frameBox.layer = 'frame'



          frameBox = makerjs.model.mirror(frameBox, false, true);
          frameBox = makerjs.model.scale(frameBox, coff)
          frameBox.caption = {
            text: `${Math.round(obj.width * 1.1 * coff)} x ${Math.round(obj.height * 1.1 * coff)} x ${Math.round(layer.depth  * coff)}`,
            anchor: new makerjs.paths.Line([0, -(obj.height - obj.height * 1.1) / 2 * coff - 10], [obj.width * coff, -(obj.height - obj.height * 1.1) / 2 * coff  - 10])
          }
          makerjs.model.move(frameBox, [((obj.width - obj.width * 1.1) / 2) * coff, ((obj.height - obj.height * 1.1) / 2) * coff * -1]);
          frameBox.layer= idx
          MODEL.models["frameBox_" + key + "_" + layer.depth] = frameBox

          layer.elements && Array.isArray(layer.elements) && layer.elements.forEach((el) => {

            draw(el, idx)

          })
        })

      }





    //})





    const svg = makerjs.exporter.toSVG(MODEL)


    const output = makerjs.exporter.toDXF(MODEL)
    const blob = new Blob([output], {type: 'text/plain;charset=utf-8'})
    const blobSvg = new Blob([svg], {type: 'text/plain;charset=utf-8'})
    FileSaver.saveAs(blob, app.name + '.dxf')
    FileSaver.saveAs(blobSvg, app.name + '.svg')

  }

  render() {
    
    const { app, export_in_dxf } = this.props

    if (!export_in_dxf) {
      return null; // Возвращаем null, если export_in_dxf false
    }

    return (
      <div className="editor-share-button-wrapper">
        <span onClick={this.generate} rel="noopener noreferrer" className="editor-share-button">
          Экспорт в DXF
        </span>
      </div>
    )
  }
}

const mapStateToProps = (state, {match}) => {

  const { appId } = match.params
  const app = getApp(state, getCurrentAppId(state))
  const components = app ? Object.values(app.components) : []

  return {
    app,
    components,
    export_in_dxf: export_in_dxf(state),
  }

}

export default withRouter(
  connect(mapStateToProps, {})(ShareButton)
)