import {deepMap, getId, traverseMapObject} from 'common/utils'
import {selectors, sourceTypes} from 'common/constants'

export const changeChildIds = (object, map = {}) => {
  let children = object.children

  if (!children) {
    return object
  }

  children = deepMap(object.children, itm => {
    let newId = getId()
    map[itm.id] = newId

    return {
      ...itm,
      id: newId,
    }
  })

  children = traverseMapObject(children, itm => {
    if (itm && itm.type === selectors.LIST_ITEM && map[itm.listObjectId]) {
      return {
        ...itm,
        listObjectId: map[itm.listObjectId],
      }
    }

    if (itm && itm.type === sourceTypes.INPUT && map[itm.objectId]) {
      return {
        ...itm,
        objectId: map[itm.objectId],
      }
    }

    return itm
  })

  return { ...object, children }
}
