import React, {Component} from 'react'
import {connect} from 'react-redux'
import {formatterOptions} from 'common/constants'
import {MultiMenuTrigger} from 'react-multi-menu'

import {buildFormula} from 'utils/formulas'


import {getComponent, getCurrentAppId,} from 'ducks/editor/objects'

import EntityTextarea from '../Forms/EntityTextarea'
import {IconButton} from '../Icon'
import BindingEntity from './BindingEntity'

import './BindableTextControl.scss'

export class NakedBindableTextControl extends Component {
  static defaultProps = {
    disableBinding: false,
    hideBinding: false,
  }

  constructor(props) {
    super(props)

    const { childRef } = props

    this.textareaRef = childRef || React.createRef()
    this.textarea = this.textareaRef
  }

  handleChange = newValue => {
    let { name, onChange } = this.props

    onChange({ [name]: addLabelToFormula(newValue) })
  }

  renderEntity = (entity, { onDelete, onUpdate, onClick }) => {
    let {
      appId,
      componentId,
      objectId,
      role,
      reference,
      disableIcon,
      disableChip,
      getLabel,
      enableTestValue,
      fromModal,
    } = this.props

    reference = role === 'listItem' ? reference : null

    return (
      <BindingEntity
        entity={entity}
        appId={appId}
        componentId={componentId}
        objectId={objectId}
        role={role}
        reference={reference}
        getLabel={getLabel}
        onDelete={onDelete}
        onUpdate={onUpdate}
        onClick={onClick}
        disableIcon={disableIcon}
        disableChip={disableChip}
        enableTestValue={enableTestValue}
        fromModal={fromModal}
      />
    )
  }

  handleAddEntity = binding => {
    if (binding.type === 'formula' || !binding.source) {
      return this.textarea.current.addEntity(binding, true)
    }

    let { dataType } = binding.source
    let options = formatterOptions[dataType] || []
    let option = options[0]
    let format = option ? { type: option.value } : undefined

    this.textarea.current.addEntity({
      ...binding,
      format,
    })
  }

  handleMouseDown = e => {
    e.preventDefault()
  }

  getOptions = () => () => {
    let { disableFormulas, externalAPIField } = this.props

    let options =  []

    if (externalAPIField) {
      options = [
        { type: 'title', label: 'Logged In User' },
        ...options[0].children,
      ]
    }

    if (!disableFormulas) {
      if (options.length > 0) {
        options.push(null)
      }

      options.push({
        label: 'New Formula...',
        value: buildFormula(''),
      })
    }

    return addLabelToOptions(options)
  }

  render() {
    let {
      disableBinding,
      hideBinding,
      label,
      displayName,
      placeholder,
      value,
      hideDisplayName,
      meta,
      apiBaseURL,
    } = this.props

    let { touched, error } = meta || {}

    displayName = displayName || label

    value = value || ''

    if (value && !Array.isArray(value) && typeof value === 'object') {
      value = ['', value, '']
    }

    const icon = (
      <IconButton
        orange
        type="magic-text"
        onMouseDown={this.handleMouseDown}
        className="bindable-text-control-trigger-icon"
      />
    )

    let bindableTextControlClass = 'bindable-text-control'
    let entityTextAreaClass = ''

    if (apiBaseURL) {
      bindableTextControlClass += ' bindable-text-control-api'
      entityTextAreaClass += 'entity-textarea-api'
    }

    return (
      <div className={bindableTextControlClass}>
        <div className="bindable-text-control-header">
          {!hideDisplayName ? <p>{displayName}</p> : null}
          {!hideBinding && !disableBinding ? (
            <div className="bindable-text-control-trigger-wrapper">
              <MultiMenuTrigger
                menu={this.getOptions()}
                onSelect={this.handleAddEntity}
                className="bindable-text-control-trigger"
                menuClassName="bindable-text-control-menu"
                rowHeight={40}
                width={260}
              >
                {icon}
              </MultiMenuTrigger>
            </div>
          ) : null}
        </div>

        <EntityTextarea
          value={value}
          onChange={this.handleChange}
          placeholder={placeholder}
          renderEntity={this.renderEntity}
          childRef={this.textareaRef}
          className={
            touched && error
              ? 'bindable-text-control-error'
              : entityTextAreaClass
          }
        />
      </div>
    )
  }
}

export function addLabelToOptions(opts) {
  return (
    opts &&
    opts.map(opt => {
      if (!opt) {
        return null
      }

      if (opt.children) {
        return {
          ...opt,
          children:
            typeof opt.children === 'function'
              ? () => addLabelToOptions(opt.children())
              : addLabelToOptions(opt.children),
        }
      }

      return {
        ...opt,
        value: {
          ...opt.value,
          label: opt.label,
        },
      }
    })
  )
}

function addLabelToFormula(values) {
  if (Array.isArray(values)) {
    return values.map(value => {
      if (typeof value === 'object' && value.type === 'formula') {
        return { ...value, label: '0' }
      } else {
        return value
      }
    })
  }

  return values
}

const mapStateToProps = (
  state,
  { objectId, role, reference, actionId, disableBinding, externalAPIField }
) => {
  const appId = getCurrentAppId(state)
  const componentId = getComponent(state, objectId)?.id

  const props = {
    appId,
    componentId,
    objectId,
  }


  return props
}

export default connect(mapStateToProps)(NakedBindableTextControl)
