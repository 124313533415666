import { SET_TEMPLATE } from './templateActions'

const initialState = {
    available: true,
    name: '3I-0705-3',
    bid: 'skb-3i-0705-3',
    width: 1000,
    height: 1000,
    lower_width: 456,
    lower_height: 696,
    template_type_id: 0,
    label: '3I-0705-3',
    border_radius: 18,
    depth: 312,
    category_string: "",
    border_width: 48,
    iconUrl: "",
}

export const getWidth = (state) => state.width

const templateDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TEMPLATE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

export default templateDetailsReducer
