import React, {Component} from 'react'
import Loading from '../../Shared/Loading'

export default class CanvasLoader extends Component {
  render() {
    return (
      <div className="canvas-loader">
        <Loading />
      </div>
    )
  }
}
