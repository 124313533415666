import { SET_OUTER_RULE } from './templateActions';


const initialState = null


//[
    // {
    //     "x": 0,
    //     "y": 0,
    //     "shape": "POLYGON",
    //     "width": 13,
    //     "height": 8,
    //     "rotation": 0,
    //     "depth": 1,
    //     "points": [
    //         [
    //             12.75,
    //             8
    //         ],
    //         [
    //             12.845670887112366,
    //             7.980969933081024
    //         ],
    //         [
    //             12.9267767499998,
    //             7.926776749999799
    //         ],
    //         [
    //             12.980969933081024,
    //             7.845670887112367
    //         ],
    //         [
    //             13,
    //             7.75
    //         ],
    //         [
    //             13,
    //             0.25
    //         ],
    //         [
    //             12.980969933081024,
    //             0.15432911288763324
    //         ],
    //         [
    //             12.9267767499998,
    //             0.0732232500002003
    //         ],
    //         [
    //             12.845670887112366,
    //             0.019030066918975744
    //         ],
    //         [
    //             12.75,
    //             0
    //         ],
    //         [
    //             0.25,
    //             0
    //         ],
    //         [
    //             0.15432911288763324,
    //             0.019030066918975744
    //         ],
    //         [
    //             0.0732232500002003,
    //             0.0732232500002003
    //         ],
    //         [
    //             0.019030066918975744,
    //             0.15432911288763324
    //         ],
    //         [
    //             0,
    //             0.25
    //         ],
    //         [
    //             0,
    //             7.75
    //         ],
    //         [
    //             0.019030066918975744,
    //             7.845670887112367
    //         ],
    //         [
    //             0.0732232500002003,
    //             7.926776749999799
    //         ],
    //         [
    //             0.15432911288763324,
    //             7.980969933081024
    //         ],
    //         [
    //             0.25,
    //             8
    //         ],
    //         [
    //             1.5,
    //             8
    //         ],
    //         [
    //             1.5,
    //             7.9375
    //         ],
    //         [
    //             1.5025446158833802,
    //             7.90192128149703
    //         ],
    //         [
    //             1.5101267155483724,
    //             7.867066840677021
    //         ],
    //         [
    //             1.5225919490151751,
    //             7.833646214791001
    //         ],
    //         [
    //             1.539686559605448,
    //             7.802339752692765
    //         ],
    //         [
    //             1.561062549675206,
    //             7.773784764885652
    //         ],
    //         [
    //             1.5862847648856517,
    //             7.7485625496752055
    //         ],
    //         [
    //             1.6148397526927651,
    //             7.727186559605448
    //         ],
    //         [
    //             1.6461462147910004,
    //             7.710091949015175
    //         ],
    //         [
    //             1.6795668406770206,
    //             7.697626715548372
    //         ],
    //         [
    //             1.71442128149703,
    //             7.69004461588338
    //         ],
    //         [
    //             1.75,
    //             7.6875
    //         ],
    //         [
    //             3.0625,
    //             7.6875
    //         ],
    //         [
    //             3.09807871850297,
    //             7.69004461588338
    //         ],
    //         [
    //             3.132933159322979,
    //             7.697626715548372
    //         ],
    //         [
    //             3.1663537852089996,
    //             7.710091949015175
    //         ],
    //         [
    //             3.197660247307235,
    //             7.727186559605448
    //         ],
    //         [
    //             3.2262152351143483,
    //             7.7485625496752055
    //         ],
    //         [
    //             3.251437450324794,
    //             7.773784764885652
    //         ],
    //         [
    //             3.272813440394552,
    //             7.802339752692765
    //         ],
    //         [
    //             3.289908050984825,
    //             7.833646214791001
    //         ],
    //         [
    //             3.302373284451628,
    //             7.867066840677021
    //         ],
    //         [
    //             3.30995538411662,
    //             7.90192128149703
    //         ],
    //         [
    //             3.3125,
    //             7.9375
    //         ],
    //         [
    //             3.3125,
    //             8
    //         ],
    //         [
    //             9.6875,
    //             8
    //         ],
    //         [
    //             9.6875,
    //             7.9375
    //         ],
    //         [
    //             9.69004461588338,
    //             7.90192128149703
    //         ],
    //         [
    //             9.697626715548372,
    //             7.867066840677021
    //         ],
    //         [
    //             9.710091949015176,
    //             7.833646214791001
    //         ],
    //         [
    //             9.727186559605448,
    //             7.802339752692765
    //         ],
    //         [
    //             9.748562549675206,
    //             7.773784764885652
    //         ],
    //         [
    //             9.77378476488565,
    //             7.7485625496752055
    //         ],
    //         [
    //             9.802339752692765,
    //             7.727186559605448
    //         ],
    //         [
    //             9.833646214791,
    //             7.710091949015175
    //         ],
    //         [
    //             9.86706684067702,
    //             7.697626715548372
    //         ],
    //         [
    //             9.90192128149703,
    //             7.69004461588338
    //         ],
    //         [
    //             9.9375,
    //             7.6875
    //         ],
    //         [
    //             11.25,
    //             7.6875
    //         ],
    //         [
    //             11.28557871850297,
    //             7.69004461588338
    //         ],
    //         [
    //             11.32043315932298,
    //             7.697626715548372
    //         ],
    //         [
    //             11.353853785209,
    //             7.710091949015175
    //         ],
    //         [
    //             11.385160247307235,
    //             7.727186559605448
    //         ],
    //         [
    //             11.41371523511435,
    //             7.7485625496752055
    //         ],
    //         [
    //             11.438937450324794,
    //             7.773784764885652
    //         ],
    //         [
    //             11.460313440394552,
    //             7.802339752692765
    //         ],
    //         [
    //             11.477408050984824,
    //             7.833646214791001
    //         ],
    //         [
    //             11.489873284451628,
    //             7.867066840677021
    //         ],
    //         [
    //             11.49745538411662,
    //             7.90192128149703
    //         ],
    //         [
    //             11.5,
    //             7.9375
    //         ],
    //         [
    //             11.5,
    //             8
    //         ],
    //         [
    //             12.75,
    //             8
    //         ]
    //     ],
    //     "name": "untitled",
    //     "groups": [],
    //     "isFingerNotch": false
    // }
//]

const outerRuleReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_OUTER_RULE:
            return action.payload;
        default:
            return state;
    }
};

export default outerRuleReducer;
