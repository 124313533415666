import React, {Component} from 'react'
import MultiMenu from 'react-multi-menu'
import classNames from 'classnames'

import Tooltip from 'components/Shared/Tooltip'
import Icon from 'components/Shared/Icon'

export default class MenuControl extends Component {
  static defaultProps = {
    rowHeight: 36,
  }

  setValue = () => {
    let { autoSelect, name, options, onChange, value } = this.props

    if (autoSelect && !value && options?.length > 0) {
      onChange({ [name]: options?.[0]?.value })
    }
  }

  handleChange = val => {
    let { name, onChange, input } = this.props

    if (input) {
      input.onChange(val)
    }

    if (onChange) {
      onChange({ [name]: val })
    }
  }

  componentDidMount() {
    this.setValue()
  }

  componentDidUpdate() {
    this.setValue()
  }

  renderTooltip() {
    const { tooltip } = this.props

    return (
      <Tooltip small hideArrow placement="bottom-start" tooltip={tooltip}>
        <Icon type="help-small" />
      </Tooltip>
    )
  }

  render() {
    let {
      displayName,
      value,
      options,
      comparator,
      placeholder,
      getLabel,
      input,
      rowHeight,
      childWidth,
      tooltip,
      handleSearch,
      searchValue,
      searchPlaceholder,
      className,
    } = this.props

    if (input) {
      value = input.value
    }

    return (
      <div className={classNames('library-inspect-menu-control', className)}>
        {tooltip ? (
          <div className="library-inspect-menu-control-header">
            <p>{displayName}</p>
            {this.renderTooltip()}
          </div>
        ) : (
          <p>{displayName}</p>
        )}
        <MultiMenu
          className="small"
          options={options}
          value={value}
          onChange={this.handleChange}
          comparator={comparator}
          getLabel={getLabel}
          placeholder={placeholder}
          rowHeight={rowHeight}
          childWidth={childWidth}
          handleSearch={handleSearch}
          searchValue={searchValue}
          searchPlaceholder={searchPlaceholder}
        />
      </div>
    )
  }
}
