import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notify } from 'ducks/notifications';
import { withRouter } from 'react-router-dom';

import Page from 'components/Shared/Page';
import Menu from "../../Menu";

import { getApp } from 'ducks/apps/selectors'

import { 
    updateApp,
    updateAppOuterRule, 
    updateAppParts, 
    initializeStateAction
} from 'ducks/apps/actions';

import { setData, setDataV2, setEditorDefault } from 'ducks/editor/objects'

import { copyWithApp } from 'ducks/apps/initialState';

import { getTemplateTypeById } from 'components/Admin/template/apiUtils';
import { setTemplate } from 'components/Admin/template/store/templateActions';
import { initializeStateTemplate } from 'components/Admin/template/store/initialState';


import Template2dViews from 'components/Admin/template/2d/Editor';
import Template3dViews from 'components/Admin/template/3d/Template3dViews';

import TemplateDetail from './form/TemplateDetail';
import OuterRuleEditor from './form/OuterRuleEditor';
import PointsEditor from './form/PointsEditor';



class TemplateCreate extends Component {

    state = {
        activeTab: 'form',
        activeViewTab: '2d',
        isLoading: true,
    }

    async componentDidMount() {

        const { id } = this.props.match.params;

        const { 
            app, 
            setTemplate,
            setDataV2,
            updateApp
        } = this.props

        const template = initializeStateTemplate()

        try {

            const data = await getTemplateTypeById(id);

            setTemplate({ 
                ...template,
                template_type_id: data.id
            })

            this.setState({ isLoading: false });

        } catch (error) {
            this.props.notify(`Error: ${error.message}`);
            this.setState({ isLoading: false });
        }



        const newAppData = {
            outer_rule: '',
            name: template.name,
            width: template.width,
            height: template.height,
            lower_width: template.lower_width,
            lower_height: template.lower_height,
            depth: template.depth,
            border_width: template.border_width,
            border_radius: template.border_radius,
            components: {
                "n581kk19ktbw9cctimjy5rykhe": {
                    name: template.name,
                    width: template.width,
                    height: template.height,
                    depth: template.depth,

                    "id": "n581kk19ktbw9cctimjy5rykhe",
                    "draft_id": "kwfkpfa5efbpmkyab6ih4rwyta",
                    "order": 1,
                    "x": 0,
                    "y": 0,
                    "type": "component",
                    "hidden": false,
                    "objects": [],
                    "opacity": 1,
                    "positioning": null,
                    "background_color": "@background"
                }
            },
        };

        const new_data = copyWithApp(app, newAppData)

        updateApp(app.id, new_data); setDataV2(new_data.id, new_data.components, new_data)

    }

    componentDidUpdate(prevProps) {
        const { outer_rule: prevOuterRule } = prevProps;
        const { outer_rule: currentOuterRule } = this.props;

        const { app } = this.props
        const { 
            updateOuterRule,
            setDataV2, 
        } = this.props;

        if (currentOuterRule !== prevOuterRule) {
            updateOuterRule(app.id, currentOuterRule); setDataV2(app.id, app.components, app)
        }
    }

    switchTab = (tab) => {
        this.setState({ activeTab: tab });
    }

    switchViewTab = (tab) => {
        this.setState({ activeViewTab: tab });
    }

    render() {
        const { outer_rule } = this.props;
        const { activeTab, activeViewTab } = this.state;

        return (
            <Page className="admin-users-page" title="Шаблоны">
                <Menu />
                <section className="mb-4">
                    <h1 className="text-2xl font-bold">Создание нового шаблона</h1>
                </section>

                <div className="flex flex-col lg:flex-row p-4">

                    <div className="lg:flex-grow">
                        <div className="flex relative z-10">
                            <div
                                className={`px-4 py-2 cursor-pointer border border-gray-300 rounded-t-lg mr-2 transition duration-300 ${activeViewTab === '2d' ? 'bg-blue-500 text-white' : ''}`}
                                onClick={() => this.switchViewTab('2d')}
                            >
                                2D
                            </div>
                            <div
                                className={`px-4 py-2 cursor-pointer border border-gray-300 rounded-t-lg transition duration-300 ${activeViewTab === '3d' ? 'bg-blue-500 text-white' : ''}`}
                                onClick={() => this.switchViewTab('3d')}
                            >
                                3D
                            </div>
                        </div>

                        <div className="border border-gray-300 rounded-b-lg p-4 bg-gray-50 shadow-sm h-screen min-h-96">
                            {activeViewTab === '2d' && <Template2dViews />}
                            {activeViewTab === '3d' && <Template3dViews />}
                        </div>
                    </div>

                    <div className="lg:w-1/3 lg:mr-4 mb-4 lg:mb-0 z-10">
                        <div className="flex">
                            {['form', 'outer_rule'].map(tab => (
                                <div
                                    key={tab}
                                    className={`px-4 py-2 cursor-pointer border border-gray-300 rounded-t-lg mr-2 transition duration-300 ${activeTab === tab ? 'bg-blue-500 text-white' : ''}`}
                                    onClick={() => this.switchTab(tab)}
                                >
                                    {tab.charAt(0).toUpperCase() + tab.slice(1).replace('_', ' ')}
                                </div>
                            ))}
                            {outer_rule && outer_rule.length > 0 && (
                                <div
                                    className={`px-4 py-2 cursor-pointer border border-gray-300 rounded-t-lg transition duration-300 ${activeTab === 'points' ? 'bg-blue-500 text-white' : ''}`}
                                    onClick={() => this.switchTab('points')}
                                >
                                    Points
                                </div>
                            )}
                        </div>

                        <div className="border border-gray-300 rounded-b-lg p-4 bg-gray-50 shadow-sm overflow-scroll" style={{ height: '750px' }} >
                            {activeTab === 'form' && <TemplateDetail />}
                            {activeTab === 'outer_rule' && <OuterRuleEditor />}
                            {activeTab === 'points' && outer_rule && outer_rule.length > 0 && <PointsEditor />}
                        </div>

                    </div>


                    
                </div>
            </Page>
        );
    }
}

const mapStateToProps = (state) => {

    const app = getApp(state, "n581kk19ktbw9cctimjy5rykhe")

    return {
        app: app,
        template: state.admin.template.details,
        outer_rule: state.admin.template.outer_rule,
    };
}



const mapDispatchToProps = (dispatch) => ({

    notify:         (message)   => dispatch(notify(message)),
    setTemplate:    (template)  => dispatch(setTemplate(template)),


    updateOuterRule: (appId, outerRule) => dispatch(updateAppOuterRule(appId, outerRule)),
    updateAppParts: (appId, parts) => dispatch(updateAppParts(appId, parts)),


    setData: (app_id, app_components, app) => dispatch(setData(app_id, app_components, app)),
    setDataV2: (app_id, app_components, app) => dispatch(setDataV2(app_id, app_components, app)),

    initializeStateAction: (app_id, data) => dispatch(initializeStateAction(app_id, data)),
    setEditorDefault: (data) => dispatch(setEditorDefault(data)),
    updateApp: (app_id, data) => dispatch(updateApp(app_id, data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TemplateCreate))
