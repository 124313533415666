import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {Field, reduxForm} from 'redux-form'
import QS from 'qs'

import Button from '../Shared/Button'
import WrappedInput from '../Shared/Forms/WrappedInput'
import AuthPageLogo from './Logo'

const FORM_NAME = 'loginForm'

class LoginForm extends Component {
  render() {
    let { handleSubmit, error, submitting, redirect } = this.props
    let redirectQuery = QS.stringify({ redirect }, { addQueryPrefix: true })

    return (
      <form onSubmit={handleSubmit} className="auth-form">
        <AuthPageLogo />
        <h1>Вход в систему</h1>
        <Field
          autoFocus
          name="email"
          placeholder="Эл. почта"
          type="email"
          autoComplete="email"
          component={WrappedInput}
        />
        <Field
          name="password"
          placeholder="Пароль"
          type="password"
          autoComplete="current-password"
          component={WrappedInput}
        />
        <div className="auth-form-submission-row">
          <div className={'auth-form-footer'}>
            <Button large teal loading={submitting}>
              Войти в свой аккаунт
            </Button>
            <Link to="/forgot-password" className="link-button">
              Забыли пароль?
            </Link>
          </div>

        </div>
        {error && <div className="auth-form-error">{error}</div>}

        <div className="auth-form-link">
          {'Нет аккаунта? '}
          <Link to={`/signup${redirectQuery}`} className="link-button">
            Создайте учетную запись
          </Link>
        </div>
      </form>
    )
  }
}

export default reduxForm({
  form: FORM_NAME,
})(LoginForm)
