import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {pathLength, subPath} from 'common/utils'
import {COMPONENT, LIBRARY_COMPONENT} from 'common/constants'

import {getAppComponent} from 'utils/libraries'
import {features} from 'utils/objects'
import {getObjectName} from 'utils/naming'

import {IconButton} from 'components/Shared/Icon'
import Element from 'components/Shared/Element'
import {TYPE_NAMES} from 'components/Editor/RightPanel'

import DropTarget from './DropTarget'

export const EMPTY_ARRAY = []

const stopPropagation = e => e.stopPropagation()

export default class LayerItem extends Component {

  static contextTypes = {
    getDraggingObjects: PropTypes.func,
    getSelection: PropTypes.func,
    getLayers: PropTypes.func,
    execute: PropTypes.func,
  }

  state = { expanded: false, autoExpanded: true }

  handleClick = e => {
    let {
      object: { id },
    } = this.props

    let { execute } = this.context

    e.stopPropagation()

    return execute('setSelection', id, e.shiftKey)
  }

  handleDragStart = (e, offset) => {
    let {
      object: { id },
      selected,
    } = this.props

    let { getSelection, execute } = this.context
    let position = [e.clientX, e.clientY]
    let selection = getSelection()

    let ids = [id]

    if (selected) {
      ids = selection
    }

    execute('startDrag', ids, offset, position)
  }

  handleDrag = e => {
    let position = [e.clientX, e.clientY]
    let { execute } = this.context

    execute('drag', position)
  }

  handleDragEnd = e => {
    let { execute, getLayers } = this.context
    let layers = getLayers()

    execute('endDrag')

    if (!layers.dropTarget) {
      return
    }

    execute('reorderObjects', layers.objects, layers.dropTarget, layers.options)
  }

  handleMouseDown = e => {
    e.stopPropagation()
  }

  handleMouseMove = e => {
    let {
      object: { id, type },
    } = this.props

    let { expanded } = this.state
    let { getDraggingObjects, execute } = this.context

    if (!this.dragInProgress()) {
      return
    }

    let el = e.currentTarget
    let bbox = el.getBoundingClientRect()

    let dropAfter = e.clientY > bbox.top + bbox.height / 2

    let dropInside

    if (features[type].children) {
      let bottom = bbox.top + (bbox.height * 3) / 4

      if (expanded) {
        bottom = bbox.bottom + 1
      }

      dropInside =
        e.clientY > bbox.top + bbox.height / 4 &&
        e.clientY < bottom - bbox.height / 4


    }

    if (type === COMPONENT) {
      let draggingObjects = getDraggingObjects()
      let nonComponent = false

      draggingObjects.forEach(obj => {
        if (obj.type !== COMPONENT) {
          nonComponent = true
        }
      })

      if (nonComponent) {
        dropInside = true
        dropAfter = false
      }
    }

    execute('setDropTarget', id, { dropAfter, dropInside })
  }

  handleMouseEnter = e => {
    let {
      object: { id },
    } = this.props

    let { execute } = this.context

    window.setTimeout(() => {
      execute('setLayersHover', id)
    }, 0)
  }

  handleMouseLeave = e => {
    let { execute } = this.context

    execute('setLayersHover', null)
  }

  toggleVisibility = e => {

    let { object } = this.props
    let { hidden, id } = object
    let { execute } = this.context

    e.stopPropagation()

    execute('updateObject', id, { hidden: !hidden })
  }

  toggleExpanded = e => {
    e.stopPropagation()

    this.setState(state => ({
      expanded: !state.expanded,
      autoExpanded: false,
    }))
  }

  dragInProgress = () => {
    let { dragInProgress } = this.props

    return dragInProgress
  }

  dropBefore = () => {
    let { path, dropBefore, dropTarget } = this.props

    return path === dropTarget && dropBefore
  }

  dropAfter = () => {
    let { path, dropAfter, dropTarget } = this.props

    return path === dropTarget && dropAfter
  }

  dropInside = () => {
    let { path, dropInside, dropTarget } = this.props

    return path === dropTarget && dropInside
  }

  shouldComponentUpdate(newProps, newState) {
    for (let key in newProps) {
      // eslint-disable-next-line react/destructuring-assignment
      if (newProps[key] !== this.props[key]) {
        return true
      }
    }

    if (newState !== this.state) {
      return true
    }

    return false
  }

  componentWillUnmount() {
    this._deleted = true
  }

  renderTypeHeader = () => {
    let { object } = this.props

    if (!object) return null

    if (object?.type === LIBRARY_COMPONENT) {
      const { displayName } =
        getAppComponent(null, object.libraryName, object.componentName) || {}

      return displayName || 'Missing Component'
    }

    return TYPE_NAMES[object.type]
  }

  render() {
    let {
      path,
      object,
      selection,
      hoverSelection,
      dragInProgress,
      dropTarget,
      dropBefore,
      dropAfter,
      dropInside,
      level
    } = this.props

    let { hidden, children, type } = object

    let { expanded, autoExpanded } = this.state

    selection = selection.filter(itm => {
      return subPath(itm, pathLength(path)) === path
    })

    if (selection.length === 0) {
      selection = EMPTY_ARRAY
    }

    hoverSelection = hoverSelection.filter(itm => {
      return subPath(itm, pathLength(path)) === path
    })

    if (hoverSelection.length === 0) {
      hoverSelection = EMPTY_ARRAY
    }

    let selected = selection.indexOf(path) !== -1
    let canvasHovered = hoverSelection.indexOf(path) !== -1

    if (selection !== EMPTY_ARRAY && !expanded && autoExpanded) {
      setTimeout(() => {
        if (this._deleted) {
          return
        }

        this.setState({
          expanded: true,
          autoExpanded: true,
        })
      }, 10)
    } else if (expanded && selection === EMPTY_ARRAY && autoExpanded) {
      setTimeout(() => {
        if (this._deleted) {
          return
        }

        this.setState({
          expanded: false,
          autoExpanded: true,
        })
      }, 10)
    }

    let expandEnabled = type === COMPONENT || (children && children.length > 0)


    return (
      <Element
        className={classNames('layer-item', {
          hidden,
          selected,
          expanded,
          'component-layer': type === COMPONENT,
          'canvas-hovered': canvasHovered && !selected,
        })}
        dragTolerence={10}
        onClick={this.handleClick}
        onDragStart={this.handleDragStart}
        onDrag={this.handleDrag}
        onDragEnd={this.handleDragEnd}
        onMouseDown={this.handleMouseDown}
      >
        <div className="layer-info">
          {this.dropInside() && <DropTarget inside />}

          {expandEnabled ? (
            <IconButton
              type="arrow-drop-down"
              className="layer-expand-icon"
              onClick={this.toggleExpanded}
              onMouseDown={stopPropagation}
            />
          ) : (
            <span className="layer-no-expand" />
          )}

          <div
            className={classNames('layer-info-sub', { hidden })}
            onMouseMove={this.handleMouseMove}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            {this.dropBefore() && <DropTarget before />}
            {this.dropAfter() && <DropTarget after />}

            <div className="layer-label">
              <span className="layer-label-subtitle">
                {this.renderTypeHeader()}
              </span>
              <span className="layer-label-title">{getObjectName(object)}</span>
            </div>

            <IconButton
              type={hidden ? 'hidden' : 'eye'}
              onClick={this.toggleVisibility}
              onMouseDown={stopPropagation}
            />
            
          </div>
        </div>
        {expanded && children && children.length > 0 && (
          <div className="layer-item-children">
            {children
              .slice()
              .reverse()
              .map((child, i) => (
                <LayerItem
                  object={child}
                  key={child.id}
                  path={`${path}.${children.length - i - 1}`}
                  selection={selection}
                  hoverSelection={hoverSelection}
                  dragInProgress={dragInProgress}
                  dropTarget={dropTarget}
                  dropBefore={dropBefore}
                  dropAfter={dropAfter}
                  dropInside={dropInside}
                />
              ))}
          </div>
        )}
      </Element>
    )
  }
}