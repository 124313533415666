export const capitalize = str => {
  if (!str) return str

  let first = str.substring(0, 1).toUpperCase()
  let rest = str.substring(1)

  return `${first}${rest}`
}

export const fontWeightOptions = [
  { value: '300', label: 'Light' },
  { value: '400', label: 'Normal' },
  { value: '500', label: 'Medium' },
  { value: '600', label: 'Semi Bold' },
  { value: '700', label: 'Bold' },
  { value: '800', label: 'Extra Bold' },
  { value: '900', label: 'Extra Black' },
]

export const getFontWeightName = value => {
  if (typeof value !== 'string') value = `${value}`

  switch (value.toLowerCase()) {
    case '100':
      return 'Thin'
    case '200':
      return 'Extra Light'
    case '300':
      return 'Light'
    case '400':
    case 'normal':
    case 'regular':
      return 'Normal'
    case '500':
      return 'Medium'
    case '600':
      return 'Semi Bold'
    case '700':
    case 'bold':
      return 'Bold'
    case '800':
      return 'Extra Bold'
    case '900':
      return 'Extra Black'
  }
}

/**
 * Returns a font family name from a font target value
 * @param {string} value
 * @param {object} branding - App branding object
 * @example
 * const branding = { fonts: { body: { family: 'Roboto' } } }
 * return normalizeFont(value, branding)
 */
export const normalizeFont = (value, branding = {}) => {
  const { fonts = {} } = branding

  if (!value || typeof value !== 'string' || !fonts) return null

  // the value "system" is the legacy fontFamily value
  if (value === 'system') {
    return fonts?.body?.family || null
  }

  const match = /^@(heading|body)?$/.exec(value)

  if (!match || !fonts[match[1]]) return null

  return fonts[match[1]].family
}

export const defaultFonts = '-apple-system, "SF Pro Text", sans-serif'

export const getFontFamily = (value, branding = {}) => {
  const font = normalizeFont(value, branding)

  if (!font || font === 'default') return defaultFonts

  return `${font}, ${defaultFonts}`
}


