import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notify } from 'ducks/notifications';
import { withRouter } from 'react-router-dom';

import MovePointUp from 'components/Admin/template/PointsEditor/MovePointUp';
import MovePointDown from 'components/Admin/template/PointsEditor/MovePointDown';
import RemovePoint from 'components/Admin/template/PointsEditor/RemovePoint';
import PointsRawData from 'components/Admin/template/PointsEditor/PointsRawData';
import AddPoint from 'components/Admin/template/PointsEditor/AddPoint';

import TemplatePointsXY from '../../PointsEditor/TemplatePointsXY';

class PointsEditor extends Component {
    
    render() {
        const { points, setting } = this.props;

        return (
            <div className="mt-5">
                {points.map((point, pointIndex) => (
                    <div key={pointIndex} className={`flex flex-col justify-between mb-2 p-4 border rounded-lg ${setting.activePointIndex === pointIndex ? 'bg-blue-50 border-blue-500' : 'bg-white border-gray-300'} shadow-sm cursor-pointer`}>
                        <div className="grid grid-cols-3 gap-4">

                            <TemplatePointsXY pointIndex={pointIndex} point_xy={point} />

                            <div className="grid grid-cols-3 gap-1">
                                <MovePointUp pointIndex={pointIndex} />
                                <RemovePoint pointIndex={pointIndex} />
                                <MovePointDown pointIndex={pointIndex} />
                            </div>

                        </div>
                    </div>
                ))}
                <AddPoint />
                <PointsRawData />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const setting = state.admin.template.setting;
    const active_outer_rule = state.admin.template.outer_rule[setting.activeRuleIndex] || {};
    
    return {
        setting: setting,
        points: active_outer_rule.points || [],
    };
};

const mapDispatchToProps = (dispatch) => ({
    notify: (message) => dispatch(notify(message)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PointsEditor));
