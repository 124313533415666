export const identifyCurrentUser = user => {
  let { id, email, name } = user

  if (!window.FS) {
    return false
  }

  window.setTimeout(() => {
    window.FS.identify(id, { email, displayName: name })
  }, 0)
}
