import Base from './base'

export class HitResult extends Base{

  constructor(arg0, arg1) {
    super(...arguments)
  }
  initialize(type, item, values) {
    this.type = type;
    this.item = item;
    // Inject passed values, so we can be flexible about the HitResult
    // properties.
    // This allows the definition of getters too, e.g. for 'pixel'.
    if (values)
      this.inject(values);
  }


  static getOptions(options) {

    return Object.assign({
      // Type of item, for instanceof check: Group, Layer, Path,
      // CompoundPath, Shape, Raster, SymbolItem, ...
      class: 'Path',
      type: null,
      // Tolerance
      tolerance: 10,
      // Hit the fill of items
      fill: false,
      // Hit the curves of path items, taking into account the stroke
      // width.
      stroke: true,
      // Hit the part of segments that curves pass through, excluding
      // its segments (Segment#point)
      segments: true,
      // Hit the parts of segments that define the curvature
      handles: false,
      // Only first or last segment hits on path (mutually exclusive
      // with segments: true)
      ends: false,
      // Hit test the item position
      position: false,
      // Hit test the center of the bounds
      center: false,
      // Hit test the corners and side-centers of the bounding box
      bounds: true,
      //  Hit items that are marked as guides
      guides: false,
      // Only hit selected objects
      selected: false
    }, options);
  }
}
