import React, {Component} from 'react'
import {connect} from 'react-redux'
import Page from 'components/Shared/Page'
import moment from "moment";
import GridTable from "@nadavshaar/react-grid-table";


import { patchDraft, requestAllDrafts } from "../../ducks/apps/actions";
import { getAllDrafts } from "../../ducks/apps/selectors";

import Menu from "./Menu";

const NameRender = ({tableManager, value, field, data, column, colIndex, rowIndex}) => {
    let endpointURL = `/apps/${data.id}/screens`

    return (

        <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
            <span className='rgt-text-truncate' style={{marginLeft: 10}}>
                <a href={endpointURL}>{value}</a>
            </span>
        </div>
    )
}

const EDIT_SVG = (
    <svg
        height="16"
        viewBox="0 0 20 20"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="#fff" stroke="#1856bf" transform="translate(2 2)">
            <path
                d="m8.24920737-.79402796c1.17157287 0 2.12132033.94974747 2.12132033 2.12132034v13.43502882l-2.12132033 3.5355339-2.08147546-3.495689-.03442539-13.47488064c-.00298547-1.16857977.94191541-2.11832105 2.11049518-2.12130651.00180188-.00000461.00360378-.00000691.00540567-.00000691z"
                transform="matrix(.70710678 .70710678 -.70710678 .70710678 8.605553 -3.271644)"
            />
            <path d="m13.5 4.5 1 1"/>
        </g>
    </svg>
);

const CANCEL_SVG = (
    <svg
        height="20"
        viewBox="0 0 20 20"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" stroke="#dc1e1e" transform="translate(5 5)">
            <path d="m.5 10.5 10-10"/>
            <path d="m10.5 10.5-10-10z"/>
        </g>
    </svg>
);

const SAVE_SVG = (
    <svg
        height="20"
        viewBox="0 0 20 20"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m.5 5.5 3 3 8.028-8"
            fill="none"
            stroke="#4caf50"
            transform="translate(5 6)"
        />
    </svg>
);

class AdminDrafts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: '',
            columns: [
                {
                    id: 'CreateAt',
                    field: 'create_at',
                    label: 'Дата создания',
                    editable: false,
                    getValue: ({value, column}) => moment(value, 'x').format("DD.MM.YYYY HH:mm")
                },
                {
                    id: 'UpdateAt',
                    field: 'update_at',
                    label: 'Дата измненения',
                    editable: false,
                    getValue: ({value, column}) => moment(value, 'x').format("DD.MM.YYYY HH:mm")
                },
                {
                    id: 'Name',
                    field: 'name',
                    label: 'Название',
                    cellRenderer: NameRender,
                    editable: true,
                    width: "200px"

                },

                {
                    id: 'Email',
                    field: 'user',
                    label: 'Эл. адрес',
                    editable: false,
                    getValue: ({value, column}) => value?.email
                },
                {
                    id: 'Phone',
                    field: 'user',
                    label: 'Телефон',
                    editable: false,
                    getValue: ({value, column}) => value?.phone

                },
                {
                    id: 'Nickname',
                    field: 'user',
                    label: 'Имя',
                    editable: false,
                    getValue: ({value, column}) => value?.nickname

                },

                {
                    id: 'Status',
                    field: 'status',
                    label: 'Статус заказа',
                    getValue: ({value, column}) => {
                        switch (value) {
                            case "draft":
                                return "Черновик"
                            case "checkout":
                                return "Заказ"
                            case "complete":
                                return "Завершен"
                        }

                    },
                    editorCellRenderer: ({
                                             tableManager,
                                             value,
                                             data,
                                             column,
                                             colIndex,
                                             rowIndex,
                                             onChange
                                         }) => (
                        <select


                            onChange={(e) => {

                                onChange({...data, [column.field]: e.target.value})
                            }

                            }
                        >
                            <option value={'draft'}>Черновик</option>
                            <option value={'checkout'}>Заказ</option>
                            <option value={'complete'}>Выполнен</option>
                        </select>
                    )

                },
                {
                    id: "buttons",
                    width: "max-content",
                    pinned: true,
                    sortable: false,
                    resizable: false,
                    cellRenderer: ({
                                       tableManager,
                                       value,
                                       data,
                                       column,
                                       colIndex,
                                       rowIndex
                                   }) => (
                        <div className={'gr-buttons-cell-container'}>
                            <button
                                title="Редактировать"
                                className={'gr-edit-button'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    tableManager.rowEditApi.setEditRowId(data.id);
                                }}
                            >
                                {EDIT_SVG}
                            </button>
                        </div>
                    ),
                    editorCellRenderer: ({
                                             tableManager,
                                             value,
                                             data,
                                             column,
                                             colIndex,
                                             rowIndex,
                                             onChange
                                         }) => (
                        <div className={'gr-buttons-celleditor-container'}>
                            <button
                                title="Отмена"
                                className={'gr-cancel-button'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    tableManager.rowEditApi.setEditRowId(null);
                                }}
                            >
                                {CANCEL_SVG}
                            </button>
                            <button
                                title="Сохранить"
                                className={'gr-save-button'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    let rowsClone = [...tableManager.rowsApi.rows];

                                    let updatedRowIndex = rowsClone.findIndex(
                                        (r) => r.id === data.id
                                    );
                                    rowsClone[updatedRowIndex] = data;

                                    const {status, name, id} = data
                                    props.patchDraft(id , {
                                        status,
                                        name
                                    });

                                    tableManager.rowEditApi.setEditRowId(null);
                                    tableManager.asyncApi.resetRows()
                                }}
                            >
                                {SAVE_SVG}
                            </button>
                        </div>
                    )
                }
            ]
        }

        //this.handleQuery = debounce(this.handleQuery, 200)
    }


    handleChange = e => {
        e.preventDefault()
        this.setState({value: e.target.value})
    }


    onRowsRequest = async (requestData, tableManager) => {

        const {requestAllDrafts} = this.props
        const {value} = this.state

        const response = await requestAllDrafts({
            page: tableManager.paginationApi.page - 1,
            per_page: tableManager.paginationApi.pageSize,
            sort: requestData.sort.colId,
            is_asc: requestData.sort.isAsc,
            term: requestData.searchText,
        }).then(({value}) => {
            return value
        })

        if (!response?.data) return;

        return {
            rows: response.data.items,
            totalRows: response.data.total_count
        };
    }


    render() {
        let {users} = this.props
        const {value, columns} = this.state

        return (
            <Page className="admin-users-page" title="Все черновики">
                <Menu/>
                <section className="users-header">
                    <h1>Все черновики</h1>
                </section>
                <div className="grid-content">
                    <GridTable
                        showSearch={true}
                        showColumnVisibilityManager={true}
                        texts={{
                            search: 'Поиск:',
                            totalRows: 'Общее кол-во:',
                            rows: 'Показано:',
                            selected: 'Выбрано',
                            rowsPerPage: 'Элементов на странице:',
                            page: 'Страница:',
                            of: 'из',
                            prev: 'Пред.',
                            next: 'След.',
                            columnVisibility: 'Колонки'
                        }}
                        columns={columns}
                        onRowsRequest={this.onRowsRequest}
                    />
                </div>

            </Page>
        )
    }
}

const mapStateToProps = state => ({
    drafts: getAllDrafts(state),
})

export default connect(mapStateToProps, {requestAllDrafts, patchDraft})(AdminDrafts)
