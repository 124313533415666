
export const convertUnit = (value, unit = 'mm') => {
  return Math.ceil(value* 1/0.2645833333)
}

export const pxToUnit = (value, unit = 'mm') => {
  return Math.round(value* 0.2645833333)
}

export const inchToMM = (value, unit = 'mm') => {
  return Math.ceil(value* 25.4)
}
