import React, {Component} from 'react'
import EmptyState from '../../../Shared/EmptyState'

export default class ComponentError extends Component {
  render() {
    return (
      <EmptyState>
        <h2>We’re having trouble loading this component!</h2>
      </EmptyState>
    )
  }
}
