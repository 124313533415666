import React, {Component} from 'react'
import {connect} from 'react-redux'
import {MultiMenuTrigger} from 'react-multi-menu'
import {IconButton} from '../../Shared/Icon'
import {COPY_APP, DELETE_APP, INVITE_DRAFT, RENAME_DRAFT, showModal} from "../../../ducks/editor/modals";

class OverflowMenu extends Component {

    handleInvite = () => {
        let {showModal, appId} = this.props
        showModal(INVITE_DRAFT, {appId})
    }

    handleRename = () => {
        let {showModal, appId} = this.props
        showModal(RENAME_DRAFT, {appId})
    }

    handleDelete = () => {
        let {showModal, appId} = this.props
        showModal(DELETE_APP, {appId})
    }

    handleCopy = () => {
        let {showModal, appId} = this.props
        showModal(COPY_APP, {appId})
    }

    getMenu = () => {
        
        let {appId} = this.props

        let menuItems = [

            {
                label: (
                    <div className="context-menu-item">

                        Поделиться
                    </div>
                ),
                onClick: this.handleInvite,
            },
            {
                label: (
                    <div className="context-menu-item">
                        Переименовать
                    </div>
                ),
                onClick: this.handleRename,
            },
            {
                label: (
                    <div className="context-menu-item" >
                        Дублировать
                    </div>
                ),
                onClick: this.handleCopy,
            },
            {
                label: (
                    <div className="context-menu-item">
                        Удалить
                    </div>
                ),
                onClick: this.handleDelete,
            }
        ]

        return menuItems
    }

    render() {
        return (
            <div className="board-brick-board-context-button">
                <MultiMenuTrigger
                    menu={this.getMenu()}
                    className="board-brick-board-context-trigger "
                    rowHeight={40}
                >
                    <IconButton type="more-vert" />
                </MultiMenuTrigger>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {}
}

export default connect(mapStateToProps, {
    showModal,
})(OverflowMenu)
