import React, {Component} from 'react'
import {connect} from 'react-redux'
import {change, SubmissionError} from 'redux-form'


import {getCurrentUser} from 'ducks/users'

import Loading from 'components/Shared/Loading'
import EmptyState from 'components/Shared/EmptyState'

import Form, {FORM_NAME} from './Form'

class Account extends Component {
  state = { successBanner: false }

  handleSubmit = async data => {
    let { change } = this.props

    try {
      //await updateAccount(data)
      window.setTimeout(() => this.setState({ successBanner: false }), 5000)
      this.setState({ successBanner: true })

      change(FORM_NAME, 'password', '')
      change(FORM_NAME, 'oldPassword', '')
    } catch (err) {
      this.setState({ successBanner: false })

      if (err.errors) {
        throw new SubmissionError(err.errors)
      } else if (err.message) {
        throw new SubmissionError({ _error: err.message })
      }
    }
  }

  render() {
    let { user } = this.props
    let { successBanner } = this.state

    if (!user) {
      return (
        <EmptyState greedy>
          <Loading />
        </EmptyState>
      )
    }

    let { email, name, developer } = user
    let initialValues = { email, name, developer }

    return (
      <div className="account-settings">
        {successBanner && (
          <div className="settings-alert settings-save-success">
            Successfully saved!
          </div>
        )}
        <Form onSubmit={this.handleSubmit} initialValues={initialValues} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: getCurrentUser(state),
})

export default connect(mapStateToProps, { change })(Account)
