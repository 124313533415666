// DraftInitTemplate.js
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import { ensureTemplatesAreLoaded } from 'ducks/apps/actions';
import { getTemplatesState } from 'ducks/apps/selectors';

import { setDraftInitTemplateSize } from './store/actions';

import Loading from './loading';
import WizardFooter from '../wizard_footer';
import TemplateSelect from './components/template_select';

const DraftInitTemplate = ({
  history,
  templates,
  loading,
  ensureTemplatesAreLoaded,
  draftInitTemplateSizes,
  setDraftInitTemplateSize,
}) => {

  const { template_id } = draftInitTemplateSizes;

  useEffect(() => {
    ensureTemplatesAreLoaded()
  }, [ensureTemplatesAreLoaded])

  const handleTemplateSelect = (selectedTemplateId) => {
    setDraftInitTemplateSize({ template_id: selectedTemplateId })
  }

  const handleNext = () => {

    if (!template_id) {
      return
    }

    history.push('/draft/init/template/sizes/select/name')

  }

  return (
    <>
      <div className="stepper stepper-teal">
        <div className="step step-done">
          <div className="step-bullet">
            <span className="icon icon-done" expanded="false"></span>
          </div>
          <div className="step-text">Тип кейса</div>
        </div>
        <hr />
        <div className="step step-done step-current">
          <div className="step-bullet">2</div>
          <div className="step-text">Выбор кейса</div>
        </div>
        <hr />
        <div className="step">
          <div className="step-bullet">3</div>
          <div className="step-text">Настройки</div>
        </div>
      </div>

      <div id="new-app-template-form" className="new-app-form">
        {loading ? (
          <div className="new-app-template-loading">

            <TemplateSelect options={[]} />

            <Loading expanded />

          </div>
        ) : (

          <TemplateSelect
            name="template_id"
            options={templates}
            onChange={handleTemplateSelect}
          />

        )}
      </div>

      <WizardFooter>

        <Link to={`/draft/init/mode`} className="btn previous">
          Назад
        </Link>

        {template_id && (
          <div className="btn next align-right" onClick={handleNext}>
            Далее
          </div>
        )}

      </WizardFooter>
    </>
  );
};

const mapStateToProps = (state) => {
  const templatesState = getTemplatesState(state, 'template');
  return {
    templates: templatesState.list,
    loading: templatesState.loading,
    draftInitTemplateSizes: state.draftInitTemplateSizes,
  }
}

const mapDispatchToProps = {
  ensureTemplatesAreLoaded,
  setDraftInitTemplateSize,
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DraftInitTemplate)
)
