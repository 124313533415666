import { SET_TEMPLATE_SETTING } from './templateActions';

const initialState = {
    activeRuleIndex: null,
    activePointIndex: null,
};

const templateSettingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TEMPLATE_SETTING:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default templateSettingReducer;
