import {COMPONENT, LIST} from 'common/constants'

import {getInsertPath, getObject, insert, pathLength, remapSiblings, remove, subPath,} from 'common/utils'

const PARENT_TYPES = [COMPONENT, LIST]

export const getParentIds = (list, map, objectId) => {
  let path = map[objectId]
  let parentIds = []

  for (let i = 1; i <= pathLength(path) - 1; i += 1) {
    let fragment = subPath(path, i)
    let parentObj = getObject(list, fragment)

    if (PARENT_TYPES.includes(parentObj.type)) {
      parentIds.push(parentObj.id)
    }
  }

  return parentIds
}

export const changeObjectParent = (list, map, id, parentId) => {
  let path = map[id]
  let object = getObject(list, path)

  list = remove(list, path)
  map = remapSiblings(list, map, path)
  let parentPath = map[parentId]
  let newPath = getInsertPath(list, parentPath)
  list = insert(list, newPath, object)
  map = remapSiblings(list, map, newPath)

  return [list, map]
}
