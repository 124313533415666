/* eslint-disable global-require */
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Field, reduxForm} from 'redux-form'

import WrappedInput from 'components/Shared/Forms/WrappedInput'
import Modal from 'components/Shared/Modal'
import {selectObjects} from "../../../ducks/editor/objects";
import { getApp } from "ducks/apps/selectors";
import {withRouter} from "react-router-dom";
import WrappedText from "../../Shared/Forms/WrappedText";
import {getCurrentUser} from "../../../ducks/users";

const FORM_NAME = 'checkoutForm'

export const validate = data => {
    let errors = {}

    if (!data.phone) {
        errors.phone = 'Укажите телефон'
    }
    if (!data.email) {
        errors.email = 'Укажите эл. адрес'
    }
    return errors
}

class CheckoutForm extends Component {

    handleCancel = e => {
        let {onCancel} = this.props
        e.preventDefault()


        onCancel()
    }

    componentDidMount(props) {

    }


    render() {
        let {
            handleSubmit,
            submitting,
            error,
            dirty
        } = this.props

        return (
            <form onSubmit={handleSubmit} id="checkout-form" className={'checkout-form'}>
                <Modal.Header title="Оформление заказа"/>
                <Modal.Content>
                    <div className={'inspect-form'}>
                        {error && !submitting ? (
                            <div className="inspect-form-alert">{error}</div>
                        ) : null}
                        <div
                            className="checkout-note">{'Наш менеджер свяжеться с вами для уточнения заказа и рассчета стоимости'}</div>

                        <Field
                            autoFocus
                            className={'inline-field'}
                            name="checkout_phone"
                            label="Телефон"
                            placeholder={"+799912345678"}
                            component={WrappedInput}

                        />
                        <Field
                            name="checkout_email"
                            className={'inline-field'}
                            label="Эл. адрес"
                            type={"email"}
                            component={WrappedInput}

                        />
                        <Field
                            name="comment"
                            className={'textarea-field'}
                            label="Комментарий к заказу"
                            type={"text"}
                            component={WrappedText}

                        />
                    </div>

                </Modal.Content>
                <Modal.Actions>
                    <Modal.Button type="button" text onClick={this.handleCancel}>
                        Отмена
                    </Modal.Button>
                    <Modal.Button
                        form="new-screen-form"
                        type="submit"
                        loading={submitting}
                        enabled={dirty && !submitting}
                        onClick={handleSubmit}
                    >
                        Оформить
                    </Modal.Button>
                </Modal.Actions>
            </form>
        )
    }

}

const mapStateToProps = (state, {match}) => {
    const u = getCurrentUser(state)
    return {
    list: selectObjects(state),
    app: getApp(state, match.params.appId),
    initialValues: {
        phone: u.phone,
        email: u.email
    }, // retrieve name from redux store
}}

export default withRouter(connect(mapStateToProps, {})(
    reduxForm({
        initialValues: {},
        validate,
        enableReinitialize: true,
        form: FORM_NAME,
    })(CheckoutForm)
))

