import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'

import Frame from './Frame'
import CloseButton from './CloseButton'

import './Trace.scss'
import {getZoom, resetZoom} from "../../../ducks/editor/objects";
import {connect} from "react-redux";

class Trace extends Component {
  state = {

  }
  getBackURL = () => {
    let { appId } = this.props

    return `/apps/${appId}/screens`
  }

  handleClose = () => {
    let { history } = this.props
    const {resetZoom} = this.props
    resetZoom()
    history.push(this.getBackURL())
  }

  render() {
    let { match } = this.props
    let { appId } = match.params


    return (
      <div className="editor-trace">
        <Frame appId={appId} />
        <CloseButton onClose={this.handleClose} />
      </div>
    )
  }
}

const mapStateToProps = (state, {appId}) => {

  return {
    zoom: getZoom(state),
  }
}

export default withRouter(connect(mapStateToProps, {resetZoom})(Trace))


