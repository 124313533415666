import { Engine as BabylonEngine, ILoadingScreen } from "@babylonjs/core";

export default  class CustomLoadingScreen {
    _loadingDiv
    _loadingTextDiv

    constructor(engine) {
        engine.loadingScreen = this;
        this._loadingDiv = document.getElementById('loadingScreen')
        this._loadingTextDiv = document.getElementById('loadingText')
    }

    set loadingUIText(e) {
        this._loadingTextDiv.innerHTML = e;
    }

    set loadingUIBackgroundColor(e) {
        this._loadingDiv.style.backgroundColor  = e;
    }

    displayLoadingUI() {

        this._loadingDiv.style.display = 'block';
    }

    hideLoadingUI() {
        this._loadingDiv.style.display = 'none';
    }
}