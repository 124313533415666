import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

import {evaluateLibraryProps, getComponentInfo, getLibraryComponent,} from 'utils/libraries'

import {scaleValue} from 'utils/zoom'
import {normalizeFont} from 'utils/type'

import {getLibraryStatus} from 'ducks/editor/libraries'
import {getLibraryGlobals} from 'ducks/editor/objects'


import LibraryComponentWrapper from './LibraryComponentWrapper'
import LibraryComponentError from './LibraryComponentError'

import './LibraryComponent.scss'
import {defaultBranding} from "../../../../utils/colors";

class LibraryComponent extends Component {
  renderLoading() {
    return null
  }

  shouldComponentUpdate = newProps => {
    let criticalProps = [
      'object',
      'zoom',
      'libraryVersion',
      'branding',
      'libraryGlobals',
    ]

    for (let prop of criticalProps) {
      // eslint-disable-next-line react/destructuring-assignment
      if (newProps[prop] !== this.props[prop]) {
        return true
      }
    }

    return false
  }

  render() {
    let {
      object,
      zoom,
      libraryVersion,
      getLabel,
      match,
      x,
      y,
      branding,
      libraryGlobals,
    } = this.props

    let { appId } = match.params
    let { width, height, libraryName, componentName } = object

    let ComponentClass = getLibraryComponent(
      libraryName,
      libraryVersion,
      componentName
    )

    let info = getComponentInfo(libraryName, libraryVersion, componentName)

    let boundingWidth = scaleValue(width + 40, zoom)
    let boundingHeight = scaleValue(height + 40, zoom)
    let offsetX = x - scaleValue(20, zoom)
    let offsetY = y - scaleValue(20, zoom)

    let widthScaled = scaleValue(width, zoom)
    let heightScaled = scaleValue(height, zoom)

    let styleHeight = info?.resizeY ? height + 40 : undefined

    const wrapperProps = {
      boundingHeight,
      boundingWidth,
      offsetX,
      offsetY,
      zoom,
      styleHeight,
    }

    if (!ComponentClass || !info) {
      return (
        <LibraryComponentWrapper {...wrapperProps}>
          <LibraryComponentError object={object} />
        </LibraryComponentWrapper>
      )
    }

    let props = {
      ...evaluateLibraryProps(info, object.attributes, getLabel, {
        branding,
        libraryGlobals,
      }),
      appId,
      _fonts: {
        body: normalizeFont('@body', branding),
        heading: normalizeFont('@heading', branding),
      },
      _width: width,
      _height: height,
      _getUserValue: () => Promise.resolve(null),
      _setUserValue: (key, value) => Promise.resolve(value),
    }

    return (
      <g>
        <LibraryComponentWrapper {...wrapperProps}>
          <ComponentClass {...props} editor />
        </LibraryComponentWrapper>

        <rect
          x={x}
          y={y}
          width={widthScaled}
          height={heightScaled}
          fill="none"
          stroke="none"
        />
      </g>
    )
  }
}

const mapStateToProps = (state, { match, object, libraryVersion }) => {
  return {
    status: getLibraryStatus(state, object.libraryName, libraryVersion),

    branding: defaultBranding,
    libraryGlobals: getLibraryGlobals(
      state,
      object.libraryName,
      object.componentName
    ),
  }
}

export default withRouter(connect(mapStateToProps)(LibraryComponent))
