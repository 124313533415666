import React from 'react'
import PropTypes from 'prop-types'

const LibraryComponentWrapper = props => {
  const {
    offsetX,
    offsetY,
    boundingWidth,
    boundingHeight,
    zoom,
    styleHeight,
    children,
  } = props

  const posX = Math.floor(offsetX)
  const posY = Math.floor(offsetY)
  const transformX = offsetX % 1
  const transformY = offsetY % 1

  return (
    <foreignObject
      x={posX}
      y={posY}
      width={boundingWidth}
      height={boundingHeight}
      pointerEvents="none"
      transform={`translate(${transformX},${transformY})`}
    >
      <div
        className="library-component-inner-wrapper"
        style={{
          transform: `scale(${zoom.scale})`,
          transformOrigin: 'left top',
          width: `${(1 / zoom.scale) * 100}%`,
          zIndex: -1,
        }}
      >
        <div style={{ padding: 20, height: styleHeight }}>{children}</div>
      </div>
    </foreignObject>
  )
}

LibraryComponentWrapper.propTypes = {
  offsetX: PropTypes.number.isRequired,
  offsetY: PropTypes.number.isRequired,
  boundingWidth: PropTypes.number.isRequired,
  boundingHeight: PropTypes.number.isRequired,
  zoom: PropTypes.object.isRequired,
  styleHeight: PropTypes.number,
}

export default React.memo(LibraryComponentWrapper)
