import React from "react";
import {fileOpen} from "browser-fs-access";

import {Slider} from "components/Shared/Slider";
import Button from "components/Shared/Button";
import cn from 'classnames';
import classNames from 'classnames';
import MagicWand from "utils/magicwand";
import styles from './index.module.scss';
import { getApp } from "ducks/apps/selectors";
import {getCurrentAppId, resetZoom} from "ducks/editor/objects";
import {connect} from "react-redux";
import {setTool} from "ducks/editor/tools";
import {
    getEditing,
    getImage,
    getMode,
    getPath,
    getSize,
    resetTrace,
    setEditing,
    setMode,
    updatePath,
    updateSize,
    uploadImage
} from "ducks/trace";
import {PathItem, Point, Segment} from "../../../../utils/vector";
import {SHAPE_COMPONENT} from "../../../../common/constants";
import {getId} from "../../../../common/utils";
import {withRouter} from "react-router-dom";
import TraceForm from "./Form";

export class Panel extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            options: {
                tolerance: 15,
                blur: 5,
                simplifyTolerant : 1,
                simplifyCount : 20,
            },

            selected: false,
            manual: false,
            points: [],
            size: {width: 0, height: 0, depth: 0}
        }

    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    processImageBlob = (
        data,
        size,
        options
    ) => {

        const _that = this
        const {updatePath, zoom} = _that.props;

        const {tolerance, blur, simplifyTolerant, simplifyCount} = options
        let imgCanvas = document.createElement("canvas");
        if (imgCanvas) {

            imgCanvas.width = size.width;
            imgCanvas.height = size.height;
            const el = new Image();
            el.src = data.src;

            el.onload = () => {

                const ctx = imgCanvas.getContext('2d');
                // @ts-ignore
                ctx.drawImage(el, 0, 0);

                // @ts-ignore
                let imageData = ctx.getImageData(0, 0, imgCanvas.width, imgCanvas.height)

                var image = {
                    data: imageData.data,
                    width: imageData.width,
                    height: imageData.height,
                    bytes: 4
                };
                const blurRadius = blur;
                const colorThreshold = tolerance || 0;


                // @ts-ignore
                let mask = MagicWand.floodFill(image, 0, 0, colorThreshold, null, false);

                {
                    // @ts-ignore
                    mask = MagicWand.gaussBlurOnlyBorder(mask, blurRadius);
                    let contours = MagicWand.traceContours(mask)

                    let contoursSimplified = MagicWand.simplifyContours(contours, simplifyTolerant, 1);


                    let str = ""
                    let prev = []
                    let pathPrev = []
                    let f = false
                    let points = false
                    contoursSimplified.forEach(function (curr) {
                        if (curr.inner && !f) {
                            let ps = curr.points;

                            const points = []
                            const p = PathItem.create(ps.map(({x, y}) => {
                                points.push([x, y])
                                return new Segment(x, y)
                            }))
                            p.setClosed(true)

                            const bbox = p.bounds.bbox();

                            let unit = window.devicePixelRatio === 2 ? 2 : 1

                            p.scale(new Point(zoom.scale * unit, zoom.scale * unit))
                            p.adjust(bbox.x * zoom.scale * unit, bbox.y * zoom.scale * unit)
                            p.reorient(true, true);

                            const data = {
                                ...p.bounds.bbox(),
                                points: p.points
                            }

                            _that.setState(prevState => ({
                                ...prevState,
                                points,
                                size: {
                                    ...prevState.size,
                                    ...p.bounds.bbox()
                                }

                            }), () => {
                                updatePath(data)
                            })

                            f = true


                        }

                    })


                }
            }


        }

    }

    handleChangeSize = (data) => {

        const {updateSize} = this.props;


        this.setState((prevState) => {

            return {
                ...prevState,
                size: {
                    ...prevState.size,
                    ...data
                }

            }
        }, () => {
            updateSize(data)
        })

    }
    handleChangeEditing = (data) => {
        const {setEditing} = this.props;
        setEditing(data)
    }

    handleChangeMode = (data) => {
        const {setMode} = this.props;
        setMode(data)
    }

    handleChangeOptions = (changes) => {
        const {image} = this.props;
        

        const options = {
        ...this.state.options,
        ...changes
        }

        this.setState((prevState) => {

            return {
                ...prevState,
                options: {
                    ...options
                }

            }
        })


        if (image) {
            this.processImageBlob(image, {
                width: image.width,
                height: image.height,
            }, options);
        }

    }

    handleSubmit = (size) => {

        const options = {}
        let { history, app,  setTool, path, resetTrace, resetZoom} = this.props


        if (!path) return null

        const shape = PathItem.create()
        shape.setSegments(path.points)
        shape.setClosed(true)

        let bounds = shape.bounds

        const scaleX = size.width / bounds.width;
        const scaleY = size.height / bounds.height;
        shape.scale(new Point(scaleX, scaleY))
        shape.adjust(0,0)
        options.orientations = [
            {


                "id": getId(),
                "name": "TraceObject",
                "width": size.width,
                "depth": size.depth,
                "height": size.height,
                "data": [
                    {
                        "x": 0,
                        "y": 0,
                        "w": size.width,
                        "h": size.height,
                        "d": size.depth,
                        "p": shape.points.map((p) => p.point).reverse(),
                        "s": "POLYGON"
                    }
                ],

            },

        ]

        setTool(SHAPE_COMPONENT, options, [0, 0])
        resetTrace()
        resetZoom()
        history.push(`/apps/${app.id}/screens`)
    }

    render() {
        let {...props} = this.props;


        const {uploadImage, mode, editing} = props;
        let {options, selected, size} = this.state


        return (
            <div className={cn(styles.component)}>
                <div className={cn(styles.wrapper)}>

                    <div className={cn(styles.content)}>

                        {!selected && <>
                            <div className={cn(styles.row)}>
                                <div className={cn(styles.item, styles.x12)}>
                                    Функция «Трассировка изображения» позволяет преобразовывать растровые изображения
                                    (JPEG,
                                    PNG) в
                                    чертеж.
                                </div>
                            </div>

                            <div className={cn(styles.row)}>
                                <div className={cn(styles.item, styles.x12)}>
                                    <Button

                                        onClick={async () => {
                                            const file = await fileOpen({
                                                mimeTypes: ["image/!*"],
                                                multiple: false,
                                            });
                                            //return files.map(async (file) => {
                                            if (!file.type.includes("image")) {
                                                alert("Неподдерживаемый формат изображения")
                                                return;
                                            }
                                            if (file.size > 1024 * 1024 * 20) {
                                                alert("Выберите изображение меньшего размера (до 2 МБ) ")
                                                return;
                                            }

                                            const reader = new FileReader();

                                            reader.addEventListener(
                                                "load",
                                                (e) => {
                                                    const image = new Image();
                                                    image.src = e.target.result;
                                                    image.onload = () => {

                                                        const {src, width, height} = image
                                                        if (image && (image.width > 4000 || image.height > 4000 )) {
                                                            alert("Слишком большой размер изображения (до 4000 px)")
                                                            return;
                                                        }

                                                        this.setState({selected: true}, () => {
                                                            uploadImage({src, width, height})
                                                        })

                                                        if (image) {

                                                            this.processImageBlob(image, {
                                                                width: image.width,
                                                                height: image.height,
                                                            }, options);
                                                        }

                                                    };

                                                },
                                                false
                                            );
                                            reader.readAsDataURL(file);
                                        }}
                                        primary
                                        fluid
                                    >
                                        Выбрать изображение
                                    </Button>
                                </div>
                            </div>

                            <div className={cn(styles.row)}>
                                <div className={cn(styles.item, styles.x12)} style={{ color: 'red' }}>
                                    Загружаются изображения размером не более 2000 x 2000 пикселей
                                </div>
                            </div>

                        </>}

                        {selected && !editing && mode === 'auto' &&
                        <>
                            <div className={cn(styles.row)}>
                                <div className="panel-section">

                                    <div className="panel-section-header">
                                        <h4>Автотрассировки</h4>
                                    </div>
                                    <div
                                        className={classNames('panel-section-body')}
                                    > <div className="field-header">
                                        Чувствительность
                                    </div>
                                        <div className={cn(styles.item, styles.x12)}>
                                            <Slider aria-label="Volume" value={options.tolerance}
                                                    onChange={(event, payload) => {
                                                        
                                                        this.handleChangeOptions({tolerance : payload.value});
                                                    }} max={255} step={5}/>
                                        </div>
                                    </div>

                                    <div
                                        className={classNames('panel-section-body')}
                                    >
                                        <div className="field-header">
                                          Степень размытия
                                        </div>
                                        <div className={cn(styles.item, styles.x12)}>
                                            <Slider aria-label="Blur" value={options.blur}
                                                    onChange={(event, payload) => {
                                                        this.handleChangeOptions({blur : payload.value});
                                                    }} max={255} step={5}/>
                                        </div>
                                    </div>
                                    <div
                                        className={classNames('panel-section-body')}
                                    >
                                        <div className="field-header">
                                            Сглаживание кривой
                                        </div>
                                        <div className={cn(styles.item, styles.x12)}>
                                            <Slider aria-label="Blur" value={options.simplifyTolerant}
                                                    onChange={(event, payload) => {
                                                        this.handleChangeOptions({simplifyTolerant : payload.value});
                                                    }} max={255} step={1}/>
                                        </div>
                                    </div>
                                    {/*<div
                                        className={classNames('panel-section-body')}
                                    >
                                        <div className="field-header">
                                            Максимальное количество точек
                                        </div>
                                        <div className={cn(styles.item, styles.x12)}>
                                            <Slider aria-label="Blur" value={options.simplifyCount}
                                                    onChange={(event, payload) => {
                                                        this.handleChangeOptions({simplifyCount : payload.value});
                                                    }} max={255} step={1}/>
                                        </div>
                                    </div>*/}
                                    <div className={classNames('panel-section-body')}>
                                        <div className={cn(styles.item, styles.x12)}>
                                            <Button
                                                secondary
                                                fluid
                                                onClick={() => {
                                                    this.handleChangeMode('manual')
                                                }}

                                                /*disabled={disabled}*/
                                            >
                                                Ручной режим
                                            </Button>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className={cn(styles.row)}>
                                <div className={cn(styles.item, styles.x12)}>
                                    <Button
                                        primary
                                        fluid
                                        onClick={() => {
                                            this.handleChangeEditing(true)
                                        }}

                                        /*disabled={disabled}*/
                                    >
                                        Закончить редактирование
                                    </Button>
                                </div>
                            </div>
                        </>
                        }
                        {selected && !editing && mode === 'manual' &&
                        <>
                            <div className={cn(styles.row)}>
                                <div className="panel-section">


                                    <div className={classNames('panel-section-body')}>
                                        <div className={cn(styles.item, styles.x12)}>
                                            <Button
                                                secondary
                                                fluid
                                                onClick={() => {
                                                    this.handleChangeMode('auto')
                                                }}

                                                /*disabled={disabled}*/
                                            >
                                                Переключить в авторежим
                                            </Button>
                                        </div>
                                    </div>

                                </div>


                            </div>
                            <div className={cn(styles.row)}>
                                <div className={cn(styles.item, styles.x12)}>
                                    <Button
                                        primary
                                        fluid
                                        onClick={() => {
                                            this.handleChangeEditing(true)
                                        }}

                                        /*disabled={disabled}*/
                                    >
                                        Закончить редактирование
                                    </Button>
                                </div>
                            </div>
                        </>}
                        { editing && <>
                            <div className={cn(styles.row)}>
                                <div className={cn(styles.item, styles.x12)}>
                                    <Button
                                        secondary
                                        fluid
                                        onClick={() => {
                                            this.handleChangeEditing(false)
                                        }}

                                        /*disabled={disabled}*/
                                    >
                                        Вернуться к редактированию
                                    </Button>
                                </div>
                            </div>
                           <TraceForm onSubmit={this.handleSubmit}/>
                        </>}

                    </div>
                </div>
            </div>

        );
    }
}


export const mapStateToProps = (state, {appId}) => {

    const app = getApp(state, getCurrentAppId(state))
    const image = getImage(state)
    const path = getPath(state)
    const size = getSize(state)
    const mode = getMode(state)
    const editing = getEditing(state)
    return {
        app,
        image,
        path,
        size,
        mode,
        editing
    }
}


export default withRouter(connect(mapStateToProps, {
    updatePath,
    uploadImage,
    updateSize,
    setMode,
    setTool,
    setEditing,
    resetZoom,
    resetTrace
})(Panel))
