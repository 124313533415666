import React, {Component} from 'react'
import {connect} from 'react-redux'
import Page from 'components/Shared/Page'
import moment from "moment";
import GridTable from "@nadavshaar/react-grid-table";

import { patchDraft, requestAllDrafts } from "../../ducks/apps/actions";
import { getAllDrafts } from "../../ducks/apps/selectors";

import Menu from "./Menu";

const NameRender = ({tableManager, value, field, data, column, colIndex, rowIndex}) => {
    
    let endpointURL = `/apps/${data.id}/screens`

    return (
        <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
            <span className='rgt-text-truncate' style={{marginLeft: 10}}>
                <a href={endpointURL}>{value}</a>
            </span>
        </div>
    )
}

class AdminOrders extends Component {
    constructor(props) {
        super(props)

        this.state = {
            value: '',
            columns: [
                {
                    id: 'StatusUpdateAt',
                    field: 'status_update_at',
                    label: 'Дата статуса',
                    editable: false,
                    getValue: ({value, column}) => moment(value, 'x').format("DD.MM.YYYY HH:mm")
                },
                {
                    id: 'Name',
                    field: 'name',
                    label: 'Название',
                    cellRenderer: NameRender,
                    editable: true,
                    width: "200px"

                },
                {
                    id: 'Email',
                    field: 'checkout_email',
                    label: 'Эл. адрес',
                    editable: false

                },
                {
                    id: 'Phone',
                    field: 'checkout_phone',
                    label: 'Телефон',
                    editable: false

                },

                {
                    id: 'Comment',
                    width: "max-content",
                    field: 'comment',
                    label: 'Комментарий',
                    editable: false,
                }

            ]
        }

        //this.handleQuery = debounce(this.handleQuery, 200)
    }


    handleChange = e => {
        e.preventDefault()
        this.setState({value: e.target.value})
    }


    onRowsRequest = async (requestData, tableManager) => {

        const {requestAllDrafts} = this.props
        const {value} = this.state

        const response = await requestAllDrafts({
            page: tableManager.paginationApi.page - 1,
            per_page: tableManager.paginationApi.pageSize,
            sort: requestData.sort.colId,
            is_asc: requestData.sort.isAsc,
            term: requestData.searchText,
            status: 'checkout',
        }).then(({value}) => {
            return value
        })

        if (!response?.data) return;

        return {
            rows: response.data.items,
            totalRows: response.data.total_count
        }
    }


    render() {
        let {users} = this.props
        const {value, columns} = this.state

        return (
            <Page className="admin-users-page" title="Заказы">
                <Menu/>
                <section className="users-header">
                    <h1>Заказы</h1>
                </section>
                <div className="grid-content">
                    <GridTable
                        showSearch={true}
                        showColumnVisibilityManager={true}
                        texts={{
                            search: 'Поиск:',
                            totalRows: 'Общее кол-во:',
                            rows: 'Показано:',
                            selected: 'Выбрано',
                            rowsPerPage: 'Элементов на странице:',
                            page: 'Страница:',
                            of: 'из',
                            prev: 'Пред.',
                            next: 'След.',
                            columnVisibility: 'Колонки'
                        }}
                        columns={columns}
                        onRowsRequest={this.onRowsRequest}
                    />
                </div>

            </Page>
        )
    }
}

const mapStateToProps = state => ({
    drafts: getAllDrafts(state),
})

export default connect(mapStateToProps, {requestAllDrafts, patchDraft})(AdminOrders)
