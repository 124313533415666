import React, {Component} from 'react'
import classNames from 'classnames'

import Incrementer from './Incrementer'
import {convertUnit, pxToUnit} from "../../../utils/unit";

const UP_ARROW = 38
const DOWN_ARROW = 40
const RETURN = 13

const formatters = {
  number: val => {
    if (typeof val === 'number') {
      return Math.max(0, val)
    }

    let stripped = val.replace(/[^\d\-]/g, '')

    if (`${val}` !== stripped) {
      return +stripped
    }

    return +(+val).toFixed(2)
  },
}

export default class TextControl extends Component {

  format = (val, oldValue) => {

    let { type, allowEmpty } = this.props
    let formatter = formatters[type] || (v => v)
    let result = formatter(val)

    if (result !== val) {
      if (val === '' && allowEmpty) {
        return null
      }

      return result
    }

    return val
  }

  getDiff = e => {
    return e.shiftKey ? 10 : 1
  }

  handleKeyDown = e => {
    
    let { value } = this.props
    let newValue = this.format(e.target.value, value)

    e.stopPropagation()

    if (e.which === UP_ARROW) {
      e.preventDefault()

      this.handleChangeValue(+newValue + this.getDiff(e))


    } else if (e.which === DOWN_ARROW) {
      e.preventDefault()

      
      this.handleChangeValue(+newValue - this.getDiff(e))


    } else if (e.which === RETURN) {
      e.currentTarget.blur()
    }

  }

  handleChangeValue = newValue => {

    let { name, onChange, type, percentage, minValue, maxValue, converted } = this.props

    if (type === 'number' && percentage) {
      newValue = Math.max(0, newValue)
      newValue = Math.min(100, newValue)
      newValue = newValue / 100
    }

    if (type === 'number' && typeof minValue === 'number') {
      if (newValue < minValue) {
        newValue = minValue
      }
    }

    if (type === 'number' && typeof maxValue === 'number') {
      if (newValue > maxValue) {
        newValue = maxValue
      }
    }

    if (converted) {
      newValue = convertUnit(newValue)
    }


    if(name == 'width' || name == 'height'){
      if(newValue <= 100){
        newValue = 100
      }
    }
 
    if(name == 'width' || name == 'height' || name == 'depth'){
      if(newValue <= 0){
        newValue = 0
      }
    }

    onChange({ [name]: newValue })

  }

  handleChange = e => {

    let { value } = this.props
    let newValue = this.format(e.target.value, value)

    return this.handleChangeValue(newValue)
  }

  render() {
    let {
      label,
      name,
      value,
      onFocus,
      onBlur,
      placeholder,
      disabled,
      type,
      gray,
      disableIncrementer,
      converted,
      suffix,
      error
    } = this.props

    let isNumber = type === 'number'

    if (value === undefined || value === null) {
      value = ''
    }

    if (isNumber && value !== '') {
      let numberValue = +value

      if (converted) {
        numberValue = pxToUnit(numberValue)
      }

      if (!Number.isNaN(numberValue)) {
        value = +numberValue.toFixed(2)
      }
    }

    return (
      <div
        className={classNames('text-control', {
          disabled,
          'text-control-gray': gray,
        })}
      >
        <div className="text-control-sub">

          <input
            type="text"
            name={name}
            value={`${value}`}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
            autoComplete="off"
            placeholder={placeholder}
          />

          {isNumber && !disableIncrementer ? (
            <Incrementer value={value} onChange={this.handleChangeValue} />
          ) : null}
          {suffix && <div className={'text-control-suffix'}>{suffix}</div>}
        </div>
        <label>{label}</label>
      </div>
    )
  }

}
