import {DEFAULT_SELECTION} from './selection'

const SET_TAB = Symbol('SET_TAB')
const SET_ADD_TAB = Symbol('SET_ADD_TAB')

// Reducer

const initalState = {
  activeTab: null,
  activeAddTab: null,
}

export default (state = initalState, action) => {
  let selection = DEFAULT_SELECTION

  switch (action.type) {
    case SET_TAB:
      return {
        ...state,
        selection,
        activeTab: action.tabName,
      }
    case SET_ADD_TAB:
      return {
        ...state,
        activeAddTab: action.addTabName,
      }
  }
}

// Actions

export const setActiveTab = tabName => ({
  type: SET_TAB,
  tabName,
})

export const setActiveAddTab = addTabName => ({
  type: SET_ADD_TAB,
  addTabName,
})

// Selectors

export const getActiveTab = state => {
  return state.editor.objects.present.activeTab
}

export const getActiveAddTab = state => {
  return state.editor.objects.present.activeAddTab
}
