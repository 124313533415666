import axios from 'axios'

import {apiURL} from '../../utils/io'

const REQUEST_USER = 'REQUEST_USER'

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
  if (action.type === `${REQUEST_USER}_FULFILLED`) {
    return {
      ...state,
      [action.meta.userId]: action.payload.data,
    }
  }

  return state
}

// Actions

export const requestUser = userId => ({
  type: REQUEST_USER,
  payload: axios.get(`${apiURL}/users/${userId}`),
  meta: { userId },
})

// Selector

export const getUser = (state, userId) => {
  return state.admin.users[userId]
}
