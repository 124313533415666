import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notify } from 'ducks/notifications';
import { withRouter } from 'react-router-dom';

import { setTemplateSetting, setOuterRule } from 'components/Admin/template/store/templateActions';
import NumberInputIndex from 'components/Admin/template/NumberInputIndex';

import {
    onOuterRuleChange,
    onAddOuterRule,
    onRemoveOuterRule,
    setActiveRule
} from './outerRuleFunctions';


class OuterRuleEditor extends Component {

    handleOuterRuleChange = (index, e) => {

        const { outer_rule } = this.props
        const { setOuterRule } = this.props

        onOuterRuleChange(
            index, 
            e, 
            outer_rule, 
            setOuterRule,
        );

    };

    handleAddOuterRule = () => {

        const { outer_rule } = this.props
        const { setOuterRule, setTemplateSetting } = this.props

        onAddOuterRule(
            outer_rule, 
            setOuterRule, 
            setTemplateSetting,
        );

    };

    handleRemoveOuterRule = (index) => {
        onRemoveOuterRule(index, this.props.outer_rule, this.props.setOuterRule, this.props.setting, this.props.setTemplateSetting);
    };

    handleSetActiveRule = (index) => {
        setActiveRule(index, this.props.setTemplateSetting);
    };

    render() {
        const { outer_rule, setting } = this.props;

        return (
            <div>
                {outer_rule && outer_rule.map((rule, index) => (
                    <div
                        key={index}
                        className={`mb-4 p-4 border rounded-lg ${setting.activeRuleIndex === index ? 'border-blue-500 bg-blue-50' : 'border-gray-300 bg-white'} shadow-sm cursor-pointer`}
                        onClick={() => this.handleSetActiveRule(index)}
                    >
                        <h3 className="text-xl font-semibold mb-4">Outer Rule {index}</h3>

                        <NumberInputIndex
                            code={`x`}
                            name="x"
                            value={rule.x}
                            onChange={this.handleOuterRuleChange}
                            index={index}
                        />

                        <NumberInputIndex
                            code={`y`}
                            name="y"
                            value={rule.y}
                            onChange={this.handleOuterRuleChange}
                            index={index}
                        />

                        <NumberInputIndex
                            code={`width`}
                            name="width"
                            value={rule.width}
                            onChange={this.handleOuterRuleChange}
                            index={index}
                        />

                        <NumberInputIndex
                            code={`height`}
                            name={"height"}
                            value={rule.height}
                            onChange={this.handleOuterRuleChange}
                            index={index}
                        />

                        <NumberInputIndex
                            code={`rotation`}
                            name={"rotation"}
                            value={rule.rotation}
                            onChange={this.handleOuterRuleChange}
                            index={index}
                        />

                        <NumberInputIndex
                            code={`depth`}
                            name={"depth"}
                            value={rule.depth}
                            onChange={this.handleOuterRuleChange}
                            index={index}
                        />

                        <label htmlFor={`outer_rule_${index}_name`} className="block text-sm font-medium text-gray-700 mt-4">Name</label>
                        <input
                            type="text"
                            id={`outer_rule_${index}_name`}
                            name="name"
                            value={rule.name}
                            onChange={(e) => this.handleOuterRuleChange(index, e)}
                            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        />

                        <div className="text-sm text-gray-600 mt-2">Количество точек: {rule.points ? rule.points.length : 0}</div>

                        <button
                            type="button"
                            onClick={(e) => { e.stopPropagation(); this.handleRemoveOuterRule(index); }}
                            className="mt-4 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                            Remove Rule
                        </button>
                    </div>
                ))}
                <button
                    type="button"
                    onClick={this.handleAddOuterRule}
                    className="mt-4 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                    Add Rule
                </button>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        outer_rule: state.admin.template.outer_rule,
        setting: state.admin.template.setting,
    };
}


const mapDispatchToProps = (dispatch) => ({
    notify: (message) => dispatch(notify(message)),
    setTemplateSetting: (setting) => dispatch(setTemplateSetting(setting)),
    setOuterRule: (outer_rule) => dispatch(setOuterRule(outer_rule)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OuterRuleEditor));
