import deepEqual from 'deep-equal'

const identity = (itm) => {
  return itm;
};

export const sameElements = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  arr1 = arr1.slice(0);
  arr2 = arr2.slice(0);
  arr1.sort(function (a, b) {
    return a - b;
  });
  arr2.sort(function (a, b) {
    return a - b;
  });

  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};

export const  uniqueElements = function (arr) {
  const getter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : identity;
  const deep = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  const set = new Set();
  const result = [];
  arr.forEach(function (element) {
    const val = getter(element);

    if (deep) {
      let exists = false;

      for (let i = 0; i < result.length; i += 1) {
        if (deepEqual(getter(result[i]), val)) {
          exists = true;
          break;
        }
      }

      if (!exists) {
        result.push(element);
      }
    } else {
      if (!set.has(val)) {
        result.push(element);
        set.add(val);
      }
    }
  });
  return result;
};

export const xor = (arr1, arr2) => {
  const newArr = arr1.slice(0);
  arr2.forEach(function (itm) {
    const index = newArr.indexOf(itm);

    if (index === -1) {
      newArr.push(itm);
    } else {
      newArr.splice(index, 1);
    }
  });
  return newArr;
};

export const sort = function (arr) {
  const keyFunc = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function (itm) {
    return itm;
  };
  return arr.slice(0).sort(function (a, b) {
    const aKey = keyFunc(a);
    const bKey = keyFunc(b);

    if (aKey < bKey) {
      return -1;
    } else if (bKey < aKey) {
      return 1;
    } else {
      return 0;
    }
  });
}; // Single-level array-flattening function


export const flatten = (arr) => {
  let result = [];
  let _iteratorNormalCompletion = true;
  let _didIteratorError = false;
  let _iteratorError = undefined;

  try {
    for (var _iterator = arr[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      const itm = _step.value;
      result = result.concat(itm);
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }

  return result;
}; // Array.indexOf but with an accessor func
// Useful because of immutability


export const indexOf = (arr, accessor) => {
  for (let i = 0; i < arr.length; i += 1) {
    if (accessor(arr[i])) {
      return i;
    }
  }

  return -1;
};
