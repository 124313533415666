import React, {Component} from 'react'
import DocumentEvents from 'react-document-events'


export default class PositioningManager extends Component {

  // Not sure when this would ever happen but...
  _prevDragPoint = [0, 0]

  handleMouseMove = e => {

    let { drag } = this.props


    const clientX = e.clientX || (e.touches && e.touches[0].clientX) || undefined ;
    const clientY = e.clientY || (e.touches &&  e.touches[0].clientY) || undefined ;

    if (clientX !== undefined && clientY !== undefined) {
      this._prevDragPoint = [clientX, clientY]
    }

    drag(this._prevDragPoint, e.shiftKey, e.altKey)

    // console.log(1)
  }

  handleMouseUp = () => {
    let { endDrag } = this.props

    endDrag()
  }

  render() {
    return (
      <DocumentEvents
        onTouchMove={this.handleMouseMove}
        onMouseMove={this.handleMouseMove}
        onMouseUp={this.handleMouseUp}
        onTouchEnd={this.handleMouseUp}
        onKeyUp={this.handleKeyDown}
      />
    )
  }
}
