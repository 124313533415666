import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Page from 'components/Shared/Page';
import GridTable from "@nadavshaar/react-grid-table";
import Menu from "./Menu";
import { apiURL, BASE_URL } from '../../utils/io';
import { getAuthToken } from '../../utils/auth';




class Template extends Component {
    
    constructor(props) {

        super(props);

        this.state = {
            tempate_type: { id: 0, name: '' },
            columns: [
          
                {
                    id: 'thumb',
                    field: 'thumb',
                    label: 'Изображение',
                    cellRenderer: ({ data }) => (
                        <div>
                            <img src={`${BASE_URL + '/media' + data.thumb}`} width='100' height='100' alt="thumbnail" />
                            <input type="file" onChange={(e) => this.handleImageChange(e, data.id)} />
                        </div>
                    ),
                    width: "200px"
                },
                {
                    id: 'Перейти',
                    label: '',
                    width: "100px",
                    cellRenderer: ({ data }) => (
                        <Link to={`/admin/templates/${data.id}`} className="text-blue-500 underline">{data.name}</Link>
                    )
                },
                {
                    id: 'Delete',
                    label: '',
                    width: "100px",
                    cellRenderer: ({ data }) => (
                        <button onClick={() => this.Delete(data.id)} className="text-red-500">Удалить</button>
                    )
                },
            ],
            rows: [],
            totalRows: 0,
        };
    }

    handleImageChange = async (e, id) => {
        this.setState({ isLoading: true });
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await fetch(`${apiURL}/templates/${id}/image`, {
                method: 'PUT',
                body: formData
            });

            if (response.ok) {
                await this.All(this.props.match.params.id);
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            console.error('Ошибка при загрузке изображения:', error);
        }
    }

    createDraft = async (name) => {
        let { history } = this.props;

        try {
            let token = getAuthToken();

            const response = await fetch(`${apiURL}/drafts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `BEARER ${token}`
                },
                body: JSON.stringify({ sizes: 'template', template_id: this.state.tempate_type.id, name: `${name}_draft_view` })
            });

            if (response.ok) {
                const data = await response.json();
                if (data.id) {
                    history.push(`/apps/${data.id}`);
                }
            } else {
                throw new Error('Failed to createDraft the category');
            }
        } catch (error) {
            console.error('Error createDraft template:', error);
        }
    }

    handleChange = (e, data) => {
        const target = e.target;
        const type = target.type;
        let value = type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let row_update = null;

        this.setState(prevState => ({
            rows: prevState.rows.map(row => {
                if (row.id === data.id) {
                    if (type === 'number') {
                        value = parseFloat(value);
                    }
                    row_update = { ...row, [name]: value };
                    return row_update;
                }
                return row;
            })
        }), async () => await this.Update(row_update));
    }

    Update = async (data) => {
        try {
            const response = await fetch(`${apiURL}/templates`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error('Failed to update category');
            }
        } catch (error) {
            console.error('Error updating template type:', error);
        }
    }

    componentDidMount() {
        this.All(this.props.match.params.id);
        this.TemplateTypeGetById(this.props.match.params.id);
    }

    TemplateTypeGetById = async (id) => {
        this.setState({ isLoading: true });

        try {
            const response = await fetch(`${apiURL}/template_types/${id}`);
            if (response.ok) {
                const data = await response.json();
                this.setState(() => ({
                    tempate_type: data,
                }));
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            console.error('Error fetching template types:', error);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    All = async (id) => {
        this.setState({ isLoading: true });

        try {
            const response = await fetch(`${apiURL}/template_types/${id}/templates`);
            if (response.ok) {
                const data = await response.json();
                this.setState({
                    rows: data,
                    totalRows: data.length,
                    isLoading: false
                });
            } else {
                throw new Error('Network response was not ok.');
            }
        } catch (error) {
            console.error('Error fetching template types:', error);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    Delete = async (id) => {
        this.setState({ isLoading: true });

        try {
            const response = await fetch(`${apiURL}/templates/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                this.setState(prevState => ({
                    rows: prevState.rows.filter(o => o.id !== id),
                    totalRows: prevState.totalRows - 1
                }));
            } else {
                throw new Error('Failed to delete the category');
            }
        } catch (error) {
            console.error('Error deleting template:', error);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    render() {
        const { columns, rows, totalRows, isLoading, tempate_type } = this.state;

        return (
            <Page className="admin-users-page" title="Шаблоны">

                <Menu />

                <section className="users-header mb-4">
                    <h1 className="text-2xl font-bold">Шаблоны - {tempate_type.name}</h1>
                </section>

                <Link to={`/admin/template-type/${tempate_type.id}/templates/new`} className="inline-block mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">+ новый</Link>

                <div className="grid-content">
                    {isLoading ? (
                        <div>Loading...</div>  // Индикатор загрузки
                    ) : (
                        <GridTable
                            showSearch={true}
                            showColumnVisibilityManager={true}
                            columns={columns}
                            rows={rows}
                            totalRows={totalRows}
                            texts={{
                                search: 'Поиск:',
                                totalRows: 'Общее кол-во:',
                                rows: 'Показано:',
                                selected: 'Выбрано',
                                rowsPerPage: 'Элементов на странице:',
                                page: 'Страница:',
                                of: 'из',
                                prev: 'Пред.',
                                next: 'След.',
                                columnVisibility: 'Колонки'
                            }}
                        />
                    )}
                </div>
            </Page>
        );
    }
}

export default withRouter(Template)
