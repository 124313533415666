import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notify } from 'ducks/notifications';
import { withRouter } from 'react-router-dom';

import { setOuterRule, setTemplateSetting } from 'components/Admin/template/store/templateActions';

class PointsRawData extends Component {

    state = {
        rawData: "",
    };

    handleRawDataChange = (e) => {
        this.setState({ rawData: e.target.value });
    };

    roundToPrecision = (num, precision = 4) => {
        return parseFloat(num.toFixed(precision));
    };
    
    applyRawData = () => {
        try {
            const parsedPoints = JSON.parse(this.state.rawData);
            if (Array.isArray(parsedPoints)) {
                parsedPoints.forEach(point => {
                    if (!Array.isArray(point) || point.length !== 2) {
                        this.props.notify('Invalid point format');
                        throw new Error('Invalid point format');
                    }
                    point[0] = this.roundToPrecision(parseFloat(point[0]), 4);
                    point[1] = this.roundToPrecision(parseFloat(point[1]), 4);
                });

                const { outer_rule, setting, setOuterRule } = this.props;
                const activeRuleIndex = setting.activeRuleIndex;

                const updatedOuterRule = outer_rule.map((rule, index) => {
                    if (index === activeRuleIndex) {
                        return { ...rule, points: parsedPoints };
                    }
                    return rule;
                });

                setOuterRule(updatedOuterRule);
                this.setState({ rawData: '' });
                this.props.notify('Raw data applied successfully');
            } else {
                this.props.notify('Invalid points array');
                throw new Error('Invalid points array');
            }
        } catch (error) {
            this.props.notify('Error applying raw data: ' + error.message);
        }
    };

    render() {
        const { rawData } = this.state;

        return (
            <div className="flex flex-col mt-5">
                <textarea
                    className="w-full h-32 p-2 border border-gray-300 rounded-md resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={rawData}
                    onChange={this.handleRawDataChange}
                    placeholder='Paste raw data example: [
                            [
                                3.9184,
                                3.7168
                            ],
                            [
                                3.8642,
                                3.6357
                            ],
                        ]'
                />
                <button
                    type="button"
                    onClick={this.applyRawData}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    Apply Raw Data
                </button>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    setting: state.admin.template.setting,
    outer_rule: state.admin.template.outer_rule,
});

const mapDispatchToProps = (dispatch) => ({
    notify: (message) => dispatch(notify(message)),
    setTemplateSetting: (setting) => dispatch(setTemplateSetting(setting)),
    setOuterRule: (outer_rule) => dispatch(setOuterRule(outer_rule)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PointsRawData));
