import React, {Component} from 'react'
import classNames from 'classnames'
import {Link} from 'react-router-dom'

import Icon from 'components/Shared/Icon'

import Loading from '../Loading'
import './Button.scss'

export default class Button extends Component {
  render() {
    let {
      className,
      to,
      target,
      text,
      outlined,
      large,
      small,
      block,
      danger,
      secondary,
      white,
      component,
      loading,
      children,
      teal,
      darkPink,
      orange,
      yellow,
      green,
      purple,
      gray,
      red,
      pink,
      square,
      focussed,
      padded,
      fluid,
      icon,
      linked,
      listAddButton,
      fitContent,
      ...props
    } = this.props

    props.disabled = props.disabled || loading

    let ButtonComponent = to ? Link : 'button'

    if (to && target === '_blank') {
      ButtonComponent = 'a'

      props.href = to
      props.target = target
    }

    if (component) {
      ButtonComponent = component
    }

    return (
      <ButtonComponent
        {...props}
        to={to}
        className={classNames('btn', className, {
          'btn-danger': danger,
          'btn-block': block,
          'btn-text': text,
          'btn-outlined': outlined,
          'btn-small': small,
          'btn-large': large,
          'btn-secondary': secondary,
          'btn-white': white,
          'btn-loading': loading,
          'btn-teal': teal,
          'btn-dark-pink': darkPink,
          'btn-orange': orange,
          'btn-yellow': yellow,
          'btn-green': green,
          'btn-purple': purple,
          'btn-gray': gray,
          'btn-red': red,
          'btn-pink': pink,
          'btn-focussed': focussed,
          'btn-square': square,
          'btn-padded': padded,
          'btn-fluid': fluid,
          'btn-list-add': listAddButton,
          'btn-fit-content': fitContent,
          'btn-linked': linked,
        })}
      >
        {icon ? <Icon type={icon} /> : null}
        {children}
        {loading ? (
          <div className="btn-loading-loader">
            <Loading small />
          </div>
        ) : null}
      </ButtonComponent>
    )
  }
}

Button.defaultProps = {
  type: 'contained',
}
