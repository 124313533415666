import React, {Component} from 'react'

import {getCurrentAppId} from "../../../ducks/editor/objects";
import {connect} from "react-redux";
import { getApp } from "ducks/apps/selectors";
import {withRouter} from "react-router-dom";
import * as BABYLON from 'babylonjs';
import BabylonScene from './Scene'; // import the component above linking to file we just created.
import {PathItem, Point as PathPoint} from "../../../utils/vector";
import * as earcut from "earcut";
import "@babylonjs/core/Debug/debugLayer";
import "@babylonjs/inspector";
import {GROUP, SHAPE,} from 'common/constants'
import {Menu, MenuItem} from "../../Shared/Menu";
import CustomLoadingScreen from "./Loading";
import "./loader.scss";
import {getBaseZoom} from "../../../utils/zoom";
import {getId} from "../../../common/utils";
window.earcut = earcut;

class Frame extends Component {

  camera = null

  constructor(props) {
    super(props)
    this.camera = this.engine = null
    this.state = {
      activeLayer : 0,
      scene: null
    }
  }

  onSceneMount = (e) => {

    const {canvas, scene, engine} = e;

    const {app, components} = this.props

    this.engine = engine
    scene.useRightHandedSystem = true;
    this.camera = new BABYLON.ArcRotateCamera("Camera", Math.PI / 3, Math.PI / 3, 50, BABYLON.Vector3(app.width / 100 / 2, app.height / 100 / 2, 0), scene);
    this.camera.setTarget(BABYLON.Vector3.Zero());
    this.camera.attachControl(canvas, true);

    const loadingScreen = new CustomLoadingScreen(engine);
    loadingScreen.loadingUIText = "Загрузка..."

    this.setState({scene},  ()=>{
      engine.displayLoadingUI();
      this.drawLayer()
      engine.hideLoadingUI();
    })


      this.engine.runRenderLoop(() => {
        if (scene) {
          scene.render();

        }
      });


  }

  getOverflows = (componentId) => {

    const {app} = this.props
    let baseZoom = getBaseZoom()

    const arr = Object.values(app.components).sort(function (a, b) {
      return a.order - b.order;
    })

    function flattenLayer(objects, flatten) {

      flatten = flatten || {}

      if (!Array.isArray(objects)) {
        objects = [objects]
      }

      objects.forEach((item) => {

        if (item.type === GROUP) {
          item.children?.forEach((obj) => {
            obj = Object.assign({},  obj)
            flattenLayer(obj, flatten)
          })
          return
        }

        if (item.compound && item.compound.length > 0) {
          item.compound?.forEach((obj) => {
            flattenLayer(obj, flatten)
          })
          return
        }

        if (item.points && item.points.length > 0) {
          let p = PathItem.create(item.points)
          const bounds = p.bounds

          item = Object.assign({}, {
            id: getId(),
            isClosed: true,
            //children: curr.children,
            points: item.points,
            x: bounds.x,
            y: bounds.y,
            width: bounds.width,
            height: bounds.height,
            depth: item.depth,
            type: SHAPE
          })
        }


        flatten[item.id] = item
      })


      return flatten
    }

    let overflow = {}
    let elements = []


    arr.forEach((component) => {

      const flatten = flattenLayer(component.objects)

      overflow[component.id] = elements
      elements = []

      overflow[component.id].forEach((obj) => {
        obj = Object.assign({},  obj)
        if (obj.depth - component.depth > 0) {
          obj.depth = obj.depth - component.depth
          elements.push(obj)
        }
      })

      Object.values(flatten).forEach((obj) => {
        obj = Object.assign({},  obj)

        if ( obj.depth - component.depth > 0) {
          obj.depth = obj.depth - component.depth
          elements.push(obj)
        }
      })


    })

    return overflow[componentId]
  }

  drawLayer = () => {

    const { scene} = this.state;
    const {app, components} = this.props



    let {activeLayer} = this.state
    let layer = components[activeLayer]

    if (!layer) {
      layer = Object.values(components)[0];
    }

    new BABYLON.HemisphericLight("light", new BABYLON.Vector3(1, 1, 0));
    new BABYLON.HemisphericLight("light", new BABYLON.Vector3(1, 1, 0));


    let box
    let plate
    let boxMesh


    if (app && app.outer_rule && Array.isArray(app.outer_rule)) {
      app.outer_rule.map((outer) => {



        const path = PathItem.create(outer.points)
        path.adjust(0, 0)
        //path.flatten()
        path.setClosed(true)
        path.scale(new PathPoint(1, -1))
        path.reorient(true, true);
        let rulePoints = path.segments.map((s) => {
          return new BABYLON.Vector3(s.point.x, s.point.y, 0)
        })

        const [end] = rulePoints
        rulePoints = [...rulePoints, end].reverse()

        const extrudeRulePath = [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 0, layer.depth / 100)]
        const cap = rulePoints.map((p) => {
          return new BABYLON.Vector3(p.x, 0, p.y)
        })

        box = BABYLON.MeshBuilder.ExtrudeShapeCustom("box", {
          shape: rulePoints,
          path: extrudeRulePath,
          sideOrientation: BABYLON.Mesh.DOUBLESIDE,
          updatable: true
        }, scene);
        box.position = new BABYLON.Vector3(0, 0, 0)


        var capFront = BABYLON.MeshBuilder.CreatePolygon("polygon", {
          shape: cap,
          sideOrientation: BABYLON.Mesh.FRONTSIDE
        }, scene);
        capFront.rotation.x = -Math.PI / 2;
        var capBack = BABYLON.MeshBuilder.CreatePolygon("polygon", {
          shape: cap,
          sideOrientation: BABYLON.Mesh.BACKSIDE
        }, scene);

        capBack.rotation.x = -Math.PI / 2;
        capBack.position.z = layer.depth / 100

        boxMesh = BABYLON.Mesh.MergeMeshes([capFront, box, capBack]);

      })


    } else {

      boxMesh = BABYLON.MeshBuilder.CreateBox("box", {
        width: app.width / 100,
        height: app.height / 100,
        depth: layer.depth / 100
      }, scene);
      boxMesh.position = new BABYLON.Vector3((app.width / 2) / 100, (app.height / 2) / 100, (layer.depth / 2) / 100)
    }

    plate = BABYLON.CSG.FromMesh(boxMesh);
    const multiMat = new BABYLON.MultiMaterial("multiMat", scene);

    this.camera.setTarget(new BABYLON.Vector3((app.width / 2) / 100, (app.height / 2) / 100, (layer.depth / 2) / 100));
    const boxMat = new BABYLON.StandardMaterial("mat0", scene)
    boxMat.diffuseColor = BABYLON.Color3.FromHexString("#0ea7e2")
    boxMat.backFaceCulling = !0
    multiMat.subMaterials.push(boxMat)

    const drawRect = (width, height, depth, x, y, borderRadius, rounded) => {


      const model = BABYLON.MeshBuilder.CreateBox("extrudedInBox" + Math.floor(Math.random() * 9999), {
        width: width,
        height: height,
        depth: depth
      }, scene);

      model.position = new BABYLON.Vector3((x * 96) / 100 + width / 2, (y * 96) / 100 + height / 2, depth / 2);//(2, 3, 4)
      //geometry.translate(-globalW / 2 + (x / 98) + (width / 96) / 2, globalH / 2 - (y / 98) - (height / 96) / 2, globalD / 2 - (shapeObject.depth / 96) / 2 - 20);

      const Z = new BABYLON.StandardMaterial("mat1", scene);
      Z.diffuseColor = BABYLON.Color3.FromHexString("#f86396")
      Z.backFaceCulling = true;
      multiMat.subMaterials.push(Z)

      let ex = BABYLON.CSG.FromMesh(model);

      plate = plate.subtract(ex)
      model.dispose()


    }

    const drawPolygon = (width, height, depth, x, y, points) => {

      const w = width
      const h = height
      const d = depth

      const path = PathItem.create(points)
      path.setClosed(true)

      path.scale(new PathPoint(1, -1))
      const newOptions = {
        ...options,
        depth: d
      }


      var box = d3threeD(path.getPathData());
      const geometry = new THREE.ExtrudeGeometry(box, newOptions)
      const mesh = this.createMesh(geometry);
      geometry.translate(-globalW / 2 + (x) + w / 2, globalH / 2 - (y) - h / 2, -globalD / 2 + d / 2);
      //geometry.translate(-globalW / 2 + (x) + w / 2, -globalH / 2 + (y) + h / 2, -globalD / 2 + d / 2);
      boxMesh = CSG.subtract(boxMesh, mesh);

    }

    if (app && app.in_case_shapes) {
      app.in_case_shapes.forEach((sh) => {

        if (sh.data) {
          sh.data.forEach((o) => {
            const {width, height, depth, x, y, borderRadius, rounded, shape, points} = o
            if (shape === 'RECT') {
              drawRect(width, height, depth, x, y)
            }
            if (shape === 'POLYGON') {


              //drawPolygon(width, height, depth, x, y, points)
            }

          })
        }/* else {

          const {width, height, length, xx, yy, borderRadius, rounded} = sh
          drawRect(width, length, height, xx, yy, borderRadius, rounded)

        }*/


      })
    }


    const draw = (shapeObject, parent) => {


      const {width, height, depth, x, y, points, compound, id, angle} = shapeObject
      let path = PathItem.create()

      if (shapeObject.children && shapeObject.type === GROUP) {
        shapeObject.children.forEach((children) => {
          draw(children, shapeObject)
        })
        return
      }

      if (compound && compound.length > 0) {
        path = PathItem.create(compound)
        path.children.forEach((children) => {
          const bounds = children.bounds
          draw({...children, points: children.points, x: bounds.x, y: bounds.y, width: bounds.width, height: bounds.height, depth: children.depth},shapeObject)
        })

        return
      }

      if (points && points.length > 0) {
        path = PathItem.create(points)
      }

      path.setClosed(true)
      path.reorient(true, true);
      path.scale(new PathPoint(1, -1))

      if (!path.segments ) {
        return
      }


      path.flatten()
      path.adjust(0, 0)
      //path.scale(new PathPoint(1.005, 1.005))

      let shapePoints = path.segments.map((s) => {
        return new BABYLON.Vector3((s.point.x ) / 100, (s.point.y ) / 100, 0)
      })

      const [end] = shapePoints
      shapePoints = [...shapePoints, end].reverse()

      const extrudePath = [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 0, depth / 100)]

      const cap = shapePoints.map((p) => {
        return new BABYLON.Vector3(p.x, 0, p.y)
      })

      const extrusion = BABYLON.MeshBuilder.ExtrudeShapeCustom(id, {
        shape: shapePoints,
        path: extrudePath,
        sideOrientation: BABYLON.Mesh.DOUBLESIDE,
        updatable: true
      }, scene);
      extrusion.position = new BABYLON.Vector3(0, 0, 0)

      const capFront = BABYLON.MeshBuilder.CreatePolygon("polygon", {
        shape: cap,
        sideOrientation: BABYLON.Mesh.FRONTSIDE
      }, scene);
      capFront.rotation.x = -Math.PI / 2;
      const capBack = BABYLON.MeshBuilder.CreatePolygon("polygon", {
        shape: cap,
        sideOrientation: BABYLON.Mesh.BACKSIDE
      }, scene);
      capBack.rotation.x = -Math.PI / 2;
      capBack.position.z = depth / 100


      const model = BABYLON.Mesh.MergeMeshes([capFront, extrusion, capBack]);

      model.position = new BABYLON.Vector3(x / 100, (app.height - y - height) / 100, (layer.depth  - depth) / 100 + 0.05);//(2, 3, 4)
      //geometry.translate(-globalW / 2 + (x / 98) + (width / 96) / 2, globalH / 2 - (y / 98) - (height / 96) / 2, globalD / 2 - (shapeObject.depth / 96) / 2 - 20);

      let ex = BABYLON.CSG.FromMesh(model);

      const Z = new BABYLON.StandardMaterial("mat1", scene);
      Z.diffuseColor = BABYLON.Color3.FromHexString("#fadd34")
      Z.backFaceCulling = true;
      multiMat.subMaterials.push(Z)


      plate = plate.subtract(ex)
      //plate.toMesh("", multiMat, scene, true).simplify([{quality:0.5, distance: 0, optimizeMesh: true}])
      model.dispose()


    }

    //objects.map((obj) => {
    layer.objects && Array.isArray(layer.objects) && layer.objects.forEach((el) => {

      draw(el)

    })

    this.getOverflows(layer.id)?.forEach((el) => {
      draw(el)
    })

    boxMesh.dispose()


//G.dispose()


    plate.toMesh("csg4", multiMat, scene, true)

  }
  componentWillUnmount() {

  }

  render() {

    //let scale = this.getScale()
    let styles = {
      width: window.innerWidth,
      height: window.innerHeight,
    }

    const {state, props} = this
    if (!props.app) return null
    /*let innerStyles = {
      width: Math.ceil(scale * styles.width),
      height: Math.ceil(scale * styles.height),
    }*/

    return (
      <div className="editor-preview-frame-wrapper" >
        {/* <DocumentEvents target={window} onResize={this.forceRender}/>*/}
        <div id={"loadingScreen"}>
          <div id={'loadingText'}></div>
        </div>
        <BabylonScene key={this.state.activeLayer} engineOptions={{

          antialias: true,
          audioEngine: false,
          disableWebGL2Support: false,
          powerPreference: "high-performance",
          failIfMajorPerformanceCaveat: false,
          useHighPrecisionFloats: true,
          preserveDrawingBuffer: true,
          stencil: true,

        }} width={styles.width} height={styles.height} onSceneMount={this.onSceneMount} activeLayer={this.state.activeLayer} canvasId="root-canvas"/>

        <div className={'select-layers'}  >
          <Menu

              value={state.value}
              onChange={(event)=> {
                this.setState({
                  activeLayer:event
                });

                this.state.scene.dispose()
               // this.drawLayer()
              }}
              >
            {Object.values(props.components).map((item, itemIndex) => (



                      <MenuItem
                          value={item.id}
                          key={itemIndex}>
                        {item.name} {itemIndex}
                      </MenuItem>
                  ))}


          </Menu>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, {appId}) => {

  const app = getApp(state, getCurrentAppId(state))
  
  const components = app && app.components

  return {
    app: app,
    components: components,
  }

}

export default withRouter(connect(mapStateToProps, {})(Frame))
