/* eslint-disable global-require */
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Field, reduxForm} from 'redux-form'

import WrappedInput from 'components/Shared/Forms/WrappedInput'
import Modal from 'components/Shared/Modal'

const FORM_NAME = 'newScreenForm'

export const validate = data => {
    let errors = {}

    if (!data.depth) {
        errors.depth = 'Укажите глубину слоя'
    }
    if (!data.name) {
        errors.name = 'Укажите название слоя'
    }
    return errors
}

class NewScreenForm extends Component {
    handleCancel = e => {
        let {onCancel} = this.props
        e.preventDefault()

        onCancel()
    }

    componentDidMount() {

    }

    render() {
        let {
            chooseTemplate,
            handleSubmit,
            defaultName,
            templatesLoaded,
            submitting,
            error
        } = this.props

        return (
            <form onSubmit={handleSubmit} id="new-screen-form" className={'new-layer-form'}>
                <Modal.Header title="Новый слой"/>
                <Modal.Content>
                    <div className={'inspect-form'}>
                        {error && !submitting ? (
                            <div className="inspect-form-alert">{error}</div>
                        ) : null}

                        <Field
                            autoFocus
                            className={'inline-field'}
                            name="name"
                            label="Название"
                            placeholder={"Название"}
                            component={WrappedInput}

                        />
                        <Field
                            name="depth"
                            className={'inline-field'}
                            label="Глубина"
                            type={"number"}
                            component={WrappedInput}

                        /></div>
                </Modal.Content>
                <Modal.Actions>
                    <Modal.Button type="button" text onClick={this.handleCancel}>
                        Отмена
                    </Modal.Button>
                    <Modal.Button
                        form="new-screen-form"
                        type="submit"
                        loading={submitting}
                    >
                        Создать слой
                    </Modal.Button>
                </Modal.Actions>
            </form>
        )
    }

}

function mapStateToProps(state, {chooseTemplate, primaryPlatform}) {


    return {}
}

export default connect(mapStateToProps, {})(
    reduxForm({
        initialValues: {
            template: 'blank',
        },
        validate,
        form: FORM_NAME,
    })(NewScreenForm)
)

