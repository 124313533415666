import {GROUP, SHAPE} from "../common/constants";
import {PathItem} from "./vector";
import {getId} from "../common/utils";

export const calculate = (components) => {

    let result = {}

    const unit = 1 / 0.2645833333
    const coff = 0.264583333

    const getOverflows = (mod) => {

        const arr = Object.values(components).sort(function (a, b) {
            return a.order - b.order;
        })

        function flattenLayer(objects, flatten) {

            flatten = flatten || {}

            if (!Array.isArray(objects)) {
                objects = [objects]
            }

            objects.forEach((item) => {

                if (item.type === GROUP) {
                    item.children?.forEach((obj) => {
                        obj = Object.assign({}, obj)
                        flattenLayer(obj, flatten)
                    })
                    return
                }

                if (item.compound && item.compound.length > 0) {
                    item.compound?.forEach((obj) => {
                        flattenLayer(obj, flatten)
                    })
                    return
                }

                if (item.points && item.points.length > 0) {
                    let p = PathItem.create(item.points)

                    p.setClosed(true)
                    p.flatten()
                    p.reorient(true/*res.getFillRule() === 'nonzero'*/, true);
                    p.adjust(0, 0)

                    const bounds = p.bounds


                    item = Object.assign({}, {
                        id: getId(),
                        name: item.name,
                        area: Math.round(p.getArea()) ,
                        length: Math.round(p.getLength() ),
                        depth: item.depth,

                    })
                }


                flatten[item.id] = item
            })


            return Object.values(flatten)
        }

        let overflow = {}

        let flatten = []
        let cloned = []


        const crat = 5 * unit

        arr.forEach((component) => {

            let depths = []

            if (mod < component.depth) {
                const len = ~~(component.depth / mod);
                depths = new Array(len).fill(mod);

                if (component.depth % mod !== 0) {
                    let module = component.depth % mod

                    const len2 = ~~(module / crat);
                    depths.push(len2 * crat)
                }
            } else {
                const len2 = ~~(component.depth / crat);
                depths.push(len2 * crat)
            }

            let ff = flattenLayer(component.children)
            flatten = [...flatten, ...ff]


            depths.forEach((depth, index) => {
                let elements = []

                cloned = flatten.slice()

                cloned.forEach((flat, index) => {
                    const obj = Object.assign({}, flat)

                    if (obj.depth - depth > 0) {
                        obj.depth = depth
                        flat.depth -= depth
                        elements.push(obj)
                    } else {
                        flatten.splice(index, 1)
                        elements.push(obj)
                    }
                })

                if (!overflow[component.id]) {
                    overflow[component.id] = []
                }

                overflow[component.id].push({depth: depth, elements: elements})

            })


        })

        return overflow
    }

    const elements = getOverflows(50 * unit)



    const calc = (r, shape, parent) => {

        const {width, height, depth, x, y, points, compound, id, angle} = shape

        let path = PathItem.create()

        if (compound && compound.length > 0) {
            path = PathItem.create(compound)
        } else if (points && points.length > 0) {
            path = PathItem.create(points)
        }
        path.setClosed(true)
        path.flatten()
        path.reorient(true/*res.getFillRule() === 'nonzero'*/, true);
        path.adjust(0, 0)

        const coff = 0.264583333

        r[shape.id] = {
            area: Math.round(path.getArea() * Math.pow(coff, 2)),
            length: Math.round(path.getLength() * coff)
        }
    }


/*    Object.keys(elements).forEach((layerIdx) => {
        result[layerIdx] = {}
        elements[layerIdx].forEach((subLayer, subLayerId) => {
                result[layerIdx][subLayerId] = {}
                subLayer.elements.forEach((el) => {
                    const lr = {}
                    calc(lr, el)

                    result[layerIdx][subLayerId] = {
                        ...result[layerIdx][subLayerId],
                        ...lr
                    }



                })
            })
    })*/

    return elements

}