import React from 'react'
import {Route, Switch} from 'react-router-dom'
import Preview from 'components/Editor/Preview'

const Screens = props => {
  return (
    <Switch>
      <Route path="/apps/:appId/screens/preview" component={Preview} />
    </Switch>
  )
}

export default Screens
