import React, {Component} from 'react'
import Textarea from 'react-textarea-autosize'
import classNames from "classnames";

import './WrappedText.scss'

export default class WrappedText extends Component {
    handleKeyDown = e => {
        let {form} = e.currentTarget

        if (e.which === 13 && !e.shiftKey && form) {
            e.preventDefault()
            e.stopPropagation()

            form.dispatchEvent(new Event('submit', {cancelable: true}))
        }
    }

    render() {
        let {meta,
            label,
            large,
            input,
            className,
            prefix,
            suffix, ...props} = this.props

        return (<div
                className={classNames('wrapped-text', className )}
            >
                <div className={'wrapped-text-inner'}>
                    {label ? <label>{label}</label> : null}
                    <div className="wrapped-text-inner-inner-wrapper">

                        <div className="wrapped-text-input">
                            <Textarea minRows = {10} {...props} {...input} onKeyDown={this.handleKeyDown}/>
                        </div>

                    </div>
                </div>
            </div>
        )

    }
}
