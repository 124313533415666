import {getTool} from '../../utils/tools'

export const SET_TOOL = Symbol('SET_TOOL')
const RESET_TOOL = Symbol('RESET_TOOL')

const INITIAL_STATE = {
  activeTool: null,
  attributes: null,
  startPosition: null,
}

// REDUCER

export default (state = INITIAL_STATE, action) => {
  if (action.type === SET_TOOL) {
    return {
      ...state,
      activeTool: getTool(action.objectType),
      startPosition: action.startPosition,
      attributes: {
        ...action.attributes,
        type: action.objectType,
      },
    }
  }

  if (action.type === RESET_TOOL) {
    return INITIAL_STATE
  }

  return state
}

// ACTIONS

export const setTool = (objectType, attributes, startPosition) => ({
  type: SET_TOOL,
  attributes,
  objectType,
  startPosition,
})

export const resetTool = () => ({
  type: RESET_TOOL,
})

// SELECTORS

export const getActiveTool = state => {
  return state.editor.tools.activeTool
}

export const getObjectAttributes = state => {
  return state.editor.tools.attributes
}

export const getStartPosition = state => {
  return state.editor.tools.startPosition
}
