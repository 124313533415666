import React, {Component} from 'react'
import {connect} from "react-redux";
import {requestLibraries, searchComponents} from "ducks/components";
import {Tree} from 'components/Shared/Tree';
import {DEFAULT_PER_PAGE, fetchTemplateTypes, searchTemplates} from 'utils/io'
import './tab.scss'
import classNames from "classnames";
import ImagePlaceholder from "components/Shared/ImagePlaceholder";
import Loading from "components/Shared/Loading";
import Scrollbar from "../../Shared/Scrollbar";


class Tab extends Component {

  constructor(props) {
    super(props)
    this.state = {
      error: null,
      search: "",
      types: [],
      searchResult: [],
      loading: false,
      loaded: false,
    }
  }

  componentDidMount() {
    fetchTemplateTypes().then((result) => {

        this.setState({loading: false, loaded: true, types: result || []})
      }
    )
  }

  handleSearch = e => {
    const v = e.target.value || ""

    const {searchComponents,} = this.props

    const needLoading = !!v

    if (needLoading) {
      this.setState({loading: needLoading, loaded: false, search: v})
      searchTemplates(v).then((result) => {
          this.setState({loading: false, loaded: true, searchResult: result || []})
        }
      )
    } else {
      this.setState({loading: false, loaded: false, search: v})
    }


  }

  render() {
    const {

      options,
      searchPlaceholder,
      selectCallback,
      libraries,
      sections,
      loadChildrenPaginated
    } = this.props

    const {search, searchResult, loading, loaded, types} = this.state

    const results = search ? searchResult : types


    return (
      <>
        {/* < defaultOpen={defaultOpen} openAll={!!search}>*/}
        <div className="editor-add-panel-search">
          <i className="material-icons">search</i>
          <input
            placeholder={searchPlaceholder}
            value={search}
            onChange={this.handleSearch}
            autoFocus
          />
        </div>
        <Scrollbar>

        {!loaded && !search &&<div className={'components-tree editor-add-panel-accordion'}><div className="components-tree-content"><Loading/></div></div>}

        {loaded && !search && < Tree nodes={results}
                                     loadChildren={loadChildrenPaginated}
                                     pageLimit={DEFAULT_PER_PAGE}
                                     paginated={true}
                                     selectCallback={selectCallback}
                                     useLocalState={true}
        />}

        {search && (<div className={'components-tree editor-add-panel-accordion'}>
          <div className="components-tree-content">

            {/* Animation for node expand / collapse */}

            {/* Loading: Overridable loading bar for pagination */}
            {loading && <Loading/>}
            {searchResult.length > 0 && (
              <div className="components-tree-children">


                {searchResult.map((childNode) => (
                  <div className="component-tree-add-item-wrapper">

                    <div

                      onClick={e =>
                        selectCallback(e, childNode)
                      }

                      key={childNode.id}
                      className={classNames(
                        'component-tree-add-item'
                      )}
                    >

                      

                      <ImagePlaceholder
                        src={`${childNode.thumb}`}
                        width='300'
                        height='300'
                        className={classNames('component-tree-add-icon', childNode.iconClassName)}
                      />

                      <div className="component-tree-add-name">
                        <div className={'component-name'}> {childNode.name}</div>
                        {childNode.size && <div className={'component-size'}>{childNode.size}</div>}
                      </div>
                    </div>
                  </div>
                ))}

              </div>
            )}

          </div>
        </div>)}

      </Scrollbar></>
    )
  }
}

const mapStateToProps = (state, {match}) => {

  return {}
}

export default connect(mapStateToProps, {
  requestLibraries,
  searchComponents
})(Tab)
