import {isFun, isNum, isUndef} from "./util";


export default class Emittr {
  _handlers;
  _maxHandlers;
  fired;
  emitter;
  event;
  wrappedHandler;
  handler;

  constructor(maxHandlers = 10) {
    this.setMaxHandlers(maxHandlers);
    this._handlers = Object.create(null);
  }

  static _callEventHandlers(emitter, handlers, args) {
    if (!handlers.length) {
      return;
    }
    if (handlers.length === 1) {
      Reflect.apply(handlers[0], emitter, args);
      return;
    }
    handlers = [...handlers];
    let idx;
    for (idx = 0; idx < handlers.length; idx++) {
      Reflect.apply(handlers[idx], emitter, args);
    }
  }

  static _addHandler = (
    emitter,
    name,
    handler,
    prepend = false
  ) => {
    if (!isFun(handler)) {
      throw new TypeError("Expected event handler to be a function, got " + typeof handler);
    }
    emitter._handlers[name] = emitter._handlers[name] || [];
    emitter.emit("addHandler", name, handler);
    prepend ? emitter._handlers[name].unshift(handler) : emitter._handlers[name].push(handler);
    return emitter;
  };

  static _onceWrapper = function _onceWrapper(...args) {
    // @ts-ignore
    if (!this.fired) {
      // @ts-ignore
      this.fired = true;
      // @ts-ignore
      this.emitter.off(this.event, this.wrappedHandler);
      // @ts-ignore
      Reflect.apply(this.handler, this.emitter, args);
    }
  };

  static _removeHandler = (emitter, name, handler) => {
    if (!isFun(handler)) {
      throw new TypeError("Expected event handler to be a function, got " + typeof handler);
    }
    if (isUndef(emitter._handlers[name]) || !emitter._handlers[name].length) {
      return emitter;
    }
    let idx = -1;
    if (emitter._handlers[name].length === 1) {
      if (emitter._handlers[name][0] === handler || (emitter._handlers[name][0]).handler === handler) {
        idx = 0;
        handler = (emitter._handlers[name][0] ).handler || emitter._handlers[name][0];
      }
    } else {
      for (idx = emitter._handlers[name].length - 1; idx >= 0; idx--) {
        if (
          emitter._handlers[name][idx] === handler ||
          (emitter._handlers[name][idx] ).handler === handler
        ) {
          handler = (emitter._handlers[name][idx] ).handler || emitter._handlers[name][idx];
          break;
        }
      }
    }
    if (idx === -1) {
      return emitter;
    }
    idx === 0 ? emitter._handlers[name].shift() : emitter._handlers[name].splice(idx, 1);
    emitter.emit("removeHandler", name, handler);
    return emitter;
  };

  setMaxHandlers(count) {
    if (!isNum(count) || count <= 0) {
      throw new TypeError(`Expected maxHandlers to be a positive number, got '${count}' of type ${typeof count}`);
    }
    this._maxHandlers = count;
    return this;
  }

  getMaxHandlers() {
    return this._maxHandlers;
  }

  emit(name, ...args) {
    if (typeof this._handlers[name] !== "object" || !Array.isArray(this._handlers[name])) {
      return false;
    }
    Emittr._callEventHandlers(this, this._handlers[name], args);
    return true;
  }

  on(name, handler) {
    Emittr._addHandler(this, name, handler);
    return this;
  }

  prependOn(name, handler) {
    Emittr._addHandler(this, name, handler, true);
    return this;
  }

  once(name, handler) {
    if (!isFun(handler)) {
      throw new TypeError("Expected event handler to be a function, got " + typeof handler);
    }
    Emittr._addHandler(this, name, this._wrapOnceHandler(name, handler));
    return this;
  }

  prependOnce(name, handler) {
    if (!isFun(handler)) {
      throw new TypeError("Expected event handler to be a function, got " + typeof handler);
    }
    Emittr._addHandler(this, name, this._wrapOnceHandler(name, handler), true);
    return this;
  }

  off(name, handler) {
    Emittr._removeHandler(this, name, handler);
    return this;
  }

  removeAllHandlers() {
    const handlers = this._handlers;
    this._handlers = Object.create(null);
    const removeHandlers = handlers["removeHandler"];
    delete handlers["removeHandler"];
    let idx, eventName;
    for (eventName in handlers) {
      for (idx = handlers[eventName].length - 1; idx >= 0; idx--) {
        Emittr._callEventHandlers(this, removeHandlers, [
          eventName,
          (handlers[eventName][idx]).handler || handlers[eventName][idx],
        ]);
      }
    }
    return this;
  }

  _wrapOnceHandler(name, handler) {
    const onceState = {
      fired: false,
      handler,
      wrappedHandler: undefined,
      emitter: this,
      event: name,
    };
    const wrappedHandler = Emittr._onceWrapper.bind(onceState);
    onceState.wrappedHandler = wrappedHandler;
    wrappedHandler.handler = handler;
    wrappedHandler.event = name;
    return wrappedHandler;
  }
}
