import React, { Component } from 'react'
import deepEquals from 'fast-deep-equal'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { hasChildren, isFullyFetched, shouldShowMore } from '../util'
import classNames from "classnames"
import Loading from '../../Loading'
import ImagePlaceholder from "../../ImagePlaceholder"
import { BASE_URL } from "../../../../utils/io"



class TreeNode extends Component {

  // Each TreeNode contains its own state, but props are the real
  // source of truth (unless usesLocalState === true) so we need
  // to ensure that important prop changes (selected, expanded, children)
  // are also reflected in the local state.

  static getDerivedStateFromProps({node, useLocalState, value}, state) {

    const { selected, expanded, children, value : old } = state
    if (value !== old ) {
      return { value: value }
    }

    return null

  }

  constructor(props) {
    super(props)

    const { node } = props
    const { expanded, selected, children, page } = node

    this.state = {
      expanderLoading: false,
      paginatorLoading: false,
      expanded: expanded || false,
      selected: selected || false,
      children: children || [],
      value: "",
      page: page || 0,
    }

  }

  // PERFORMANCE: only update the node when pertinent component
  // state changes. This synergizes with getDerivedStateFromProps
  shouldComponentUpdate(nextProps, nextState) {
    return !deepEquals(this.state, nextState)
  }

  // handler for paginating on a list of siblings. Determine if more siblings need to be
  // loaded and append them to the end of the list. This method is only called
  // if we are paginating
  loadMore = async (e, node) => {

    const { paginated, pageLimit, parse, loadChildren, } = this.props;

    const state = {...this.state};

    if ( !isFullyFetched(node, state.children.length) && paginated && pageLimit) {

      state.page += 1;

      const loadedChildren = await loadChildren(node, state.page, pageLimit);

      state.children = state.children.concat(
        parse ? parse(loadedChildren) : loadedChildren,
      );

    }

    this.setState(state);

  };

  onKeyLoadMore = async (e, node) => {
    if (e.key === 'Enter') {
      await this.loadMore(e, node);
    }
  };


  toggle = async (e, node) => {

    const {
      pageLimit,
      parse,
      loadChildren,
      toggleCallback,
      paginated,
    } = this.props;

    const state = {...this.state};
    state.children = state.children || []

    if (
      // nothing is loaded so we should load
      (state.children.length === 0 && hasChildren(node)) ||
      // we're not paginating and the children aren't fully loaded so let's load them
      // i.e. when you have nodes that have shared identities
      (!paginated && state.children?.length < node.counter)
    ) {
      //state.page += 1;
      const loadedChildren = await loadChildren(node, state.page, pageLimit);

      state.children = parse ? parse(loadedChildren) : loadedChildren;
    }
    state.expanded = !state.expanded;

    this.setState(state);

    if (toggleCallback) {
      toggleCallback(e, node, state);
    }

  };


  onKeyToggle = async (e, node) => {
    if (e.key === 'Enter') {
      await this.toggle(e, node);
    }
  };

  // handler for selecting a node.
  // fires selectCallback() prop with event and node
  select = (e, node) => {

    const {selectCallback} = this.props
    const state = {...this.state}
    state.selected = !state.selected
    node.selected = !state.selected

    this.setState(state)

    if (selectCallback) {
      selectCallback(e, node, state);
    }
    
  };

  onKeySelect = (e, node) => {
    if (e.key === 'Enter') {
      this.select(e, node);
    }
  };

  handleToggle = async (
    e,
    node,
    callable,
    disabled,
  ) => {
    e.stopPropagation();
    if (!disabled) {
      const {expanded, children} = this.state;
      if (!expanded && ((children && children.length === 0) || !children)) {
        this.setState({expanderLoading: true});
        await callable(e, node);
        this.setState({expanderLoading: false});
      } else {
        await callable(e, node);
      }
    }
  };

  // pagination "load more" handler to set paginator loading states and
  // prevent multiple "load more" actions from being triggered simultaneously.
  handleLoadMore = async (
    e,
    node,
    callable,
    disabled,
  ) => {
    if (!disabled) {
      this.setState({paginatorLoading: true});
      await callable(e, node);
      this.setState({paginatorLoading: false});
    }
  };

  // render children if they exist and the node is expanded
  // ensure that depth is incremented for hierarchical indentation
  renderChildren() {

    const { node, value } = this.props

    const { expanded, children } = this.state
  
    if (!expanded || !hasChildren(node)) {
      return []
    }
  
    return children.map((childNode, index) => (

      <div key={`${childNode.id}-${index}`} className="component-tree-add-item-wrapper" onClick={e => this.select(e, childNode)}>

        <ImagePlaceholder
          src={`${BASE_URL + '/media' + childNode.thumb}`}
          width='300'
          height='300'
          className={classNames('component-tree-add-icon', childNode.iconClassName)}
        />

        <div className="component-tree-add-name 1">

          <div className="component-name">
            {childNode.name}
          </div>

          {childNode.size && <div className="component-size">
            {childNode.size}
          </div>}

        </div>

        {value === childNode.id && (
          <div className="template-select-option-selected-badge">
            <span className="icon icon-done"></span>
          </div>
        )}

      </div>

    ))
  }


  render() {

    const {
      node,
      paginated
    } = this.props;


    const {
      expanderLoading,
      paginatorLoading,
      expanded
    } = this.state;

    const children = this.renderChildren();

    return (
      <React.Fragment>

        {/* ListItem: Overridable container component */}
        <div className={classNames("components-tree editor-add-panel-accordion", { "components-tree-expanded": expanded}) }>

          <div className="components-tree-title" onClick={ e => this.handleToggle(e, node, this.toggle, expanderLoading) }>
            <span className={classNames('icon', { "icon-expand-vertical": expanded, "icon-expand": !expanded })}/>
            <div className="components-tree-title-inner">{node.name || node.label}</div>
            <div className="components-count">{node.counter || ""}</div>
          </div>

          <div className="components-tree-content">
            <TransitionGroup>

              {expanderLoading && (
                <CSSTransition key="loading" timeout={200} classNames="slide">
                  <Loading />
                </CSSTransition>
              )}

              {children.length > 0 && (
                <CSSTransition key="children" timeout={200} classNames="slide">
                  <div className="components-tree-children">

                    {children}

                    {paginatorLoading && <Loading />}

                  </div>
                </CSSTransition>
              )}


              {children.length > 0 && !paginatorLoading && paginated && shouldShowMore(node, children.length) && (
                <CSSTransition key="paginator" timeout={200} classNames="slide">
                  <div
                    className="components-tree-paginator"
                    onClick={e => this.handleLoadMore(e, node, this.loadMore, paginatorLoading)}
                    tabIndex={0}
                    role="button"
                    aria-label="Показать еще"
                  >
                    <span>Показать еще</span>
                  </div>
                </CSSTransition>
              )}

            </TransitionGroup>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TreeNode;