import React from 'react'
import BaseShape from '../BaseShape'
import { PathItem, Point as PathPoint } from "utils/vector";


export default class Shape extends BaseShape {

  getPathData() {
    const {width, height, points, compound, isClosed, zoom, x, y} = this.props

    let {angle, rotateWidth, rotateHeight, parent} = this.props

    let  rotateX = parent && parent.x || x
    let rotateY = parent && parent.y || y

    rotateWidth = rotateWidth || width
    rotateHeight = rotateHeight|| height

    this.path = PathItem.create()

    if (compound && compound.length > 0) {
      this.path = PathItem.create(compound)
    } else if (points && points.length > 0) {
      this.path = PathItem.create(points)
    }

    this.path.setClosed(isClosed)
    this.path.scale(zoom.scale)
    let unit = window.devicePixelRatio === 2 ? 2 : 1
    this.path.adjust(x*zoom.scale, y*zoom.scale)
    //this.path.getRotatePoint(rotateX, rotateY)



    //this.path.rotate(angle || 0, new PathPoint((rotateX/unit + rotateWidth/2) , (rotateY/unit  + rotateHeight/2)))
    return this.path.getPathData()

  }

  doubleClickHandler() {
    if (this.isSelected()) {
      return this.props.onEdit()
    }

    return super.doubleClickHandler()
  }

  renderPath() {
    return (
      <path
        {...this.getBorderProps()}
        fill={this.getBackgroundColor()}
        d={this.getPathData()}
      />
    )
  }

  render() {
    let {
      xScaled,
      yScaled,
      points,
      zoom,
      hideShadows,
      shadow,

      x,
      y,
      width,
      height,

      ...styles
    } = this.props

    return (
      <g
        className="shape"
        onMouseDown={this.handleMouseDown}
        onTouchStart={this.handleMouseDown}
        onDoubleClick={this.handleDoubleClick}
        style={styles}
        /*transform={`translate(${xScaled} ${yScaled})`}*/
      >

        {this.renderPath()}
      </g>
    )
  }
}
