import React, {Component} from 'react'

export default class Group extends Component {
  render() {
    let { children, object, transform } = this.props

    let { opacity } = object

    let styles = {
      opacity,
    }

    return (
      <g className="group" style={styles} transform={transform}>
        {children}
      </g>
    )
  }
}
