import {COMPONENT} from 'common/constants'

import {deepMap, getBoundingBox, getObject, getObjectsInRect, optimize, subPath,} from 'common/utils'

export const HANDLER_LINK = 10
export const HANDLER_INDEPENDENT = 11

const identity = obj => obj

export const createRect = (point1, point2, shouldRound = false) => {
  let round = shouldRound ? Math.round : identity

  return {
    x: round(Math.min(point1[0], point2[0])),
    y: round(Math.min(point1[1], point2[1])),
    width: round(Math.abs(point2[0] - point1[0])),
    height: round(Math.abs(point2[1] - point1[1])),
  }
}

// Returns ID of best parent for OBJ
// If possible, returns top-most fully-containing parent
// Otherwise, uses center-offset distance as tie-break
export const getBestParent = (obj, parents) => {

  if (obj.type === COMPONENT) {
    return null
  }

  let matches = getObjectsInRect(parents, obj)

  let matchObjects = parents.filter(({ id }) => {
    return matches.indexOf(id) !== -1
  })

  let fullyContainedId = null

  let bestDist = Infinity
  let bestId = null

  for (let i = 0; i < matchObjects.length; i += 1) {
    let matchObj = matchObjects[i]
    let intersection = getIntersection(obj, matchObj)

    // Determine whether fully-contained
    if (getArea(intersection) === getArea(obj)) {
      fullyContainedId = matchObj.id
    }

    // Otherwise, calculate offset dist
    let dist = getCenterOffset(matchObj, obj)

    if (dist < bestDist) {
      bestId = matchObj.id
      bestDist = dist
    }
  }

  return fullyContainedId || bestId
}

export const checkIntersection = (obj, parents) => {

  if (obj.type === COMPONENT) {
    return null
  }

  let matches = getObjectsInRect(parents, obj)

  let matchObjects = parents.filter(({ id }) => {
    return matches.indexOf(id) !== -1
  })

  let fullyContainedId = null

  let bestDist = Infinity
  let bestId = null

  for (let i = 0; i < matchObjects.length; i += 1) {
    let matchObj = matchObjects[i]
    let intersection = getIntersection(obj, matchObj)

    // Determine whether fully-contained
    if (getArea(intersection) === getArea(obj)) {
      fullyContainedId = matchObj.id
    }

    // Otherwise, calculate offset dist
    let dist = getCenterOffset(matchObj, obj)

    if (dist < bestDist) {
      bestId = matchObj.id
      bestDist = dist
    }
  }

  return fullyContainedId || bestId
}

export const getArea = obj => {
  return obj.width * obj.height
}

export const getCenterOffset = (obj1, obj2) => {
  let c1 = [obj1.x + obj1.width / 2, obj1.y + obj1.height / 2]
  let c2 = [obj2.x + obj2.width / 2, obj2.y + obj2.height / 2]

  return getDist(c1, c2)
}

export const getDist = (point1, point2) => {
  
  if (!point1 || !point2) {
    return NaN
  }

  let [x1, y1] = point1
  let [x2, y2] = point2

  return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2)
}

// Returns box (x, y, width, height) of intersection
export const getIntersection = (obj1, obj2) => {
  if (getObjectsInRect([obj1], obj2).length === 0) {
    return null
  }

  let x1 = Math.max(obj1.x, obj2.x)
  let y1 = Math.max(obj1.y, obj2.y)
  let x2 = Math.min(obj1.x + obj1.width, obj2.x + obj2.width)
  let y2 = Math.min(obj1.y + obj1.height, obj2.y + obj2.height)

  return {
    x: x1,
    y: y1,
    width: x2 - x1,
    height: y2 - y1,
  }
}

export const getSelectionFromRect = (objects, rect) => {
  let children = []

  if (!rect) {
    return []
  }

  objects.forEach(obj => {
    children = children.concat(
      (obj.children || []).map(child => ({
        ...child,
        x: child.x + obj.x,
        y: child.y + obj.y,
      }))
    )
  })

  return getObjectsInRect(children, rect)
}

export const getInsertPosition = list => {
  let minY = Infinity
  let maxX = -Infinity

  if (list.length === 0) {
    return { x: 0, y: 0 }
  }

  list.forEach(screen => {
    if (screen.x + screen.width > maxX) {
      maxX = screen.x + screen.width
    }

    if (screen.y < minY) {
      minY = screen.y
    }
  })

  return {
    x: maxX + 100,
    y: minY,
  }
}
export const getRelativeDepth = list => {
  let minY = Infinity
  let maxX = -Infinity

  if (list.length === 0) {
    return { x: 0, y: 0 }
  }

  list.forEach(screen => {

    if (screen.depth + screen.width > maxX) {
      maxX = screen.x + screen.width
    }

    if (screen.y < minY) {
      minY = screen.y
    }
  })

  return {
    x: maxX + 100,
    y: minY,
  }
}



export const getAbsoluteBboxV2 = (object, list, map) => {

  const subPath = (path, length) => {
    return (path || '').split('.').slice(0, length).join('.');
  }

  const getObject = (objects, path) => {
    if (!objects || !path || path.length === 0) {
        return null;
    }

    if (typeof path === 'string') {
        path = path.split('.');
    }

    if (path.length === 1) {
        return objects[path[0]];
    }

    const newObject = objects[path[0]];
    return newObject && getObject(newObject.children, path.slice(1)) || null;
  }

  let path = map?.[object?.id]

  if (!path) {
    return object
  }

  let componentPath = subPath(path, 1)

  if (componentPath === path) {
    return object
  }

  let component = getObject(list, componentPath)

  return {
    id: object.id,
    x: object.x + component.x,
    y: object.y + component.y,
    width: object.width,
    height: object.height,
  }
}

export const getAbsoluteBbox = (object, list, map) => {

  let path = map?.[object?.id]

  if (!path) {
    return object
  }

  let componentPath = subPath(path, 1)

  if (componentPath === path) {
    return object
  }

  let component = getObject(list, componentPath)

  return {
    id: object.id,
    x: object.x + component.x,
    y: object.y + component.y,
    width: object.width,
    height: object.height,
  }
}




export const translate = (bbox, offsetX, offsetY, reverse = false) => {
  if (reverse) {
    offsetX = -offsetX
    offsetY = -offsetY
  }

  return {
    ...bbox,
    x: bbox.x + offsetX,
    y: bbox.y + offsetY,
  }
}

export const toRelativeCoords = (objects, parentId, screenBbox, list, map) => {
  let bbox = getBoundingBox(objects)

  let offsetX = 0
  let offsetY = 0

  let parentObj = getObject(list, map[parentId])
  parentObj = { ...parentObj, x: 0, y: 0 }

  if (getIntersection(bbox, parentObj)) {
    // Do nothing
  } else {
    let parentBbox = getIntersection(parentObj, screenBbox)
    parentBbox = parentBbox || parentObj

    let parentOffsetX = parentBbox.x - parentObj.x
    let parentOffsetY = parentBbox.y - parentObj.y

    offsetX =
      bbox.x -
      Math.max(
        0,
        Math.floor((parentBbox.width - bbox.width) / 2 + parentOffsetX)
      )

    offsetY =
      bbox.y -
      Math.max(
        0,
        Math.floor((parentBbox.height - bbox.height) / 2 + parentOffsetY)
      )
  }

  let result = deepMap(objects, obj => ({
    ...obj,
    x: obj.x - offsetX,
    y: obj.y - offsetY,
  }))

  return result
}

export const getPasteParent = (object, screenBbox, list, map) => {
  if (list.length === 0) {
    return null
  }

  let naturalParentId = getBestParent(object, list)
  let naturalParent = naturalParentId && getObject(list, map[naturalParentId])

  if (naturalParent && isFullyContained(object, screenBbox)) {
    return naturalParentId
  }

  // Otherwise return the center-most parent

  let bestParent = optimize(list, component => {
    return -getCenterOffset(component, screenBbox)
  })

  return bestParent.id
}

export const isFullyContained = (childObj, parentObj) => {
  return (
    parentObj.x <= childObj.x &&
    parentObj.y <= childObj.y &&
    parentObj.x + parentObj.width >= childObj.x + childObj.width &&
    parentObj.y + parentObj.height >= childObj.y + childObj.height
  )
}

const ALIGN_CONFIG = {
  left: ['x'],
  right: ['x', 'width'],
  top: ['y'],
  bottom: ['y', 'height'],
  'vertical-center': ['y', 'height_half'],
  'horizontal-center': ['x', 'width_half'],
}


export const alignToRect = (object, absoluteObject, rect, direction) => {
  let config = ALIGN_CONFIG[direction]

  if (!config) {
    return object
  }

  let diff = 0

  config.forEach(itm => {
    let key = itm.split('_')[0]
    let result = absoluteObject[key] - rect[key]

    if (itm.match(/_half$/)) {
      result = Math.round(result / 2)
    }

    diff += result
  })

  return {
    ...object,
    [config[0]]: object[config[0]] - diff,
  }
}


export const scalePoint = (object,  rect) => {
  let config = ALIGN_CONFIG[direction]

  if (!config) {
    return object
  }

  let diff = 0

  config.forEach(itm => {
    let key = itm.split('_')[0]
    let result = absoluteObject[key] - rect[key]

    if (itm.match(/_half$/)) {
      result = Math.round(result / 2)
    }

    diff += result
  })

  return {
    ...object,
    [config[0]]: object[config[0]] - diff,
  }
}
