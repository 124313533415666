import React from 'react'
import BaseShape from './BaseShape'

import {PathItem, Point as PathPoint, Segment} from "../../../utils/vector";

export default class Ellipse extends BaseShape {

  getPathData() {
    this.dimension = []

    const {width, height, points, compound, isClosed, zoom, x, y, angle} = this.props

    this.path = PathItem.create()

    if (compound && compound.length > 0) {
      this.path = PathItem.create(compound)
    } else if (points && points.length > 0) {
      this.path = PathItem.create(points)
    } else {
      const kappa = 4 * (Math.sqrt(2) - 1) / 3,
        ellipseSegments = [
          new Segment([-1, 0], [0, kappa], [0, -kappa]),
          new Segment([0, -1], [-kappa, 0], [kappa, 0]),
          new Segment([1, 0], [0, -kappa], [0, kappa]),
          new Segment([0, 1], [kappa, 0], [-kappa, 0])
        ];

      const center = new PathPoint(x, y)
      const radius = new PathPoint(width / 2, height / 2)

      var segments = new Array(4);
      for (var i = 0; i < 4; i++) {
        var segment = ellipseSegments[i];
        segments[i] = new Segment(
          segment._point.multiply(radius).add(center),
          segment._handleIn.multiply(radius),
          segment._handleOut.multiply(radius)
        );
      }

      this.path = PathItem.create(segments)


    }
    this.path.setClosed(true)
    this.path.scale(zoom.scale)
    this.path.adjust(x * zoom.scale, y* zoom.scale)
   // this.path.adjust(x * zoom.scale, y* zoom.scale)
    //this.path.rotate(angle || 0);

    /*const position = this.path.getPosition()
    this.dimension = [
      [position.x,
        position.y,
        position.x + bbox.width / 2,
        position.y,

        bbox.width / 2],
      [position.x, position.y, position.x, position.y + bbox.height / 2, bbox.height / 2]
    ]*/
   
    return this.path.getPathData()
  }

  doubleClickHandler() {

    if (this.isSelected()) {
      return this.props.onEdit()
    }

    return super.doubleClickHandler()
  }

  renderDimesion() { }

  renderPath() {
    return (
      <path
        {...this.getBorderProps()}
        fill={this.getBackgroundColor()}
        d={this.getPathData()}
      />
    )
  }

  render() {
    let {
      xScaled,
      yScaled,
      points,
      zoom,
      hideShadows,
      shadow,

      x,
      y,
      width,
      height,

      ...styles
    } = this.props


    return (
      <g className="shape">
      <g
        className="shape"
        onMouseDown={this.handleMouseDown}
        onTouchStart={this.handleMouseDown}
        onDoubleClick={this.handleDoubleClick}
        style={styles}
        /*transform={`translate(${xScaled} ${yScaled})`}*/
      >

        {this.renderPath()}

      </g>
    {this.dimension && (<g className="shape-editor-size">
      {this.dimension.map((pt, i) => (

        <g>
          <line className="shape-editor-line-angle" x1={pt[0]} y1={pt[1]} x2={pt[2]} y2={pt[3]}/>
        </g>
      ))}
    </g>)}</g>
    )
  }
}
