import React, {Component} from 'react'
import {Link, withRouter} from 'react-router-dom'
import DocumentEvents from 'react-document-events'

import {ESC} from '../../../utils/keyboard'
import Icon from '../../Shared/Icon'

class CloseButton extends Component {
  handleKeyDown = e => {
    let { history } = this.props

    if (e.which === ESC) {
      history.push(this.getBackURL())
    }
  }

  getBackURL = () => {
    let { appId } = this.props

    return `/apps/${appId}/screens`
  }

  render() {
    let backURL = this.getBackURL()

    return (
      <Link to={backURL} className="editor-preview-close">
        <DocumentEvents onKeyDown={this.handleKeyDown} />
        <Icon type="close" />
      </Link>
    )
  }
}

export default withRouter(CloseButton)
