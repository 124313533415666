import React, {Component} from 'react'
import classNames from 'classnames'

import './EmptyState.scss'

export default class EmptyState extends Component {
  render() {
    let { children, className, greedy, bordered } = this.props

    return (
      <div
        className={classNames('empty-state', className, { greedy, bordered })}
      >
        {children}
      </div>
    )
  }
}
