

const SET_LOADING = Symbol('SCREEN_TEMPLATES_SET_LOADING')
const SET_CATEGORIES_LOADING = Symbol('SCREEN_TEMPLATES_CATEGORIES_SET_LOADING')
const LOAD_LIST = Symbol('SCREEN_TEMPLATES_LOAD_LIST')
const LOAD_CATEGORIES_LIST = Symbol('SCREEN_TEMPLATES_LOAD_CATEGORIES_LIST')
const CREATED = Symbol('SCREEN_TEMPLATE_CREATED')
const UPDATED = Symbol('SCREEN_TEMPLATE_UPDATED')
const ADD_LOCAL_CATEGORY = Symbol('SCREEN_TEMPLATES_ADD_STATIC_CATEGORY')

const LIST_INITIAL_STATE = {
  list: [],
  localList: [],
  loading: false,
  loaded: false,
}

const staticTemplatesByPlatform = {
  web: [
    {
      name: 'Blank Screen',
      iconClassName: 'editor-add-component-icon-blank-web-screen',
      id: 'blank',
      categories: ['Simple'],
    },
  ],
  mobile: [
    {
      name: 'Blank Screen',
      iconClassName: 'editor-add-component-icon-blank-screen',
      id: 'blank',
      categories: ['Simple'],
    },
  ],
}

const INITIAL_STATE = {
  templatesByPlatform: {
    web: {
      ...LIST_INITIAL_STATE,
      list: staticTemplatesByPlatform.web,
    },
    mobile: {
      ...LIST_INITIAL_STATE,
      list: staticTemplatesByPlatform.mobile,
    },
  },
  categoriesByPlatform: {
    web: {
      ...LIST_INITIAL_STATE,
      localList: ['Simple', 'Navigation', 'List', 'Forms'],
    },
    mobile: {
      ...LIST_INITIAL_STATE,
      localList: ['Simple', 'Navigation', 'List', 'Forms'],
    },
  },
}

// REDUCER
function immutableUpsert(array, getKey, element) {
  if (!array || array.length === 0) {
    return [element]
  }

  const elementKey = getKey(element)

  const index = array.findIndex(otherEl => getKey(otherEl) === elementKey)

  if (index < 0) {
    return [...array, element]
  }

  const newArray = array.slice()

  newArray[index] = {
    ...array[index],
    ...element,
  }

  return newArray
}

function immutableUniquePush(array, ...elements) {
  if (!array) {
    return elements
  }

  return elements.reduce((newArray, element) => {
    if (newArray.indexOf(element) < 0) {
      if (newArray === array) {
        newArray = array.slice()
      }

      newArray.push(element)
    }

    return newArray
  }, array)
}

function immutableRemoveKey(array, getKey, keyToRemove) {
  if (!array || array.length === 0) {
    return array
  }

  const index = array.findIndex(el => getKey(el) === keyToRemove)

  if (index < 0) {
    return array
  }

  const newArray = array.slice()
  newArray.splice(index, 1)

  return newArray
}

function extractTemplateKey(template) {
  return template.id
}

export default function screenTemplatesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_LOADING: {
      const { platform, loading, loaded } = action.payload

      return {
        ...state,
        templatesByPlatform: {
          ...state.templatesByPlatform,
          [platform]: {
            ...LIST_INITIAL_STATE,
            ...state.templatesByPlatform[platform],
            loading,
            loaded,
          },
        },
      }
    }
    case LOAD_LIST: {
      let { platform, list } = action.payload
      const staticList = staticTemplatesByPlatform[platform]

      if (staticList) {
        list = [...staticList, ...list]
      }

      return {
        ...state,
        templatesByPlatform: {
          ...state.templatesByPlatform,
          [platform]: {
            ...LIST_INITIAL_STATE,
            ...state.templatesByPlatform[platform],
            list,
            loading: false,
            loaded: true,
          },
        },
      }
    }
    case CREATED: {
      let { platform, template } = action.payload

      const templateState = {
        ...LIST_INITIAL_STATE,
        ...state.templatesByPlatform[platform],
      }

      if (template.enabled && templateState.loaded) {
        return {
          ...state,
          templatesByPlatform: {
            ...state.templatesByPlatform,
            [platform]: {
              ...templateState,
              list: immutableUpsert(
                templateState.list,
                extractTemplateKey,
                template
              ),
            },
          },
        }
      }

      return state
    }
    case UPDATED: {
      let { platform, template } = action.payload

      const templateState = {
        ...LIST_INITIAL_STATE,
        ...state.templatesByPlatform[platform],
      }

      if (!templateState.loaded) {
        return state
      }

      return {
        ...state,
        templatesByPlatform: {
          ...state.templatesByPlatform,
          [platform]: {
            ...templateState,
            list: template.enabled
              ? immutableUpsert(
                  templateState.list,
                  extractTemplateKey,
                  template
                )
              : immutableRemoveKey(
                  templateState.list,
                  extractTemplateKey,
                  extractTemplateKey(template)
                ),
          },
        },
      }
    }
    case SET_CATEGORIES_LOADING: {
      const { platform, loading, loaded } = action.payload

      return {
        ...state,
        categoriesByPlatform: {
          ...state.categoriesByPlatform,
          [platform]: {
            ...LIST_INITIAL_STATE,
            ...state.categoriesByPlatform[platform],
            loading,
            loaded,
          },
        },
      }
    }
    case LOAD_CATEGORIES_LIST: {
      const { platform, list } = action.payload

      const categoryState = {
        ...LIST_INITIAL_STATE,
        ...state.categoriesByPlatform[platform],
        list,
      }

      return {
        ...state,
        categoriesByPlatform: {
          ...state.categoriesByPlatform,
          [platform]: {
            ...categoryState,
            list: immutableUniquePush(
              categoryState.list,
              ...categoryState.localList
            ),
            loading: false,
            loaded: true,
          },
        },
      }
    }
    case ADD_LOCAL_CATEGORY: {
      const { platform, category } = action.payload

      const categoryState = {
        ...LIST_INITIAL_STATE,
        ...state.categoriesByPlatform[platform],
      }

      const localList = immutableUniquePush(categoryState.localList, category)

      return {
        ...state,
        categoriesByPlatform: {
          ...state.categoriesByPlatform,
          [platform]: {
            ...categoryState,
            localList,
            list: immutableUniquePush(categoryState.list, ...localList),
          },
        },
      }
    }

    default:
  }

  return state
}

// ACTIONS

export const loadScreenTemplates = (platform, list) => ({
  type: LOAD_LIST,
  payload: { platform, list },
})

export const loadScreenTemplateCategories = (platform, list) => ({
  type: LOAD_CATEGORIES_LIST,
  payload: { platform, list },
})

export const screenTemplateCreated = (platform, template) => ({
  type: CREATED,
  payload: { platform, template },
})

export const screenTemplateUpdated = (platform, template) => ({
  type: UPDATED,
  payload: { platform, template },
})

export const addNewScreenTemplateCategory = (platform, category) => ({
  type: ADD_LOCAL_CATEGORY,
  payload: { platform, category },
})

// SELECTORS

export const getScreenTemplatesState = (state, platform) => {
  return (
    state.editor.screenTemplates.templatesByPlatform[platform] ||
    LIST_INITIAL_STATE
  )
}

export const getScreenTemplatesCategoriesState = (state, platform) => {
  return (
    state.editor.screenTemplates.categoriesByPlatform[platform] ||
    LIST_INITIAL_STATE
  )
}

const compareCategories = (a, b) => {
  if (a.title === 'Simple') {
    return -1
  }

  if (b.title === 'Simple') {
    return 1
  }

  if (a.title > b.title) {
    return 1
  }

  if (a.title < b.title) {
    return -1
  }

  // a must be equal to b
  return 0
}

function groupTemplatesIntoCategories(templates) {
  const categoriesMap = new Map()

  for (const template of templates) {
    for (const categoryName of template.categories || ['Simple']) {
      let category = categoriesMap.get(categoryName)

      if (!category) {
        category = {
          title: categoryName,
          options: [template],
        }

        categoriesMap.set(categoryName, category)
      } else {
        category.options.push(template)
      }
    }
  }

  const categories = Array.from(categoriesMap.values())
  categories.sort(compareCategories)

  return categories
}

export const getCategorizedScreenTemplates = (state, platform) => {
  const templatesState = getScreenTemplatesState(state, platform)

  return groupTemplatesIntoCategories(templatesState.list)
}

export const getScreenTemplatesCategories = (state, platform) => {
  const categoriesState = getScreenTemplatesCategoriesState(state, platform)

  return categoriesState.list
}

// THUNKS

const makeEnsureLoadedThunk = options => {
  const { selector, setLoading, request } = options

  return (...args) => (dispatch, getState) => {
    const state = selector(getState(), ...args)

    if (state.loading || state.loaded) {
      return
    }

    dispatch(setLoading(...args))
    request(...args)
  }
}

export const ensureScreenTemplatesAreLoaded = makeEnsureLoadedThunk({
  selector: getScreenTemplatesState,
  setLoading: platform => ({
    type: SET_LOADING,
    payload: { platform, loading: true, loaded: false },
  }),
  //request: platform => requestScreenTemplates(platform),
})

export const ensureScreenTemplateCategoriesAreLoaded = makeEnsureLoadedThunk({
  selector: getScreenTemplatesCategoriesState,
  setLoading: platform => ({
    type: SET_CATEGORIES_LOADING,
    payload: { platform, loading: true, loaded: false },
  }),
  //request: platform => requestScreenTemplateCategories(platform),
})
