import shortUuid from 'short-uuid';
import {v4} from 'uuid'

const BASE_36_ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyz';

export const  leftPad = (str, length, character) => {
  for (let i = str.length; i < length; i += 1) {
    str = '' + character + str;
  }

  return str;
};

export const getId = () => {
  const translater = shortUuid(BASE_36_ALPHABET);
  const id = translater.fromUUID(v4());
  return leftPad(id, 26, '0');
};

export const getTableId = () => {
  return 't_' + getId();
};

export const  getColumnId = () => {
  return 'c_' + getId();
};
