import React, {Component} from 'react'
import {pxToUnit} from "utils/unit";

export default class Rule extends Component {
  static defaultProps = {
    x: 0,
    y: 0,
    height: 0,
    width: 0,
  }

  render() {
    let {
      width,
      height,
      depth,
      widthScaled,
      heightScaled,

      position,

    } = this.props

    const x = (widthScaled / 2) - 5
    const y = (heightScaled / 2) - 5
    const pad = 12

    return (


      <g className={'rule-sizes'}>
        {/*<line className={'depth-line'} x1={x-2}  y1={y} x2={x-2} y2={y+10} />*/}
        {depth  && <text  x={x-5} y={y +5 } textAnchor="center">{pxToUnit(depth)}mm</text>}
        {/*<text  x={-pad * 2}
               y={heightScaled/2}
               transform={`rotate(-90, ${-pad * 2}, ${heightScaled / 2})`}
               dominantBaseline="middle"
               textAnchor="middle">{height}px</text>*/}

   {/*     <line id="rule_left"  x1={-pad} x2={-pad} y1={0} y2={height}/>*/}

        <text  x={widthScaled / 2}
               y={heightScaled + pad }
               dominantBaseline="middle"
               textAnchor="middle">{pxToUnit(width)} x {pxToUnit(height)} mm</text>

        {/*<line id="rule_top"  x1={0} x2={width} y1={-pad} y2={-pad}/>*/}
        {/*<line id="rule_right" stroke={'#ff0000'} x1={width + pad} x2={width + pad} y1={0} y2={height}/>
        <line id="rule_bottom" stroke={'#ff0000'} x1={0} x2={width} y1={height + pad} y2={height + pad}/>*/}
      </g>
    )
  }
}
