import axios from 'axios'

import qs from 'qs'

import {identifyCurrentUser} from 'utils/fullstory'

import {apiURL} from 'utils/io'

const SET_CURRENT_USER = Symbol('SET_CURRENT_USER')
const SET_CURRENT_USER_TOKEN = Symbol('SET_CURRENT_USER_TOKEN')
const SET_AUTH_VISIBLE = Symbol('SET_AUTH_VISIBLE')

const GET_ME = 'GET_ME'

const LOGOUT = Symbol('LOGOUT')
const LOGIN = 'LOGIN'

const REQUEST_USERS = 'REQUEST_USERS'
/*const VERIFY_EMAIL = 'VERIFY_EMAIL'
const RECOVERY_PASSWORD = 'RECOVERY_PASSWORD'*/

const INITIAL_STATE = {
  users: [],
  currentUser: null,
  currentUserToken: null,
  authVisible: true,
}

// Reducer

export default (state = INITIAL_STATE, action) => {
  if (action.type === SET_CURRENT_USER) {
    let {userObject} = action
    window.adaloDeveloper = userObject.developer

    // Setup analytics tools


    identifyCurrentUser(userObject)

    return {
      ...state,
      currentUser: userObject,
      authVisible: false,
    }
  }

  if (action.type === SET_CURRENT_USER_TOKEN) {


    let {token} = action
    if (token) {

      window.localStorage.setItem('protonSession', token)
      axios.defaults.headers['Authorization'] = `BEARER ${token}`
      return {
        ...state,
        currentUserToken: token,
        authVisible: false,
      }
    }

  }

  if (action.type === LOGOUT) {

    window.localStorage.removeItem('protonSession')
    delete axios.defaults.headers['Authorization']

    return {
      ...state,
      currentUser: null,
      currentUserToken: null,
      authVisible: true,
    }
  }

  if (action.type === `${LOGIN}_FULFILLED`) {

    let {payload: {data, headers: {token}}} = action


    // Setup analytics tools

    identifyCurrentUser(data)

    window.localStorage.setItem('protonSession', token)
    axios.defaults.headers['Authorization'] = `BEARER ${token}`

    return {
      ...state,
      currentUser: data,
      currentUserToken: token,
      authVisible: false,
    }


  }

  if (action.type === `${GET_ME}_FULFILLED`) {


    let {payload: {data}} = action

    identifyCurrentUser(data)

    return {
      ...state,
      currentUser: data,
      authVisible: false,
    }

  }

  if (action.type === `${GET_ME}_REJECTED`) {


    window.localStorage.removeItem('protonSession')
    delete axios.defaults.headers['Authorization']

    return {
      ...state,
      currentUser: null,
      currentUserToken: null,
      authVisible: true,
    }
  }

  if (action.type === SET_AUTH_VISIBLE) {

    return {
      ...state,
      authVisible: true,
    }
  }

  if (action.type === `${REQUEST_USERS}_FULFILLED`) {
    return {
      ...state,
      users: action.payload.data,
    }
  }

  return state
}

// Actions

export const setCurrentUser = userObject => ({
  type: SET_CURRENT_USER,
  userObject,
})

export const setCurrentUserToken = token => ({
  type: SET_CURRENT_USER_TOKEN,
  token,
})

/*export const verifyEmail = (data) => {
  return {
    type: VERIFY_EMAIL,
    payload: axios.post(`${apiURL}/users/email/verify`, data, {headers: {'X-Requested-With': 'XMLHttpRequest'}}),
  }
}*/

export const setAuthVisible = () => ({
  type: SET_AUTH_VISIBLE,
})

export const requestUsers = opts => ({
  type: REQUEST_USERS,
  payload: axios.get(`${apiURL}/users/all?${qs.stringify(opts)}`),
})

export const login = data => {

  return {
    type: LOGIN,
    payload: axios.post(`${apiURL}/users/login`, data),
  }
}

export const logout = data => {
  return {
    type: LOGOUT
  }
}


export const getMe = token => {
  return {
    type: GET_ME,
    payload: axios.get(`${apiURL}/users/me`),
  }
}
// Selectors

export const getCurrentUser = state => {
  return state.users.currentUser
}

export const isAdmin = state => {
  return state.users.currentUser?.roles.includes("system_admin")
}

export const export_in_dxf = state => {
  return state.users.currentUser?.export_in_dxf
}

export const getCurrentUserToken = state => {
  return state.users.currentUserToken
}

export const getAuthVisible = state => {
  return state.users.authVisible
}

export const getUsers = state => {
  return state.users.users
}

