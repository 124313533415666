import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { notify } from 'ducks/notifications';
import { withRouter } from 'react-router-dom';

import { setOuterRule, setTemplateSetting } from 'components/Admin/template/store/templateActions';

class MovePointDown extends Component {

    setActivePoint = (pointIndex) => {
        this.props.setTemplateSetting({ activePointIndex: pointIndex });
    };

    onMovePointDown = (pointIndex) => {
        const { outer_rule, setting, setOuterRule } = this.props;
        const activeRuleIndex = setting.activeRuleIndex;
        const points = [...outer_rule[activeRuleIndex].points];

        if (pointIndex < 0 || pointIndex >= points.length - 1) {
            return;
        }

        [points[pointIndex], points[pointIndex + 1]] = [points[pointIndex + 1], points[pointIndex]];

        const updatedOuterRule = outer_rule.map((rule, index) => {
            if (index === activeRuleIndex) {
                return { ...rule, points: points };
            }
            return rule;
        });

        setOuterRule(updatedOuterRule);

        // Update active point
        if (this.props.setting.activePointIndex === pointIndex) {
            this.setActivePoint(pointIndex + 1);
        } else if (this.props.setting.activePointIndex === pointIndex + 1) {
            this.setActivePoint(pointIndex);
        }
    };

    render() {
        const { pointIndex } = this.props;

        return (
            <button
                type="button"
                onClick={() => this.onMovePointDown(pointIndex)}
                className="px-2 py-1 text-xs text-white bg-blue-600 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
                ↓
            </button>
        );
    }
}

MovePointDown.propTypes = {
    pointIndex: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
    setting: state.admin.template.setting,
    outer_rule: state.admin.template.outer_rule,
});

const mapDispatchToProps = (dispatch) => ({
    notify: (message) => dispatch(notify(message)),
    setTemplateSetting: (setting) => dispatch(setTemplateSetting(setting)),
    setOuterRule: (outer_rule) => dispatch(setOuterRule(outer_rule)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MovePointDown));
