import React, {Component} from 'react'
import {connect} from 'react-redux'
import {SubmissionError} from 'redux-form'
import Form from './ForgotPasswordForm'
import AuthPage from './AuthPage'
import 'styles/Auth.css'
import {forgotPassword} from "../../utils/auth";

class ForgotPassword extends Component {
  handleForgotPassword = data => {

    new Promise((resolve, reject) => {
      forgotPassword(data).then((response, a, b) => {
        resolve()
      }).catch((err) => {
        const data = err.response?.data
        if (data) {
          return reject(new SubmissionError({_error: data.message}))
        }
        return reject(SubmissionError({_error: 'error'}))
      })

    })

  }

  render() {
    return (
      <AuthPage className="login-page">
        <Form onSubmit={this.handleForgotPassword} />
      </AuthPage>
    )
  }
}

export default connect(null)(ForgotPassword)
