import {PathItem, Point as PathPoint, Point, Rectangle as PathRectangle, Segment, Size, Group} from "./vector";
import {CompoundPath} from "./vector/classes";
import {GROUP, RECTANGLE} from "../common/constants";

const SVG_NS = 'http://www.w3.org/2000/svg'

const LEFT = 'LEFT'
const RIGHT = 'RIGHT'
const TOP = 'TOP'
const BOTTOM = 'BOTTOM'

const resizeB = {
  LEFT: 'right',
  RIGHT: 'left',
  TOP: 'bottom',
  BOTTOM: 'top'
}

export const calculateArcPath = (path, curve, angle, scale) => {

  const coff = ((30 / scale))

  let textMedian = new Point()
  let arcpath = PathItem.create()

  if (curve && curve._segment2) {
    const center = curve._segment2.point;

    const medianPoint = curve._segment1.point.rotate(angle / 2, center);
    const medianVector = medianPoint.subtract(center);

    medianVector.length = coff
    const through = center.add(medianVector);

    medianVector.length = 40 / scale
    textMedian = center.add(medianVector);
    const offset = path.getOffsetOf(curve._segment2.point)
    let from = path.getPointAt(offset - coff)
    let to = path.getPointAt(offset + coff)

    if (to) {
      arcpath.moveTo(from);
      arcpath.arcTo2(through, to);
    }
  }

  return {
    arcpath,
    textMedian
  }

}

export const calculatePoint = ({point, handleIn, handleOut}, options) => {
  const rx = point.x - options.x
  const ry = point.y - options.y

  let cloned = {x: rx / options.ws, y: ry / options.hs}

  let inControl = handleIn.isZero() ? null : {
    x: (handleIn.x + rx) / options.ws,
    y: (handleIn.y + ry) / options.hs
  }
  let outControl = handleOut.isZero() ? null : {
    x: (handleOut.x + rx) / options.ws,
    y: (handleOut.y + ry) / options.hs
  }

  let arcpoint = {
    point: transformPointObject(cloned, options.dx, options.dy, options.scaleX, options.scaleY),

  }

  if (!handleIn.isZero()) arcpoint = {
    ...arcpoint,
    inControl: transformPointObject(inControl, options.dx, options.dy, options.scaleX, options.scaleY)
  }
  if (!handleOut.isZero()) arcpoint = {
    ...arcpoint,
    outControl: transformPointObject(outControl, options.dx, options.dy, options.scaleX, options.scaleY)
  }

  return arcpoint
}

export const getVectorShape = shapeObj => {

  const path = PathItem.create(shapeObj.points.map(({point, inControl, outControl}) => {
    let seg = [];

    const [x1, y1] = point
    seg.push([x1, y1])
    if (inControl) {
      seg.push([inControl[0] - x1, inControl[1] - y1])
    }
    if (outControl) {
      seg.push([outControl[0] - x1, outControl[1] - y1])
    }


    return new Segment(...seg)
  }))


  if (shapeObj.isClosed) {
    path.closePath()
  }

  const bbox = path.bounds.bbox()


  let x = bbox.x * shapeObj.width + shapeObj.x
  let y = bbox.y * shapeObj.height + shapeObj.y
  let width = bbox.width * shapeObj.width
  let height = bbox.height * shapeObj.height

  let segments = []

  segments = shapeObj.points.map(({point, inControl, outControl, type, length}) => {

    const [x1, y1] = point

    let p = [[x1 * shapeObj.width + shapeObj.x, y1 * shapeObj.height + shapeObj.y]]

    const handleIn = transformPoint(inControl, x1, y1, shapeObj.width, shapeObj.height)
    const handleOut = transformPoint(outControl, x1, y1, shapeObj.width, shapeObj.height)

    handleIn && p.push(handleIn)
    handleOut && p.push(handleOut)

    return p
  })

  const origin = PathItem.create(segments.map((seg) => {
    return new Segment(...seg)
  }))

  return {
    ...shapeObj,
    x,
    y,
    width,
    height,
    /*    points,*/
    segments,
    path: origin,

  }
}

export const updateShape = (shapeObj, zoom) => {

  const {path} = getVectorShape(shapeObj)

  const {x, y, width, height} = path.bounds.bbox()

  const ws = shapeObj.width
  const hs = shapeObj.height

  let dx = (x - shapeObj.x) / ws
  let dy = (y - shapeObj.y) / hs
  let scaleX = width > 0 ? ws / width : 0
  let scaleY = height > 0 ? hs / height : 0

  let prev = null
  let offset = 0

  let options = {
    x: shapeObj.x,
    y: shapeObj.y,
    ws,
    hs,
    dx,
    dy,
    scaleX,
    scaleY
  }


  let points = path.segments.map(({point, handleIn, handleOut, type, curve, next}) => {
    let angle = 0;
    if (curve) {
      const curve2 = next && next.curve ? next.curve : curve;
      const v1 = curve._segment1.point.subtract(curve._segment2.point);
      const v2 = curve2._segment2.point.subtract(curve2._segment1.point);

      angle = v1.getDirectedAngle(v2);
    }

    offset += (curve && curve.length > 0 ? curve.length : 0)

    let converted = calculatePoint({point, handleIn, handleOut}, options)


    converted = {
      ...converted,
      length: curve && curve.length > 0 ? curve.length : 0,
      offset: offset - (curve && curve.length > 0 ? curve.length / 2 : 0),
      angle: angle,
    }


    let {arcpath, textMedian} = calculateArcPath(path, curve, angle, zoom.scale)

    if (arcpath.segments.length > 0) {
      converted = {
        ...converted,
        textMedian: transformPointObject({
          x: (textMedian.x - shapeObj.x) / ws,
          y: (textMedian.y - shapeObj.y) / hs
        }, dx, dy, scaleX, scaleY),
        arc: arcpath.segments.map(({point, handleIn, handleOut}) => {
          return calculatePoint({point, handleIn, handleOut}, options)
        })
      }
    }


    return converted
  })


  return {
    ...shapeObj,
    x,
    y,
    width: width || 1,
    height: height || 1,
    points,
  }
}

export const transformPoint = (point, dx, dy, sx, sy) => {
  if (!point) {
    return point
  }

  let [x, y] = point

  return [(x - dx) * sx, (y - dy) * sy]
}

export const transformPointObject = (point, dx, dy, sx, sy, relative) => {
  if (!point) {
    return point
  }

  let {x, y} = point

  if (relative) {
    x = x + relative.x
    y = y + relative.y
  }

  return [(x - dx) * sx, (y - dy) * sy]
}

export const convertPoint = (ref, transform) => {
  if (!transform) {
    return point;
  }

  var point = ref.point,
    inControl = ref.inControl,
    outControl = ref.outControl;

  const p = transform(point);

  const handleIn = inControl && transform(inControl)
  const handleOut = outControl && transform(outControl)

  return [
    p,
    handleIn && [handleIn[0] - p[0], handleIn[1] - p[1]],
    handleOut && [handleOut[0] - p[0], handleOut[1] - p[1]],
  ];
};

export const transformPath = (points, transform) => {
  if (!transform) {
    return points;
  }

  return points.map(function (_ref) {
    var point = _ref.point,
      inControl = _ref.inControl,
      outControl = _ref.outControl;

    const p = transform(point);

    const handleIn = inControl && transform(inControl)
    const handleOut = outControl && transform(outControl)
    return [
      p,
      handleIn && [handleIn[0] - p[0], handleIn[1] - p[1]],
      handleOut && [handleOut[0] - p[0], handleOut[1] - p[1]],
    ];
  });
};

export const relativePoint = (seg, dx, dy, w, h) => {

  const point = {
    point: [(seg.point.x - dx) / w, (seg.point.y - dy) / h],
  }
  if (seg.inControl) {
    point.inControl = [
      (seg.inControl.x + (seg.point.x - dx)) / w,
      (seg.inControl.y + (seg.point.y - dy)) / h
    ]
  }
  if (seg.outControl) {
    point.outControl = [
      (seg.outControl.x + (seg.point.x - dx)) / w,
      (seg.outControl.y + (seg.point.y - dy)) / h
    ]
  }
  return point

}

export const updateShapeAngle = (obj, changes) => {

  const {angle} = changes
  const {compound, points} = obj

  let shape = PathItem.create()

  if (compound && compound.length > 0) {
    shape = PathItem.create(compound)
  } else if (points && points.length > 0) {
    shape = PathItem.create(points)
  }


 let _diff = angle
  if (obj.angle != 0) {
    _diff = angle - obj.angle
  }

  let  rotateX =   obj.x
  let rotateY =   obj.y
  let bounds = shape.bounds



  //shape.adjust(obj.x, obj.y)
  //shape.rotate(_diff || 0)

  let unit = window.devicePixelRatio === 2 ? 2 : 1
  shape.rotate(_diff || 0, shape.getRotatePoint(obj.x/unit, obj.y/unit));
  bounds = shape.bounds

  //
  //shape.rotate(_diff)

  let newPoints = []
  let newCompound = []

  if (shape instanceof CompoundPath) {
    newCompound = shape.children.map((path) => {
      return {points: path.points, depth: path.depth}
    })
  } else {
    newPoints = shape.points
  }


/*  if (obj.type === RECTANGLE) {

    obj = {
      ...obj,
      angle
    }
    const rect = createRectangle(obj)
    newPoints = rect.points
  }*/

  return {
    points: newPoints,
    compound: newCompound,
    angle,
    x: bounds.left,
    y: bounds.top,
    width: bounds.width,
    height: bounds.height

  }

}


export const updateShapeBounds = (obj, changes) => {


  const {activeResize} = changes
  const {compound, points} = obj

  let shape = PathItem.create()

  if (compound && compound.length > 0) {
    shape = PathItem.create(compound)
  } else if (points && points.length > 0) {
    shape = PathItem.create(points)
  }

  let bounds = shape.bounds
  var scaleX = changes.width / bounds.width;
  var scaleY = changes.height / bounds.height;
  var prevPos = new Point(bounds.top, bounds.left);

  let centerPoint = []

  if (activeResize)
  Object.keys(activeResize).map((k) => {
    centerPoint.push(bounds[resizeB[k]])
  })

  shape.scale(new Point(scaleX, scaleY), new Point(...centerPoint))



  let newPoints = []
  let newCompound = []

  if (shape instanceof CompoundPath) {
    newCompound = shape.children.map((path) => {
      return {points: path.points, depth: path.depth}
    })
  } else {
    newPoints = shape.points
  }
  shape.setPosition(prevPos)
  bounds = shape.bounds

  if (obj.type === RECTANGLE) {

    obj = {
      ...obj,
      width: bounds.width,
      height: bounds.height
    }
    const rect = createRectangle(obj)
    newPoints = rect.points
  }

  return {
    points: newPoints,
    compound: newCompound,
    /*x: bounds.left,
    y: bounds.top,*/
    width: bounds.width,
    height: bounds.height
  }
}


export const internalMove = (obj) => {

  const {compound, points} = obj

  let shape = PathItem.create()
  if (compound && compound.length > 0) {
    shape = PathItem.create(compound)
  } else if (points && points.length > 0) {
    shape = PathItem.create(points)
  }

  if (
      (!shape.segments && !shape.children) ||
      ((shape.segments && !shape.segments.length) && (shape.children && !shape.children.length))
   ) {

    return {
      compound,
      points
    }
  }

  let bounds = shape.bounds


  //shape.adjust(obj.x, obj.y )
  shape.adjust(obj.x , obj.y )

  // let unit = window.devicePixelRatio === 2 ? 2 : 1
  //shape.rotate(angle || 0, shape.getRotatePoint(obj.x/unit, obj.y/unit));

  let newPoints = []
  let newCompound = []

  if (shape instanceof CompoundPath) {
    newCompound = shape.children.map((path) => {
      return {points: path.points, depth: path.depth}
    })
  } else {
    newPoints = shape.points
  }

  return {
    points: newPoints,
    compound: newCompound,
  }
}

export const moveShape = (obj, originalObject, diffX, diffY) => {
  return internalMove(obj)
}

const FLIP_CONFIG = {
  x: [1,-1],
  y: [-1,1],
}

export const flip = (object, direction) => {
  let config = FLIP_CONFIG[direction]

  if (!config) {
    return object
  }

  const {compound, points, children} = object

  if (children && children.length > 0) {

  }

  let shape = PathItem.create()

  if (compound && compound.length > 0) {
    shape = PathItem.create(compound)
  } else if (points && points.length > 0) {
    shape = PathItem.create(points)
  }


  shape.scale(new PathPoint(config[0], config[1]))


  let newPoints = []
  let newCompound = []

  if (shape instanceof CompoundPath) {
    newCompound = shape.children.map((path) => {
      return {points: path.points, depth: path.depth}
    })
  } else {
    newPoints = shape.points
  }

  return {
    ...object,
    points: newPoints,
    compound: newCompound,
  }

}

export const createRectangle =(obj, zoom)=>{

  const kappa = 4 * (Math.sqrt(2) - 1) / 3

  const {width, height, points, compound, isClosed, x, y, borderRadius,angle} = obj

  let path = PathItem.create()

  const rect = new PathRectangle(x, y, width, height);
  let r =  new PathPoint(borderRadius ? [borderRadius, borderRadius] : [0,0])

  const bl = rect.getBottomLeft(true),
    tl = rect.getTopLeft(true),
    tr = rect.getTopRight(true),
    br = rect.getBottomRight(true)
  ;

  let segments = [];
  if (!r || r.isZero()) {
    segments = [
      new Segment(bl),
      new Segment(tl),
      new Segment(tr),
      new Segment(br)
    ];
  } else {

    r = Size.min(new Size(borderRadius, borderRadius), rect.getSize(true).divide(2));
    const rx = r.width,
      ry = r.height,
      hx = rx * kappa,
      hy = ry * kappa;
    segments = [
      new Segment(bl.add(rx, 0), null, [-hx, 0]),
      new Segment(bl.subtract(0, ry), [0, hy]),
      new Segment(tl.add(0, ry), null, [0, -hy]),
      new Segment(tl.add(rx, 0), [-hx, 0], null),
      new Segment(tr.subtract(rx, 0), null, [hx, 0]),
      new Segment(tr.add(0, ry), [0, -hy], null),
      new Segment(br.subtract(0, ry), null, [0, hy]),
      new Segment(br.subtract(rx, 0), [hx, 0])
    ];
  }

  let newCompound = []
  let newPoints = []


  path = PathItem.create(segments.map(({point, handleIn, handleOut}) => {
    return new Segment(point, handleIn, handleOut)
  }))

  path.setClosed(isClosed)
  //path.scale(zoom.scale)
  path.adjust(x, y)

  let unit = window.devicePixelRatio === 2 ? 2 : 1
 // path.rotate(angle || 0, path.getRotatePoint(x/unit, y/unit));

  if (path instanceof CompoundPath) {
    newCompound = path.children.map((path) => {
      return {points: path.points, depth: path.depth}
    })
  } else {
    newPoints = path.points
  }

  obj = {
    ...obj,
    compound :newCompound ,
    points : newPoints,
    isClosed: true
  }
  return obj
}


