import React, {Component} from 'react'
import {connect} from 'react-redux'
import {SubmissionError} from 'redux-form'
import QS from 'qs'


import {login, setCurrentUserToken} from '../../ducks/users'
import Form from './LoginForm'
import AuthPage from './AuthPage'
import 'styles/Auth.css'

class Login extends Component {
  
  getRedirect = () => {
    let {location} = this.props

    let {redirect} = QS.parse(location.search, {
      ignoreQueryPrefix: true,
    })

    return redirect
  }

  handleLogin = async data => {
    let {history, login} = this.props
    let redirect = this.getRedirect()

    return login(data).then(() => {

      history.push(redirect || '/')
    }).catch((err) => {
      throw new SubmissionError({_error: 'Invalid email or password'})
    })

  }

  render() {
    let redirect = this.getRedirect()

    return (
      <AuthPage className="login-page">
        <Form onSubmit={this.handleLogin} redirect={redirect}/>
      </AuthPage>
    )
  }
}

export default connect(null, {setCurrentUserToken, login})(Login)
