import {cnb} from "cnbuilder";
import * as React from "react";
import {DraggableCore} from "react-draggable";

import {isFun, isUndef, renderDivWithRenderer} from "./util";


export default class ScrollbarThumb extends React.Component {
  initialOffsetX = 0;
  initialOffsetY = 0;
  lastDragData = {
    x: 0,
    y: 0,
    deltaX: 0,
    deltaY: 0,
    lastX: 0,
    lastY: 0,
  };
  element = null;
  prevUserSelect;
  prevOnSelectStart= (ev) => {}

  elementRefHack = React.createRef();

  static selectStartReplacer = () => false;

  componentDidMount() {
    if (!this.element) {
      this.setState(() => {
        throw new Error(
          "<ScrollbarThumb> Element was not created. Possibly you haven't provided HTMLDivElement to renderer's `elementRef` function."
        );
      });
      return;
    }
  }

  componentWillUnmount() {
    this.handleOnDragStop();

    this.elementRef(null);
  }

   handleOnDragStart = (ev, data) => {
    if (!this.element) {
      this.handleOnDragStop(ev, data);
      return;
    }

    if (global.document) {
      this.prevUserSelect = global.document.body.style.userSelect;
      global.document.body.style.userSelect = "none";

      this.prevOnSelectStart = global.document.onselectstart;
      global.document.onselectstart = ScrollbarThumb.selectStartReplacer;
    }

    this.props.onDragStart &&
      this.props.onDragStart(
        (this.lastDragData = {
          x: data.x - this.initialOffsetX,
          y: data.y - this.initialOffsetY,
          lastX: data.lastX - this.initialOffsetX,
          lastY: data.lastY - this.initialOffsetY,
          deltaX: data.deltaX,
          deltaY: data.deltaY,
        })
      );

    this.element.classList.add("dragging");
  };

  handleOnDrag = (ev, data) => {
    if (!this.element) {
      this.handleOnDragStop(ev, data);
      return;
    }

    this.props.onDrag &&
      this.props.onDrag(
        (this.lastDragData = {
          x: data.x - this.initialOffsetX,
          y: data.y - this.initialOffsetY,
          lastX: data.lastX - this.initialOffsetX,
          lastY: data.lastY - this.initialOffsetY,
          deltaX: data.deltaX,
          deltaY: data.deltaY,
        })
      );
  };

  handleOnDragStop = (ev, data) => {
    const resultData = data
      ? {
          x: data.x - this.initialOffsetX,
          y: data.y - this.initialOffsetY,
          lastX: data.lastX - this.initialOffsetX,
          lastY: data.lastY - this.initialOffsetY,
          deltaX: data.deltaX,
          deltaY: data.deltaY,
        }
      : this.lastDragData;

    this.props.onDragEnd && this.props.onDragEnd(resultData);

    this.element && this.element.classList.remove("dragging");

    if (global.document) {
      global.document.body.style.userSelect = this.prevUserSelect;

      global.document.onselectstart = this.prevOnSelectStart;
      this.prevOnSelectStart = null;
    }

    this.initialOffsetX = 0;
    this.initialOffsetY = 0;
    this.lastDragData = {
      x: 0,
      y: 0,
      deltaX: 0,
      deltaY: 0,
      lastX: 0,
      lastY: 0,
    };
  };

  handleOnMouseDown = (ev) => {
    if (!this.element) {
      return;
    }

    ev.preventDefault();
    ev.stopPropagation();

    if (!isUndef(ev.offsetX)) {
      /* istanbul ignore next */
      this.initialOffsetX = ev.offsetX;
      /* istanbul ignore next */
      this.initialOffsetY = ev.offsetY;
    } else {
      const rect = this.element.getBoundingClientRect();
      this.initialOffsetX = (ev.clientX || (ev ).touches[0].clientX) - rect.left;
      this.initialOffsetY = (ev.clientY || (ev ).touches[0].clientY) - rect.top;
    }
  };

  render() {
    const {
      elementRef,

      axis,

      onDrag,
      onDragEnd,
      onDragStart,

      ...props
    } = this.props ;

    props.className = cnb(
      "ScrollbarsCustom-Thumb",
      axis === "x" ? "ScrollbarsCustom-ThumbX" : "ScrollbarsCustom-ThumbY",
      props.className
    );

    if (props.renderer) {
      (props ).axis = axis;
    }


    return ( /*@ts-ignore*/
     <DraggableCore
        allowAnyClick={false}
        enableUserSelectHack={false}
        onMouseDown={this.handleOnMouseDown}
        onDrag={this.handleOnDrag}
        onStart={this.handleOnDragStart}
        onStop={this.handleOnDragStop}
        // ToDo: Fixit!
        // react-draggable developers did not update typings so there is no appropriate prop
        // @ts-ignore
        nodeRef={this.elementRefHack}
      >
        {renderDivWithRenderer(props, this.elementRef)}
      </DraggableCore>
    );
  }

  elementRef = (ref) => {
    isFun(this.props.elementRef) && this.props.elementRef(ref);
    this.element = ref;

    // @ts-ignore
    this.elementRefHack["current"] = ref;
  };
}
