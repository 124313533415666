import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form'
import {connect} from 'react-redux'


import SettingsFormField, {SettingsFormSubmit,} from 'components/Shared/Forms/SettingsFormField'


const validate = values => {
  let errors = {}


  return errors
}

class SettingsForm extends Component {


  render() {
    let {
      appId,
      handleSubmit,
      dirty,
      submitting,

    } = this.props

    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <Field
          component={SettingsFormField}
          name="name"
          label="Название"
          placeholder="Пусто"
          type="text"
        />
        <Field
          component={SettingsFormField}
          name="description"
          label="Описание"
          type="text"
          maxLength="80"
        />

        <SettingsFormSubmit
          enabled={dirty && !submitting}
          buttonText="Обновить"
        />
      </form>
    )
  }
}

const mapStateToProps = (state, { appId }) => {


  return {

  }
}

const connected = connect(mapStateToProps)(SettingsForm)

export default reduxForm({
  form: 'AppSettings',
  validate,
  enableReinitialize: true,
})(connected)

