import React, {Component} from 'react'
import {connect} from 'react-redux'


import {createInvite} from 'ducks/apps/actions'
import { getAppFromList } from 'ducks/apps/selectors'

import CopyAppForm from './Form'
import {withRouter} from "react-router-dom";

class InviteDraft extends Component {
    handleSubmit = async values => {
        let {app, opts, createInvite, onSuccess} = this.props

        
        if (!app) {
            return
        }

        let {id} = app

        createInvite(id, values.email)

        onSuccess(id)

    }

    handleCancel = () => {
        
        let {onCancel} = this.props
        onCancel()

        //history.push(`/`)
    }

    render() {
        let {app} = this.props

        if (!app) {
            return null
        }

        let initialValues = {
            name: `${app.name}`,
        }

        return (

            <CopyAppForm
                onSubmit={this.handleSubmit}
                onCancel={this.handleCancel}
                initialValues={initialValues}
            />

        )
    }
}

const mapStateToProps = (state, {opts}) => {
    return {
        app: getAppFromList(state, opts.appId),
    }
}

export default withRouter(
    connect(mapStateToProps, {
        createInvite,
    })(InviteDraft)
)

