import React, {Component} from 'react'
import DocumentEvents from 'react-document-events'

import 'styles/Slider.css'

export default class Slider extends Component {
  state = {
    activeDrag: false,
    dragOffset: 0,
    bbox: null,
  }

  knobMouseDown = e => {
    if (!this.el) {
      return
    }

    e.stopPropagation()

    let knob = e.currentTarget
    let knobBounds = knob.getBoundingClientRect()

    // offset from center of knob
    let offset = e.clientX - (knobBounds.left + knobBounds.width / 2)

    let bbox = this.el.getBoundingClientRect()

    this.setState({
      bbox,
      activeDrag: true,
      dragOffset: offset,
    })
  }

  trackMouseDown = e => {
    if (!this.el) {
      return
    }

    let bbox = this.el.getBoundingClientRect()

    this.setState({
      bbox,
      activeDrag: true,
      dragOffset: 0,
    })

    // Initial onChange event...

    let percentValue = (e.clientX - bbox.left) / bbox.width

    return this.handleChange(percentValue)
  }

  handleMouseMove = e => {
    let { activeDrag, dragOffset, bbox } = this.state

    if (!activeDrag || !bbox) {
      return
    }

    let position = e.clientX - bbox.left - dragOffset

    return this.handleChange(position / bbox.width)
  }

  handleChange = percentValue => {
    let { name, onChange, min, max } = this.props

    if (!onChange) {
      return
    }

    let value = min + (max - min) * percentValue

    if (value > max) {
      value = max
    }

    if (value < min) {
      value = min
    }

    onChange({ [name]: Math.round(value) })
  }

  handleMouseUp = e => {
    this.setState({ activeDrag: false })
  }

  ref = el => {
    this.el = el
  }

  render() {
    let { activeDrag } = this.state

    let { min, max, value } = this.props

    if (value > max) {
      value = max
    }

    if (value < min || Number.isNaN(+value)) {
      value = min
    }

    let position = (value - min) / (max - min)
    position = Math.min(1, Math.max(0, position))

    let knobStyles = {
      left: `${100 * position}%`,
    }

    return (
      <div className="slider" onMouseDown={this.trackMouseDown} ref={this.ref}>
        <DocumentEvents
          active={activeDrag}
          onMouseMove={this.handleMouseMove}
          onMouseUp={this.handleMouseUp}
        />
        <div className="slider-track" />
        <div
          className="slider-knob"
          onMouseDown={this.knobMouseDown}
          style={knobStyles}
        />
      </div>
    )
  }
}
