import React, {Component} from 'react'
import Stripe from '../Stripe'

import {IconButton} from '../Icon'

import 'styles/Snackbar.css'

export default class Snackbar extends Component {
  render() {
    let { children, onDismiss } = this.props

    return (
      <div className="snackbar">
        <Stripe className="snackbar-stripe" />
        <div className="snackbar-content">{children}</div>
        {onDismiss && <IconButton white type="close" onClick={onDismiss} />}
      </div>
    )
  }
}
