import React, { useEffect } from 'react';

const TalkMeScript = () => {

  useEffect(() => {

    // Добавление скрипта только в продакшн-режиме
    if (process.env.NODE_ENV === 'production') {

      (function (d, w, m, i) {
        window.supportAPIMethod = m;
        const s = d.createElement('script');
        s.id = 'supportScript';
        const id = '5b9c510bfc2f8459135208d1e8672a6d';
        s.src = (!i
          ? 'https://lcab.talk-me.ru/support/support.js'
          : 'https://static.site-chat.me/support/support.int.js') + '?h=' + id;
        s.onerror = i ? undefined : function () {
          c(d, w, m, true);
        };
        w[m] = w[m] ? w[m] : function () {
          (w[m].q = w[m].q ? w[m].q : []).push(arguments);
        };
        (d.head ? d.head : d.body).appendChild(s);
      })(document, window, 'TalkMe');
    }

  }, [])

  return null; // Ничего не отображаем в компоненте
};

export default TalkMeScript;