import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'


import ColorDropdown from '../ColorDropdown'

import 'styles/ColorControl.css'
import {defaultBranding} from "../../../../utils/colors";

class LibraryColorControl extends Component {
  render() {
    let { name, value, displayName, onChange, branding, values } = this.props

    return (
      <ColorDropdown
        normalize
        className="library-color-control"
        branding={branding}
        name={name}
        onChange={onChange}
        value={value}
        title={displayName}
        ctx={values}
      />
    )
  }
}

const mapStateToProps = (state, { match }) => ({
  branding: defaultBranding,
})

export default withRouter(connect(mapStateToProps)(LibraryColorControl))
