import {cnb} from "cnbuilder";
import * as React from "react";

import {isFun, isUndef, renderDivWithRenderer} from "./util";

export default class ScrollbarTrack extends React.Component {
  element = null;

  componentDidMount() {
    if (!this.element) {
      this.setState(() => {
        throw new Error(
          "Element was not created. Possibly you haven't provided HTMLDivElement to renderer's `elementRef` function."
        );
      });
      return;
    }

    this.element.addEventListener("click", this.handleClick);
  }

  componentWillUnmount() {
    if (this.element) {
      this.element.removeEventListener("click", this.handleClick);
      this.element = null;

      this.elementRef(null);
    }
  }

  render() {
    const {
      elementRef,

      axis,
      onClick,

      ...props
    } = this.props;

    props.className = cnb(
      "ScrollbarsCustom-Track",
      axis === "x" ? "ScrollbarsCustom-TrackX" : "ScrollbarsCustom-TrackY",
      props.className
    );

    if (props.renderer) {
      (props).axis = axis;
    }

    return renderDivWithRenderer(props, this.elementRef);
  }

  elementRef = (ref) => {
    isFun(this.props.elementRef) && this.props.elementRef && this.props.elementRef(ref);
    this.element = ref;
  };

  handleClick = (ev) => {
    if (!ev || !this.element || ev.button !== 0) {
      return;
    }

    if (isFun(this.props.onClick) && ev.target === this.element) {
      if (!isUndef(ev.offsetX)) {
        this.props.onClick && this.props.onClick(ev, {
          axis: this.props.axis,
          offset: this.props.axis === "x" ? ev.offsetX : ev.offsetY,
        });
      } else {
        // support for old browsers
        /* istanbul ignore next */
        const rect = this.element.getBoundingClientRect();
        /* istanbul ignore next */
        this.props.onClick && this.props.onClick(ev, {
          axis: this.props.axis,
          offset:
            this.props.axis === "x"
              ? (ev.clientX || (ev).touches[0].clientX) - rect.left
              : (ev.clientY || (ev).touches[0].clientY) - rect.top,
        });
      }
    }

    return true;
  };
}
