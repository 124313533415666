import React, {Component} from 'react'
import {connect} from 'react-redux'
import DocumentEvents from 'react-document-events'
import {SHAPE} from 'common/constants'
import {scale, unScale} from '../../../../utils/zoom'
import {ESC} from '../../../../utils/keyboard'
import {HANDLER_LINK} from '../../../../utils/geometry'
import {PathItem, Segment} from '../../../../utils/vector'


import {createObject, updateObject,} from '../../../../ducks/editor/objects'

import {
    endDrag,
    getDraggingInControl,
    getDraggingOutControl,
    getDraggingSelectedPoint,
    getEditingPath,
    getSelectedPoint,
    setDraggingInControl,
    setDraggingOutControl,
    setDraggingSelectedPoint,
    setEditingPath,
    setEditingShape,
    setSelectedPoint,
} from '../../../../ducks/editor/shapeEditing'

import {resetTool} from '../../../../ducks/editor/tools'
import Point from './Point'
import 'styles/ShapeEditor.css'
import {getPath, updatePath} from "../../../../ducks/trace";


class PathEditor extends Component {

  constructor(props) {
    super(props)

    this.state = {
      lastCursorPosition: null,
      hitPoint: {x: 0, y: 0}

    }
    this.path = PathItem.create()
    this.compoundPath = PathItem.create()

    let {object, updatePath, editingPath} = props

    if (object) {


      if (object.compound && object.compound.length > 0) {
        this.compoundPath = PathItem.create(object.compound)
        this.compoundPath.setClosed(object.isClosed)

        this.path = this.compoundPath.children[editingPath]
        this.path.setClosed(object.isClosed)
        this.path.adjust(object.x, object.y)
      } else  {
        this.path.setSegments(object.points)
        this.path.setClosed(object.isClosed)
        this.path.adjust(object.x, object.y)
        this.compoundPath.setSegments(object.points)
        this.compoundPath.setClosed(object.isClosed)
        this.compoundPath.adjust(object.x, object.y)
      }

      const bounds = this.compoundPath.bounds

      const newObj = {
        ...object,
        width: bounds.width,
        height: bounds.height,
        x: bounds.left,
        y: bounds.top
      }

      updatePath(newObj)

    }
  }
  componentWillReceiveProps(NextProps) {
    let {object, updatePath, editingPath} = NextProps


    if (object) {


      if (object.compound && object.compound.length > 0) {
        this.compoundPath = PathItem.create(object.compound)
        this.compoundPath.setClosed(object.isClosed)

        this.path = this.compoundPath.children[editingPath]
        this.path.setClosed(object.isClosed)
        this.path.adjust(object.x, object.y)
      } else  {
        this.path.setSegments(object.points)
        this.path.setClosed(object.isClosed)
        this.path.adjust(object.x, object.y)
        this.compoundPath.setSegments(object.points)
        this.compoundPath.setClosed(object.isClosed)
        this.compoundPath.adjust(object.x, object.y)
      }

    }
  }

  handleKeyDown = e => {
    let {setEditingPath, setEditingShape, resetTool} = this.props

    if (e.which === ESC) {
      //setEditingPath(null)
      //setEditingShape(null)
      //resetTool()
    }
  }

  handleSelectPoint = index => () => {
    let {setSelectedPoint} = this.props
    let closePointIndex = this.getClosePointIndex()

    if (index === closePointIndex) {
      let {setDraggingOutControl} = this.props

      this.updateObject({isClosed: true}, 'closePath')

      this.setState({
        lastCursorPosition: null,
      })

      setDraggingOutControl()
    }

    setSelectedPoint(index, true)
  }

  updateObject = (changes, undoKey) => {
    let {object, updatePath, zoom, editingPath} = this.props
    let newObj = {...object, ...changes}

    if (undoKey) {
      this._lastUndoKey = undoKey
    }

    if (newObj) {

     if (newObj.compound && newObj.compound.length > 0) {
        newObj.compound[editingPath] = newObj.points
        this.compoundPath.children[editingPath].setSegments(newObj.points)
      } else {
        this.compoundPath.setSegments(newObj.points)
      }


      const bounds = this.compoundPath.bounds

      newObj = {
        ...newObj,
        width: bounds.width,
        height: bounds.height,
        x: bounds.left,
        y: bounds.top,
      }

      this.compoundPath.setClosed(newObj.isClosed)
    }

    updatePath(newObj)
  }

  handleMouseDown = e => {


    let {
      object,
      screens,
      createObject,
      resetTool,
      setDraggingOutControl,
      setSelectedPoint,
      setEditingShape,
      setEditingPath,
      editingPath,
      updatePath,
      app,
    } = this.props

    let {selectedPoint} = this.props

    //let [x, y] = this.unScalePoint([e.clientX, e.clientY])

    let [x, y] = this.getRealPoint([e.clientX, e.clientY])


    let newPoint = {point: [x, y]}

    if (!object) {
      // Creating Object


      updatePath({
        x,
        y,
        type: SHAPE,
        width: 1,
        height: 1,
        points: [{point: [x, y]}],
      })


      resetTool()

      this.setState({
        lastCursorPosition: null,
      })

      setDraggingOutControl()
      setSelectedPoint(0)
    } else {
      // Updating Object
      let pathPoints = []
      if (object.compound && object.compound.length > 0) {
        pathPoints = object.compound[editingPath]
      } else {
        pathPoints = object.points
      }


      if (!this.canCreatePoint()) {
        setEditingPath(null)
        setEditingShape(null)
      } else if (selectedPoint === pathPoints.length - 1) {
        // Drawing path forward
        let points = pathPoints.slice()
        points.push(newPoint)


        this.setState({lastCursorPosition: null})


        setDraggingOutControl()
        setSelectedPoint(selectedPoint + 1)

        this.updateObject({points}, `addPoint-${+new Date()}`)

      } else if (selectedPoint === 0) {
        // Drawing path backward
        let points = pathPoints.slice()
        points.unshift(newPoint)


        this.updateObject({points}, `addPoint-${+new Date()}`)

        this.setState({
          lastCursorPosition: null,
        })

        setDraggingOutControl()
      } else {
        // Done editing
        //setEditingPath(null)
        //setEditingShape(null)
      }
    }
  }

  handleMouseMove = e => {


    let lastCursorPosition = this.getRealPoint([e.clientX, e.clientY])


    let {
      object,
      draggingInControl,
      draggingOutControl,
      draggingSelectedPoint,
      editingPath
    } = this.props

    let {selectedPoint} = this.props

    let points = []

    if (object) {
      if (object.compound && object.compound.length > 0) {
        points = object.compound[editingPath] || []
      } else {
        points = object.points || []
      }
    }

    if (points[selectedPoint] && (draggingInControl || draggingOutControl)) {

      points = points.slice()

      let key = draggingInControl ? 'handleIn' : 'handleOut'
      let otherKey = draggingInControl ? 'handleOut' : 'handleIn'

      let [pointX, pointY] = points[selectedPoint].point


      let otherPoint = [
        pointX - lastCursorPosition[0],
        pointY - lastCursorPosition[1],
      ]

      points[selectedPoint] = {
        ...points[selectedPoint],
        [key]: [lastCursorPosition[0] - pointX, lastCursorPosition[1] - pointY],
        [otherKey]: otherPoint,
        type: HANDLER_LINK
      }

      this.updateObject({points})

      this.setState({
        lastCursorPosition: null
      })

    } else if (draggingSelectedPoint) {

      points = points.slice()
      let point = points[selectedPoint]

      let diffX = lastCursorPosition[0] - point.point[0]
      let diffY = lastCursorPosition[1] - point.point[1]

      point['point'] = [point['point'][0] + diffX, point['point'][1] + diffY]
      points[selectedPoint] = point

      this.updateObject({points}, `dragPoint-${selectedPoint}`)

      this.setState({
        lastCursorPosition: null
      })
    } else {

      this.setState({
        lastCursorPosition
      })
    }
  }

  handleMouseLeave = () => {
    this.setState({
      lastCursorPosition: null,
    })
  }

  handleMouseUp = () => {
    let {object, updateObject, endDrag, setDraggingSelectedPoint, zoom} = this.props

    endDrag()
    setDraggingSelectedPoint(false)

    if (object && object.points && object.points.length > 1) {
      /*   let newObj = updateShape(object, zoom)*/
      updatePath(object)
    }
  }

  canCreatePoint = () => {
    let {object, selectedPoint} = this.props

    if (!object) {
      return true
    }

    if (object.isClosed) {
      return false
    }

    return selectedPoint === 0 || selectedPoint === object.points.length - 1
  }

  getEndPoint = () => {
    let {object, selectedPoint} = this.props

    return object && this.canCreatePoint() && object.points[selectedPoint]
  }

  getClosePointIndex = () => {
    let {object, selectedPoint} = this.props

    if (!object || (object.points && object.points.length === 0) || object.isClosed) {
      return null
    }

    if (selectedPoint === 0) {
      return object.points.length - 1
    }

    if (selectedPoint === object.points.length - 1) {
      return 0
    }

    return null
  }

  convertPoint = point => {
    let {zoom,  object} = this.props

    if (!point) {
      return point
    }

    let [x, y] = point
    let absolutePoint = point

    return scale(absolutePoint, zoom)
  }

  getRealPoint = point => {
    let {object,  zoom} = this.props

    let [x, y] = unScale(point, zoom)


    x = Math.round(x)
    y = Math.round(y)

    /*if (object) {
      x = (x - object.x) / object.width
      y = (y - object.y) / object.height
    }*/

    return [x, y]
  }

  render() {
    let {lastCursorPosition, hitPoint} = this.state

    let {
      selectedPoint,
      object,
      setDraggingInControl,
      setDraggingOutControl,
      zoom,
      editingPath,
        width, height
    } = this.props

    let cursorClass = null
    let canCreate = this.canCreatePoint()
    let pathLength = 0

    let endPoint = this.getEndPoint()


    let path = ''
    let points = [];
    let dimension = []
    let renderPath = PathItem.create()
    if (object ) {

      const point = this.convertPoint([object.x, object.y])

      const compoundPath = this.compoundPath.clone()
      compoundPath.scale(zoom.scale)

      const compoundBbox = compoundPath.bounds.bbox()
      compoundPath.adjust(...point)

      if (object.compound && object.compound[editingPath]) {
        points = object.compound[editingPath]
        renderPath = compoundPath.children[editingPath]
      } else {
        points = object.points
        renderPath = compoundPath
      }

      if (lastCursorPosition && endPoint) {
        renderPath.draw(new Segment(this.convertPoint(lastCursorPosition)), object.points.length)
      }

      path = renderPath.getPathData()


    }


    let closePointIndex = this.getClosePointIndex()

    cursorClass = "point-editor"
    if (canCreate) {
      cursorClass += ' cursor-pen-plus'
    }
    const that = this

    return (
      <g
        onMouseDown={this.handleMouseDown}
        onMouseMove={this.handleMouseMove}
        onMouseUp={this.handleMouseUp}
        onMouseLeave={this.handleMouseLeave}
        className={cursorClass}
        pointerEvents="all"
      >
        <DocumentEvents onKeyDown={this.handleKeyDown}/>
        <rect
          x={0}
          y={0}
          width={window.innerWidth}
          height={window.innerHeight}
          style={{fill: 'none'}}
        />

        <path id="editorLine" className="trace-editor-line " d={path}/>

        {object && (
          <g className="shape-editor-points">
            {points.map((pt, i) => (
              <Point
                point={pt}
                key={i} // eslint-disable-line react/no-array-index-key
                onSelect={this.handleSelectPoint(i)}
                selected={selectedPoint === i}
                isClosePoint={i === closePointIndex}
                scalePoint={this.convertPoint}
                setDraggingInControl={setDraggingInControl}
                setDraggingOutControl={setDraggingOutControl}
              />
            ))}
          </g>
        )}


      </g>
    )
  }
}

const mapStateToProps = state => ({
  object: getPath(state),
  editingPath: getEditingPath(state),
  draggingOutControl: getDraggingOutControl(state),
  draggingInControl: getDraggingInControl(state),
  selectedPoint: getSelectedPoint(state),
  draggingSelectedPoint: getDraggingSelectedPoint(state),
})

export default connect(mapStateToProps, {

  resetTool,
  createObject,
  updateObject,
  updatePath,
  setEditingShape,
  setEditingPath,
  setDraggingOutControl,
  setDraggingInControl,
  endDrag,
  setSelectedPoint,

  setDraggingSelectedPoint,
})(PathEditor)
