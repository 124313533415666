const DRAG_START = Symbol('DRAG_START')
const DRAG_MOVE = Symbol('DRAG_MOVE')
const DRAG_END = Symbol('DRAG_END')

const INITIAL_STATE = {
  draggingHomeScreen: false,
  draggingAuthScreen: false,
  position: null,
  offset: null,
}

export default (state = INITIAL_STATE, action) => {
  if (action.type === DRAG_START) {
    let { position, offset, dragType } = action

    let newState = {
      ...state,
      position,
      offset,
    }

    if (dragType === 'home') {
      newState.draggingHomeScreen = true
    } else if (dragType === 'auth') {
      newState.draggingAuthScreen = true
    } else {
      throw new Error(`Invalid drag type: ${dragType}`)
    }

    return newState
  }

  if (action.type === DRAG_MOVE) {
    let { position } = action

    return {
      ...state,
      position,
    }
  }

  if (action.type === DRAG_END) {
    return INITIAL_STATE
  }

  return state
}

// ACTIONS

export const startDrag = (position, offset, dragType) => ({
  type: DRAG_START,
  dragType,
  position,
  offset,
})

export const drag = position => ({
  type: DRAG_MOVE,
  position,
})

export const endDrag = () => ({
  type: DRAG_END,
})

// SELECTORS

export const getDragPosition = state => state.editor.launchComponent.position
export const getDragOffset = state => state.editor.launchComponent.offset

export const getDrag = (state, type) => {
  let { launchComponent } = state.editor
  let { position, offset } = launchComponent

  let typeFlag = type === 'home' ? 'draggingHomeScreen' : 'draggingAuthScreen'

  if (!launchComponent[typeFlag] || !position || !offset) {
    return null
  }

  return {
    position,
    offset,
  }
}
