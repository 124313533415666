import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notify } from 'ducks/notifications';
import { withRouter } from 'react-router-dom';

import { setOuterRule } from 'components/Admin/template/store/templateActions';

class AddPoint extends Component {

    onAddPoint = () => {
        const { outer_rule, setting, setOuterRule } = this.props;

        if (setting.activeRuleIndex === null || setting.activeRuleIndex === undefined) {
            this.props.notify("No active rule selected.");
            return;
        }

        const updatedOuterRule = outer_rule.map((rule, index) => {
            if (index === setting.activeRuleIndex) {
                return { ...rule, points: [...rule.points, [0, 0]] };
            }
            return rule;
        });

        setOuterRule(updatedOuterRule);
    };

    render() {
        return (
            <button
                type="button"
                onClick={this.onAddPoint}
                className="mt-4 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            >
                Добавить
            </button>
        );
    }
}

const mapStateToProps = (state) => {
    const setting = state.admin.template.setting;
    const outer_rule = state.admin.template.outer_rule;

    return {
        setting: setting,
        outer_rule: outer_rule,
    };
};

const mapDispatchToProps = (dispatch) => ({
    notify: (message) => dispatch(notify(message)),
    setOuterRule: (outer_rule) => dispatch(setOuterRule(outer_rule)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddPoint));
