import React, {Component} from 'react'

export default class GenericWrapper extends Component {
  render() {
    let { xScaled, yScaled, children } = this.props

    let transform = `translate(${xScaled} ${yScaled})`

    return <g transform={transform}>{children}</g>
  }
}
