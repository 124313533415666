const START_DRAG = Symbol('START_DRAG')
const DRAG = Symbol('DRAG')
const END_DRAG = Symbol('END_DRAG')
const SET_DROP_TARGET = Symbol('SET_DROP_TARGET')

const INITIAL_STATE = {
  dragging: false,
  dropTarget: null,
  options: {},
  objects: [],
  offset: null,
  position: null,
}

export default (state = INITIAL_STATE, action) => {
  if (action.type === START_DRAG) {
    let { objects, offset, position } = action

    return {
      ...state,
      objects,
      offset,
      position,
      dragging: true,
    }
  }

  if (action.type === DRAG) {

    let { position } = action

    return {
      ...state,
      position,
    }
  }

  if (action.type === END_DRAG) {
    return INITIAL_STATE
  }

  if (action.type === SET_DROP_TARGET) {
    let { dropTarget, options } = action

    return {
      ...state,
      dropTarget,
      options,
    }
  }

  return state
}

// ACTIONS

export const startDrag = (objects, offset, position) => ({
  type: START_DRAG,
  objects,
  offset,
  position,
})

export const drag = position => ({
  type: DRAG,
  position,
})

export const endDrag = () => ({ type: END_DRAG })

export const setDropTarget = (dropTarget, options) => ({
  type: SET_DROP_TARGET,
  dropTarget,
  options,
})

// SELECTORS

export const getLayers = state => state.editor.layers
