import React, {Component} from 'react'

export default class ResizeHandle extends Component {
  static defaultProps = {
    x: 0,
    y: 0,
    height: 8,
    width: 8,
  }

  render() {
    let {
      invisible,
      cursor,
      x,
      y,
      onMouseDown,
      height,
      width,
      position,
    } = this.props

    const translateX = () => {
      if (position === 'top' || position === 'bottom') return 0

      return x - 4
    }

    const translateY = () => {
      if (position === 'left' || position === 'right') return 0

      return y - 4
    }

    return (
      <g
        transform={`translate(${translateX()}, ${translateY()})`}
        onMouseDown={onMouseDown}
      >
        {invisible ? null : (
          /*{<circle
            className="resize-handle"
            r={4}
            cx={width / 2}
            cy={height / 2}
          />}*/
          <rect
          className="resize-handle"
          width={8}
          height={8}
          x={(width / 2) - 4}
          y={(height / 2) - 4}

          />
        )}
        <rect
          className="resize-click-target"
          width={width}
          height={height}
          style={{
            cursor: `${cursor}-resize`,
          }}
        />
      </g>
    )
  }
}
