import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

import {deleteDraft} from '../../ducks/apps/actions'
import { getAppFromList } from 'ducks/apps/selectors'

import 'styles/DeleteApp.css'
import Modal from "../Shared/Modal";

class DeleteAppModal extends Component {
    handleDelete = () => {
        let {app: {id}, onSuccess, deleteDraft} = this.props
        deleteDraft(id)
        onSuccess()
    }


    handleCancel = () => {
        
        let {onCancel} = this.props
        onCancel()
    }

    render() {
        let {app} = this.props

        if (!app) {
            return null
        }

        let {name} = app

        return (
            <div>
                <Modal.Header title="Удаление"/>
                <Modal.Content>
                    Вы действительно хотите удалить проект <strong>{name}</strong>?

                </Modal.Content>
                <Modal.Actions>
                    <Modal.Button type="button" text onClick={this.handleCancel}>
                        Отмена
                    </Modal.Button>
                    <Modal.Button type="submit" onClick={this.handleDelete}>Удалить</Modal.Button>
                </Modal.Actions>
            </div>
        )
    }
}

const mapStateToProps = (state, {opts}) => {
    return {
        app: getAppFromList(state, opts.appId),
    }
}
export default withRouter(
    connect(mapStateToProps, {
        deleteDraft,
    })(DeleteAppModal)
)
