import {
    COMPONENT_INSTANCE,
    DATE_PICKER,
    ELLIPSE,
    FILE_UPLOAD,
    FORM,
    IMAGE,
    IMAGE_UPLOAD,
    INPUT,
    LABEL,
    LIBRARY_COMPONENT,
    LINE,
    LIST,
    SECTION,
    SELECT,
    SHAPE,
    SHAPE_COMPONENT,
    WEB_VIEW,
} from 'common/constants'

import 'styles/tools.css'

export const RECTANGLE = Symbol('RECTANGLE')
export const OVAL = Symbol('OVAL')
export const TEXT = Symbol('TEXT')
export const VECTOR = Symbol('VECTOR')
export const UPLOAD = Symbol('UPLOAD')
export const ADD_COMPONENT = Symbol('ADD_COMPONENT')
export const ADD_LIBRARY_COMPONENT = Symbol('ADD_LIBRARY_COMPONENT')
export const ADD_SHAPE_COMPONENT = Symbol('ADD_SHAPE_COMPONENT')
export const DRAGGABLE = Symbol('DRAGGABLE')

export const tools = [
  RECTANGLE,
  OVAL,
  TEXT,
  VECTOR,
  UPLOAD,
  ADD_COMPONENT,
  ADD_LIBRARY_COMPONENT,
  ADD_SHAPE_COMPONENT,
  DRAGGABLE,
]

export const isValidTool = tool => {
  return tools.includes(tool)
}

export const getTool = objectType => {
  switch (objectType) {
    case SHAPE:
      return VECTOR
    case COMPONENT_INSTANCE:
      return ADD_COMPONENT
    case LIBRARY_COMPONENT:
      return ADD_LIBRARY_COMPONENT
    case SHAPE_COMPONENT:
      return ADD_SHAPE_COMPONENT
    case SECTION:
    case LINE:
    case ELLIPSE:
    case IMAGE:
    case LABEL:
    case INPUT:
    case IMAGE_UPLOAD:
    case FILE_UPLOAD:
    case DATE_PICKER:
    case SELECT:
    case WEB_VIEW:
    case FORM:
    case LIST:
      return DRAGGABLE
    default:
      return RECTANGLE
  }
}

export const getCursorClass = tool => {
  switch (tool) {
    case RECTANGLE:
    case OVAL:
    case UPLOAD:
      return 'cursor-crosshair'
    case TEXT:
      return 'cursor-text'
    case ADD_COMPONENT:
      return 'cursor-move'
    default:
      return 'default'
  }
}
