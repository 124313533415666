// redux-store.js

import {applyMiddleware, combineReducers, compose, createStore} from 'redux'
import thunkMiddleware from 'redux-thunk'
import {createPromise} from 'redux-promise-middleware'
import {reducer as formReducer} from 'redux-form'

import * as reducers from './ducks'

const promise = createPromise({ types: { fulfilled: 'success' } });

// devtools
const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        serialize: true,
      })
    : compose

const middleware = applyMiddleware(thunkMiddleware, promise)

export default createStore(
  combineReducers({ ...reducers, form: formReducer }),
  composeEnhancers(middleware)
)



