import React, {Component} from 'react'
import classNames from 'classnames'
import BodyClass from '../Shared/BodyClass'

export default class AuthPage extends Component {
  render() {
    let { className, children, error } = this.props

    return (
      <div
        className={classNames('auth-page', className, {
          'auth-page-has-error': error,
        })}
      >
        <BodyClass className="auth-screen" />
        {error ? <ErrorBanner error={error} /> : null}
        <div className="auth-page-body">{children}</div>
      </div>
    )
  }
}

class ErrorBanner extends Component {
  render() {
    let { error } = this.props

    return <div className="auth-page-error">{error}</div>
  }
}
