import React, {Component} from 'react'
import {connect} from 'react-redux'

import {NEW_SCREEN_MODAL, showModal} from 'ducks/editor/modals'

import EmptyState from 'components/Shared/EmptyState'

import './Empty.scss'

class EmptyCanvas extends Component {
  handleClick = e => {
    e.preventDefault()
    const { showModal } = this.props

    return showModal(NEW_SCREEN_MODAL, { zoom: true })
  }

  render() {
    return (
      <EmptyState greedy className="editor-empty-canvas">

        <p>
          <a className="add-screen-link" onClick={this.handleClick}>
            Добавить слой
          </a>{' '}

        </p>
      </EmptyState>
    )
  }
}

export default connect(null, { showModal })(EmptyCanvas)
