import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {GROUP} from 'common/constants'
import {setLayersHover, setSelection} from '../../../ducks/editor/selection'
import GenericInspectRow from './GenericRow'
import {getObjectName} from '../../../utils/naming'
import {TYPE_NAMES} from '../RightPanel'

class GroupControlChild$ extends Component {
  static displayName = 'GroupControlChild'
  static propTypes = {
    object: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
    setSelection: PropTypes.func.isRequired,
    setLayersHover: PropTypes.func.isRequired,
  }

  handleMouseEnter = () => {
    const { setLayersHover, object } = this.props
    setLayersHover(object.id)
  }

  handleMouseLeave = () => {
    const { setLayersHover } = this.props
    setLayersHover(null)
  }

  handleClick = () => {
    const { setSelection, object } = this.props
    setSelection(object.id, false)
  }

  render() {
    const { object } = this.props

    return (
      <div
        className="group-child-component"
        onClick={this.handleClick}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div>
          <div className="group-child-component-type">
            {TYPE_NAMES[object.type]}
          </div>
          <div className="group-child-component-name">
            {getObjectName(object)}
          </div>
        </div>
        <i className="material-icons">arrow_forward</i>
      </div>
    )
  }
}

const GroupControlChild = connect(null, { setLayersHover, setSelection })(
  GroupControlChild$
)

export default class GroupControl extends Component {
  static propTypes = {
    object: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf([GROUP]),
    }).isRequired,
  }

  render() {
    const {
      object: { children },
    } = this.props

    return (
      <GenericInspectRow
        className="group-components-inspect-row"
        title="Объекты"
      >
        {children.map(child => (
          <GroupControlChild key={child.id} object={child} />
        ))}
      </GenericInspectRow>
    )
  }
}
