import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'

import Frame from './Frame'
import CloseButton from './CloseButton'

import './Trace.scss'

class LayerEditor extends Component {
  state = {

  }

  handleChangeSize = size => {
    this.setState({ size })
  }

  render() {
    let { match } = this.props
    let { appId } = match.params


    return (
      <div className="editor-preview">
        <Frame appId={appId} />
        <CloseButton appId={appId} />
      </div>
    )
  }
}

export default withRouter(LayerEditor)
