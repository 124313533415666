import {getLibrary as getLibraryUtil, loadLibrary, removeLibrary as removeLibraryUtil,} from '../../utils/libraries'

const LOAD = 'LOAD_LIBRARY'
const LOAD_PENDING = `${LOAD}_PENDING`
const LOAD_FULFILLED = `${LOAD}_FULFILLED`
const LOAD_REJECTED = `${LOAD}_REJECTED`

const REMOVE = 'REMOVE_LIBRARY'
const REMOVE_FULFILLED = `${REMOVE}_FULFILLED`

const INITIAL_STATE = {}

const DEFAULT = {
  ready: false,
  loading: false,
  error: null,
  name: 'loading',
  displayName: 'Loading...',
}

const LOADING = {
  ready: false,
  loading: true,
  error: null,
  displayName: 'Loading...',
}

const READY = {
  ready: true,
  loading: false,
  error: null,
}

const ERROR = {
  ready: false,
  loading: false,
  error: true,
  displayName: 'Error',
}

export default (state = INITIAL_STATE, action) => {
  if (action.type === LOAD_PENDING) {
    let { libraryName, version } = action

    // prevent set of an undedinfed library
    if (!libraryName) return state

    // return existing state of library version if it already exists
    if (state?.[libraryName]?.[version]) return state

    // set loading state for requested library
    return {
      ...state,
      [libraryName]: {
        ...state[libraryName],
        [version]: {
          ...LOADING,
          version,
          name: libraryName,
        },
      },
    }
  }

  if (action.type === LOAD_FULFILLED) {
    let { libraryName, version } = action.payload

    let library = getLibraryUtil(libraryName, version)

    return {
      ...state,
      [libraryName]: {
        ...state[libraryName],
        [version]: {
          ...READY,
          ...library,
        },
      },
    }
  }

  if (action.type === LOAD_REJECTED) {
    let { libraryName, version } = action.payload

    return {
      ...state,
      [libraryName]: {
        ...state[libraryName],
        [version]: {
          ...ERROR,
          version,
          name: libraryName,
        },
      },
    }
  }

  // removes library
  if (action.type === REMOVE_FULFILLED) {
    const { libraryName } = action.payload

    const newState = { ...state }
    delete newState?.[libraryName]

    return newState
  }

  return state
}

// Actions

export const requestLibrary = (libraryName, version) => ({
  type: LOAD,
  libraryName,
  version,
  payload: async () => {
    try {
      await loadLibrary(libraryName, version)

      return { libraryName, version }
    } catch (err) {
      throw { libraryName, version }
    }
  },
})

export const removeLibrary = libraryName => ({
  type: REMOVE,
  libraryName,
  payload: async () => {
    try {
      await removeLibraryUtil(libraryName)

      return { libraryName }
    } catch (err) {
      throw new Error(`ERROR REMOVING LIBRARY: ${libraryName}`)
    }
  },
})

// Selectors

export const getLibraryStatus = (state, libraryName, version) => {
  let { libraries } = state.editor

  if (!libraries[libraryName] || !libraries[libraryName][version]) {
    return DEFAULT
  }

  return libraries[libraryName][version]
}
