import React, {Component} from 'react'

import BindableTextControl, {NakedBindableTextControl,} from './BindableTextControl'
import FormulaControl from './FormulaControl'

export default BindableTextControl

export class WrappedBindableTextControl extends Component {
  handleChange = ({ value }) => {
    let {
      input: { onChange },
    } = this.props

    onChange(value)
  }

  render() {
    let {
      input: { value },
      ...props
    } = this.props

    return (
      <BindableTextControl
        {...props}
        name="value"
        value={value}
        onChange={this.handleChange}
      />
    )
  }
}

export { FormulaControl, NakedBindableTextControl }

export class WrappedFormulaControl extends Component {
  handleChange = ({ value }) => {
    let {
      input: { onChange },
    } = this.props

    onChange(value)
  }

  render() {
    let {
      input: { value },
      ...props
    } = this.props

    return (
      <FormulaControl
        {...props}
        name="value"
        value={value}
        onChange={this.handleChange}
      />
    )
  }
}
