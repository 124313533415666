import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {adjustZoom, getBaseZoom, getOffset, getTransform} from 'utils/zoom'

import {setCanvasHover, setSelection} from '../../../ducks/editor/selection'
import {beginDrag, drag, endDrag} from '../../../ducks/editor/positioning'
import {getActiveStack, getDividers, updateDivider} from '../../../ducks/editor/stack'

import {
    getCurrentAppId,
    getPlatform,
    getSelectedParent,
    getYOffset,
    getZoom,
    resetZoom,
    setZoom,
} from '../../../ducks/editor/objects'

import { getApp } from "ducks/apps/selectors";

import Panel from "./Panel";
import {getEditing, getImage, getPath, resetTrace} from "../../../ducks/trace";
import PathEditor from "./PathEditor";

export const DEFAULT_SIZE_TRACE_CANVAS = {
  width: 1000,
    height: 1000
}

class Canvas extends Component {
    static contextTypes = {
        editable: PropTypes.bool,
    }

    constructor(props) {
        super(props)
        this.canvasRef = React.createRef()

        let { width ,height} = DEFAULT_SIZE_TRACE_CANVAS
        const activeZoom = adjustZoom({
                x: 0,
                y:0,
                width: width,
                height: height
            }
        )

        this.state = {
            activeZoom,
        }

    }


    setZoom = (scale, offset) => {
        window.clearTimeout(this._zoomTimeout)
        this._zoomTimeout = window.setTimeout(this.saveZoom, 100)

        let activeZoom = {scale, offset}
        this.setState({activeZoom})
    }

    saveZoom = () => {
        let {setZoom} = this.props
        let {activeZoom} = this.state
        let outerActiveZoom = activeZoom

        if (!activeZoom) {
            return
        }

        setZoom(activeZoom.scale, activeZoom.offset)

        window.setTimeout(() => {
            let {activeZoom} = this.state

            if (activeZoom !== outerActiveZoom) {
                return
            }

            this.setState({activeZoom: null})
        }, 10)
    }

    getZoom = () => {
        let {zoom} = this.props
        let {activeZoom} = this.state

        return activeZoom || zoom
    }

    componentDidMount() {
        document.addEventListener('gesturestart', this.handleGestureStart)
        document.addEventListener('gesturechange', this.handleWheel)
        this.canvasRef.current.addEventListener('wheel', this.handleWheel)



    }
    componentWillUnmount() {
        this.props.resetTrace()
        document.removeEventListener('gesturestart', this.handleGestureStart)
        document.removeEventListener('gesturechange', this.handleWheel)
        this.canvasRef.current.removeEventListener('wheel', this.handleWheel)
    }
    handleWheel = e => {
        e.stopPropagation()
        e.preventDefault()

        let { objects } = this.props
        let zoom = this.getZoom()



        let prevScale = zoom.scale
        let prevOffset = zoom.offset

        let [offsetX, offsetY] = prevOffset

        // ZOOM
        if (e.ctrlKey || e.scale) {
            let scale

            if (e.scale) {
                scale = prevScale * (e.scale / this._prevEventScale)
                this._prevEventScale = e.scale
            } else {
                scale = prevScale * (1 + 0.01 * -e.deltaY)
            }

            let center = [e.clientX, e.clientY]

            if (scale > 64) {
                scale = 64
            } else if (scale < 1 / 16) {
                scale = 1 / 16
            }

            let offset = getOffset(scale, center, prevScale, prevOffset)

            return this.setZoom(scale, offset)
        }

        // PAN
        let newOffset = [offsetX - e.deltaX, offsetY - e.deltaY]

        this.setZoom(zoom.scale, newOffset)


    }

    handleGestureStart = e => {
        e.preventDefault()

        this._prevEventScale = 1
    }


    render() {
        let {
            image,
            path,
            editing
        } = this.props
        let { width ,height } = DEFAULT_SIZE_TRACE_CANVAS


        if (image) {
            width=image.width
            height=image.height
        }

        /*const zoom = adjustZoom({
            x: 0,
            y:0,
            width: width,
            height: height
            }
        )*/

        let {activeZoom} = this.state

        let zoom = this.getZoom()

        let transform = getTransform(zoom, true, 0, 0)
        let styles = {transform}
        let baseZoom = getBaseZoom()

        const {bbox} = path || {}

        return (
            <div className={'trace-view-view'} ref={this.canvasRef}>
                <svg
                    className="trace-view-canvas-objects"
                    width={Math.ceil(width * baseZoom.scale)}
                    height={Math.ceil(height * baseZoom.scale)}
                    style={styles}
                >
                    <g>

                        {image && <image
                            href={image.src}
                            x={'0'}
                            y={'0'}
                            width={width* baseZoom.scale}
                            height={height* baseZoom.scale}
                            preserveAspectRatio={`xMidYMid meet`}
                        />}
                        {image && path && <>
                             <rect
                                 {...bbox}
                                className="trace-selection-box"
                                 strokeWidth = {1} fill={"transparent"}
                                 stroke={"#3e87f8"}

                             />

                             </>
                          }

                    </g>

                </svg>
                <svg className="bounding-boxes"
                     width="100%"
                     height="100%"
                     >
                    {(image) && <PathEditor canEdit={editing} zoom={zoom} />}
                </svg>
                <Panel    width={width* baseZoom.scale}
                          height={height* baseZoom.scale}
                         zoom={baseZoom}/>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    const selectedParent = getSelectedParent(state)
    const app = getApp(state, getCurrentAppId(state))
    const image = getImage(state)
    const path = getPath(state)
    const editing = getEditing(state)

    return {
        selectedParent,
        app,
        dividers: getDividers(state),
        yOffset: getYOffset(state),
        platform: getPlatform(state),
        activeStack: getActiveStack(state),
        zoom: getZoom(state),
        image,
        path,
        editing

    }
}

export default connect(mapStateToProps, {
    setCanvasHover,
    setSelection,
    updateDivider,
    beginDrag,
    setZoom,
    resetZoom,
    drag,
    endDrag,
    resetTrace
})(Canvas)
