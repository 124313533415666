import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

// utils
// ducks
import { getApp } from "ducks/apps/selectors";
import {getCurrentUser} from 'ducks/users'

// shared components
import Button from 'components/Shared/Button'

import './CheckoutButton.scss'
import {CHECKOUT_MODAL, showModal} from "../../../ducks/editor/modals";

class PreviewButton extends Component {
    handleClick = e => {
        e.preventDefault()
        const {showModal} = this.props

        return showModal(CHECKOUT_MODAL, {zoom: true})
    }

    render() {
        const {app} = this.props
        if (app?.status !== "draft") return null
        return (
            <div className="editor-checkout-button-wrapper">
                <Button onClick={this.handleClick} className="editor-checkout-button">
                    Оформить
                </Button>
            </div>
        )
    }
}

const mapStateToProps = (state, {match}) => ({
    app: getApp(state, match.params.appId),
    user: getCurrentUser(state),
})

export default withRouter(connect(mapStateToProps, {showModal})(PreviewButton))
