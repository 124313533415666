import {COMPONENT_INSTANCE, LABEL, ROW} from 'common/constants'

import {deepMap, getBoundingBox, traverse} from 'common/utils'

import {Yoga} from './yoga'
import {updateObjectWidth} from './text'

const numericProperties = [
  'width',
  'height',
  'marginLeft',
  'marginTop',
  'marginRight',
  'marginBottom',
  'paddingLeft',
  'paddingTop',
  'paddingRight',
  'paddingBottom',
]

const getBottomPadding = component => {
  let wrapper = component.layout.body[0] || {}

  let objects = wrapper.children || []
  let bbox = getBoundingBox(objects.map(obj => obj.attributes))

  if (!bbox) {
    bbox = { y: 0, height: 0 }
  }

  return component.height - (bbox.y + bbox.height)
}

// Implements yoga layout with flexbox
// Takes COMPONENT (with children, layout) and WIDTH, HEIGHT
export const computeLayout = (component, width, height, otherComponents) => {
  // We'll ignore height for now
  // We need to do 2 passes with yoga
  // First we need to build up the Yoga nodes
  if (!Yoga.Node) {
    throw new Error('YOGA NOT YET LOADED')
  }

  if (!component || !component.layout || !component.layout.body) {
    return
  }

  let wrapper = component.layout.body[0]

  if (!wrapper) {
    return
  }

  let objects = wrapper.children

  let baseNode = new Yoga.Node()
  baseNode.width = pointValue(width)
  baseNode.justifyContent = Yoga.Constants.justify['flex-start']

  let nodes = {}

  buildGraph(baseNode, objects, nodes, otherComponents)
  setProperties(baseNode, wrapper)

  // First pass
  baseNode.calculateLayout()

  // Second pass
  // If has been resized, calculate text widths
  if (component.width !== width) {
    setTextHeights(nodes, objects, otherComponents)
    baseNode.calculateLayout()
  }

  let bottomPadding = getBottomPadding(component)

  // Return
  let result = {}

  for (let objectId of Object.keys(nodes)) {
    result[objectId] = nodes[objectId].getComputedLayout()
  }

  let newHeight = 0

  for (let child of objects) {
    let layout = result[child.id]

    if (layout && layout.top + layout.height > newHeight) {
      newHeight = layout.top + layout.height
    }
  }

  return [result, newHeight + bottomPadding]
}

const buildGraph = (baseNode, objects, nodeMap, otherComponents) => {
  for (let i = 0; i < objects.length; i += 1) {
    let obj = objects[i]
    let node = new Yoga.Node()

    setProperties(
      node,
      obj,
      baseNode.flexDirection === Yoga.Constants.flexDirection.row
    )

    baseNode.insertChild(node, i)
    nodeMap[obj.id] = node

    if (obj.children && obj.children.length > 0) {
      buildGraph(node, obj.children, nodeMap, otherComponents)
    } else if (obj.type === COMPONENT_INSTANCE) {
      let componentObj = otherComponents[obj.attributes.componentId]
      let wrapper = componentObj.layout.body[0]

      wrapper = {
        ...wrapper,
        layout: {
          ...wrapper.layout,
          paddingBottom: getBottomPadding(componentObj),
        },
      }

      buildGraph(node, [wrapper], nodeMap, otherComponents)
    }
  }
}

const setProperties = (node, object, setFlex = false) => {
  if (object.type === ROW) {
    node.flexDirection = Yoga.Constants.flexDirection.row
  } else {
    node.flexDirection = Yoga.Constants.flexDirection.column
  }

  if (setFlex && object.layout.flex) {
    node.flex = object.layout.flex
  }

  // Do this first in case it's overridden by layout
  if (
    object.attributes &&
    object.attributes.height &&
    object.type !== COMPONENT_INSTANCE &&
    (!object.children || object.children.length === 0)
  ) {
    node.height = pointValue(object.attributes.height)
  }

  numericProperties.forEach(prop => {
    if (object.layout[prop]) {
      node[prop] = pointValue(object.layout[prop])
    }
  })
}

const setTextHeights = (nodeMap, objects, otherComponents) => {
  objects = expand(objects, otherComponents)

  traverse(objects, obj => {
    if (obj.type === LABEL) {
      let layout = nodeMap[obj.id].getComputedLayout()

      let layoutObj = {
        ...obj,
        ...obj.attributes,
        width: layout.width,
        height: layout.height,
      }

      let computed = updateObjectWidth(layoutObj)

      if (nodeMap[obj.id]) {
        nodeMap[obj.id].height = pointValue(computed.height)
      }
    }
  })
}

const expand = (objects, components) => {
  return deepMap(objects, obj => {
    if (obj.type === COMPONENT_INSTANCE) {
      let component = components[obj.attributes.componentId]

      if (!component) {
        return obj
      }

      obj = {
        ...obj,
        children: component.layout && component.layout.body,
      }
    }

    return obj
  })
}

const pointValue = value => ({
  value,
  unit: Yoga.Constants.unit.point,
})

// Visualization Functions
const visualize = node => {
  let children = visualizeChildren(node)

  let result = {}

  numericProperties.forEach(prop => {
    let hasVal = node[prop] && node[prop].unit === Yoga.Constants.unit.point

    if (hasVal) {
      result[prop] = node[prop].value
    }
  })

  if (children && children.length > 0) {
    result.children = children
  }

  return result
}

const visualizeChildren = node => {
  let result = []

  for (let i = 0; node.getChild(i) !== null; i += 1) {
    result.push(visualize(node.getChild(i)))
  }

  return result
}

export const visualizeOutput = node => {
  let childResults = []

  for (let i = 0; node.getChild(i); i += 1) {
    childResults.push(visualizeOutput(node.getChild(i)))
  }

  return {
    ...node.getComputedLayout(),
    children: childResults,
  }
}
