const SET_SELECTION = Symbol('SET_SELECTION')
const SET_SELECTION_DIVIDER = Symbol('SET_SELECTION_DIVIDER')
const SET_SELECTION_STACK = Symbol('SET_SELECTION_STACK')

const SET_EXPAND_SELECTION = Symbol('SET_EXPAND_SELECTION')
const SET_DIVIDER_HOVER = Symbol('SET_DIVIDER_HOVER')
const UPDATE_DIVIDER = Symbol('UPDATE_DIVIDER')
const INITIAL_STATE = {

  dividers: [
    {id: '574bd3258f60449dacc813d13bf9da71', position:100},

  ],
  activeStack: 0
}

export default function stackReducer(state = INITIAL_STATE, action) {

  if (action.type === SET_SELECTION_DIVIDER) {
    let {object} = action

    return {
      ...state,
      selectedDivider: object && object.id,
    }
  }
  if (action.type === SET_SELECTION_STACK) {
    let {object} = action

    return {
      ...state,
      activeStack: object,
    }
  }
  if (action.type === UPDATE_DIVIDER) {


    let {objectId, position} = action
    const array = [...state.dividers]

    const index = array.findIndex(el => el.id == objectId)

    if (index < 0) {
      return {
        ...state
      }
    }

    const newArray = array.slice()


    newArray[index] = {
      ...newArray[index],
      position
    }
    return {
      ...state,
      dividers:
        [...newArray]

    }
  }
  if (action.type === SET_DIVIDER_HOVER) {
    let {object} = action

    return {
      ...state,
      hoverDivider: object && object.id,
    }
  }


  return state
}

export const setSelectionDivider = (object) => {
  return {
    type: SET_SELECTION_DIVIDER,
    object,
  }
}

export const setSelectionStack = (object) => {
  return {
    type: SET_SELECTION_STACK,
    object,
  }
}
export const resetSelection = () => {
  return {
    type: SET_SELECTION,
    object: null,
  }
}

export const updateDivider = (objectId, position) => ({
  type: UPDATE_DIVIDER,
  objectId,
  position
})

export const setDividerHover = object => ({
  type: SET_DIVIDER_HOVER,
  object,
})


// SELECTORS

export const getSelection = ({editor}) => editor.objects.present.selection

export const getDividers = ({editor}) => editor.stack.dividers
export const getStack= ({editor}) => editor.stack.list
export const getActiveStack= ({editor}) => editor.stack.activeStack
export const getHoverSelection = ({editor}) => {
  return editor.objects.present.hoverSelection
}
