import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'

import {deleteApp, requestApp, updateApp} from 'ducks/apps/actions'
import { getApp } from "ducks/apps/selectors";



import Page from 'components/Shared/Page'
import Icon from 'components/Shared/Icon'
import Item from 'components/Shared/Panel/Item'

import Form from './Form'

import 'styles/Settings.css'

class Settings extends Component {
  handleDelete = () => {
    let {match, history, app, deleteApp} = this.props
    let {appId} = match.params

    if (!app) {
      return
    }

    if (!window.confirm(`Are you sure you want to delete ${app.name}`)) {
      return
    }

    deleteApp(appId)
    history.push('/')
  }

  handleSubmit = data => {
    let {match, updateApp} = this.props
    let {appId} = match.params

    updateApp(appId, data)
  }

  getInitialData = () => {
    let {app} = this.props

    let {
      name,
      icon,
      mixpanelToken,
      OrganizationId,
      description,
      showBranding,
    } = app

    // this should be enabled by default,
    // especially if a user isn't a paying customer yet
    if (showBranding === undefined) showBranding = true

    return {
      name,
      icon,
      mixpanelToken,
      OrganizationId,
      description,
      showBranding,
    }
  }

  componentDidMount() {
    const {match} = this.props
    const {appId} = match.params

  }

  render() {
    const {app, match, location, history} = this.props
    const {appId} = match.params

    if (!app) {
      return null
    }

    const GROUP = 'settings'

    return (
      <div className="app-settings">
        <Item title="Настройки" group={GROUP} fluid defaultExpanded>
          <Form
            appId={appId}
            onSubmit={this.handleSubmit}
            initialValues={this.getInitialData()}
          />
        </Item>
        <Item title="Отображение" group={GROUP} fluid>

        </Item>


      </div>
    )
  }
}

const mapStateToProps = (state, {match}) => ({
  app: getApp(state, match.params.appId),
})

export const SettingsSub = withRouter(
  connect(mapStateToProps, {updateApp, deleteApp})(Settings)
)

class SettingsPage extends Component {
  componentDidMount() {
    let {requestApp, match} = this.props
    let {appId} = match.params

    requestApp(appId)
  }

  render() {
    return (
      <Page form title="Settings">
        <h1>Настройки</h1>
        <SettingsSub/>
      </Page>
    )
  }
}

export default connect(null, {requestApp})(SettingsPage)

export class SettingsPanel extends Component {
  render() {
    return (
      <div className="app-settings-panel">
        <h2 className="left-panel-title">
          <Icon type="settings"/>
          {' Настройки'}
        </h2>
        <SettingsSub/>
      </div>
    )
  }
}
