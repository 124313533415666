import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form'

import SettingsFormField, {SettingsFormSubmit,} from 'components/Shared/Forms/SettingsFormField'

import ToggleField from 'components/Shared/Forms/ToggleField'

import Button from 'components/Shared/Button'
import {validate as signupValidate} from 'components/Auth/SignupForm'

export const FORM_NAME = 'accountForm'

const validate = data => {
  let errors = signupValidate(data)

  if (!data.password) {
    delete errors.password
  }

  if (data.password && !data.oldPassword) {
    errors.oldPassword = 'Required when changing password'
  }

  return errors
}

class AccountForm extends Component {
  state = {
    passwordVisible: false,
  }

  showPassword = () => {
    this.setState({ passwordVisible: true })
  }

  render() {
    let { handleSubmit, valid, dirty, error, submitting } = this.props
    let { passwordVisible } = this.state

    return (
      <form onSubmit={handleSubmit}>
        {error && !submitting ? (
          <div className="settings-alert">{error}</div>
        ) : null}
        <div className="settings-form-fields">
          <Field
            component={SettingsFormField}
            name="email"
            label="Email"
            placeholder="me@example.com"
            type="email"
          />
          <Field
            component={SettingsFormField}
            name="name"
            label="Name"
            placeholder="Jane Doe"
            type="text"
          />
          {passwordVisible ? (
            <React.Fragment>
              <Field
                skipTouch
                component={SettingsFormField}
                name="oldPassword"
                label="Old Password"
                placeholder="••••••••"
                type="password"
              />
              <Field
                component={SettingsFormField}
                name="password"
                label="New Password"
                placeholder="••••••••"
                type="password"
              />
            </React.Fragment>
          ) : (
            <div className="settings-form-row">
              <label>Password</label>
              <div className="settings-form-button-wrapper">
                <Button large square block gray onClick={this.showPassword}>
                  Change Password
                </Button>
              </div>
            </div>
          )}
          <Field
            largeHelpText
            component={ToggleField}
            name="developer"
            label="Developer Mode"
            inputValue="Enable Developer Mode"
            helpText={
              <span>
                {'Learn more at '}
                <a
                  href="https://developers.adalo.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  developers.adalo.com
                </a>
              </span>
            }
          />
        </div>
        <SettingsFormSubmit
          enabled={valid && dirty && !submitting}
          title="Update Account"
        />
      </form>
    )
  }
}

export default reduxForm({
  form: FORM_NAME,
  validate,
  enableReinitialize: true,
})(AccountForm)
