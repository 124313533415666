
// example orintation data

// {
//     "id": "60b99ba5f1a6cf002e4383b500",
//     "name": "Flat",
//     "component_id": "60b99ba5f1a6cf002e4383b400",

//     "width": 1049.3793103448277,
//     "depth": 143.6220472440945,
//     "height": 1041.0460415763987,
//     "iconUrl": "data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSIxMDUwLjM4MTE5IiBoZWlnaHQ9IjEwNDIuMDYwMDYiIHZpZXdCb3g9Ii0wLjUwMDUzLC0wLjUxMzI2LDEwNTAuMzgxMTksMTA0Mi4wNjAwNiI+PGcgZmlsbD0iI2UwZTBlMCIgZmlsbC1ydWxlPSJub256ZXJvIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iMSIgc3Ryb2tlLWxpbmVjYXA9ImJ1dHQiIHN0cm9rZS1saW5lam9pbj0ibWl0ZXIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWRhc2hhcnJheT0iIiBzdHJva2UtZGFzaG9mZnNldD0iMCIgZm9udC1mYW1pbHk9Im5vbmUiIGZvbnQtd2VpZ2h0PSJub25lIiBmb250LXNpemU9Im5vbmUiIHRleHQtYW5jaG9yPSJub25lIiBzdHlsZT0ibWl4LWJsZW5kLW1vZGU6IG5vcm1hbCI+PGc+PHBhdGggZD0iTTI2LjYxNDIzLDMwMy40NTE2NWwtMTAuNDgxNTQsNy4xODEyNmwtNS40NDc0LDYuMjg1NDZsLTQuMTkwMzEsOC4zODA2MmwtMC43OTE5MywxNi4xNzI5OWwtNS43MDMwNSwzMzQuNTc4OWwxLjg4NTY0LDE1LjA2MjE3bDYuMjg1NDYsMTEuNzMyODdsMTUuMDg1MTIsMTIuNTcwOTNsMjIuMzY4MTgsMTAuMDYwNDZsNjQyLjU0MzU2LDI3My43NDYzN2wxLjkwMTAyLDQxLjgyMjM2bDE1OS4wMzQ0NywtMC4yNTI5NGwwLjY1MDkxLC03Ny42ODg3NGwxNzYuNzk0NTMsLTM0NC4wODM5OGwxOS41MjAwOCwtNTEuNjA2NTlsMy4zMTAzNCwtNjEuMjQxMzhsLTYuNjIwNjksLTY2LjIwNjlsLTE5Ljg2MjA3LC00Ni4zNDQ4M2wtMTk3LjM1MDk3LC0zNzIuNjE5MjdsLTExLjc5MDI3LC0xMC44ODMzM2wtMTIuOTYyMDUsLTUuMjIxOTRsLTE2Ljg2NDUzLC00Ljg5NjE1bC02LjQxMywxLjE5MDA0bC0yMi41MjcwNSw5LjA2Nzg1eiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+",
// }


export const initializeStateOrintation = () => ({
    id: '',
    component_id: '',
    name: '',
    depth: 0,
    height: 0,
    iconUrl: '',
})