import {traverse, uniqueElements} from 'common/utils'

import {
    backgroundStyle,
    bindingTypes,
    borderPosition,
    borderStyle,
    COMPONENT,
    COMPONENT_INSTANCE,
    dataTypes,
    DATE_PICKER,
    ELLIPSE,
    FILE_UPLOAD,
    FORM,
    GROUP,
    IMAGE,
    IMAGE_UPLOAD,
    imageResize,
    INPUT,
    inputTypes,
    LABEL,
    LIBRARY_COMPONENT,
    LINE,
    LIST,
    positioning,
    RECTANGLE,
    SECTION,
    SELECT,
    SHAPE,
    SHAPE_COMPONENT,
    statusBarStyles,
    WEB_VIEW,
} from 'common/constants'
import {convertUnit} from "./unit";


const positionFeatures = {
  x: true,
  y: true,
  width: true,
  height: true,
  angle: true
}

const genericFeatures = {
  ...positionFeatures,
  name: true,
  opacity: true,
  positioning: true,
  hidden: true,
}

const textFeatures = {
  fontSize: true,
  fontFamily: true,
  fontWeight: true, // 100 - 900
  fontStyle: true, // 'normal' or 'italic'
  color: true,
}

const shapeFeatures = {
  backgroundStyle: true,
  backgroundColor: true,
  borderColor: true,
  borderStyle: true,
  borderWidth: true,
}

const sectionFeatures = {
  ...shapeFeatures,
  borderRadius: true,
}

export const features = {
  [LABEL]: {
    ...genericFeatures,
    ...textFeatures,
    textAlignment: true,
    autoWidth: true,
    text: true,
    multiline: true,
    selectable: true,
  },
  [SECTION]: {
    ...genericFeatures,
    ...sectionFeatures,
    borderPosition: true,
    shadow: true,
  },
  [RECTANGLE]: {
    ...genericFeatures,
    ...sectionFeatures,
    borderPosition: true,
    shadow: true,
  },

  [LINE]: {
    ...genericFeatures,
    ...shapeFeatures,
    borderPosition: true,
    shadow: true,
  },
  [ELLIPSE]: {
    ...genericFeatures,
    ...shapeFeatures,
    points: true,
    shadow: true,
  },
  [SHAPE]: {
    ...genericFeatures,
    ...shapeFeatures,
    points: true,
    isClosed: true,
  },
  [IMAGE]: {
    ...genericFeatures,
    ...sectionFeatures,
    borderRadius: true,
    imageResize: true,
    filename1x: true,
  },
  [GROUP]: {
    ...genericFeatures,
    children: true,
  },
  [LIST]: {
    ...genericFeatures,
    children: true,
    rowMargin: true,
    listType: true,
    columnCount: true,
    masonry: true,
  },
  [INPUT]: {
    ...genericFeatures,
    ...sectionFeatures,
    ...textFeatures,
    inputType: true,
    autoFocus: true,
    placeholder: true,
    placeholderColor: true,
    defaultValue: true,
    multiline: true,
    padding: true,
    shadow: true,
    maxLength: true,
  },
  [COMPONENT]: {
    ...positionFeatures,
    backgroundColor: true,
    statusBarStyle: true,
    reverseScroll: true,
  },
  [IMAGE_UPLOAD]: {
    ...genericFeatures,
    borderRadius: true,
    // ...sectionFeatures,
    // accentColor: true,
    // imageUploadType: true,
  },
  [FILE_UPLOAD]: {
    ...genericFeatures,
  },
  [DATE_PICKER]: {
    ...genericFeatures,
    ...sectionFeatures,
    ...textFeatures,
    padding: true,
    shadow: true,
  },
  [SELECT]: {
    ...genericFeatures,
    ...sectionFeatures,
    ...textFeatures,
    padding: true,
    shadow: true,
    placeholder: true,
  },
  [FORM]: {
    ...genericFeatures,

    submitButton: true,
    fields: true,
    fieldStyles: true,
  },
  [WEB_VIEW]: {
    ...genericFeatures,
    uri: true,
  },
  [COMPONENT_INSTANCE]: {
    ...genericFeatures,
  },
  [LIBRARY_COMPONENT]: {
    ...genericFeatures,
  },
  [SHAPE_COMPONENT]: {
    ...genericFeatures,
    orientation: true,
  },
}

const genericDefaults = {
  name: null,
  opacity: 1,
  x: 0,
  y: 0,
  angle: 0,
  width: 0,
  height: 0,
  positioning: positioning.DEFAULT,
  hidden: false,
}

const textDefaults = {
  fontSize: 16,
  fontWeight: '400',
  fontStyle: 'normal',
  fontFamily: '@body',
  color: '#000',
}

const shapeDefaults = {
  backgroundStyle: backgroundStyle.COLOR,
  backgroundColor: '#ddd',
  borderStyle: borderStyle.SOLID,
  borderWidth: 1,
  borderColor: '#999',
}

const shadowDefaults = {
  shadow: {
    enabled: false,
    color: 'rgba(0, 0, 0, 0.2)',
    x: 0,
    y: 2,
    size: 4,
  },
}

const sectionDefaults = {
  ...shapeDefaults,
  borderRadius: 0,
}

export const defaults = {
  [LABEL]: {
    ...genericDefaults,
    ...textDefaults,
    color: '@text',
    textAlignment: 'left',
    text: 'Enter Text',
    width: 343,
    height: 19,
    fontSize: 16,
    fontWeight: 500,
    autoWidth: false,
    multiline: true,
    selectable: false,
  },
  [SECTION]: {
    ...genericDefaults,
    ...sectionDefaults,
    ...shadowDefaults,
    borderPosition: borderPosition.CENTER,
    backgroundColor: '#e0e0e0',
    borderStyle: borderStyle.NONE,
    borderWidth: 1,
    borderColor: '#BDBDBD',
    borderRadius: 0,
    width: 343,
    height: 100,
  },
  [RECTANGLE]: {
    ...genericDefaults,
    ...sectionDefaults,
    ...shadowDefaults,
    borderPosition: borderPosition.CENTER,
    backgroundColor: '#e0e0e0',
    borderStyle: borderStyle.NONE,
    borderWidth: 1,
    borderColor: '#BDBDBD',
    borderRadius: 0,
    width: 343,
    height: 100,
  },
  [LINE]: {
    ...genericDefaults,
    ...sectionDefaults,
    borderPosition: borderPosition.CENTER,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    borderStyle: borderStyle.SOLID,
    borderWidth: 1,
    borderColor: '#000',
    width: 343,
    height: 1,
  },
  [ELLIPSE]: {
    ...genericDefaults,
    ...shapeDefaults,
    ...shadowDefaults,
    width: convertUnit(100),
    height: convertUnit(100),
    points: [],
    isClosed: true,
    borderWidth: 1,
    backgroundColor: '#e0e0e0',
  },
  [SHAPE]: {
    ...genericDefaults,
    ...shapeDefaults,
    points: [],
    isClosed: false,
    borderWidth: 1,
    width: 1,
    height: 1,
  },
  [IMAGE]: {
    ...genericDefaults,
    ...sectionDefaults,
    backgroundStyle: backgroundStyle.NONE,
    borderStyle: borderStyle.NONE,
    borderRadius: 0,
    imageResize: imageResize.COVER,
    width: 375,
    height: 210,
  },
  [GROUP]: {
    ...genericDefaults,
    children: [],
  },
  [LIST]: {
    ...genericDefaults,
    width: 375,
    height: 80 * 3 + 8 * 3,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    children: [],
    rowMargin: 8,
    columnCount: 1,
    masonry: true,
  },
  [INPUT]: {
    ...genericDefaults,
    ...textDefaults,
    ...sectionDefaults,
    ...shadowDefaults,
    inputType: inputTypes.DEFAULT,
    autoFocus: false,
    placeholder: 'Enter Text',
    placeholderColor: '#a9a9a9',
    defaultValue: '',
    backgroundColor: '#fff',
    padding: 8,
    width: 343,
    height: 40,
    borderStyle: borderStyle.SOLID,
    borderWidth: 1,
    borderColor: '#BDBDBD',
    borderRadius: 4,
    multiline: false,
    maxLength: null,
  },
  [COMPONENT]: {
    ...genericDefaults,
    backgroundColor: '#fff',
    statusBarStyle: statusBarStyles.DARK_CONTENT,
    children: [],
    width: 375,
    height: 667,
    reverseScroll: false,
    x: undefined,
    y: undefined,
  },
  [IMAGE_UPLOAD]: {
    ...genericDefaults,
    width: 343,
    height: 210,
    backgroundColor: '#fff',
    borderStyle: borderStyle.SOLID,
    borderWidth: 1,
    borderColor: '#BDBDBD',
    borderRadius: 0,
  },
  [FILE_UPLOAD]: {
    ...genericDefaults,
    width: 343,
    height: 210,
    backgroundColor: '#fff',
    borderStyle: borderStyle.SOLID,
    borderWidth: 1,
    borderColor: '#BDBDBD',
  },
  [DATE_PICKER]: {
    ...genericDefaults,
    ...textDefaults,
    ...sectionDefaults,
    ...shadowDefaults,
    padding: 10,
    width: 343,
    height: 40,
    backgroundColor: '#fff',
    borderStyle: borderStyle.SOLID,
    borderWidth: 1,
    borderColor: '#BDBDBD',
    borderRadius: 4,
  },
  [SELECT]: {
    ...genericDefaults,
    ...textDefaults,
    ...sectionDefaults,
    ...shadowDefaults,
    padding: 10,
    placeholder: 'Select...',
    width: 343,
    height: 40,
    backgroundColor: '#fff',
    borderStyle: borderStyle.SOLID,
    borderWidth: 1,
    borderColor: '#BDBDBD',
    borderRadius: 4,
  },
  [FORM]: {
    ...genericDefaults,
    width: 343,
    height: 338,
    submitButton: {
      backgroundColor: '@secondary',
      fontFamily: '@body',
      fontWeight: '500',
    },
    fieldStyles: {
      inputs: {
        color: '@text',
        accentColor: '@secondary',
        errorColor: 'red',
        fontFamily: '@body',
      },
      labels: {
        color: '@text',
        fontFamily: '@body',
      },
    },
  },
  [WEB_VIEW]: {
    ...genericDefaults,
    width: 375,
    height: 591,
  },
  [COMPONENT_INSTANCE]: {
    ...genericDefaults,
  },
  [LIBRARY_COMPONENT]: {
    ...genericDefaults,
  },
}

export const getNewScreenSize = platform => {
  if (platform === 'web') {
    return {
      width: 1200,
      height: 800,
    }
  }

  return {
    width: 375,
    height: 667,
  }
}

const baseBindingTypes = [bindingTypes.VISIBILITY]

export const allowedBindingTypes = {
  [LABEL]: [...baseBindingTypes, bindingTypes.SET_TEXT],
  [SECTION]: [...baseBindingTypes],
  [LINE]: [...baseBindingTypes],
  [ELLIPSE]: [...baseBindingTypes],
  [RECTANGLE]: [...baseBindingTypes],
  [SHAPE]: [...baseBindingTypes],
  [IMAGE]: [...baseBindingTypes, bindingTypes.SET_IMAGE],
  [GROUP]: [...baseBindingTypes],
  [LIST]: [...baseBindingTypes, bindingTypes.LIST],
  [INPUT]: [...baseBindingTypes, bindingTypes.SET_TEXT],
  [FORM]: [...baseBindingTypes],
  [LIBRARY_COMPONENT]: [...baseBindingTypes],
  [IMAGE_UPLOAD]: [...baseBindingTypes, bindingTypes.SET_IMAGE],
  [IMAGE_UPLOAD]: [...baseBindingTypes],
  [DATE_PICKER]: [...baseBindingTypes, bindingTypes.SET_DATE],
  [SELECT]: [...baseBindingTypes, bindingTypes.LIST],
  [WEB_VIEW]: [...baseBindingTypes, bindingTypes.SET_URI],
}

export const bindingDataTypes = {
  [bindingTypes.SET_TEXT]: [dataTypes.TEXT, dataTypes.DATE, dataTypes.NUMBER],
  [bindingTypes.LIST]: [dataTypes.LIST],
  [bindingTypes.SET_IMAGE]: [dataTypes.IMAGE],
  [bindingTypes.VISIBILITY]: [
    dataTypes.TEXT,
    dataTypes.DATE,
    dataTypes.DATE_ONLY,
    dataTypes.NUMBER,
    dataTypes.IMAGE,
    dataTypes.BOOLEAN,
    dataTypes.LIST,
  ],
  [bindingTypes.SET_DATE]: [dataTypes.DATE],
  [bindingTypes.SET_URI]: [dataTypes.TEXT],
  [bindingTypes.LIBRARY_PROP]: [
    dataTypes.TEXT,
    dataTypes.NUMBER,
    dataTypes.DATE,
    dataTypes.DATE_ONLY,
    dataTypes.IMAGE,
    dataTypes.BOOLEAN,
    dataTypes.OBJECT,
    dataTypes.LIST,
  ],
}

export const getDataTypes = (bindingTypes = []) => {
  let types = []

  bindingTypes.forEach(bindingType => {
    types = types.concat(bindingDataTypes[bindingType] || [])
  })

  return uniqueElements(types)
}

export const dataTypeAllowed = (bindingTypes, dataType) => {
  let dataTypes = getDataTypes(bindingTypes)

  return dataTypes.indexOf(dataType) !== -1
}

export const bestBindingType = (bindingTypes, dataType, allowNull = false) => {
  bindingTypes = sortBindingTypes(bindingTypes)

  for (let bindingType of bindingTypes) {
    if (bindingDataTypes[bindingType].indexOf(dataType) !== -1) {
      return bindingType
    }
  }

  if (!allowNull) {
    throw new Error(
      `Cannot get binding type for data type '${dataType}' ` +
        `(${JSON.stringify(bindingTypes)})`
    )
  }

  return null
}

export const sortBindingTypes = types => {
  const sortedTypes = [
    bindingTypes.LIBRARY_PROP,
    bindingTypes.SET_DATE,
    bindingTypes.SET_DATE,
    bindingTypes.SET_TEXT,
    bindingTypes.SET_IMAGE,
    bindingTypes.LIST,
    bindingTypes.SET_URI,
    bindingTypes.VISIBILITY,
    bindingTypes.SET_PROPERTY,
  ]

  return sortedTypes.filter(type => {
    return types.indexOf(type) !== -1
  })
}

export const getAllKeys = objects => {
  let keys = {}

  for (let obj of objects) {
    for (let key of Object.keys(obj)) {
      keys[key] = true
    }
  }

  return Object.keys(keys)
}

export const commonValues = objects => {
  let keys = getAllKeys(objects)
  let values = { ...objects[0] }

  for (let obj of objects) {
    for (let key of keys) {
      if (!(key in obj) || obj[key] !== values[key]) {
        delete values[key]
      }
    }
  }

  return values
}

export const omitKeys = (object, toOmit) => {
  if (!toOmit || toOmit.length === 0) {
    return object
  }

  const omitSet = new Set(toOmit)
  const values = { ...object }

  for (let key of Object.keys(object)) {
    if (omitSet.has(key)) {
      delete values[key]
    }
  }

  return values
}

export const convertType = (object, newType) => {
  let newObject = {}

  for (let key in object) {
    if (features[newType][key]) {
      newObject[key] = object[key]
    }
  }

  return {
    ...defaults[newType],
    ...newObject,
    id: object.id,
    type: newType,
  }
}


export const indexByTypeV2 = objects => {

  let result = {}

  const traverse = (objects, func, traverseChildren, parentObj) => {

    for (let i = 0; i < objects.length; i += 1) {
        const obj = objects[i];
        func(obj, parentObj, objects[i - 1], objects[i + 1]);

        if (obj && obj.children && (!traverseChildren || traverseChildren(obj))) {
            if (!Array.isArray(obj.children)) {
                throw new Error('obj.children is not an Array: ' + JSON.stringify(obj));
            }

            traverse(obj.children, func, traverseChildren, obj);
        }
    }
  }; // Depth-first tree map

  traverse(objects, obj => {
    let { id, type } = obj

    if (!result[type]) {
      result[type] = []
    }

    result[type].push(id)
  })

  return result
}

export const indexByType = objects => {
  let result = {}

  traverse(objects, obj => {
    let { id, type } = obj

    if (!result[type]) {
      result[type] = []
    }

    result[type].push(id)
  })

  return result
}

export const getInputDataType = inputObject => {
  switch (inputObject.type) {
    case DATE_PICKER:
      return dataTypes.DATE
    case IMAGE_UPLOAD:
      return dataTypes.IMAGE
    case FILE_UPLOAD:
      return dataTypes.FILE
    default:
      return dataTypes.TEXT
  }
}

// POLYFILL
if (!Object.values) Object.values = obj => Object.keys(obj).map(e => obj[e])

export const deepGet = (obj, key = []) => {
  if (key.length === 0) {
    return obj
  }

  if (!obj) {
    return undefined
  }

  return deepGet(obj[key[0]], key.slice(1))
}

export const deepSet = (obj, key = [], value) => {
  if (key.length === 0) {
    return value
  }

  if (!obj) {
    obj = {}
  }

  return {
    ...obj,
    [key[0]]: deepSet(obj[key[0]], key.slice(1), value),
  }
}
