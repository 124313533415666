import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

import {getBestParent} from 'utils/geometry'
import {scale, scaleValue, unScale} from 'utils/zoom'
import {getSnapValue} from 'utils/snapping'
import {defaults} from 'utils/objects'

import {createObject, getCurrentAppId, selectObjects,} from 'ducks/editor/objects'

import CanvasObject from './CanvasObject'

import {getXGrid, getYGrid, resetSnaps, setXSnap, setYSnap,} from 'ducks/editor/snapping'


import { getApp } from 'ducks/apps/selectors'

import {getStartPosition, resetTool} from 'ducks/editor/tools'
import 'styles/AddLibraryComponent.css'

import {ELLIPSE, RECTANGLE} from 'common/constants'
import {PathItem, Point as PathPoint, Segment, Size} from "utils/vector";
import {CompoundPath, Rectangle} from "utils/vector/classes";


const OPTIONS = Symbol('AddDraggableComponent$OPTIONS')
export const ADD_DRAGGABLE_OPTIONS = OPTIONS

const MIN_RADIUS_SQUARED = 20 ** 2

function centerObject(x, y, obj) {
 // x -= Math.floor(obj.width / 2)
 // y -= Math.floor(obj.height / 2)

  return [x, y]
}

function applyOffset(x, y, props) {


  return [x, y]
}

class AddDraggableComponent extends Component {
  constructor(props) {
    super(props)

    let startPosition = props.startPosition
        ? unScale(props.startPosition, props.zoom)
        : [0, 0]
    let {
      options: {type},
    } = props



    let [x, y] = unScale(startPosition, props.zoom)
    let [x2, y2] = centerObject(x, y, defaults[type])
    let [x3, y3] = applyOffset(x2, y2, props)

    this.state = {
      x: x3,
      y: y3,
      mouseOver: !!props.startPosition,
      xSnapped: x3,
      ySnapped: y3,
      widthSnapped: 0,
      heightSnapped: 0,
    }
  }

  handleMouseUp = e => {
    let {startPosition} = this.props
    let [x, y] = startPosition
    let distSquared = (e.clientX - x) ** 2 + (e.clientY - y) ** 2

    if (distSquared < MIN_RADIUS_SQUARED) {
      return
    }

    this.handleMouseDown(e)
  }

  handleMouseDown = e => {
    let {xSnapped, ySnapped} = this.state

    let {createObject, resetTool, resetSnaps, options} = this.props
    let {type} = options

    let obj = {...options, ...defaults[type], x: xSnapped, y: ySnapped}

    const kappa = 4 * (Math.sqrt(2) - 1) / 3
    let compound = []
    let points = []
    let segments = [];

    switch (obj.type) {
      case ELLIPSE:



        const ellipseSegments = [
            new Segment([-1, 0], [0, kappa], [0, -kappa]),
            new Segment([0, -1], [-kappa, 0], [kappa, 0]),
            new Segment([1, 0], [0, -kappa], [0, kappa]),
            new Segment([0, 1], [kappa, 0], [-kappa, 0])
          ];

        
        const center = new PathPoint(obj.x, obj.y)
        let radius = new PathPoint(obj.width / 2, obj.height / 2)

        segments = new Array(4);
        for (let i = 0; i < 4; i++) {
          const segment = ellipseSegments[i];
          segments[i] = new Segment(
            segment._point.multiply(radius).add(center),
            segment._handleIn.multiply(radius),
            segment._handleOut.multiply(radius)
          );
        }

        const ellipse = PathItem.create(segments.map(({point, handleIn, handleOut}) => {
          return new Segment(point, handleIn, handleOut)
        }))

        ellipse.setClosed(true)
        ellipse.adjust(obj.x, obj.y )




        if (ellipse instanceof CompoundPath) {
          compound = ellipse.children.map((path) => {
            return path.points
          })
        } else {
          points = ellipse.points
        }


        obj = {
          ...obj,
          compound,
          points,
          isClosed: true
        }
        break;
      case RECTANGLE:

        const rect = new Rectangle(obj.x, obj.y, obj.width, obj.height);
        let r =  new PathPoint(obj.borderRadius ? [obj.borderRadius, obj.borderRadius] : [0,0])

        const bl = rect.getBottomLeft(true),
          tl = rect.getTopLeft(true),
          tr = rect.getTopRight(true),
          br = rect.getBottomRight(true)
        ;


        if (!r || r.isZero()) {
          segments = [
            new Segment(bl),
            new Segment(tl),
            new Segment(tr),
            new Segment(br)
          ];
        } else {
          r = Size.min(new Size(obj.borderRadius, obj.borderRadius), rect.getSize(true).divide(2));
          const rx = r.width,
            ry = r.height,
            hx = rx * kappa,
            hy = ry * kappa;
          segments = [
            new Segment(bl.add(rx, 0), null, [-hx, 0]),
            new Segment(bl.subtract(0, ry), [0, hy]),
            new Segment(tl.add(0, ry), null, [0, -hy]),
            new Segment(tl.add(rx, 0), [-hx, 0], null),
            new Segment(tr.subtract(rx, 0), null, [hx, 0]),
            new Segment(tr.add(0, ry), [0, -hy], null),
            new Segment(br.subtract(0, ry), null, [0, hy]),
            new Segment(br.subtract(rx, 0), [hx, 0])
          ];
        }

        const rectangle = PathItem.create(segments.map(({point, handleIn, handleOut}) => {
          return new Segment(point, handleIn, handleOut)
        }))

        rectangle.setClosed(true)
        //rectangle.adjust(obj.x, obj.y)

        if (rectangle instanceof CompoundPath) {
          compound = rectangle.children.map((path) => {
            return path.points
          })
        } else {
          points = rectangle.points
        }


        obj = {
          ...obj,
          compound,
          points,
          isClosed: true
        }
        break;
    }


    obj = {
      ...obj,
      x: xSnapped,
      y: ySnapped,
    }

    createObject(obj)

    resetTool()
    resetSnaps()
  }

  handleMouseMove = e => {
    let {zoom, options} = this.props


    let [x, y] = unScale([e.clientX, e.clientY], zoom)

    let obj = {...options, ...defaults[options.type]}

    let [x2, y2] = centerObject(x, y, obj)
    let [x3, y3] = applyOffset(x2, y2, this.props)

    let parent = this.getBestParent(x3, y3)

    let snap = this.calculateSnappedPosition(x, y, parent)
    let [xSnapped, ySnapped] = snap

    this.setState({

      mouseOver: true,
      x: x3,
      y: y3,
      xSnapped,
      ySnapped,

    })
  }

  handleMouseLeave = () => {
    this.setState({mouseOver: false})
  }

  getBestParent = (xArg, yArg) => {
    let {x, y} = this.state

    x = xArg || x
    y = yArg || y

    let { options, screens } = this.props

    let obj = {...options, ...defaults[options.type], x, y}

    let parentId = getBestParent(obj, screens)


    return screens.filter(s => s.id === parentId)[0]
  }

  calculateGridSnapPosition = (x, y) => {
    let {options, xGrid, yGrid, setXSnap, setYSnap, zoom} = this.props
    let {width, height} = defaults[options.type]

    if (!xGrid || !yGrid) {
      return [x, y, width, height]
    }

    let xDiff = 0
    let yDiff = 0

    let xCoords = {
      left: x,
      center: x + width / 2,
      right: x + width,
    }

    let yCoords = {
      top: y,
      center: y + height / 2,
      bottom: y + height,
    }

    let xSnap = getSnapValue(xGrid, xCoords, zoom)
    let ySnap = getSnapValue(yGrid, yCoords, zoom)

    if (xSnap) {
      let key = Object.keys(xSnap)[0]
      xDiff = xSnap[key] - xCoords[key]
      setXSnap(xSnap[key])
    } else {
      setXSnap(null)
    }

    if (ySnap) {
      let key = Object.keys(ySnap)[0]
      yDiff = ySnap[key] - yCoords[key]
      setYSnap(ySnap[key])
    } else {
      setYSnap(null)
    }

    return [Math.round(x + xDiff), Math.round(y + yDiff), width, height]
  }

  calculateSnappedPosition = (x, y, parent) => {
    return this.calculateGridSnapPosition(x, y)
  }

  renderComponent = () => {
    let {zoom, options} = this.props
    let {type} = options
    let {xSnapped, ySnapped} = this.state
    let [x, y] = scale([xSnapped, ySnapped], zoom)
    let {width, height} = defaults[type]

    let unScaledWidth = width
    let unScaledHeight = height

    width = scaleValue(unScaledWidth, zoom)
    height = scaleValue(unScaledHeight, zoom)

    let object = {
      ...options,
      width: unScaledWidth,
      height: unScaledHeight,
      x: 0,
      y: 0,
    }


    let childZoom = {...zoom, offset: [0, 0]}

    return (
      <g transform={`translate(${x}, ${y})`} opacity={0.5}>
        <CanvasObject  object={object} zoom={childZoom}/>
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          stroke="none"
          fill="none"
        />
      </g>
    )
  }

  render() {
    let {mouseOver} = this.state

    let width = window.innerWidth
    let height = window.innerHeight

    return (
      <g className="add-component-instance">
        {mouseOver ? this.renderComponent() : null}
        <rect
          onMouseDown={this.handleMouseDown}
          onTouchEnd={this.handleMouseDown}
          onMouseUp={this.handleMouseUp}
          onMouseMove={this.handleMouseMove}
          onTouchMove={this.handleMouseMove}
          onMouseLeave={this.handleMouseLeave}
          onMouseEnter={this.handleMouseMove}
          x={0}
          y={0}
          width={width}
          height={height}
          className="add-component-backdrop"
        />
      </g>
    )
  }
}

const mapStateToProps = (state, {match, options}) => ({
  app: getApp(state, getCurrentAppId(state)),

  screens: selectObjects(state),

  xGrid: getXGrid(state),
  yGrid: getYGrid(state),
  startPosition: getStartPosition(state),
})

export default withRouter(
  connect(mapStateToProps, {
    createObject,
    resetTool,
    setXSnap,
    setYSnap,
    resetSnaps,
  })(AddDraggableComponent)
)
