import React from 'react';


// разработан специально для точек, их много и дизайн должен быть миималистичным
const NumberInputPoints = ({ code, name, value, onChange,  onFocus = () => {}}) => {

    // Конвертация из пикселей в миллиметры
    const widthMm = (value / 96) * 25.4

    // Конвертация из пикселей в точки
    const widthPts = value * 0.75

    // Конвертация из пикселей в дюймы
    const widthInches = value / 96




    return (
    
        <div className="col-span-2 flex flex-col p-2 border border-gray-300 rounded-lg bg-gray-50 shadow-sm w-full">
            
            <div className="flex">
                
                <div className='mr-1'>{ code === '0' ? 'x' : 'y' }</div>

                <input
                    type="number"
                    min="0"
                    id={code}
                    name={code}
                    value={value}
                    onFocus={onFocus}
                    onChange={onChange}
                    placeholder={name}
                    className="w-full px-2 py-0 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />

            </div>

            <div className="grid grid-cols-4 gap-2 mt-2 text-[8px] text-gray-600">
                <span>PX</span>
                <span>MM</span>
                <span>PT</span>
                <span>IN</span>
            </div>

            <div className="grid grid-cols-4 gap-2 text-[10px] text-gray-600">
                <span className='overflow-hidden'>{value}</span>
                <span className='overflow-hidden'>{widthMm.toFixed(2)}</span>
                <span className='overflow-hidden'>{widthPts.toFixed(2)}</span>
                <span className='overflow-hidden'>{widthInches.toFixed(2)}</span>
            </div>

        </div>
    );
};

export default NumberInputPoints;
