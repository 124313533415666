import React, {Component} from 'react'
import {connect} from 'react-redux'

import {CHECKOUT_MODAL, getActiveModal, NEW_SCREEN_MODAL,} from 'ducks/editor/modals'

import Modal from 'components/Shared/Modal'
import NewScreenModal from 'components/Editor/NewScreenModal'
import CheckoutModal from 'components/Editor/CheckoutModal'


class ModalWrapper extends Component {
  handleSuccess = value => {
    let { activeModal } = this.props
    activeModal.resolve(value)
  }

  handleReject = err => {
    let { activeModal } = this.props
    activeModal.reject(err)
  }

  renderModal = (children, modalProps) => {
    return (
      <div className="modal-wrapper-wrapper">
        <Modal {...modalProps} scrolling onClose={this.handleReject}>
          {children}
        </Modal>
      </div>
    )
  }

  render() {
    let { activeModal } = this.props

    if (!activeModal) {
      return null
    }

    if (activeModal.modalType === NEW_SCREEN_MODAL) {
      return this.renderModal(
        <NewScreenModal
          opts={activeModal.opts}
          onSuccess={this.handleSuccess}
          onCancel={this.handleReject}
        />,
        { className: 'new-screen-modal' }
      )
    }

    if (activeModal.modalType === CHECKOUT_MODAL) {
      return this.renderModal(
          <CheckoutModal
              opts={activeModal.opts}
              onSuccess={this.handleSuccess}
              onCancel={this.handleReject}

          />,
          { className: 'new-screen-modal', important: true }
      )
    }

    return null
  }
}

const mapStateToProps = state => ({
  activeModal: getActiveModal(state),
})

export default connect(mapStateToProps)(ModalWrapper)
