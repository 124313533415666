import axios from 'axios'
import {buildIndex} from 'common/utils'

import {baseURL} from '../utils/io'

const FETCH_CUSTOM_ACTIONS = 'FETCH_CUSTOM_ACTIONS'
const CREATE_CUSTOM_ACTION = 'CREATE_CUSTOM_ACTION'
const DELETE_CUSTOM_ACTION = 'DELETE_CUSTOM_ACTION'

const INITIAL_STATE = {}

// Reducer

export default (state = INITIAL_STATE, action) => {
  if (action.type === `${FETCH_CUSTOM_ACTIONS}_FULFILLED`) {
    let customActions = buildIndex(action.payload.data, customAction => {
      return customAction.id
    })

    let appId = action.meta.appId

    return {
      ...state,
      [appId]: customActions,
    }
  }

  if (action.type === `${CREATE_CUSTOM_ACTION}_FULFILLED`) {
    let customAction = action.payload

    const { appId } = action.meta

    return {
      ...state,
      [appId]: {
        ...state[appId],
        [customAction.id]: customAction,
      },
    }
  }

  if (action.type === `${DELETE_CUSTOM_ACTION}_FULFILLED`) {
    let customAction = action.payload
    const { appId } = action.meta

    return {
      ...state,
      [appId]: {
        ...state[appId],
        [customAction.id]: customAction,
      },
    }
  }

  return state
}

// Actions

export const fetchCustomActions = appId => ({
  type: FETCH_CUSTOM_ACTIONS,
  payload: axios.get(`${baseURL}/apps/${appId}/custom-actions`),
  meta: { appId },
})

export const createCustomAction = (appId, action) => {
  const payload = axios
    .put(`${baseURL}/apps/${appId}/custom-actions`, action)
    .then(res => res.data)

  return {
    type: CREATE_CUSTOM_ACTION,
    payload,
    meta: { appId },
  }
}

export const deleteCustomAction = (appId, customActionId) => {
  const payload = axios
    .delete(`${baseURL}/custom-actions/${customActionId}`)
    .then(res => res.data)

  return {
    type: DELETE_CUSTOM_ACTION,
    payload,
    meta: { appId },
  }
}

// Selectors

export const getCustomActions = (state, appId) => {
  return state.customActions?.[appId] || {}
}

export const getCustomAction = (state, appId, customActionId) => {
  return state.customActions?.[appId]?.[customActionId] || {}
}
