import {Component} from 'react'

export default class ScrollStopper extends Component {
  handleWheel = e => {
    if (e.deltaX !== 0) {
      e.preventDefault()
    }
  }

  componentDidMount() {
    document.body.addEventListener('wheel', this.handleWheel, {
      passive: false,
      capture: true,
    })
  }

  componentWillUnmount() {
    document.body.removeEventListener('wheel', this.handleWheel)
  }

  render() {
    return null
  }
}
