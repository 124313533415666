import React, {Component} from 'react'
import classNames from 'classnames'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {deepMerge} from 'common/utils'

import {evaluateEnabled, getComponentInfo} from 'utils/libraries'


import {getLibraryGlobals, setLibraryGlobals, updateObject,} from 'ducks/editor/objects'

import EmptyState from 'components/Shared/EmptyState'
import {GroupedAccordion} from 'components/Shared/Accordion'
import ToggleButton from 'components/Shared/Forms/ToggleButton'

import PropControl from './PropControl'
import SettingsPanel from './SettingsPanel'
import ComponentError from './ComponentError'
import 'styles/LibraryInspect.css'

class LibraryInspectItem extends Component {
  static defaultProps = { libraryGlobals: {} }

  handleChange = (values, actionChanges, isGlobal) => {
    let { onChange } = this.props

    return onChange(values, actionChanges, isGlobal)
  }

  handleToggleEnabled = newValue => {
    let { namespace } = this.props

    // This is only relevant for children
    if (!namespace) {
      return
    }

    this.handleChange({ [namespace]: { enabled: newValue } })
  }

  hasEnabledProp = () => {
    let { config } = this.props
    let { props } = config

    if (props.filter(p => p.name === 'enabled').length > 0) {
      return true
    }

    return false
  }

  getDisabled = () => {
    let values = this.getValues()

    return this.hasEnabledProp() && !values.enabled
  }

  getValues() {
    let { values } = this.props

    return values || {}
  }

  getTitle = (isOpen = false, forceEnabled = false) => {
    let { config } = this.props
    let values = this.getValues()

    let showEnabled = this.hasEnabledProp() && (forceEnabled || !values.enabled)

    return (
      <SectionTitle
        isOpen={isOpen}
        label={config.displayName || config.name}
        enabled={values.enabled}
        showEnabled={showEnabled}
        onToggleEnabled={this.handleToggleEnabled}
      />
    )
  }

  getExpandedTitle = () => {
    return this.getTitle(true, true)
  }

  accordionRef = el => {
    this._accordion = el
  }

  shouldDisplayProp = prop => {
    let values = this.getValues()

    return evaluateEnabled(prop.enabled, values)
  }

  renderSection = () => {
    let {
      config,
      objectId,
      objectHeight,
      objectWidth,
      namespace,
      libraryGlobals,
    } = this.props

    let values = this.getValues()
    let { props, displayName, role, reference } = config

    if (this.getDisabled()) {
      return <EmptyState>{displayName} is disabled</EmptyState>
    }

    props = props.filter(prop => prop.name !== 'enabled')

    return props.map(prop =>
      this.shouldDisplayProp(prop) ? (
        <PropControl
          key={prop.name}
          prop={prop}
          onChange={this.handleChange}
          value={prop.global ? libraryGlobals[prop.name] : values[prop.name]}
          values={values}
          objectId={objectId}
          namespace={namespace}
          role={role || prop.role}
          reference={reference || prop.reference}
          objectWidth={objectWidth}
          objectHeight={objectHeight}
        />
      ) : null
    )
  }

  render() {
    let { namespace, isOnlyItem } = this.props

    if (!namespace && isOnlyItem) {
      return (
        <div className="library-non-accordion-items">
          {this.renderSection()}
        </div>
      )
    }

    return (
      <GroupedAccordion
        defaultExpanded={!namespace}
        group="library-inspect"
        title={this.getTitle()}
        expandedTitle={this.getExpandedTitle()}
        className={classNames('library-inspect-accordion', {
          'library-inspect-accordion-disabled': this.getDisabled(),
        })}
        renderChildren={this.renderSection}
      />
    )
  }
}

class SectionTitle extends Component {
  handleClick = e => {
    let { isOpen } = this.props

    if (isOpen) {
      e.stopPropagation()
    }
  }

  render() {
    let { label, enabled, showEnabled, onToggleEnabled } = this.props

    return (
      <div className="library-inspect-section-title">
        <div className="library-inspect-title-text">{label}</div>
        <div onClick={this.handleClick}>
          {showEnabled ? (
            <ToggleButton value={enabled} onChange={onToggleEnabled} />
          ) : null}
        </div>
      </div>
    )
  }
}

class LibraryInspect extends Component {
  handleChange = (changes, actionChanges = {}, isGlobal = false) => {
    let { object, updateObject, setLibraryGlobals } = this.props

    if (isGlobal) {
      let { libraryName, componentName } = object

      return setLibraryGlobals(libraryName, componentName, changes)
    }

    let newObject = deepMerge(object.attributes, changes)

    updateObject(object.id, { attributes: newObject, actions: actionChanges })
  }

  getLibraryComponent = () => {
    let { object, libraryVersion } = this.props
    let { libraryName, componentName } = object

    return getComponentInfo(libraryName, libraryVersion, componentName)
  }

  render() {
    let { object, libraryGlobals } = this.props
    let { libraryName, componentName } = object

    let info = this.getLibraryComponent()

    if (!info) {
      return <ComponentError />
    }

    return (
      <div className="library-inspect">
        {info.settingsURL && [
          <SettingsPanel
            key={`${libraryName}/${componentName}`}
            library={libraryName}
            object={object}
            settingsURL={info.settingsURL}
            height={info.settingsPanelHeight}
          />,
        ]}
        {[
          <React.Fragment key={object.id}>
            <LibraryInspectItem
              values={object.attributes}
              config={info}
              onChange={this.handleChange}
              objectId={object.id}
              isOnlyItem={(info.childComponents || []).length === 0}
              objectWidth={object.width}
              objectHeight={object.height}
              libraryGlobals={libraryGlobals}
            />
            {(info.childComponents || []).map(
              child =>
                evaluateEnabled(child.enabled, object.attributes) && (
                  <LibraryInspectItem
                    key={child.name}
                    config={child}
                    values={object.attributes[child.name]}
                    onChange={this.handleChange}
                    namespace={child.name}
                    objectId={object.id}
                    objectWidth={object.width}
                    objectHeight={object.height}
                    libraryGlobals={libraryGlobals[child.name]}
                  />
                )
            )}
          </React.Fragment>,
        ]}
      </div>
    )
  }
}

const mapStateToProps = (state, { match, object }) => {
  let { libraryName, componentName } = object

  return {
    libraryGlobals: getLibraryGlobals(state, libraryName, componentName),

  }
}

export default withRouter(
  connect(mapStateToProps, { updateObject, setLibraryGlobals })(LibraryInspect)
)
