import React, {Component} from 'react'
import Icon from '../../Shared/Icon'

export default class Incrementer extends Component {
  getDiff = e => {
    return e.shiftKey ? 10 : 1
  }

  handleClickDown = e => {
    let { onChange, value } = this.props

    return onChange(+value - this.getDiff(e))
  }

  handleClickUp = e => {
    let { onChange, value } = this.props

    return onChange(+value + this.getDiff(e))
  }

  render() {
    return (
      <div className="incrementer">
        <div
          className="incrementer-btn incrementer-increase"
          onMouseDown={this.handleClickUp}
        >
          <Icon type="up-arrow-small" />
        </div>
        <div
          className="incrementer-btn incremeneter-decrease"
          onMouseDown={this.handleClickDown}
        >
          <Icon type="down-arrow-small" />
        </div>
      </div>
    )
  }
}