import React, {Component} from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'

import history from '../../utils/history'

import Editor from '../Editor'
import Nav from '../Nav'
import Apps from '../Apps'

import Navbar from './Navbar'
import Body from './Body'
import DeleteApp from '../Delete'
import Login from '../Auth/Login'
import ForgotPassword from '../Auth/ForgotPassword'
import RecoverPassword from '../Auth/RecoverPassword'
import Signup from '../Auth/Signup'
import Verify from '../Auth/Verify'


// draft init
import WrapDraftInit from '../draft_init/wrap_draft_init'
import DraftInit from '../draft_init/draft_init'

import DraftInitCustomSizes from '../draft_init/draft_init_custom_sizes/draft_init_custom_sizes'
import DraftInitCustomSizesSelectName from '../draft_init/draft_init_custom_sizes/draft_init_select_name'

import DraftInitTemplateSizes from '../draft_init/draft_init_template_sizes/draft_init_template_sizes'
import DraftInitTemplateSizesSelectName from '../draft_init/draft_init_template_sizes/draft_init_select_name'



import Settings, {Account} from '../Settings'

import AuthRedirect from './AuthRedirect'
import ScrollStopper from './ScrollStopper'

import Notifications from './Notifications'

import 'utils/axios'


import 'styles/App.scss'
import CopyApp from "../Copy";
import AdminUsers from "../Admin/Users";
import AdminOrders from "../Admin/Orders";
import AdminDrafts from "../Admin/Drafts";


import TemplateType from "../Admin/TemplateType";
import Templates from "../Admin/Templates";
import TemplatesNull from "../Admin/TemplatesNull";
import TemplateCreate from "../Admin/template/create/TemplateCreateView";
import TemplateUpdate from "../Admin/template/update/TemplateUpdateView";

import Library from "../Admin/Library";
import Components from "../Admin/Components";
import ComponentsNull from "../Admin/ComponentsNull";
import Orientations from "../Admin/Orientations";

import OrientationUpdateView from "../Admin/orientations/update/OrientationUpdateView";


import NavAdmin from "./NavAdmin";

import TalkMeScript from "./TalkMeScript.js"



export default class App extends Component {

    constructor(props) {
        super(props);
        document.title = "Бокс 1";
    }

    render() {
        return this.renderChildren()
    }

    renderChildren() {
        return (

            <Router history={history}>

                {/* <OfflineBanner />*/}
                <Notifications/>
                <ScrollStopper/>
                <AuthRedirect/>

                <Switch>

                    <Route exact path="/login" component={Login}/>

                    <Route exact path="/verify" component={Verify}/>
                    <Route
                        path="/signup"
                        render={props => (
                            <Signup {...props}/>
                        )}
                    />
                    <Route
                        path="/accept-invite"
                        render={props => (
                            <Signup {...props}/>
                        )}
                    />
                    <Route exact path="/forgot-password" component={ForgotPassword}/>

                    <Route
                        exact
                        path="/recover-password"
                        component={RecoverPassword}
                    />
                    

                    <Route
                        exact
                        path="/draft/init/mode"
                        render={(props) => (
                            <WrapDraftInit {...props} component={DraftInit} />
                        )}
                    />

                    {/* step 2 - select custom size */}
                    <Route
                        exact
                        path="/draft/init/custom/sizes"
                        render={(props) => (
                            <WrapDraftInit {...props} component={DraftInitCustomSizes} />
                        )}
                    />

                    <Route
                        exact
                        path="/draft/init/custom/sizes/select/name"
                        render={(props) => (
                            <WrapDraftInit {...props} component={DraftInitCustomSizesSelectName} />
                        )}
                    />

                    {/* step 2 - select template size */}
                    <Route
                        exact
                        path="/draft/init/template/sizes"
                        render={(props) => (
                            <WrapDraftInit {...props} component={DraftInitTemplateSizes} />
                        )}
                    />

                    <Route
                        exact
                        path="/draft/init/template/sizes/select/name"
                        render={(props) => (
                            <WrapDraftInit {...props} component={DraftInitTemplateSizesSelectName} />
                        )}
                    />




                    
    
                    <Route render={() => (

                        <div className="app">

                            <Navbar>
                                <Switch>
                                    <Route path="/apps/:appId/:tabName" component={Nav}/>
                                    <Route component={NavAdmin}/>

                                </Switch>
                            </Navbar>
                            
                            <Body>

                                <Switch>

                                    <Route exact path="/" component={Apps}/>

                                    <Route
                                        path="/settings"
                                        render={() => (
                                            <Settings>
                                                <Switch>
                                                    <Route
                                                        exact
                                                        path="/settings/account"
                                                        component={Account}
                                                    />

                                                </Switch>
                                            </Settings>
                                        )}
                                    />

                                    <Route
                                        exact
                                        path="/delete/:appId"
                                        render={() => (
                                            <Apps>
                                                <DeleteApp/>
                                            </Apps>
                                        )}
                                    />
                                    <Route path="/copy/:appId"
                                            render={props => (

                                                <Apps>
                                                    <CopyApp {...props}/>
                                                </Apps>
                                            )}
                                    />
                                    
                                    <Route
                                        exact
                                        path="/apps/:appId"
                                        render={({match}) => (
                                            <Redirect
                                                to={`/apps/${match.params.appId}/screens`}
                                                replace
                                            />
                                        )}
                                    />

                                    <Route
                                        exact
                                        path="/apps/:appId/settings"
                                        component={Settings}
                                    />


                                    <Route path="/apps/:appId" component={Editor}/>
                                    
                                    <Route
                                        exact
                                        path="/admin/users"
                                        component={AdminUsers}
                                    />

                                    {/* <Route
                                        exact
                                        path="/admin/users/:userId"
                                        component={AdminUser}
                                    />*/}

                                    <Route
                                        exact
                                        path="/admin/orders"
                                        component={AdminOrders}
                                    />

                                    <Route
                                        exact
                                        path="/admin/drafts"
                                        component={AdminDrafts}
                                    />

                                    <Route
                                        exact
                                        path="/admin/template-type"
                                        component={TemplateType}
                                    />

                                    <Route
                                        exact
                                        path="/admin/template-type/null/templates"
                                        component={TemplatesNull}
                                    />


                                    <Route
                                        exact
                                        path="/admin/template-type/:id/templates"
                                        component={Templates}
                                    />




                                    {/* создание шаблонов */}
                                    <Route
                                        exact
                                        path="/admin/template-type/:id/templates/new"
                                        component={TemplateCreate}
                                    />

                                    {/* изменение шаблонов  */}
                                    <Route
                                        exact
                                        path="/admin/templates/:id"
                                        component={TemplateUpdate}
                                    />





                                    <Route
                                        exact
                                        path="/admin/library"
                                        component={Library}
                                    />


                                    <Route
                                        exact
                                        path="/admin/libraries/null/components"
                                        component={ComponentsNull}
                                    />


                                    {/* работа с компонентами */}


                                    {/* список компонентов */}
                                    <Route
                                        exact
                                        path="/admin/libraries/:id/components"
                                        component={Components}
                                    />

                                    <Route
                                        exact
                                        path="/admin/components/:id/orintations"
                                        component={Orientations}
                                    />

                                    

                                    {/* изменение компонента */}
                                        <Route
                                            exact
                                            path="/admin/orintations/:id"
                                            component={OrientationUpdateView}
                                        />
                                    


                                    

                                </Switch>
                            
                            </Body>
                        </div>
                    )} />

                </Switch>
                
                
                <TalkMeScript />

            </Router>

        )
    }
}
