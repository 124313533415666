/* eslint-disable react/no-unused-state */
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

//import {VelocityTransitionGroup} from 'velocity-react'
import {CSSTransition, TransitionGroup} from 'react-transition-group'

import Icon from 'components/Shared/Icon'
import 'components/Shared/Accordion.scss'
import './Accordion.css';


const Context = React.createContext()

export class AccordionGroup extends Component {
  static propTypes = {
    openAll: PropTypes.bool,
    defaultOpen: PropTypes.arrayOf(PropTypes.string),
  }

  static defaultProps = {
    openAll: false,
    defaultOpen: [],
  }

  constructor(props) {
    super(props)

    this.state = {
      openIds: new Set(props.defaultOpen),
      handleToggle: id => () => {

        this.setState(state => {
          const openIds = new Set(state.openIds)

          if (openIds.has(id)) {
            openIds.delete(id)
          } else {
            openIds.add(id)
          }

          return {openIds}
        })
      },
    }
  }

  open = ids => {
    if (ids.length === 0) {
      return
    }

    this.setState(state => {
      let openIds = state.openIds

      for (const id of ids) {
        if (state.openIds.has(id)) {
          if (openIds === state.openIds) {
            openIds = new Set(openIds)
          }

          openIds.add(id)
        }
      }

      return {openIds}
    })
  }

  replaceOpen = ids => {
    this.setState({openIds: new Set(ids)})
  }

  render() {
    const {children, openAll} = this.props
    let value = this.state

    if (openAll) {
      value = {
        openIds: {
          has: () => true,
        },
        handleToggle: () => null,
      }
    }

    return <Context.Provider value={value}>{children}</Context.Provider>
  }
}




export default class Accordion extends Component {
  static Group = AccordionGroup;
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
    collapsedTitle: PropTypes.node,
    expandedTitle: PropTypes.node,
    className: PropTypes.string,
    hideOnly: PropTypes.bool,
    hideCarret: PropTypes.bool,
    titleComponent: PropTypes.elementType,
  };

  static defaultProps = {
    hideOnly: false,
    hideCarret: false,
    titleComponent: 'div',
  };

  renderChildren() {
    let { renderChildren } = this.props;
    return renderChildren ? <div>{renderChildren()}</div> : null;
  }

  render() {
    const {
      id,
      title,
      titleComponent: TitleComponent,
      collapsedTitle,
      expandedTitle,
      className,
      hideOnly,
      hideCarret,
      fetchChildren,
    } = this.props;

    return (
      <Context.Consumer>
        {({ openIds, handleToggle }) => {
          const expanded = openIds.has(id);
          const toggle = () => {
            handleToggle(id)();
            !expanded && fetchChildren && fetchChildren(id);
          };

          const currentTitle = (expanded ? expandedTitle : collapsedTitle) || title;

          return (
            <div className={classNames('components-accordion', className, {'components-accordion-expanded': expanded})}>
              <TitleComponent className="components-accordion-title" onClick={toggle}>
                {!hideCarret && (
                  <Icon type={expanded ? 'expand-vertical' : 'expand'}/>
                )}
                {currentTitle}
              </TitleComponent>
              {!hideOnly ? (

                <TransitionGroup>
                  {expanded && (
                    <CSSTransition classNames="accordion" timeout={300}>
                      {this.renderChildren()}
                    </CSSTransition>
                  )}
                </TransitionGroup>
                
              ) : (
                this.renderChildren()
              )}
            </div>
          );
        }}
      </Context.Consumer>
    );
  }
}








// export default class Accordion extends Component {
//   static Group = AccordionGroup
//   static propTypes = {
//     id: PropTypes.string.isRequired,
//     title: PropTypes.node.isRequired,
//     collapsedTitle: PropTypes.node,
//     expandedTitle: PropTypes.node,
//     className: PropTypes.string,
//     hideOnly: PropTypes.bool,
//     hideCarret: PropTypes.bool,
//     titleComponent: PropTypes.elementType,
//   }

//   static defaultProps = {
//     hideOnly: false,
//     hideCarret: false,
//     titleComponent: 'div',
//   }

//   renderChildren() {
//     let {renderChildren} = this.props

//     return renderChildren ? <div>{renderChildren()}</div> : null
//   }

//   render() {
//     const {
//       id,
//       title,
//       titleComponent: TitleComponent,
//       collapsedTitle,
//       expandedTitle,
//       className,
//       hideOnly,
//       hideCarret,
//       fetchChildren,
//     } = this.props

//     return (
//       <Context.Consumer>
//         {({openIds, handleToggle}) => {

//           const expanded = openIds.has(id)

//           const toggle = () => {
//             handleToggle(id)()
//             !expanded && fetchChildren && fetchChildren(id)

//           }

//           const currentTitle =
//             (expanded ? expandedTitle : collapsedTitle) || title

//           return (
//             <div
//               className={classNames('components-accordion', className, {
//                 'components-accordion-expanded': expanded,
//               })}
//             >
//               <TitleComponent
//                 className="components-accordion-title"
//                 onClick={toggle}
//               >
//                 {!hideCarret ? (
//                   <Icon type={expanded ? 'expand-vertical' : 'expand'}/>
//                 ) : null}
//                 {currentTitle}
//               </TitleComponent>
//               {hideOnly ? (
//                 this.renderChildren()
//               ) : (

//                 <VelocityTransitionGroup enter={{animation: 'slideDown'}} leave={{animation: 'slideUp'}} speed={3}>
//                   { expanded ? this.renderChildren() : null}
//                 </VelocityTransitionGroup>

//               )}
//             </div>
//           )
//         }}
//       </Context.Consumer>
//     )
//   }
// }
