// initialState.js

import { DEFAULT_SELECTION } from './selection'

export const editorInitDefault = () => ({
    appId: null,
    loading: false,
    name: null,
    zoom: {
      scale: 1,
      offset: [0, 0],
    },
    map: {},
    parentMap: {},
    list: [],
  
    // Indices map to components at the top level
    // typeIndex: { COMPONENT1: { TYPE: [...] } }
    // Others: { COMPONENT1: [...] }
    typeIndex: {},
    selection: DEFAULT_SELECTION,
    hoverSelection: DEFAULT_SELECTION,
    activeComponent: null,
    textEditing: null,
    shapeEditing: null,
    draggingOutControl: false,
    draggingInControl: false,
    selectedPoint: null,
    draggingSelectedPoint: false,
    zoomAppId: null,
    dragging: false,
    panning: false,
    xGrid: null,
    yGrid: null,
    currentXSnap: null,
    currentYSnap: null,
    positioningObjects: null,
    positioningStartPoint: null,
    positioningConstraint: null,
    activeTab: null,
    sketchUpload: {
      uploadInProgress: false,
      uploadError: null,
      progress: null,
    },
    selectedParent: null,
    libraryGlobals: {},
})
