import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
    divideObjects,
    excludeObjects,
    groupObjects,
    groupObjectsToList,
    intersectObjects,
    subtractObjects,
    uniteObjects
} from '../../../ducks/editor/objects'
import Button from '../../Shared/Button'

class MultiSelectionControl extends Component {
  static propTypes = {
    objects: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      })
    ).isRequired,
    onMakeGroup: PropTypes.func.isRequired,
    onMakeList: PropTypes.func.isRequired,
  }

  handleMakeGroup = () => {
    const { onMakeGroup, objects } = this.props
    onMakeGroup(objects.map(obj => obj.id))
  }

  handleMakeUnite = () => {
    const { onMakeUnite, objects } = this.props
    onMakeUnite(objects.map(obj => obj.id))
  }
  handleMakeIntersect = () => {
    const { onMakeIntersect, objects } = this.props
    onMakeIntersect(objects.map(obj => obj.id))
  }
  handleMakeSubtract = () => {
    const { onMakeSubtract, objects } = this.props
    onMakeSubtract(objects.map(obj => obj.id))
  }
  handleMakeExclude = () => {
    const { onMakeExclude, objects } = this.props
    onMakeExclude(objects.map(obj => obj.id))
  }
  handleMakeDivide = () => {
    const { onMakeDivide, objects } = this.props
    onMakeDivide(objects.map(obj => obj.id))
  }
  render() {
    return (
      <div className="multi-selection-control">
        <Button linked onClick={this.handleMakeGroup}>
          <span>Сгруппировать</span>
        </Button>
        <Button linked onClick={this.handleMakeUnite}>

          <span>Объеденить</span>
        </Button>
        <Button linked onClick={this.handleMakeIntersect}>

          <span>intersect</span>
        </Button>
        <Button linked onClick={this.handleMakeSubtract}>

          <span>subtract</span>
        </Button>
        <Button linked onClick={this.handleMakeExclude}>

          <span>Exclude</span>
        </Button>
        <Button linked onClick={this.handleMakeDivide}>

          <span>Divide</span>
        </Button>
      </div>
    )
  }
}

export default connect(null, {
  onMakeGroup: groupObjects,
  onMakeUnite : uniteObjects,
  onMakeIntersect :intersectObjects,
  onMakeSubtract: subtractObjects,
  onMakeExclude: excludeObjects,
  onMakeDivide : divideObjects,
  onMakeList: groupObjectsToList,
})(MultiSelectionControl)
