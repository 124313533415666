import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import DocumentEvents from 'react-document-events'

import ModalHeader from './ModalHeader'
import ModalContent from './ModalContent'
import ModalContainer from './ModalContainer'
import ModalActions from './ModalActions'

import Button from '../Button'

import './Modal.scss'

const ESC = 27

export default class Modal extends Component {
  static childContextTypes = {
    onClose: PropTypes.func,
  }

  static propTypes = {
    grayOut: PropTypes.bool,
    hideDimmer: PropTypes.bool,
    scrolling: PropTypes.bool,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'fluid']),
  }

  static defaultProps = {
    grayOut: true,
    scrolling: false,
    size: 'sm',
  }

  static Header = ModalHeader
  static Container = ModalContainer
  static Content = ModalContent
  static Actions = ModalActions
  static Button = Button

  getChildContext() {
    let { onClose } = this.props

    return { onClose }
  }

  handleKeyDown = e => {
    let { onClose } = this.props

    if (['INPUT', 'TEXTAREA'].indexOf(e.target.tagName) >= 0) {
      return
    }

    if (e.which === ESC && onClose) {
      onClose()
    }
  }

  preventClose = e => {

    e.stopPropagation()
  }

  renderModal = () => {
    let {
      className,
      children,
      onClose,
      white,
      constrained,
      grayOut,
      scrolling,
      size,
      important,
      hideDimmer,
    } = this.props

    return (
      <div
        className={classNames('modal-wrapper', {
          'modal-wrapper-constrained': constrained,
          'modal-wrapper-white': white,
          'modal-wrapper-gray-out': grayOut,
          'modal-wrapper-hide-dimmer': hideDimmer,
        })}
        onClick={!important && onClose}
      >
        <div className="modal-inner-wrapper">
          <DocumentEvents onKeyDown={this.handleKeyDown} />
          <div
            className={classNames('modal', className, {
              'modal-scrolling': scrolling,
              [`modal-width-${size}`]: size,
            })}
            onClick={this.preventClose}
          >
            {children}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return ReactDOM.createPortal(this.renderModal(), document.body)
  }
}
