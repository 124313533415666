// DraftInit.js
import React from 'react';
import { withRouter } from 'react-router-dom'

import Page from '../Shared/Page'

const WrapDraftInit = ({ history, component: StepComponent }) => {
  return (
    <Page
      modalProps={{ className: 'new-app-modal', grayOut: false }}
      modalContent={<StepComponent history={history} />}
    />
  )
}

export default withRouter(WrapDraftInit)