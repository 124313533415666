import qs from 'qs'

export const assetURL = filename => {
  let params = { auto: 'compress' }

  return `${process.env.REACT_APP_IMAGE_BASE_URL}/${filename}?${qs.stringify(
    params
  )}`
}

export const assetProxyURL = filename => {
  const baseURL = process.env.REACT_APP_BACKEND_URL

  return `${baseURL}/images/asset-proxy?filename=${filename}`
}
