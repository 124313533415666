import {Component} from 'react'

const classNames = {}

export default class BodyClass extends Component {
  updateClassName = (newName = '', oldName = '') => {
    let newClassNames = newName.split(/\s+/)
    let removed = oldName.split(/\s+/)

    removed.forEach(itm => {
      if (classNames[itm]) {
        classNames[itm] -= 1

        if (classNames[itm] === 0) {
          delete classNames[itm]
        }
      }
    })

    newClassNames.forEach(itm => {
      if (!classNames[itm]) {
        classNames[itm] = 0
      }

      classNames[itm] += 1
    })

    let names = Object.keys(classNames)

    document.body.className = names.join(' ')
  }

  shouldComponentUpdate(newProps) {
    let { className } = this.props

    if (className !== newProps.className) {
      this.updateClassName(newProps.className, className)

      return true
    }

    return false
  }

  componentDidMount() {
    let { className } = this.props
    this.updateClassName(className)
  }

  componentWillUnmount() {
    let { className } = this.props
    this.updateClassName('', className)
  }

  render() {
    return null
  }
}
