import React from 'react'
import classNames from 'classnames'

import OriginalButton from '../Button'
import 'styles/NewModal.css'

export function ModalFooter({ children }) {
  return <div className="new-modal-footer">{children}</div>
}

export function PrimaryButton({ className, alignRight, ...props }) {
  return (
    <OriginalButton
      className={classNames(
        { 'modal-footer-align-right': alignRight },
        className
      )}
      {...props}
    />
  )
}

export function SecondaryButton({ className, alignRight, ...props }) {
  return (
    <OriginalButton
      className={classNames(
        'modal-footer-secondary',
        { 'modal-footer-align-right': alignRight },
        className
      )}
      {...props}
    />
  )
}

export const Button = PrimaryButton
