import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form'
import Button from '../Shared/Button'
import WrappedInput from '../Shared/Forms/WrappedInput'
import AuthPageLogo from './Logo'
import {Link} from "react-router-dom";

const FORM_NAME = 'forgotPasswordForm'

export const validate = data => {
  let errors = {}

  if (!data.email) {
    errors.email = 'Обязательное поле'
  } else if (!data.email.match(/^.+@([\w\-]+\.)+\w{2,}$/)) {
    errors.email = 'Неверный формат эл. почты'
  }

  return errors
}

class ForgotPasswordForm extends Component {
  render() {
    let { handleSubmit, error, submitSucceeded } = this.props

    return (
      <form onSubmit={handleSubmit} className="auth-form">
        <AuthPageLogo />
        <h1>Восстановление пароля</h1>
        <p>Пожалуйста, введите свой адрес эл. почты указанный при регистрации</p>
        <Field
          autoFocus
          name="email"
          placeholder="Эл. адрес"
          type="email"

          component={WrappedInput}
        />



        <div className="auth-form-submission-row">
          <div className={'auth-form-footer'}>
            <Button large teal disabled={submitSucceeded}>
              Отправить
            </Button>
            <Link to="/login" className="link-button">
              Вход в систему
            </Link>
          </div>
        </div>
        {error && <div className="auth-form-error">{error}</div>}
        {submitSucceeded && (
          <div className="auth-form-success">
            Ссылка для восстановления пароля была отправлена на вашу эл. почту.
          </div>
        )}
      </form>
    )
  }
}

export default reduxForm({
  form: FORM_NAME,
  validate,
})(ForgotPasswordForm)
