import React from 'react'


import 'styles/HelpText.css'

export default ({ text }) => {
  return (
    <div className="library-inspect-help-text">

    </div>
  )
}
