import React, { useState, useEffect, useRef } from 'react';

export default function Element(props) {
  const {
    type = 'div',
    dragTolerence = 3,
    children,
    onClick,
    onDragStart,
    onDrag,
    onDragEnd,
    onMouseDown,
    ...passThroughProps
  } = props;

  const [clickStartPoint, setClickStartPoint] = useState(null);
  const [targetOffset, setTargetOffset] = useState(null);
  const [dragging, setDragging] = useState(false);

  const isMountedRef = useRef(true);
  const targetOffsetRef = useRef(null);
  const draggingRef = useRef(false);
  const clickStartPointRef = useRef(null);

  useEffect(() => {
    return () => {
      // Очистка при размонтировании компонента
      isMountedRef.current = false;
      removeListeners();
    };
  }, []);

  const handleMouseDown = (e) => {
    if (!isMountedRef.current) return;

    if (onMouseDown) {
      onMouseDown(e);
    }

    const bbox = e.currentTarget.getBoundingClientRect();

    const startPoint = [e.clientX, e.clientY];
    const offset = [e.clientX - bbox.left, e.clientY - bbox.top];

    setClickStartPoint(startPoint);
    setDragging(false);
    setTargetOffset(offset);

    clickStartPointRef.current = startPoint;
    targetOffsetRef.current = offset;
    draggingRef.current = false;

    document.documentElement.addEventListener('mousemove', handleMouseMove);
    document.documentElement.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (!isMountedRef.current) return;

    if (!clickStartPointRef.current) {
      return;
    }

    const squaredDist =
      (e.clientX - clickStartPointRef.current[0]) ** 2 +
      (e.clientY - clickStartPointRef.current[1]) ** 2;

    if (
      !draggingRef.current &&
      squaredDist > dragTolerence ** 2
    ) {
      setDragging(true);
      draggingRef.current = true;

      if (onDragStart) {
        onDragStart(e, targetOffsetRef.current);
      }
    } else if (onDrag) {
      onDrag(e, targetOffsetRef.current);
    }
  };

  const handleMouseUp = (e) => {
    if (!isMountedRef.current) return;

    if (!clickStartPointRef.current) {
      return;
    }

    if (draggingRef.current) {
      if (onDragEnd) {
        onDragEnd(e, targetOffsetRef.current);
      }
    } else {
      if (onClick) {
        onClick(e);
      }
    }

    setClickStartPoint(null);
    clickStartPointRef.current = null;

    removeListeners();
  };

  const removeListeners = () => {
    document.documentElement.removeEventListener('mousemove', handleMouseMove);
    document.documentElement.removeEventListener('mouseup', handleMouseUp);
  };

  const ElementType = type;

  return (
    <ElementType
      {...passThroughProps}
      onMouseDown={handleMouseDown}
    >
      {children}
    </ElementType>
  );
}








// import React, {Component} from 'react'

// export default class Element extends Component {

//   static defaultProps = {
//     type: 'div',
//     dragTolerence: 3,
//   }

//   constructor(props) {

//     super(props);

//     this.state = {
//       clickStartPoint: null,
//       targetOffset: null,
//       dragging: false,
//     };

//     this.isMountedComponent = false;

//   }

//   componentDidMount() {
//     this.isMountedComponent = true;
//   }

//   componentWillUnmount() {
//     this.isMountedComponent = false;
//     this.removeListeners()
//   }

  

//   handleMouseDown = e => {

//     if (!this.isMountedComponent) return

//     let { onMouseDown } = this.props
    

//     let bbox = e.currentTarget.getBoundingClientRect()

//     if (onMouseDown) {
//       onMouseDown(e)
//     }

//     this.setState({
//       clickStartPoint: [e.clientX, e.clientY],
//       dragging: false,
//       targetOffset: [e.clientX - bbox.left, e.clientY - bbox.top],
//     })

//     document.documentElement.addEventListener('mousemove', this.handleMouseMove)
//     document.documentElement.addEventListener('mouseup', this.handleMouseUp)
//   }

//   handleMouseMove = e => {

//     if (!this.isMountedComponent) return;

//     let { clickStartPoint, dragging, targetOffset } = this.state
//     let { onDragStart, onDrag, dragTolerence } = this.props

//     if (!clickStartPoint) {
//       return
//     }

//     let squaredDist =
//       (e.clientX - clickStartPoint[0]) ** 2 +
//       (e.clientY - clickStartPoint[1]) ** 2

//     if (!dragging && squaredDist > dragTolerence ** 2) {
//       this.setState({ dragging: true })

//       if (onDragStart) {
//         onDragStart(e, targetOffset)
//       }
//     } else if (onDrag) {
//       onDrag(e, targetOffset)
//     }
//   }

//   handleMouseUp = e => {

//     if (!this.isMountedComponent) return;

//     let { clickStartPoint, dragging, targetOffset } = this.state

//     let { onClick, onDragEnd } = this.props

//     if (!clickStartPoint) {
//       return
//     }

//     if (dragging) {
//       if (onDragEnd) {
//         onDragEnd(e, targetOffset)
//       }
//     } else {
//       onClick(e)
//     }

//     this.setState({
//       clickStartPoint: null,
//     })

//     this.removeListeners()
//   }

//   removeListeners = () => {
//     let doc = document.documentElement

//     doc.removeEventListener('mousemove', this.handleMouseMove)
//     doc.removeEventListener('mouseup', this.handleMouseUp)
//   }

//   render() {
//     let {
//       type,
//       children,
//       dragTolerence,
//       onClick,
//       onDragStart,
//       onDrag,
//       onDragEnd,
//       ...passThroughProps
//     } = this.props

//     let props = {
//       ...passThroughProps,
//       onMouseDown: this.handleMouseDown,
//     }

//     return React.createElement(type, props, children)
//   }
// }