import React, {Component} from 'react'
import {sort} from 'common/utils'

import {COMPONENT_INSTANCE, ELLIPSE, GROUP, LABEL, LINE, SECTION, SHAPE,} from 'common/constants'

import {DEFAULT_ZOOM} from 'utils/zoom'
import Label from '../Label'
import Section from '../Section'
import Line from '../Line'
import Ellipse from '../Ellipse'
import Shape from '../Shape'

import Group from '../Group'

import ComponentInstance from './index'
import GenericWrapper from './GenericWrapper'

const noop = () => {}

export default class ComponentRenderer extends Component {
  getClassMapping = () => {
    const classMapping = {
      [LABEL]: Label,
      [SECTION]: Section,
      [LINE]: Line,
      [ELLIPSE]: Ellipse,
      [SHAPE]: Shape,
      [GROUP]: Group,
      [COMPONENT_INSTANCE]: ComponentInstance,
    }

    return classMapping
  }

  renderChildren() {
    let { object, layout, hideShadows } = this.props

    if (!object.children) {
      return null
    }

    let children = sort(object.children, child => child.layout.zIndex)

    // Render the children
    return children.map(child => (
      <ComponentRenderer
        key={child.id}
        object={child}
        layout={layout}
        hideShadows={hideShadows}
      />
    ))
  }

  render() {
    let { object, layout, hideShadows } = this.props

    let objectLayout = layout[object.id]
    let ComponentClass = this.getClassMapping()[object.type] || GenericWrapper

    if (!objectLayout || !ComponentClass) {
      return null
    }

    object = {
      ...object,
      ...object.attributes,
      x: objectLayout.left,
      y: objectLayout.top,
      width: objectLayout.width,
      height: objectLayout.height,
    }

    delete object.layoutText

    let { children, ...withoutChildren } = object

    let childProps = {
      ...withoutChildren,
      xScaled: object.x,
      yScaled: object.y,
      widthScaled: object.width,
      heightScaled: object.height,
      zoom: DEFAULT_ZOOM,
      hideShadows,
      onPosition: noop,
      onExpand: noop,
      onSelect: noop,
      onEdit: noop,
    }

    return (
      <ComponentClass {...childProps}>{this.renderChildren()}</ComponentClass>
    )
  }
}
