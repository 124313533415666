import React, {Component} from 'react'
import {connect} from 'react-redux'

import {scale} from 'utils/zoom'
import {getXSnap, getYSnap} from 'ducks/editor/snapping'
import 'styles/SnapGrid.css'

class SnapGrid extends Component {
  renderXSnap() {
    let { xSnap, zoom } = this.props

    if (!xSnap || xSnap.coord === null) {
      return
    }

    let { coord } = xSnap

    let [x] = scale([coord, 0], zoom)
    x = Math.round(x + 0.5) - 0.5

    return <line x1={x} x2={x} y1={0} y2={window.innerHeight} />
  }

  renderYSnap() {
    let { ySnap, zoom } = this.props

    if (!ySnap || ySnap.coord === null) {
      return
    }

    let { coord } = ySnap
    let [, y] = scale([0, coord], zoom)
    y = Math.round(y + 0.5) - 0.5

    return <line x1={0} x2={window.innerWidth} y1={y} y2={y} />
  }

  render() {
    return (
      <g className="snap-grid">
        {this.renderXSnap()}
        {this.renderYSnap()}
      </g>
    )
  }
}

const mapStateToProps = state => ({
  xSnap: getXSnap(state),
  ySnap: getYSnap(state),
})

export default connect(mapStateToProps)(SnapGrid)
