import React, {Component} from 'react'
import classNames from 'classnames'

export default class InspectRow extends Component {
  render() {
    let { children, className, labeled, oneline,  title } = this.props

    return (
      <div
        className={classNames('inspect-panel-row', className, {
          'inspect-panel-row-labeled': labeled,
        })}
      >
        {!oneline && <div className="inspect-panel-row-title">{title}</div>}
        <div className="inspect-panel-row-body">{children}</div>
      </div>
    )
  }
}
