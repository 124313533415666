import React, {Component} from 'react'
import classNames from 'classnames'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'

import Tooltip from '../Tooltip'
import Loading from '../Loading'

import './Icon.scss'
import './IconButton.scss'

export default class Icon extends Component {
  static propTypes = {
    type: PropTypes.string,
    color: PropTypes.oneOf([
      'teal',
      'orange',
      'yellow',
      'green',
      'purple',
      'darkPink',
      'white',
    ]),
  }

  static defaultProps = {
    type: 'default',
  }

  render() {
    let { type, className, big, color, ...props } = this.props

    return (
      <span
        {...props}
        className={classNames(
          'icon',
          className,
          { 'icon-big': big, [`icon-${color}`]: color },
          `icon-${type}`
        )}
        expanded="false"
      />
    )
  }
}

export class IconButton extends Component {
  renderContent() {
    let {
      onClick,
      large,
      small,
      orange,
      yellow,
      darkPink,
      light,
      white,
      className,
      loading,
      to,
      href,
      rounded,
      active,
      solid,
      muted,
      ...props
    } = this.props

    let IconButton = to ? Link : href ? 'a' : 'div'

    return (
      <IconButton
        className={classNames('icon-button-wrapper', className, {
          'icon-button-wrapper-large': large,
          'icon-button-wrapper-small': small,
          'icon-button-wrapper-rounded': rounded,
          'icon-button-wrapper-orange': orange,
          'icon-button-wrapper-yellow': yellow,
          'icon-button-wrapper-darkPink': darkPink,
          'icon-button-wrapper-light': light,
          'icon-button-wrapper-white': white,
          'icon-button-wrapper-loading': loading,
          'icon-button-wrapper-active': active,
          'icon-button-wrapper-solid': solid,
          'icon-button-wrapper-muted': muted && !active,
        })}
        onClick={onClick}
        to={to}
        href={href}
        rel={href ? 'noopener noreferrer' : null}
      >
        { loading ? <Loading small /> : <Icon {...props} /> }
      </IconButton>
    )
  }

  render() {
    let { tooltip, placement, small } = this.props

    if (tooltip) {
      let text = tooltip

      if (tooltip?.text) text = tooltip.text
      if (tooltip?.placement) placement = tooltip.placement

      return (
        <Tooltip placement={placement} tooltip={text} small={small}>
          {this.renderContent()}
        </Tooltip>
      )
    }

    return this.renderContent()
  }
}

IconButton.defaultProps = {
  placement: 'top',
}