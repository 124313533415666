function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

export  const comparePaths = (a, b) => {
  const aPieces = a.split('.');
  const bPieces = b.split('.');
  let maxCount = Math.max(aPieces.length, bPieces.length);

  for (let i = 0; i < maxCount; i += 1) {
    if (+aPieces[i] > +bPieces[i] || bPieces[i] === undefined) {
      return 1;
    }

    if (+bPieces[i] > +aPieces[i] || aPieces[i] === undefined) {
      return -1;
    }
  }

  return 0;
};

export  const sortPaths = (paths) => {
  paths = [].concat(_toConsumableArray(paths));
  paths.sort(function (a, b) {
    return comparePaths(a, b);
  });
  return paths;
};
