import {getSnapGrid} from '../../utils/snapping'

const SET_X_SNAP = Symbol('SET_X_SNAP')
const SET_Y_SNAP = Symbol('SET_Y_SNAP')
const RESET_SNAPS = Symbol('RESET_SNAPS')

// Middleware

export const snappingMiddleware = (newState, prevState) => {
  if (newState.selection !== prevState.selection) {
    return {
      ...newState,
      ...getSnapGrid(newState, newState.selection),
      currentXSnap: null,
      currentYSnap: null,
    }
  }
}

// Reducer

export default (state, action) => {
  if ([SET_X_SNAP, SET_Y_SNAP].includes(action.type)) {
    let type = action.type === SET_X_SNAP ? 'X' : 'Y'
    let key = `current${type}Snap`
    let { coord, object } = action

    return {
      ...state,
      [key]: { coord, object },
    }
  }

  if (action.type === RESET_SNAPS) {
    return {
      ...state,
      currentXSnap: null,
      currentYSnap: null,
    }
  }
}

// Actions

export const setXSnap = (coord = null, object = null) => ({
  type: SET_X_SNAP,
  coord,
  object,
})

export const setYSnap = (coord = null, object = null) => ({
  type: SET_Y_SNAP,
  coord,
  object,
})

export const resetSnaps = () => ({ type: RESET_SNAPS })

// Selectors

export const getXGrid = state => state.editor.objects.present.xGrid

export const getYGrid = state => state.editor.objects.present.yGrid

export const getXSnap = state => state.editor.objects.present.currentXSnap

export const getYSnap = state => state.editor.objects.present.currentYSnap
