import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import './Menu.scss';

const items = [
    { path: '/admin/orders', text: 'Заказы' },
    { path: '/admin/drafts', text: 'Все черновики' },
    { path: '/admin/users', text: 'Пользователи' },

    { path: '/admin/template-type', text: 'Категории кейсов' },
    { path: '/admin/template-type/null/templates', text: 'Кейсы, без категории' },
    { path: '/admin/library', text: 'Категории вещей' },
    { path: '/admin/libraries/null/components', text: 'Вещи, без категории' },
];

function Menu(props) {
    
    function handleClick(path) {
        props.history.push(path);
    }

    return (
        <ul className="menu relative z-10 bg-white">
            {items.map(item => (
                <li
                    key={item.path}
                    onClick={handleClick.bind(null, item.path)}
                    className={
                        props.location.pathname === item.path
                            ? 'menu__item menu__item--active'
                            : 'menu__item'
                    }
                >
                    <Link to={item.path}>{item.text}</Link>
                </li>
            ))}
        </ul>
    );
}

export default withRouter(Menu);