import axios from 'axios'

import {apiURL} from '../../utils/io'

const REQUEST_BUILDS = 'REQUEST_BUILDS'
const CREATE_BUILD = 'CREATE_BUILD'

const INITIAL_STATE = {
  builds: [],
}

// Reducer
export default (state = INITIAL_STATE, action) => {
  if (action.type === `${REQUEST_BUILDS}_FULFILLED`) {
    let { data: builds } = action.payload

    return { ...state, builds }
  }

  if (action.type === `${CREATE_BUILD}_FULFILLED`) {
    let { data: build } = action.payload

    return { ...state, builds: [build].concat(state.builds) }
  }

  return state
}

// Actions

export const requestBuilds = () => ({
  type: REQUEST_BUILDS,
  payload: axios.get(`${apiURL}/drafts`),
})

export const restartBuild = buildId => (dispatch, getState) => {
  let state = getState()
  let build = getBuild(state, buildId)

  if (!build) {
    window.alert('Could not find build')
  }

  let { AppId: appId, target, version } = build

  return dispatch({
    type: CREATE_BUILD,
    payload: axios.post(`${apiURL}/drafts`, { appId, target, version }),
  })
}

// Selectors

export const getBuilds = (state, target = null) => {
  let builds = state.admin.builds.builds

  if (target) {
    builds = builds.filter(build => build.target === target)
  }

  return builds
}

export const getBuild = (state, buildId) => {
  let builds = getBuilds(state)

  return builds.filter(b => b.id === buildId)[0]
}
