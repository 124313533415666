import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {Field, reduxForm} from 'redux-form'
import QS from 'qs'

import Button from '../Shared/Button'
import WrappedInput from '../Shared/Forms/WrappedInput'
import AuthPageLogo from './Logo'

const FORM_NAME = 'signupForm'

export const validate = data => {
  let errors = {}

  if (!data.email) {
    errors.email = 'Обязательное поле'
  } else if (!data.email.match(/^.+@([\w\-]+\.)+\w{2,}$/)) {
    errors.email = 'Неверный формат эл. почты'
  }

  if (!data.name) {
    errors.name = 'Обязательное поле'
  }

  if (!data.password || data.password.length < 8) {
    errors.password = 'Пароль должен быть не менее 8 символов'
  }

  return errors
}

class SignupForm extends Component {

  render() {
    let {
      handleSubmit,
      submitFailed,
      error,
      isInvite,
      submitting,
      redirect,
    } = this.props

    let redirectQuery = QS.stringify({ redirect }, { addQueryPrefix: true })

    return (
      <form onSubmit={handleSubmit} className="auth-form">
        <AuthPageLogo />
        <h1>Регистрация</h1>
        <Field
          autoFocus={!isInvite}
          disabled={isInvite}
          name="email"
          placeholder="Эл. почта"
          type="email"

          component={WrappedInput}
          autoComplete="email"
        />
        <Field
          autoFocus={isInvite}
          name="password"
          placeholder="Пароль"
          type="password"

          component={WrappedInput}
          autoComplete="new-password"
        />
        <Field
          name="name"
          placeholder="Ваше имя"
          type="text"
          component={WrappedInput}

          autoComplete="name"
        />

        <div className="auth-form-submission-row">
          <Button large teal loading={submitting}>
            Регистрация
          </Button>
        </div>
        {error && submitFailed && (
          <div className="auth-form-error">{error}</div>
        )}
        <div className="auth-form-link">
          {'Уже есть учетная запись? '}
          <Link to={`/login${redirectQuery}`} className="link-button">
            Вход
          </Link>
        </div>
      </form>
    )
  }
}

export default reduxForm({
  form: FORM_NAME,
  validate,
})(SignupForm)
