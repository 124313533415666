import React, {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'
import {Link, withRouter} from 'react-router-dom'
import {requestApps} from '../../ducks/apps/actions'
import { getApp } from "ducks/apps/selectors";
import Icon from '../Shared/Icon'
import AppIcon from '../Shared/AppIcon'
import {getCurrentAppId, getRootComponent} from "../../ducks/editor/objects";

const AppLink = ({
  app,
  className,
  children,
  isCurrent,
  icon,
  name,

  ...otherProps
}) => {
  const LinkComponent = otherProps.to ? Link : 'a'

  return (
    <LinkComponent
      className={classNames(
        'app-switcher-link',
        {
          'app-switcher-active-link': isCurrent,
        },
        className
      )}
      {...otherProps}
    >
      {icon ? (
        <img className="app-switcher-link-icon" src={icon} alt={name} />
      ) : app ? (
        <AppIcon medium app={app} />
      ) : null}
      <div className="app-switcher-link-center">
        <div className="app-switcher-link-text">{children}</div>

      </div>
      {isCurrent ? <Icon type="done" /> : null}
    </LinkComponent>
  )
}

AppLink.defaultProps = {
  isCurrent: false,
}

class AppSwitcher extends Component {
  state = {
    isNewAppModalOpen: false,
  }

  handleModalClose = () => {
    this.setState({ isNewAppModalOpen: false })
  }

  handleModalOpen = e => {
    e.preventDefault()
    this.setState({ isNewAppModalOpen: true })
  }

  componentDidMount() {
    let { requestApps } = this.props

    requestApps()
  }

  render() {
    const { currentApp, app, root } = this.props
    const { isNewAppModalOpen } = this.state

  if (!app) {
      return null
    }

    return (
      <div className="app-switcher-container">
        <div className={"app-switcher"}>
          {app.name}&nbsp;{app.description}
        </div>
      </div>
      /*<div className="app-switcher-container">
        <Dropdown
          title={
            <div className="app-switcher-current-app" title={currentApp.name}>
              <span className="app-switcher-current-app-name">
                {currentApp.name}
              </span>
              <Icon type="expand-vertical" />
            </div>
          }
          className="app-switcher"
        >
          <div className="app-switcher-dropdown-inner">
            {apps.map(app => (
              <AppLink
                app={app}
                key={app.id}
                to={`/apps/${app.id}`}
                isCurrent={currentApp.id === app.id}
                organization={app.Organization && app.Organization.name}
                name={app.name}
              >
                {app.name}
              </AppLink>
            ))}
          </div>
          <AppLink
            className="app-switcher-new-app-link"
            href="/new"
            icon={addButtonIcon}
            name="New App"
            onClick={this.handleModalOpen}
          >
            CREATE NEW APP
          </AppLink>
        </Dropdown>
        {isNewAppModalOpen ? (
          <NewAppModal onClose={this.handleModalClose} />
        ) : null}
      </div>*/
    )
  }
}

function getAppId(location) {
  if (location.match && location.match.appId) {
    return location.match.appId
  }

  const match = location.pathname.match(/^\/apps\/([\w\-]+)(\/|$)/)

  return match && match[1]
}

const mapStateToProps = (state, { location }) => {


  /*getApp
  const currentApp =
    apps && currentAppId && apps.find(app => app.id === currentAppId)*/

  return {
    app: getApp(state, getCurrentAppId(state)),
    root: getRootComponent(state),
  }
}

export default withRouter(
  connect(mapStateToProps, { requestApps })(AppSwitcher)
)
