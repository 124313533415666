// Selectors for data binding
export const CURRENT_USER_SELECTOR = 'CURRENT_USER_SELECTOR'

export const  PREVIEW_URL =
  process.env.REACT_APP_PREVIEW_URL || 'http://localhost:3001'
export const  WEB_URL = process.env.REACT_APP_WEB_BASE_URL

export const selectors = {
  CURRENT_USER: CURRENT_USER_SELECTOR,
  LIST_ITEM: 'LIST_ITEM_SELECTOR',
  ROUTE_PARAM: 'ROUTE_PARAM_SELECTOR',
  SELECT_VALUE: 'SELECT_VALUE_SELECTOR',
  CREATED_OBJECT: 'CREATED_OBJECT',
}

// Transition types
export const TRANSITION_PUSH = 'TRANSITION_PUSH'
export const TRANSITION_MODAL = 'TRANSITION_MODAL'
export const TRANSITION_NONE = 'TRANSITION_NONE'

export const transitions = {
  PUSH: TRANSITION_PUSH,
  NONE: TRANSITION_NONE,
  MODAL: TRANSITION_MODAL,

  // Slide - back view moves at same speed
  SLIDE_LEFT: 'TRANSITION_SLIDE_LEFT',
  SLIDE_RIGHT: 'TRANSITION_SLIDE_RIGHT',
  SLIDE_UP: 'TRANSITION_SLIDE_UP',
  SLIDE_DOWN: 'TRANSITION_SLIDE_DOWN',

  // Float - back view is stationary
  FLOAT_LEFT: 'TRANSITION_FLOAT_LEFT',
  FLOAT_RIGHT: 'TRANSITION_FLOAT_RIGHT',
  FLOAT_UP: 'TRANSITION_FLOAT_UP',
  FLOAT_DOWN: 'TRANSITION_FLOAT_DOWN',
}

export const TRANSITION_TYPE_OPTIONS = [
  { label: 'Push', value: transitions.PUSH },
  { label: 'Modal', value: transitions.MODAL },
  { label: 'None', value: transitions.NONE },
  null,
  { label: '← Slide Left', value: transitions.SLIDE_LEFT },
  { label: '→ Slide Right', value: transitions.SLIDE_RIGHT },
  { label: '↑ Slide Up', value: transitions.SLIDE_UP },
  { label: '↓ Slide Down', value: transitions.SLIDE_DOWN },
  null,
  { label: '← Float Left', value: transitions.FLOAT_LEFT },
  { label: '→ Float Right', value: transitions.FLOAT_RIGHT },
  { label: '↑ Float Up', value: transitions.FLOAT_UP },
  { label: '↓ Float Down', value: transitions.FLOAT_DOWN },
]

// Object types
export const LABEL = 'label'
export const SECTION = 'section'
export const ELLIPSE = 'ellipse'
export const SHAPE = 'shape'
export const RECTANGLE = 'rectangle'
export const WRAPPER = 'wrapper'
export const GROUP = 'group'
export const IMAGE = 'image'
export const LIST = 'list'
export const CARD_VIEW = 'card-view'
export const INPUT = 'input'
export const IMAGE_UPLOAD = 'image-upload'
export const FILE_UPLOAD = 'file-upload'
export const DATE_PICKER = 'date-picker'
export const SELECT = 'select'
export const FORM = 'form'
export const ROW = 'row'
export const CHECKBOX = 'checkbox'
export const COLUMN = 'column'
export const ROW_SPACER = 'rowSpacer'
export const COLUMN_SPACER = 'columnSpacer'
export const WEB_VIEW = 'web-view'
export const COMPONENT = 'component'
export const COMPONENT_INSTANCE = 'componentInstance'
export const LIBRARY_COMPONENT = 'libraryComponent'
export const SHAPE_COMPONENT = 'shapeComponent'
export const LINE = 'line'

export const TYPE_OPTIONS = [
  { label: 'Text', value: LABEL },
  { label: 'Ellipse', value: ELLIPSE },
  { label: 'Shape', value: SHAPE },
  { label: 'Rectangle', value: RECTANGLE },
  { label: 'Group', value: GROUP },
  { label: 'List', value: LIST },
  { label: 'Image', value: IMAGE },
  { label: 'Text Field', value: INPUT },
  { label: 'Image Picker', value: IMAGE_UPLOAD },
  { label: 'File Picker', value: FILE_UPLOAD },
  { label: 'Date Picker', value: DATE_PICKER },
  { label: 'Select Menu', value: SELECT },
  { label: 'Web View', value: WEB_VIEW },
  { label: 'Line', value: LINE },
]

// Generic constants
export const ACTION = 'action'

// NAVIGATION
export const NAVIGATION_BACK = 'navigation.back'

// Text
export const ALIGN_LEFT = 'left'
export const ALIGN_CENTER = 'center'
export const ALIGN_RIGHT = 'right'

// Group Types
export const GROUP_TYPE_GROUP = null
export const GROUP_TYPE_INPUT = 'input'

export const GROUP_TYPES = [
  { label: 'Group', value: GROUP_TYPE_GROUP },
  { label: 'Text Input', value: GROUP_TYPE_INPUT },
]

// Event Types
export const events = {
  TAP: 'tap',
  LONG_PRESS: 'longPress',
}

export const EVENT_NAMES = {
  [events.TAP]: 'Tap',
  [events.LONG_PRESS]: 'Long Press',
}

export const EVENT_OPTIONS = [
  { label: EVENT_NAMES[events.TAP], value: events.TAP },
  { label: EVENT_NAMES[events.LONG_PRESS], value: events.LONG_PRESS },
]

export const actionTypes = {
  CREATE_OBJECT: 'createObject',
  UPDATE_OBJECT: 'updateObject',
  DELETE_OBJECT: 'deleteObject',
  CREATE_ASSOCIATION: 'createAssociation',
  DELETE_ASSOCIATION: 'deleteAssociation',
  NAVIGATE: 'navigate',
  AUTHENTICATE: 'authenticate',
  LOGOUT: 'logout',
  SIGNUP: 'signup',
  FORGOT_PASSWORD: 'forgotPassword',
  SHARE: 'share',
  SET_INPUT_VALUE: 'setInputValue',
  CALL_API: 'callAPI',
  AUTHENTICATE_API: 'authAPI',
  SIGNUP_API: 'signupAPI',
  PUSH_NOTIFICATION: 'pushNotification',
  NOTIFICATION_PERMISSION: 'notificationPermission',
  EXTERNAL_LINK: 'externalLink',
  CUSTOM: 'custom',
  AUTHENTICATE_EXTERNAL: 'authenticateExternal',
  SIGNUP_EXTERNAL: 'signupExternal',
}

export const ACTION_NAMES = {
  [actionTypes.CREATE_OBJECT]: 'Create',
  [actionTypes.UPDATE_OBJECT]: 'Update',
  [actionTypes.DELETE_OBJECT]: 'Delete',
  [actionTypes.CREATE_ASSOCIATION]: 'Add To Collection',
  [actionTypes.DELETE_ASSOCIATION]: 'Remove From Collection',
  [actionTypes.NAVIGATE]: 'Link',
  [actionTypes.AUTHENTICATE]: 'Log In',
  [actionTypes.LOGOUT]: 'Log Out',
  [actionTypes.SIGNUP]: 'Sign Up',
  [actionTypes.FORGOT_PASSWORD]: 'Forgot Password',
  [actionTypes.SHARE]: 'Share',
  [actionTypes.SET_INPUT_VALUE]: 'Change Input Value',
  [actionTypes.CALL_API]: 'Call API',
  [actionTypes.AUTHENTICATE_API]: 'Log In',
  [actionTypes.SIGNUP_API]: 'Sign Up',
  [actionTypes.PUSH_NOTIFICATION]: 'Trigger Notification',
  [actionTypes.NOTIFICATION_PERMISSION]: 'Request Notification Permission',
  [actionTypes.EXTERNAL_LINK]: 'External Link',
  [actionTypes.CUSTOM]: 'Custom Action',
}

export const authTypes = {
  EMAIL: 'email',
  USERNAME: 'username',
  PHONE_PASSWORD: 'phone/password',
  PHONE_CODE: 'phone/code',
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
}

export const authTypeOptions = [
  { label: 'Email', value: authTypes.EMAIL },
  { label: 'Username', value: authTypes.USERNAME },
  //{ label: 'Phone', value: authTypes.PHONE_PASSWORD },
  //{ label: 'Phone / Code', value: authTypes.PHONE_CODE },
  //{ label: 'Facebook', value: authTypes.FACEBOOK },
  //{ label: 'Twitter', value: authTypes.TWITTER },
]

// Data types
export const dataTypes = {
  TEXT: 'text',
  DATE: 'date',
  DATE_ONLY: 'dateOnly',
  LOCATION: 'location',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
  LIST: 'list',
  IMAGE: 'image',
  COLOR: 'color',
  ICON: 'icon',
  FILE: 'file',
  PASSWORD: 'password',
  OBJECT: 'object',
  FOREIGN_KEY: 'foreignKey',
  ACTION_REF: 'actionRef',
}

// Control types (for libraries + inspector)
export const controlTypes = {
  MENU: 'menu',
  SLIDER: 'slider',
}

// Field source types
export const SOURCE_TYPE_INPUT = 'input'
export const sourceTypes = {
  INPUT: SOURCE_TYPE_INPUT,
  FIELD: 'field',
  COUNT: 'count',
  MIN: 'min',
  MAX: 'max',
  MIN_MAX: 'minMax',
  SUM: 'sum',
  AVERAGE: 'avg',
  DATA: 'data',
  PARAM: 'param',
  LIST_ITEM: 'listItem', // TODO: Remove (replaced by selectors)
  RELATION: 'relation', // TODO: Remove (replaced by selectors)
  ROUTE_PARAM: 'routeParam', // TODO: Remove (replaced by selectors)
  PARENT_BINDING: 'parentBinding', // TODO: Remove (replaced by selectors)
  BELONGS_TO: 'belongsToRelation',
  HAS_MANY: 'hasManyRelation',
  MANY_TO_MANY: 'manyToManyRelation',
  DATETIME: 'dateTime',
  API_ENDPOINT: 'apiEndpoint',
  API_FIELD: 'apiField',
  AUTOSAVE: 'autosave',
  ACTION_ARGUMENT: 'actionArgument',
  CUSTOM_ACTION: 'customAction',
  EXTERNAL_USERS_TOKEN: 'externalUsersToken',
  EXTERNAL_USERS_ID: 'externalUsersId',
}

export const sourceTypeNames = {
  [sourceTypes.COUNT]: 'Count',
  [sourceTypes.MIN]: 'Minimum',
  [sourceTypes.MAX]: 'Maximum',
  [sourceTypes.MIN_MAX]: 'Min / Max',
  [sourceTypes.SUM]: 'Sum',
  [sourceTypes.AVERAGE]: 'Average',
  [sourceTypes.ACTION_ARGUMENT]: 'Custom Component Action Argument',
}

export const isAggregateType = type => {
  return !!sourceTypeNames[type]
}

// Data binding types
export const bindingTypes = {
  SET_PROPERTY: 'property',
  SET_TEXT: 'text',
  SET_IMAGE: 'image',
  VISIBILITY: 'visibility',
  LIST: 'list',
  SET_DATE: 'date', // used for date picker
  SET_URI: 'uri', // temporary - replace with SET_PROPERTY
  LIBRARY_PROP: 'libraryProp', // Used as placeholder for library bindings
}

export const bindingTypeNames = {
  [bindingTypes.SET_PROPERTY]: 'Set Property',
  [bindingTypes.SET_TEXT]: 'Replace Text',
  [bindingTypes.SET_IMAGE]: 'Replace Image',
  [bindingTypes.VISIBILITY]: 'Show / Hide',
  [bindingTypes.LIST]: 'List Items',
  [bindingTypes.SET_DATE]: 'Set Date',
  [bindingTypes.SET_URI]: 'Set URL',
}

// Data formatting types
export const formatters = {
  [dataTypes.DATE]: {
    RELATIVE: 'relativeDate',
    RELATIVE_SHORT: 'relativeShort',
    ABSOLUTE_DATE: 'absoluteDate',
    ABSOLUTE_DATETIME: 'absoluteDatetime',
    WEEK_DAY_LONG: 'weekDayLong',
    WEEK_DAY_SHORT: 'weekDayShort',
    TIME: 'time',
    ISO: 'iso',
  },
  [dataTypes.DATE_ONLY]: {
    RELATIVE: 'dateOnlyRelative',
    ABSOLUTE_DATE: 'dateOnlyAbsolute',
    WEEK_DAY_LONG: 'dateOnlyWeekday',
    WEEK_DAY_SHORT: 'dateOnlyWeekdayShort',
    ISO: 'iso',
  },
  [dataTypes.NUMBER]: {
    NONE: 'none',
    COMMAS: 'commas',
    ABBREVIATED: 'abbreviated',
    CURRENCY: 'currency',
    ABBREVIATED_CURRENCY: 'abbreviatedCurrency',
  },
}

export const formatterOptions = {
  [dataTypes.DATE]: [
    {
      label: 'Relative',
      subtitle: '10 days ago',
      value: formatters.date.RELATIVE,
    },
    {
      label: 'Relative (short)',
      subtitle: '10d',
      value: formatters.date.RELATIVE_SHORT,
    },
    {
      label: 'Date',
      subtitle: '7/31/2020',
      value: formatters.date.ABSOLUTE_DATE,
    },
    {
      label: 'Date / Time',
      subtitle: '7/31/2020 at 2:15 PM',
      value: formatters.date.ABSOLUTE_DATETIME,
    },
    null,
    {
      label: 'Day of Week',
      subtitle: 'Monday',
      value: formatters.date.WEEK_DAY_LONG,
    },
    {
      label: 'Day of Week (short)',
      subtitle: 'Mon',
      value: formatters.date.WEEK_DAY_SHORT,
    },
    null,
    { label: 'Time', subtitle: '2:15 PM', value: formatters.date.TIME },
    null,
    {
      label: 'No Formatting',
      subtitle: '2020-07-31T14:15:00.000Z',
      value: formatters.date.ISO,
    },
  ],
  [dataTypes.DATE_ONLY]: [
    {
      label: 'Relative',
      subtitle: '10 days ago',
      value: formatters.dateOnly.RELATIVE,
    },
    {
      label: 'Date',
      subtitle: '7/31/2017',
      value: formatters.dateOnly.ABSOLUTE_DATE,
    },
    null,
    {
      label: 'Day of Week',
      subtitle: 'Monday',
      value: formatters.dateOnly.WEEK_DAY_LONG,
    },
    {
      label: 'Day of Week (short)',
      subtitle: 'Mon',
      value: formatters.dateOnly.WEEK_DAY_SHORT,
    },
    null,
    {
      label: 'No Formatting',
      subtitle: '2020-07-31',
      value: formatters.date.ISO,
    },
  ],
  [dataTypes.NUMBER]: [
    {
      label: 'Default',
      subtitle: '1,500,000',
      value: formatters.number.COMMAS,
    },
    null,
    { label: 'None', subtitle: '1500000', value: formatters.number.NONE },
    {
      label: 'Abbreviated',
      subtitle: '1.5M',
      value: formatters.number.ABBREVIATED,
    },
    null,
    {
      label: 'Currency',
      subtitle: '$1,500,000.00',
      value: formatters.number.CURRENCY,
    },
    {
      label: 'Currency Abreviated',
      subtitle: '$1.5M',
      value: formatters.number.ABBREVIATED_CURRENCY,
    },
  ],
}

// Sort directions
export const sortDirections = {
  ASC: 'asc',
  DESC: 'desc',
}

export const sortDirectionOptions = [
  { label: 'Ascending', value: sortDirections.ASC },
  { label: 'Descending', value: sortDirections.DESC },
]

// Object positioning
export const positioning = {
  FIXED_TOP: 'fixedTop',
  FIXED_BOTTOM: 'fixedBottom',
  FIXED: 'fixed',
  DEFAULT: null,
}

export const positioningOptions = [
  { label: 'Top', value: positioning.FIXED_TOP },
  { label: 'Bottom', value: positioning.FIXED_BOTTOM },
  { label: 'None', value: positioning.DEFAULT },
]

// Border styles
export const borderStyle = {
  NONE: 'none',
  SOLID: 'solid',
  DOTTED: 'dotted',
  DASHED: 'dashed',
}

// Alias
export const borderStyles = borderStyle

export const borderStyleOptions = [
  { label: 'None', value: borderStyle.NONE },
  { label: '▬▬▬▬▬▬▬▬▬', value: borderStyle.SOLID },
  { label: '▪▪▪▪▪▪▪▪▪', value: borderStyle.DOTTED },
  { label: '▬ ▬ ▬ ▬ ▬', value: borderStyle.DASHED },
]

export const borderTypeOptions = borderStyleOptions.slice(1)

// Background styles
export const backgroundStyle = {
  NONE: 'none',
  COLOR: 'color',
  IMAGE: 'image',
}

// Alias
export const backgroundStyles = backgroundStyle

export const backgroundStyleOptions = [
  { label: 'None', value: backgroundStyle.NONE },
  { label: 'Color', value: backgroundStyle.COLOR },
  { label: 'Image', value: backgroundStyle.IMAGE },
]

export const borderPosition = {
  INSIDE: 'inside',
  CENTER: 'center',
  OUTSIDE: 'outside',
}

export const borderPositionOptions = [
  { label: 'Inside', value: borderPosition.INSIDE },
  { label: 'Center', value: borderPosition.CENTER },
  { label: 'Outside', value: borderPosition.OUTSIDE },
]

// apto-database auth field names
export const fields = {
  USERNAME: 'username',
  EMAIL: 'email',
  PASSWORD: 'password',
  FULL_NAME: 'full_name',
  TEMPORARY_PASSWORD: 'temporary_password',
  TEMPORARY_PASSWORD_EXPIRES_AT: 'temporary_password_expires_at',
}

// Single Sign-On ID field names
export const ssoFields = {
  USER_ID: 'user_id',
  PROVIDER: 'provider',
  ID_TOKEN: 'id_token',
  ACCESS_TOKEN: 'access_token',
  OAUTH_ID: 'oauth_id',
}

// Text Inputs
export const inputTypes = {
  DEFAULT: 'default',
  LOWERCASE: 'lowercase',
  PASSWORD: 'password',
  EMAIL: 'email',
  NUMBER: 'number',
}

export const inputTypeOptions = [
  { label: 'Normal', value: inputTypes.DEFAULT },
  { label: 'Lowercase', value: inputTypes.LOWERCASE },
  { label: 'Password', value: inputTypes.PASSWORD },
  { label: 'Email', value: inputTypes.EMAIL },
  { label: 'Number', value: inputTypes.NUMBER },
]

// Comparators

export const comparators = {
  EQUAL: '==',
  NOT_EQUAL: '!=',
  GREATER_THAN: '>',
  LESS_THAN: '<',
  GREATER_THAN_OR_EQUAL: '>=',
  LESS_THAN_OR_EQUAL: '<=',
  BETWEEN: 'between',
  EMPTY: 'empty',
  NOT_EMPTY: 'notEmpty',
  CONTAINS: 'contains', // Used for string fuzzy search
  LIST_CONTAINS: 'listContains',
  LIST_NOT_CONTAINS: 'listNotContains',
  TRUE: 'true',
  FALSE: 'false',
}

export const comparatorLabels = {
  [comparators.EQUAL]: 'Is equal to',
  [comparators.NOT_EQUAL]: 'Is not equal to',
  [comparators.GREATER_THAN]: 'Is greater than',
  [comparators.LESS_THAN]: 'Is less than',
  [comparators.GREATER_THAN_OR_EQUAL]: 'Is greater than or equal',
  [comparators.LESS_THAN_OR_EQUAL]: 'Is less than or equal',
  [comparators.EMPTY]: 'Is empty',
  [comparators.NOT_EMPTY]: 'Is not empty',
  [comparators.BETWEEN]: 'Is between',
  [comparators.CONTAINS]: 'Contains',
  [comparators.LIST_CONTAINS]: 'Contains',
  [comparators.LIST_NOT_CONTAINS]: 'Does not contain',
  [comparators.TRUE]: 'Is true',
  [comparators.FALSE]: 'Is false',
}

// Status Bar

export const statusBarStyles = {
  DARK_CONTENT: 'dark',
  LIGHT_CONTENT: 'light',
  HIDDEN: 'hidden',
}

export const statusBarStyleOptions = [
  { label: 'Auto', value: null },
  { label: 'Black Text', value: statusBarStyles.DARK_CONTENT },
  { label: 'White Text', value: statusBarStyles.LIGHT_CONTENT },
  { label: 'Hidden', value: statusBarStyles.HIDDEN },
]

export const imageResize = {
  CONTAIN: 'contain',
  COVER: 'cover',
}

export const imageResizeOptions = [
  { label: 'Contain', value: imageResize.CONTAIN },
  { label: 'Cover', value: imageResize.COVER },
]

export const httpMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
}

export const endpointTypes = {
  LIST: 'list',
  DETAIL: 'detail',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  CUSTOM: 'custom',
}

export const endpointTypeNames = {
  [endpointTypes.LIST]: 'Get All',
  [endpointTypes.DETAIL]: 'Get One',
  [endpointTypes.CREATE]: 'Create',
  [endpointTypes.UPDATE]: 'Update',
  [endpointTypes.DELETE]: 'Delete',
  [endpointTypes.CUSTOM]: 'Custom',
}

export const defaultMethods = {
  [endpointTypes.LIST]: httpMethods.GET,
  [endpointTypes.DETAIL]: httpMethods.GET,
  [endpointTypes.CREATE]: httpMethods.POST,
  [endpointTypes.UPDATE]: httpMethods.PUT,
  [endpointTypes.DELETE]: httpMethods.DELETE,
}

export const restEndpointTypes = [
  endpointTypes.LIST,
  endpointTypes.DETAIL,
  endpointTypes.CREATE,
  endpointTypes.UPDATE,
  endpointTypes.DELETE,
]

export const listTypes = {
  DEFAULT: 'default',
  GRID: 'grid',
  HORIZONTAL: 'horizontal',
}

export const keyboardBehaviors = {
  DEFAULT: 'default',
  AVOID: 'avoid',
}

export const imageTypes = {
  INTERNAL: 'internal',
  URL: 'url',
}
