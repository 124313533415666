import axios from 'axios'
import {buildIndex} from 'common/utils'

import {ADDONS_URL} from '../config'

const FETCH_LIST_REQUEST = Symbol('FETCH_LIST_REQUEST')
const FETCH_LIST_SUCCESS = Symbol('FETCH_LIST_SUCCESS')
const FETCH_SERVICE_SUCCESS = Symbol('FETCH_SERVICE_SUCCESS')

const INITIAL_STATE = {
  popular: [],
  byId: {},
  loading: false,
}

export default (state = INITIAL_STATE, action) => {
  if (action.type === FETCH_LIST_REQUEST) {
    return {
      ...state,
      loading: true,
    }
  }

  if (action.type === FETCH_LIST_SUCCESS) {
    let byId = buildIndex(action.result, itm => itm.id)

    return {
      ...state,
      loading: false,
      popular: action.result,
      byId: { ...state.byId, ...byId },
    }
  }

  if (action.type === FETCH_SERVICE_SUCCESS) {
    let { result } = action

    return {
      ...state,
      byId: {
        ...state.byId,
        [result.id]: result,
      },
    }
  }

  return state
}

// Actions

export const requestPopularServices = () => dispatch => {
  dispatch({ type: FETCH_LIST_REQUEST })

  axios
    .get(`${ADDONS_URL}/services`)
    .then(response => {
      dispatch({
        type: FETCH_LIST_SUCCESS,
        result: response.data,
      })
    })
    .catch(err => {
      console.error('ADDONS FETCH FAILED!', err)
    })
}

export const requestService = serviceId => dispatch => {
  axios
    .get(`${ADDONS_URL}/services/${serviceId}`)
    .then(response => {
      dispatch({
        type: FETCH_SERVICE_SUCCESS,
        result: response.data,
      })
    })
    .catch(err => {
      console.error('GET SERVICE FAILED!', err)
    })
}

// Selectors

export const getPopularServices = state => {
  if (state.addons.popular.length === 0 && state.addons.loading) {
    return null
  }

  return state.addons.popular
}

export const getService = (state, serviceId) => {
  return state.addons.byId[serviceId]
}
