import React, {Component} from 'react'
import {connect} from "react-redux";
import {getLibraries, requestLibraries, searchComponents} from "../../../../ducks/components";
import {Tree} from 'components/Shared/Tree';
import {BASE_URL, DEFAULT_PER_PAGE} from 'utils/io'
import './tab.scss'
import classNames from "classnames";
import ImagePlaceholder from "../../../Shared/ImagePlaceholder";
import Loading from "../../../Shared/Loading";
import Scrollbar from "../../../Shared/Scrollbar";

function filter(categories, searchResult) {

    return categories
        .map(category => {
            const {children, options} = category

            if (options) {
                return {...category, options: filter(options, searchString)}
            }

            return {
                ...category,
                children: children?.filter(component =>
                    component.name.toLowerCase().includes(searchString)
                ),
            }
        })
        .filter(category => {
            const {options} = category

            if (options) {
                return options?.filter(item => item?.children?.length > 0)?.length > 0
            }

            return category?.children?.length > 0
        })
}

class Tab extends Component {

    constructor(props) {
        super(props)
        this.state = {
            error: null,
            search: "",
            searchResult: [],
            loading: false,
            loaded: false,
        }
    }

    componentDidMount() {

        let {requestLibraries} = this.props

        requestLibraries()
    }

    handleSearch = e => {
        const v = e.target.value || ""

        const {searchComponents,} = this.props

        const needLoading = !!v

        if (needLoading) {
            this.setState({loading: needLoading, loaded: false, search: v})
            searchComponents(v).then(({result}) => {

                    this.setState({loading: false, loaded: true, searchResult: result || []})
                }
            )
        } else {
            this.setState({loading: false, loaded: false, search: v})
        }


    }

    render() {
        const {

            options,
            searchPlaceholder,
            selectCallback,
            libraries,
            sections,
            loadChildrenPaginated
        } = this.props

        const {search, searchResult, loading, loaded} = this.state

        const mergedOptions = [...options, ...sections, ...libraries, searchResult]

        const results = loaded ? searchResult : mergedOptions

        return (
            <>
                {/* < defaultOpen={defaultOpen} openAll={!!search}>*/}
                <div className="editor-add-panel-search">
                    <i className="material-icons">search</i>
                    <input
                        placeholder={searchPlaceholder}
                        value={search}
                        onChange={this.handleSearch}
                        autoFocus
                    />
                </div>
                <Scrollbar>
                    {!search && < Tree nodes={results}
                                       loadChildren={loadChildrenPaginated}
                                       pageLimit={DEFAULT_PER_PAGE}
                                       paginated={true}
                                       selectCallback={selectCallback}
                                       useLocalState={true}
                    />}

                    {search && (<div className={'components-tree editor-add-panel-accordion'}>

                        <div className="components-tree-content">

                            {/* Animation for node expand / collapse */}

                            {/* Loading: Overridable loading bar for pagination */}
                            {loading && <Loading/>}
                            
                            {searchResult.length > 0 && (
                                <div className="components-tree-children">


                                    {searchResult.map((childNode) => (
                                        <div className="component-tree-add-item-wrapper">
                                            <div

                                                onClick={e =>
                                                    selectCallback(e, childNode)
                                                }

                                                key={childNode.id}
                                                className={classNames(
                                                    'component-tree-add-item'
                                                )}
                                            >

                                                <ImagePlaceholder
                                                    src={`${BASE_URL + childNode.thumb}`}
                                                    width='300'
                                                    height='300'
                                                    className={classNames('component-tree-add-icon', childNode.iconClassName)}
                                                />

                                                <div className="component-tree-add-name">
                                                    <div className="component-name">{childNode.name}</div>
                                                    {childNode.size && <div className="component-size">{childNode.size}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            )}

                        </div>
                    </div>)}

                </Scrollbar></>
        )
    }
}

const mapStateToProps = (state, {match}) => {
    const libraries = getLibraries(state)
    return {
        libraries
    }
}

export default connect(mapStateToProps, {
    requestLibraries,
    searchComponents
})(Tab)
