import React from 'react';
import classNames from 'classnames';
import 'styles/Loading.css'

const Loading = ({ expanded, small }) => (
  <div
    className={classNames('loading', {
      'loading-expanded': expanded,
      'loading-small': small,
    })}
  >
    <div className="loading-icon" />
  </div>
);

export default Loading;
