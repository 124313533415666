import React from 'react'
import PropTypes from 'prop-types'

import './tooltip.scss'

const Tooltip = ({
  children,
  tooltip,
  hideArrow,
  className,
  small,
  containerClassname,
  ...props
}) => (
  <div></div>
  /*<usePopperTooltip
    {...props}
    tooltip={({
      arrowRef,
      tooltipRef,
      getArrowProps,
      getTooltipProps,
      placement,
    }) => (
      <div
        {...getTooltipProps({
          ref: tooltipRef,
          className: classNames(
            'tooltip-container',
            {
              'tooltip-container-small': small,
            },
            className,
            containerClassname
          ),
        })}
      >
        {!hideArrow && (
          <div
            {...getArrowProps({
              ref: arrowRef,
              className: 'tooltip-arrow',
              'data-placement': placement,
            })}
          />
        )}
        {typeof tooltip === 'function' ? tooltip() : tooltip}
      </div>
    )}
  >
    {({ getTriggerProps, triggerRef }) => (
      <span
        {...getTriggerProps({ ref: triggerRef, className: 'tooltip-trigger' })}
      >
        {children}
      </span>
    )}
  </usePopperTooltip>*/
)

Tooltip.propTypes = {
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  placement: PropTypes.oneOf([
    'top-start',
    'top',
    'top-end',
    'right-start',
    'right',
    'right-end',
    'bottom-start',
    'bottom',
    'bottom-end',
    'left-start',
    'left',
    'left-end',
  ]),
  hideArrow: PropTypes.bool,
  containerClassName: PropTypes.string,
}

Tooltip.defaultProps = {
  placement: 'top',
  hideArrow: false,
}

export default Tooltip
