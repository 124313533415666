export class RAFLoop {
  /**
   * @description List of targets to update
   */
  targets = [];

  /**
   * @description ID of requested animation frame. Valuable only if loop is active and has items to iterate.
   */
  animationFrameI = 0;

  /**
   * @description Loop's state.
   */
  _isActive = false;

  /**
   * @description Loop's state.
   */
  get isActive() {
    return this._isActive;
  }

  /**
   * @description Start the loop if it wasn't yet.
   */
  start = () => {
    if (!this._isActive && this.targets.length) {
      this._isActive = true;

      this.animationFrameID && cancelAnimationFrame(this.animationFrameID);
      this.animationFrameID = requestAnimationFrame(this.rafCallback);
    }

    return this;
  };

  /**
   * @description Stop the loop if is was active.
   */
  stop = () => {
    if (this._isActive) {
      this._isActive = false;

      this.animationFrameID && cancelAnimationFrame(this.animationFrameID);
      this.animationFrameID = 0;
    }

    return this;
  };

  /**
   * @description Add target to the iteration list if it's not there.
   */
  addTarget = (target, silent = false) => {
    if (this.targets.indexOf(target) === -1) {
      this.targets.push(target);

      this.targets.length === 1 && !silent && this.start();
    }

    return this;
  };

  /**
   * @description Remove target from iteration list if it was there.
   */
  removeTarget = (target) => {
    const idx = this.targets.indexOf(target);

    if (idx !== -1) {
      this.targets.splice(idx, 1);

      this.targets.length === 0 && this.stop();
    }

    return this;
  };

  /**
   * @description Callback that called each animation frame.
   */
  rafCallback = () => {
    if (!this._isActive) {
      return 0;
    }

    for (let i = 0; i < this.targets.length; i++) {
      !this.targets[i]._unmounted && this.targets[i].update();
    }

    return (this.animationFrameID = requestAnimationFrame(this.rafCallback));
  };
}

export default new RAFLoop();
