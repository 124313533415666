import Base from "./base";
import {LinkedSize, Size} from "./sizes";
import {LinkedPoint, Point} from "./point";
import {Formatter} from "./formatter";

export class Rectangle extends Base {
  constructor(arg0, arg1) {
    super(...arguments)
    this._readIndex = true
  }

  initialize(arg0, arg1, arg2, arg3) {

    this._fw = 1
    this._fh = 1

    var args = arguments,
      type = typeof arg0,
      read;
    if (type === 'number') {
      // new Rectangle(x, y, width, height)
      this._set(arg0, arg1, arg2, arg3);
      read = 4;
    } else if (type === 'undefined' || arg0 === null) {
      // new Rectangle(), new Rectangle(null)
      this._set(0, 0, 0, 0);
      read = arg0 === null ? 1 : 0;
    } else if (args.length === 1) {
      // This can either be an array, or an object literal.
      if (Array.isArray(arg0)) {
        this._set.apply(this, arg0);
        read = 1;
      } else if (arg0.x !== undefined || arg0.width !== undefined) {
        // Another rectangle or a simple object literal
        // describing one. Use duck typing, and 0 as defaults.
        this._set(arg0.x || 0, arg0.y || 0,
          arg0.width || 0, arg0.height || 0);
        read = 1;
      } else if (arg0.from === undefined && arg0.to === undefined) {
        // Use `Base.readSupported()` to read and consume whatever
        // property the rectangle can receive, but handle `from` / `to`
        // separately below.
        this._set(0, 0, 0, 0);
        if (Base.readSupported(args, this)) {
          read = 1;
        }
      }
    }
    if (read === undefined) {
      // Read a point argument and look at the next value to see whether
      // it's a size or a point, then read accordingly.
      // We're supporting both reading from a normal arguments list and
      // covering the Rectangle({ from: , to: }) constructor, through
      // Point.readNamed().
      var frm = Point.readNamed(args, 'from'),
        next = Base.peek(args),
        x = frm.x,
        y = frm.y,
        width,
        height;
      if (next && next.x !== undefined || Base.hasNamed(args, 'to')) {
        // new Rectangle(from, to)
        // Read above why we can use readNamed() to cover both cases.
        var to = Point.readNamed(args, 'to');
        width = to.x - x;
        height = to.y - y;
        // Check if horizontal or vertical order needs to be reversed.
        if (width < 0) {
          x = to.x;
          width = -width;
        }
        if (height < 0) {
          y = to.y;
          height = -height;
        }
      } else {
        // new Rectangle(point, size)
        var size = Size.read(args);
        width = size.width;
        height = size.height;
      }
      this._set(x, y, width, height);
      read = args.__index;
    }
    // arguments.__filtered wouldn't survive the function call even if a
    // previous arguments list was passed through Function#apply().
    // Return it on the object instead, see Base.read()
    var filtered = args.__filtered;
    if (filtered)
      this.__filtered = filtered;
    if (this.__read)
      this.__read = read;
    return this;
  }


  // See Point#_set() for an explanation of #_set():
  _set(x, y, width, height) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    return this;
  }


  clone() {
    return new Rectangle(this.x, this.y, this.width, this.height);
  }

  /**
   * Checks whether the coordinates and size of the rectangle are equal to
   * that of the supplied rectangle.
   *
   * @param {Rectangle} rect
   * @return {Boolean} {@true if the rectangles are equal}
   */
  equals(rect) {
    var rt = Base.isPlainValue(rect)
      ? Rectangle.read(arguments)
      : rect;
    return rt === this
      || rt && this.x === rt.x && this.y === rt.y
      && this.width === rt.width && this.height === rt.height
      || false;
  }

  /**
   * @return {String} a string representation of this rectangle
   */
  toString() {
    /*var f = Formatter.instance;
    return '{ x: ' + f.number(this.x)
            + ', y: ' + f.number(this.y)
            + ', width: ' + f.number(this.width)
            + ', height: ' + f.number(this.height)
            + ' }';*/
  }
  bbox(options) {
    var f = Formatter.instance

    // See Point#_serialize()
    return {x : this.x,
      y: this.y,
      width: this.width,
      height: this.height}
  }
  _serialize(options) {
     var f = options.formatter;
     // See Point#_serialize()
     return {x : f.number(this._x),
             y: f.number(this._y),
             width: f.number(this._width),
             height: f.number(this._height)}
  }

  /**
   * The top-left point of the rectangle
   *
   * @bean
   * @type Point
   */
  getPoint(_dontLink) {
    var ctor = _dontLink ? Point : LinkedPoint;
    return new ctor(this.x, this.y, this, 'setPoint');
  }

  setPoint(point) {

    this.x = point.x;
    this.y = point.y;
  }


  /**
   * The size of the rectangle
   *
   * @bean
   * @type Size
   */
  getSize(_dontLink) {
    var ctor = _dontLink ? Size : LinkedSize;
    return new ctor(this.width, this.height, this, 'setSize');
  }

  setSize(/* size */) {
    var size = Size.read(arguments),
      sx = this._sx,
      sy = this._sy,
      w = size.width,
      h = size.height;
    // Keep track of how dimensions were specified through this._s*
    // attributes.
    // _sx / _sy can either be 0 (left), 0.5 (center) or 1 (right), and is
    // used as direct factors to calculate the x / y adjustments from the
    // size differences.
    // _fw / _fh can either be 0 (off) or 1 (on), and is used to protect
    // width / height values against changes.
    if (sx) {
      this.x += (this.width - w) * sx;
    }
    if (sy) {
      this.y += (this.height - h) * sy;
    }
    this.width = w;
    this.height = h;
    this._fw = this._fh = 1;
  }

  /**
   * {@grouptitle Side Positions}
   *
   * The position of the left hand side of the rectangle. Note that this
   * doesn't move the whole rectangle; the right hand side stays where it was.
   *
   * @bean
   * @type Number
   */
  getLeft() {
    return this.x;
  }
  get left() {
    return this.x;
  }
  setLeft(left) {
    if (!this._fw) {
      var amount = left - this.x;
      this.width -= this._sx === 0.5 ? amount * 2 : amount;
    }
    this.x = left;
    this._sx = this._fw = 0;
  }

  getTop() {
    return this.y;
  }
  get top() {
    return this.y;
  }
  setTop(top) {
    if (!this._fh) {
      var amount = top - this.y;
      this.height -= this._sy === 0.5 ? amount * 2 : amount;
    }
    this.y = top;
    this._sy = this._fh = 0;
  }

  /**
   * The position of the right hand side of the rectangle. Note that this
   * doesn't move the whole rectangle; the left hand side stays where it was.
   *
   * @bean
   * @type Number
   */
  getRight() {
    return this.x + this.width;
  }
  get right() {
    return this.x + this.width;
  }
  setRight(right) {
    if (!this._fw) {
      var amount = right - this.x;
      this.width = this._sx === 0.5 ? amount * 2 : amount;
    }
    this.x = right - this.width;
    this._sx = 1;
    this._fw = 0;
  }

  /**
   * The bottom coordinate of the rectangle. Note that this doesn't move the
   * whole rectangle: the top won't move.
   *
   * @bean
   * @type Number
   */
  getBottom() {
    return this.y + this.height;
  }
  get bottom() {
    return this.y + this.height;
  }
  setBottom(bottom) {
    if (!this._fh) {
      var amount = bottom - this.y;
      this.height = this._sy === 0.5 ? amount * 2 : amount;
    }
    this.y = bottom - this.height;
    this._sy = 1;
    this._fh = 0;
  }

  /**
   * The center-x coordinate of the rectangle.
   *
   * @bean
   * @type Number
   * @ignore
   */
  getCenterX() {
    return this.x + this.width / 2;
  }

  setCenterX(x) {
    // If we're asked to fix the width or if _sx is already in center mode,
    // just keep moving the center.
    if (this._fw || this._sx === 0.5) {
      this.x = x - this.width / 2;
    } else {
      if (this._sx) {
        this.x += (x - this.x) * 2 * this._sx;
      }
      this.width = (x - this.x) * 2;
    }
    this._sx = 0.5;
    this._fw = 0;
  }

  /**
   * The center-y coordinate of the rectangle.
   *
   * @bean
   * @type Number
   * @ignore
   */
  getCenterY() {
    return this.y + this.height / 2;
  }

  setCenterY(y) {
    // If we're asked to fix the height or if _sy is already in center mode,
    // just keep moving the center.
    if (this._fh || this._sy === 0.5) {
      this.y = y - this.height / 2;
    } else {
      if (this._sy) {
        this.y += (y - this.y) * 2 * this._sy;
      }
      this.height = (y - this.y) * 2;
    }
    this._sy = 0.5;
    this._fh = 0;
  }

  getCenter(_dontLink) {
    var ctor = _dontLink ? Point : LinkedPoint;
    return new ctor(this.getCenterX(), this.getCenterY(), this, 'setCenter');
  }

  setCenter(/* point */) {
    var point = Point.read(arguments);
    this.setCenterX(point.x);
    this.setCenterY(point.y);
    // A special setter where we allow chaining, because it comes in handy
    // in a couple of places in core.
    return this;
  }

  getArea() {
    return this.width * this.height;
  }
  get area() {
    return this.width * this.height;
  }
  isEmpty() {
    return this.width === 0 || this.height === 0;
  }

  contains(arg) {
    // Detect rectangles either by checking for 'width' on the passed object
    // or by looking at the amount of elements in the arguments list,
    // or the passed array:
    return arg && arg.width !== undefined
    || (Array.isArray(arg) ? arg : arguments).length === 4
      ? this._containsRectangle(Rectangle.read(arguments))
      : this._containsPoint(Point.read(arguments));
  }

  _containsPoint(point) {
    var x = point.x,
      y = point.y;
    return x >= this.x && y >= this.y
      && x <= this.x + this.width
      && y <= this.y + this.height;
  }

  _containsRectangle(rect) {
    var x = rect.x,
      y = rect.y;
    return x >= this.x && y >= this.y
      && x + rect.width <= this.x + this.width
      && y + rect.height <= this.y + this.height;
  }

  intersects(rect, epsilon = 0) {

    return rect.x + rect.width > this.x - epsilon
      && rect.y + rect.height > this.y - epsilon
      && rect.x < this.x + this.width + epsilon
      && rect.y < this.y + this.height + epsilon;
  }

  intersect(rect) {

    var x1 = Math.max(this.x, rect.x),
      y1 = Math.max(this.y, rect.y),
      x2 = Math.min(this.x + this.width, rect.x + rect.width),
      y2 = Math.min(this.y + this.height, rect.y + rect.height);
    return new Rectangle(x1, y1, x2 - x1, y2 - y1);
  }

  unite(rect) {
    var x1 = Math.min(this.x, rect.x),
      y1 = Math.min(this.y, rect.y),
      x2 = Math.max(this.x + this.width, rect.x + rect.width),
      y2 = Math.max(this.y + this.height, rect.y + rect.height);
    return new Rectangle(x1, y1, x2 - x1, y2 - y1);
  }

  include(point) {
    var x1 = Math.min(this.x, point.x),
      y1 = Math.min(this.y, point.y),
      x2 = Math.max(this.x + this.width, point.x),
      y2 = Math.max(this.y + this.height, point.y);
    return new Rectangle(x1, y1, x2 - x1, y2 - y1);
  }

  expand( amount ) {
    var
      hor = amount.width,
      ver = amount.height;
    return new Rectangle(this.x - hor / 2, this.y - ver / 2,
      this.width + hor, this.height + ver);
  }

  scale(hor, ver) {
    return this.expand(this.width * hor - this.width,
      this.height * (ver === undefined ? hor : ver) - this.height);
  }

  getTopLeft(_dontLink) {
    var ctor = _dontLink ? Point : LinkedPoint;
    return new ctor(this.getLeft(), this.getTop(), this, 'setTopLeft');
  }
  setTopLeft(/* point */) {
    var point = Point.read(arguments);
    this.setLeft(point.x);
    this.setTop(point.y);
  }
  getTopRight(_dontLink) {
    var ctor = _dontLink ? Point : LinkedPoint;
    return new ctor(this.getRight(), this.getTop(), this, 'setTopRight');
  }
  setTopRight(/* point */) {
    var point = Point.read(arguments);
    this.setRight(point.x);
    this.setTop(point.y);
  }
  getBottomLeft(_dontLink) {
    var ctor = _dontLink ? Point : LinkedPoint;
    return new ctor(this.getLeft(), this.getBottom(), this, 'setBottomLeft');
  }
  setBottomLeft(/* point */) {
    var point = Point.read(arguments);
    this.setLeft(point.x);
    this.setBottom(point.y);
  }
  getBottomRight(_dontLink) {
    var ctor = _dontLink ? Point : LinkedPoint;
    return new ctor(this.getRight(), this.getBottom(), this, 'setBottomRight');
  }
  setBottomRight(/* point */) {
    var point = Point.read(arguments);
    this.setRight(point.x);
    this.setBottom(point.y);
  }
  getLeftCenter(_dontLink) {
    var ctor = _dontLink ? Point : LinkedPoint;
    return new ctor(this.getLeft(), this.getCenterY(), this, 'setLeftCenter');
  }
  setLeftCenter(/* point */) {
    var point = Point.read(arguments);
    this.setLeft(point.x);
    this.setCenterY(point.y);
  }
  getTopCenter(_dontLink) {
    var ctor = _dontLink ? Point : LinkedPoint;
    return new ctor(this.getCenterX(), this.getTop(), this, 'setTopCenter');
  }
  setTopCenter(/* point */) {
    var point = Point.read(arguments);
    this.setCenterX(point.x);
    this.setTop(point.y);
  }
  getRightCenter(_dontLink) {
    var ctor = _dontLink ? Point : LinkedPoint;
    return new ctor(this.getRight(), this.getCenterY(), this, 'setRightCenter');
  }
  setRightCenter(/* point */) {
    var point = Point.read(arguments);
    this.setRight(point.x);
    this.setCenterY(point.y);
  }
  getBottomCenter(_dontLink) {
    var ctor = _dontLink ? Point : LinkedPoint;
    return new ctor(this.getCenterX(), this.getBottom(), this, 'setBottomCenter');
  }
  setBottomCenter(/* point */) {
    var point = Point.read(arguments);
    this.setCenterX(point.x);
    this.setBottom(point.y);
  }

}
export class LinkedRectangle extends Rectangle {
  constructor(arg0, arg1) {
    super(...arguments)
  }

  // Have LinkedRectangle appear as a normal Rectangle in debugging
  initialize(x, y, width, height, owner, setter) {
    this._set(x, y, width, height, true);
    this._owner = owner;
    this._setter = setter;
  }

  // See Point#_set() for an explanation of #_set():
  _set(x, y, width, height, _dontNotify) {
    this._x = x;
    this._y = y;
    this._width = width;
    this._height = height;
    if (!_dontNotify)
      this._owner[this._setter](this);
    return this;
  }
  bbox(options) {
    var f = Formatter.instance
    // See Point#_serialize()
    return {x : (this._x),
      y: (this._y),
      width: (this._width),
      height: (this._height)}
  }

  getX() {
    return this._x
  }
  get x() {
    return this._x
  }
  getY() {
    return this._y
  }
  get y() {
    return this._y
  }
  get width() {
    return this._width
  }

  get height() {
    return this._height
  }

  getWidth() {
    return this._width
  }

  getHeight() {
    return this._height
  }

  setX(value) {
    this._x = value;
    // Check if this setter is called from another one which sets
    // _dontNotify, as it will notify itself
    if (!this._dontNotify)
      this._owner[this._setter](this);

  }

  setY(value) {
    this._y = value;
    // Check if this setter is called from another one which sets
    // _dontNotify, as it will notify itself
    if (!this._dontNotify)
      this._owner[this._setter](this);
  }

  setWidth(value) {
    this._width = value;
    // Check if this setter is called from another one which sets
    // _dontNotify, as it will notify itself
    if (!this._dontNotify)
      this._owner[this._setter](this);
  }

  setHeight(value) {
    this._height = value;
    // Check if this setter is called from another one which sets
    // _dontNotify, as it will notify itself
    if (!this._dontNotify)
      this._owner[this._setter](this);
  }

  setPoint() {
    var proto = Rectangle.prototype;
// Make sure the above setters of x, y, width, height do not
    // each notify the owner, as we're going to take care of this
    // afterwards here, only once per change.
    this._dontNotify = true;
    proto.setPoint.apply(this, arguments);
    this._dontNotify = false;
    this._owner[this._setter](this);
  }

  setSize() {
    var proto = Rectangle.prototype;
// Make sure the above setters of x, y, width, height do not
    // each notify the owner, as we're going to take care of this
    // afterwards here, only once per change.
    this._dontNotify = true;
    proto.setSize.apply(this, arguments);
    this._dontNotify = false;
    this._owner[this._setter](this);
  }

  setCenter() {
    var proto = Rectangle.prototype;
// Make sure the above setters of x, y, width, height do not
    // each notify the owner, as we're going to take care of this
    // afterwards here, only once per change.
    this._dontNotify = true;
    proto.setCenter.apply(this, arguments);
    this._dontNotify = false;
    this._owner[this._setter](this);
  }

  setLeft() {
    var proto = Rectangle.prototype;
// Make sure the above setters of x, y, width, height do not
    // each notify the owner, as we're going to take care of this
    // afterwards here, only once per change.
    this._dontNotify = true;
    proto.setLeft.apply(this, arguments);
    this._dontNotify = false;
    this._owner[this._setter](this);
  }

  setTop() {
    var proto = Rectangle.prototype;
// Make sure the above setters of x, y, width, height do not
    // each notify the owner, as we're going to take care of this
    // afterwards here, only once per change.
    this._dontNotify = true;
    proto.setTop.apply(this, arguments);
    this._dontNotify = false;
    this._owner[this._setter](this);
  }

  setRight() {
    var proto = Rectangle.prototype;
// Make sure the above setters of x, y, width, height do not
    // each notify the owner, as we're going to take care of this
    // afterwards here, only once per change.
    this._dontNotify = true;
    proto.setRight.apply(this, arguments);
    this._dontNotify = false;
    this._owner[this._setter](this);
  }

  setBottom() {
    var proto = Rectangle.prototype;
// Make sure the above setters of x, y, width, height do not
    // each notify the owner, as we're going to take care of this
    // afterwards here, only once per change.
    this._dontNotify = true;
    proto.setBottom.apply(this, arguments);
    this._dontNotify = false;
    this._owner[this._setter](this);
  }

  setCenterX() {
    var proto = Rectangle.prototype;
// Make sure the above setters of x, y, width, height do not
    // each notify the owner, as we're going to take care of this
    // afterwards here, only once per change.
    this._dontNotify = true;
    proto.setCenterX.apply(this, arguments);
    this._dontNotify = false;
    this._owner[this._setter](this);
  }

  setCenterY() {
    var proto = Rectangle.prototype;
// Make sure the above setters of x, y, width, height do not
    // each notify the owner, as we're going to take care of this
    // afterwards here, only once per change.
    this._dontNotify = true;
    proto.setCenterY.apply(this, arguments);
    this._dontNotify = false;
    this._owner[this._setter](this);
  }

  setTopLeft() {
    var proto = Rectangle.prototype;
// Make sure the above setters of x, y, width, height do not
    // each notify the owner, as we're going to take care of this
    // afterwards here, only once per change.
    this._dontNotify = true;
    proto.setTopLeft.apply(this, arguments);
    this._dontNotify = false;
    this._owner[this._setter](this);
  }

  setTopRight() {
    var proto = Rectangle.prototype;
// Make sure the above setters of x, y, width, height do not
    // each notify the owner, as we're going to take care of this
    // afterwards here, only once per change.
    this._dontNotify = true;
    proto.setTopRight.apply(this, arguments);
    this._dontNotify = false;
    this._owner[this._setter](this);
  }

  setBottomLeft() {
    var proto = Rectangle.prototype;
// Make sure the above setters of x, y, width, height do not
    // each notify the owner, as we're going to take care of this
    // afterwards here, only once per change.
    this._dontNotify = true;
    proto.setBottomLeft.apply(this, arguments);
    this._dontNotify = false;
    this._owner[this._setter](this);
  }

  setBottomRight() {
    var proto = Rectangle.prototype;
// Make sure the above setters of x, y, width, height do not
    // each notify the owner, as we're going to take care of this
    // afterwards here, only once per change.
    this._dontNotify = true;
    proto.setBottomRight.apply(this, arguments);
    this._dontNotify = false;
    this._owner[this._setter](this);
  }

  setLeftCenter() {
    var proto = Rectangle.prototype;
// Make sure the above setters of x, y, width, height do not
    // each notify the owner, as we're going to take care of this
    // afterwards here, only once per change.
    this._dontNotify = true;
    proto.setLeftCenter.apply(this, arguments);
    this._dontNotify = false;
    this._owner[this._setter](this);
  }

  setTopCenter() {
    var proto = Rectangle.prototype;
// Make sure the above setters of x, y, width, height do not
    // each notify the owner, as we're going to take care of this
    // afterwards here, only once per change.
    this._dontNotify = true;
    proto.setTopCenter.apply(this, arguments);
    this._dontNotify = false;
    this._owner[this._setter](this);
  }

  setRightCenter() {
    var proto = Rectangle.prototype;
// Make sure the above setters of x, y, width, height do not
    // each notify the owner, as we're going to take care of this
    // afterwards here, only once per change.
    this._dontNotify = true;
    proto.setRightCenter.apply(this, arguments);
    this._dontNotify = false;
    this._owner[this._setter](this);
  }

  setBottomCenter() {
    var proto = Rectangle.prototype;
// Make sure the above setters of x, y, width, height do not
    // each notify the owner, as we're going to take care of this
    // afterwards here, only once per change.
    this._dontNotify = true;
    proto.setBottomCenter.apply(this, arguments);
    this._dontNotify = false;
    this._owner[this._setter](this);
  }

}
