import React, {Component} from 'react'

export default class Marquee extends Component {
  render() {
    let { x, y, width, height } = this.props

    let styles = {
      left: `${x}px`,
      top: `${y}px`,
      width: `${width}px`,
      height: `${height}px`,
    }

    return <div className="marquee" style={styles} />
  }
}
