// DraftInitCustomSizes.js
import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { notify } from 'ducks/notifications'
import { convertUnit } from "../../../utils/unit";
import { setDraftInitCustomSize } from './store/actions'

import 'styles/NewApp.css';
import WrappedNumberInput from './components/wrapped_number_input';




const DraftInitCustomSizes = ({
  history,
  notify,
  width,
  height,
  depth,
  setDraftInitCustomSize,
}) => {

  const [canProceed, setCanProceed] = useState(false);

  const handleChange = (name, value) => {

    setDraftInitCustomSize({ [name]: value });

    validateValues({ width, height, depth, [name]: value });
  }

  const validateValues = (valuesToValidate) => {

    const { width, height, depth } = valuesToValidate

    if (
      width && !isNaN(width) && width > 0 &&
      height && !isNaN(height) && height > 0 &&
      depth && !isNaN(depth) && depth > 0
    ) {
      setCanProceed(true)
    } else {
      setCanProceed(false)
    }

  }

  const handleNext = (e) => {

    const px_width = convertUnit(width)

    if (px_width < 99) {
      notify(`Error: Установите минимальную ширину 26мм`); return
    }

    const px_height = convertUnit(height)
    if (px_height < 99) {
      notify(`Error: Установите минимальную высоту 26мм`); return
    } 

    history.push('/draft/init/custom/sizes/select/name')

  }

  useEffect(() => {
    validateValues({ width, height, depth })
  }, [width, height, depth])

  return (
    <>
      <div className="stepper stepper-teal">
        <div className="step step-done">
          <div className="step-bullet">
            <span className="icon icon-done" expanded="false"></span>
          </div>
          <div className="step-text">Тип кейса</div>
        </div>
        <hr />
        <div className="step step-done step-current">
          <div className="step-bullet">2</div>
          <div className="step-text">Выбор кейса</div>
        </div>
        <hr />
        <div className="step">
          <div className="step-bullet">3</div>
          <div className="step-text">Настройки</div>
        </div>
      </div>

      <div id="new-app-template-form" className="new-app-form">
        <div className="panel-section">

          <div className="panel-section-header">
            <h4>Укажите габариты вашего пространства</h4>
          </div>

          <div className="panel-section-body">

            <WrappedNumberInput
              label="Ширина"
              name="width"
              value={width}
              onChange={(value) => handleChange('width', value)}
              suffix="mm"
              placeholder="Введите ширину"
            />

            <WrappedNumberInput
              label="Высота"
              name="height"
              value={height}
              onChange={(value) => handleChange('height', value)}
              suffix="mm"
              placeholder="Введите высоту"
            />

            <WrappedNumberInput
              label="Глубина"
              name="depth"
              value={depth}
              onChange={(value) => handleChange('depth', value)}
              suffix="mm"
              placeholder="Введите глубину"
            />

          </div>

          <div style={{ color: 'red', textAlign: 'center', textTransform: 'uppercase' }}>
            минимальные ширина и высота 26мм == 99px
          </div>

        </div>
      </div>

      <div className="new-app-form-footer">

        <Link to={`/draft/init/mode`} className="btn previous">
          Назад
        </Link>

        {canProceed && (
          <div className="btn next align-right" onClick={handleNext}>
            Далее
          </div>
        )}

      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  width: state.draftInitCustomSizes.width,
  height: state.draftInitCustomSizes.height,
  depth: state.draftInitCustomSizes.depth,
})

const mapDispatchToProps = (dispatch) => ({
  notify: (message) => dispatch(notify(message)),
  setDraftInitCustomSize: (payload) => dispatch(setDraftInitCustomSize(payload)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DraftInitCustomSizes)
)