// App Users

import axios from 'axios'
import {getId} from 'common/utils'

import {baseURL} from '../../utils/io'

const REQUEST_USERS = 'REQUEST_APP_USERS'
const ADD_USER = 'ADD_APP_USER'
const REMOVE_USER = 'REMOVE_APP_USER'
const INVITE_USER = 'INVITE_USER'

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
  if (action.type === `${REQUEST_USERS}_FULFILLED`) {
    let { appId, data } = action.payload

    return {
      ...state,
      [appId]: data,
    }
  }

  if (action.type === `${ADD_USER}_FULFILLED`) {
    let { appId, data } = action.payload
    let users = state[appId] || []

    if (users.filter(u => u.id === data.id).length > 0) {
      return state
    }

    users = users.concat(data)

    return {
      ...state,
      [appId]: users,
    }
  }

  if (action.type === `${REMOVE_USER}_FULFILLED`) {
    let { appId, userId } = action.payload

    let users = state[appId] || []
    users = users.filter(itm => itm.UserId !== userId)

    return {
      ...state,
      [appId]: users,
    }
  }

  if (action.type === `${INVITE_USER}_FULFILLED`) {
    let { appId, email } = action.payload

    let users = state[appId] || []

    users = users.concat({
      id: getId(),
      User: { email },
    })

    return {
      ...state,
      [appId]: users,
    }
  }

  return state
}

// Actions

export const requestAppUsers = appId => ({
  type: REQUEST_USERS,
  payload: axios.get(`${baseURL}/apps/${appId}/users`).then(resp => ({
    appId,
    data: resp.data,
  })),
})

export const addAppUser = (appId, userId) => ({
  type: ADD_USER,
  payload: axios
    .post(`${baseURL}/apps/${appId}/users`, { UserId: userId })
    .then(resp => ({
      appId,
      data: resp.data,
    })),
})

export const removeAppUser = (appId, userId) => ({
  type: REMOVE_USER,
  payload: axios
    .delete(`${baseURL}/apps/${appId}/users/${userId}`)
    .then(resp => ({
      appId,
      userId,
    })),
})

export const inviteNewUser = (appId, email) => ({
  type: INVITE_USER,
  payload: axios
    .post(`${baseURL}/apps/${appId}/invite`, { email })
    .then(() => ({
      appId,
      email,
    })),
})

// Selectors

export const getAppUsers = (state, appId) => {
  return state.appUsers[appId] || []
}
