import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { notify } from 'ducks/notifications';
import { withRouter } from 'react-router-dom';

import { setOuterRule, setTemplateSetting } from 'components/Admin/template/store/templateActions';
import NumberInputPoints from 'components/Admin/template/NumberInputPoints';

class TemplatePoint extends Component {

    state = {
        acceleration: 1,
        timeoutRef: null,
    }

    roundToPrecision = (num, precision = 4) => parseFloat(num.toFixed(precision))



    handlePointChange = (pointIndex, e) => {

        let { name, value, type } = e.target

        let parsedValue = type !== 'number' ? parseFloat(value) : value

        if (parsedValue < 0) parsedValue = 0


        const { outer_rule, setting, setOuterRule } = this.props

        const activeRuleIndex = setting.activeRuleIndex
        const active_outer_rule = outer_rule[activeRuleIndex]

        const updatedPoints = active_outer_rule.points.map((point, index) => {

            if (index === pointIndex) {
                return name === "0" ? [parsedValue, point[1]] : [point[0], parsedValue]
            }

            return point
        })

        const updatedOuterRule = outer_rule.map((rule, index) => {

            if (index === activeRuleIndex) {
                return { ...rule, points: updatedPoints }
            }

            return rule
        })

        setOuterRule(updatedOuterRule)
    }

    setActivePoint = (pointIndex) => {
        this.props.setTemplateSetting({ activePointIndex: pointIndex });
    }

    changeValue = (pointIndex, name, delta) => {
        const { outer_rule, setting } = this.props;
        const activeRuleIndex = setting.activeRuleIndex;
        const points = [...outer_rule[activeRuleIndex].points];
        const currentValue = parseFloat(points[pointIndex][name]);
        const newValue = this.roundToPrecision(Math.max(0, currentValue + delta * this.state.acceleration))
        this.handlePointChange(pointIndex, { target: { name, value: newValue, type: 'number' } });
    }

    startChange = (pointIndex, name, delta) => {
        this.setState({ acceleration: 1 });
        clearTimeout(this.state.timeoutRef);
        this.changeValue(pointIndex, name, delta);
        this.setState({
            timeoutRef: setTimeout(() => this.accelerateChange(pointIndex, name, delta), 50),
        });
    }

    stopChange = () => {
        clearTimeout(this.state.timeoutRef);
        this.setState({ acceleration: 1 });
    }

    accelerateChange = (pointIndex, name, delta) => {
        this.setState((prevState) => ({ acceleration: Math.min(prevState.acceleration + 0.5, 10) }));
        this.changeValue(pointIndex, name, delta);
        this.setState({
            timeoutRef: setTimeout(() => this.accelerateChange(pointIndex, name, delta), 10),
        });
    }

    render() {

        const { 
            pointIndex, 
            point, 
            axis 
        } = this.props

        return (
            <div className="w-full">
                
                {/* <label htmlFor={`point_${pointIndex}_${axis}`} className="mr-2 font-bold">{axis.toUpperCase()}</label>
                 */}
                <div className="grid grid-cols-3 w-full space-x-2">

                    <NumberInputPoints
                        code={axis === 'x' ? '0' : '1'}
                        name={axis === 'x' ? '0' : '1'}
                        value={point}
                        onFocus={() => this.setActivePoint(pointIndex)}
                        onChange={(e) => this.handlePointChange(pointIndex, e)}
                        className="w-full px-2"
                    />
                    
                    <div className='flex flex-col justify-between'>

                        <button
                            type="button"
                            onMouseDown={() => this.startChange(pointIndex, axis === 'x' ? '0' : '1', -0.001)}
                            onMouseUp={this.stopChange}
                            onMouseLeave={this.stopChange}
                            className="w-6 h-full text-xs flex items-center justify-center bg-gray-200 border border-gray-300 rounded hover:bg-gray-300 focus:outline-none"
                        >
                            -
                        </button>

                        <button
                            type="button"
                            onMouseDown={() => this.startChange(pointIndex, axis === 'x' ? '0' : '1', 0.001)}
                            onMouseUp={this.stopChange}
                            onMouseLeave={this.stopChange}
                            className="w-6 h-full text-xs flex items-center justify-center bg-gray-200 border border-gray-300 rounded hover:bg-gray-300 focus:outline-none"
                        >
                            +
                        </button>

                    </div>

                </div>
            </div>
        );
    }
}

TemplatePoint.propTypes = {
    pointIndex: PropTypes.number.isRequired,
    point: PropTypes.number.isRequired,
    axis: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
    setting: state.admin.template.setting,
    outer_rule: state.admin.template.outer_rule,
})

const mapDispatchToProps = (dispatch) => ({
    notify: (message) => dispatch(notify(message)),
    setTemplateSetting: (setting) => dispatch(setTemplateSetting(setting)),
    setOuterRule: (outer_rule) => dispatch(setOuterRule(outer_rule)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TemplatePoint))
