import axios from 'axios';
import qs from 'qs';
import { apiURL } from '../../utils/io';
import {
    CREATE_DRAFT,
    DELETE_DRAFT,
    LOAD_DRAFTS,
    LOAD_ALL_DRAFTS,
    LOAD_DRAFT,
    UPDATE_DRAFT,
    PATCH_DRAFT,
    CHECKOUT_DRAFT,
    COPY_DRAFT,
    LOAD_TEMPLATES_LIST,
    LOAD_TEMPLATE,
    SET_DRAFT,
    LOAD_APP,
    DELETE_APP,
    SET_APP_LIBRARIES,
    LOAD_COMPONENT,
    LOAD_APPS_LIST,
    UNSET_LAUNCH_COMPONENT,
    REQUEST_APP,
    REQUEST_ALL,
    UPDATE_APP,
    SET_LOADING_TEMPLATES,
    CREATE_INVITE,

    UPDATE_APP_OUTER_RULE,
    UPDATE_APP_PARTS,
    UPDATE_APP_NAME,
    UPDATE_APP_WIDTH,
    UPDATE_APP_HEIGHT,
    UPDATE_APP_LOWER_WIDTH,
    UPDATE_APP_LOWER_HEIGHT,
    UPDATE_APP_DEPTH,
    UPDATE_APP_BORDER_WIDTH,
    UPDATE_APP_BORDER_RADIUS,
    UPDATE_COMPONENT_POSITION,
    UPDATE_COMPONENT_NAME,
    UPDATE_COMPONENT_WIDTH,
    UPDATE_COMPONENT_HEIGHT,
    UPDATE_COMPONENT_DEPTH,
    INITIALIZE_STATE

} from './actionTypes';

import { getTemplatesState } from './selectors';


export const initializeStateAction = (appId, data) => ({
    type: INITIALIZE_STATE,
    payload: {
        appId,
        data,
    },
});

export const updateAppParts = (appId, parts) => ({
    type: UPDATE_APP_PARTS,
    payload: {
        appId,
        parts,
    },
});


export const updateAppOuterRule = (appId, outerRule) => ({
    type: UPDATE_APP_OUTER_RULE,
    payload: {
        appId,
        outerRule,
    },
});

export const updateAppName = (appId, name) => ({
    type: UPDATE_APP_NAME,
    payload: {
        appId,
        name,
    },
});

export const updateAppWidth = (appId, width) => ({
    type: UPDATE_APP_WIDTH,
    payload: {
        appId,
        width,
    },
});

export const updateAppHeight = (appId, height) => ({
    type: UPDATE_APP_HEIGHT,
    payload: {
        appId,
        height,
    },
});

export const updateAppLowerWidth = (appId, lowerWidth) => ({
    type: UPDATE_APP_LOWER_WIDTH,
    payload: {
        appId,
        lowerWidth,
    },
});

export const updateAppLowerHeight = (appId, lowerHeight) => ({
    type: UPDATE_APP_LOWER_HEIGHT,
    payload: {
        appId,
        lowerHeight,
    },
});

export const updateAppDepth = (appId, depth) => ({
    type: UPDATE_APP_DEPTH,
    payload: {
        appId,
        depth,
    },
});

export const updateAppBorderWidth = (appId, borderWidth) => ({
    type: UPDATE_APP_BORDER_WIDTH,
    payload: {
        appId,
        borderWidth,
    },
});

export const updateAppBorderRadius = (appId, borderRadius) => ({
    type: UPDATE_APP_BORDER_RADIUS,
    payload: {
        appId,
        borderRadius,
    },
});

// Экшен-креаторы для обновления свойств компонента
export const updateComponentPosition = (appId, componentId, x, y) => ({
    type: UPDATE_COMPONENT_POSITION,
    payload: {
        appId,
        componentId,
        x,
        y,
    },
});

export const updateComponentName = (appId, componentId, name) => ({
    type: UPDATE_COMPONENT_NAME,
    payload: {
        appId,
        componentId,
        name,
    },
});

export const updateComponentWidth = (appId, componentId, width) => ({
    type: UPDATE_COMPONENT_WIDTH,
    payload: {
        appId,
        componentId,
        width,
    },
});

export const updateComponentHeight = (appId, componentId, height) => ({
    type: UPDATE_COMPONENT_HEIGHT,
    payload: {
        appId,
        componentId,
        height,
    },
});

export const updateComponentDepth = (appId, componentId, depth) => ({
    type: UPDATE_COMPONENT_DEPTH,
    payload: {
        appId,
        componentId,
        depth,
    },
});















export const loadApp = app => ({
    type: LOAD_APP,
    app,
});

export const setAppLibraries = (appId, libraries) => ({
    type: SET_APP_LIBRARIES,
    payload: { appId, libraries },
});

export const loadComponent = (appId, componentId, component) => ({
    type: LOAD_COMPONENT,
    appId,
    componentId,
    component,
});

export const requestApps = () => ({ type: REQUEST_ALL });
export const requestApp = appId => ({ type: REQUEST_APP, appId });

export const setAuthComponentId = (appId, authComponentId) => ({
    type: UPDATE_APP,
    appId,
    data: { authComponentId },
});

export const updateApp = (appId, data) => ({
    type: UPDATE_APP,
    appId,
    data,
});

export const deleteApp = appId => ({
    type: DELETE_APP,
    appId,
});

export const deleteDraft = (draftId, data) => ({
    type: DELETE_DRAFT,
    payload: axios.delete(`${apiURL}/drafts/${draftId}`, data),
});

export const copyDraft = (draftId, data) => ({
    type: COPY_DRAFT,
    payload: axios.post(`${apiURL}/drafts/${draftId}/copy`, data),
});

export const updateDraft = (draftId, data) => ({
    type: UPDATE_DRAFT,
    payload: axios.put(`${apiURL}/drafts/${draftId}`, data),
});

export const patchDraft = (draftId, data) => ({
    type: PATCH_DRAFT,
    payload: axios.put(`${apiURL}/drafts/${draftId}/patch`, data),
});

export const checkoutDraft = (draftId, data) => ({
    type: CHECKOUT_DRAFT,
    payload: axios.post(`${apiURL}/drafts/${draftId}/checkout`, data),
});

export const createInvite = (draftId, email) => ({
    type: CREATE_INVITE,
    payload: axios.post(`${apiURL}/drafts/${draftId}/invite`, { email }),
});

// THUNKS
export const createDraft = (data) => ({
    type: CREATE_DRAFT,
    payload: axios.post(`${apiURL}/drafts`, data),
});

export const loadDrafts = () => ({
    type: LOAD_DRAFTS,
    payload: axios.get(`${apiURL}/drafts`),
});

export const requestAllDrafts = opts => ({
    type: LOAD_ALL_DRAFTS,
    payload: axios.get(`${apiURL}/drafts/all?${qs.stringify(opts)}`),
});

export const loadDraft = (appId) => ({
    type: LOAD_DRAFT,
    payload: axios.get(`${apiURL}/drafts/${appId}`),
});

export const setDraft = (draft) => ({
    type: SET_DRAFT,
    payload: draft,
});

export const loadTemplatesList = () => ({
    type: LOAD_TEMPLATES_LIST,
    payload: axios.get(`${apiURL}/templates`),
});

export const loadTemplate = (id) => ({
    type: LOAD_TEMPLATE,
    payload: axios.get(`${apiURL}/templates/${id}`),
});

const DEFAULT_DELAY = process.env.NODE_ENV === 'development' ? 1000 : null;

export const ensureTemplatesAreLoaded = ({ delay = DEFAULT_DELAY } = {}) => {
    return (dispatch, getState) => {
        const state = getTemplatesState(getState());

        if (state.loading || state.loaded) {
            return;
        }

        dispatch(loadTemplatesList());
    };
};

export const ensureCreateDraft = (data, { delay = DEFAULT_DELAY } = {}) => {
    return (dispatch, getState) => {
        return dispatch(createDraft(data));
    }
}
