import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form'
import Button from '../Shared/Button'
import WrappedInput from '../Shared/Forms/WrappedInput'
import AuthPageLogo from './Logo'

const FORM_NAME = 'recoverPasswordForm'

export const validate = data => {
  let errors = {}

  if (!data.password || data.password.length < 8) {
    errors.password = 'Password must be at least 8 characters'
  }

  return errors
}

class RecoverPasswordForm extends Component {
  render() {
    let { handleSubmit, error } = this.props

    return (
      <form onSubmit={handleSubmit} className="auth-form">
        <AuthPageLogo />
        <h1>Смена пароля</h1>
        <p>Пожалуйста, введите свой новый пароль</p>
        <Field
          autoFocus
          name="new_password"
          placeholder="Пароль"
          type="password"

          component={WrappedInput}
        />
        <div className="auth-form-submission-row">
          <Button large teal>
            Обновить
          </Button>
        </div>
        {error && <div className="auth-form-error">{error}</div>}
      </form>
    )
  }
}

export default reduxForm({
  form: FORM_NAME,
  validate,
})(RecoverPasswordForm)
