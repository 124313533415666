import React, {Component} from 'react';

import TreeNode from './TreeNode';

// default components and theme
import {defaultTheme as DEFAULT_THEME} from '../theme';

// open/close animation for ReactCSSTransitionGroup
import '../animations/index.css';

// depth and indent width determine hierarchical indentation
import {DEFAULT_DEPTH, DEFAULT_INDENT_WIDTH} from '../util/constants';

class Tree extends Component {
  
  // default loadChildren implementation to be overridden via props
  loadChildren = async (node, pageLimit,) => node.children;

  render() {
    const {
      depth = DEFAULT_DEPTH,
      style,
      className,
      nodes,
      theme = DEFAULT_THEME,
      indentWidth = DEFAULT_INDENT_WIDTH,

      loadChildren = this.loadChildren,
      parse,
      pageLimit,
      toggleCallback,
      selectCallback,
      useLocalState,
      paginated,
      badge,
      value
    } = this.props;

    const parsedNodes = parse ? parse(nodes) : nodes;

    return (

      <div style={{ ...style }} className={className}>
        {parsedNodes.map((node, index) => (

          <TreeNode
            key={node.id || index}
            value = {value}
            depth={depth}
            node={node}
            theme={theme}
            badge={badge}
            indentWidth={indentWidth}
            loadChildren={loadChildren}
            parse={parse}
            pageLimit={pageLimit}
            toggleCallback={toggleCallback}
            selectCallback={selectCallback}
            useLocalState={useLocalState}
            paginated={paginated}
          />
          
        ))}
      </div>
    );
  }
}

export default Tree;
