import React, {Component} from 'react'
import {connect} from 'react-redux'
import classNames from 'classnames'

import {getTransform, scale, scaleValue, unScaleValue,} from 'utils/zoom'

import {defaultBranding} from 'utils/colors'
import Icon from 'components/Shared/Icon'

class PageShadow extends Component {
  renderIcon() {
    let { component } = this.props

    if (component.reusableComponent) {
      return (
        <span className="page-title-icons">
          <Icon type="component-small" />
        </span>
      )
    }

    return (
      <span className="page-title-icons">

      </span>
    )
  }

  render() {
    let {
      component,
      zoom,
      zoomActive,
      onEnterTitle,
      onLeaveTitle,
      onClickTitle,
      yOffset,
      transparent,
    } = this.props

    let { x, y, width, height } = component

    y = y + yOffset
    height = height - yOffset

    let [left, top] = scale([x, y], zoom)
    let widthScaled = scaleValue(width, zoom)
    let heightScaled = scaleValue(height, zoom)

    let styles = {
      left,
      top,
      width: widthScaled,
      height: heightScaled,
    }

    let offset = unScaleValue(4, zoom)
    let size = unScaleValue(8, zoom)
    let hairline = unScaleValue(0.5, zoom)

    const shadowOpacity = transparent ? 0.1 : 1

    let transformStyles = {
      transform: getTransform(zoom, false, x, y),
      width,
      height,
      boxShadow: `0 ${offset}px ${size}px rgba(0, 0, 0, ${0.1 * shadowOpacity}),
                  0 0 0 ${hairline}px rgba(0, 0, 0, ${0.1 * shadowOpacity})`,
    }

    return (
      <React.Fragment>
       {/* <div className="page-shadow" style={transformStyles} />*/}
        <div
          key={component.id}
          className={classNames('page-title-wrapper', {
            'page-title-wrapper-transparent': transparent,
          })}
          style={styles}
        >
          {zoomActive || widthScaled < 22 ? null : (
            <div
              className={classNames('page-title', {
                'component-page-title': component.reusableComponent,
              })}
              onMouseEnter={onEnterTitle}
              onMouseLeave={onLeaveTitle}
              onMouseDown={onClickTitle}
            >
              <span className="page-title-text">{component.name}</span>

            </div>
          )}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  branding:  defaultBranding,
})

export default connect(mapStateToProps)(PageShadow)
