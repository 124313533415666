import React, {Component} from 'react'

export default class MenuOption extends Component {
  handleClick = () => {
    let { onSelect, option } = this.props

    if (!onSelect) {
      return
    }

    onSelect(option.value)
  }

  render() {
    let { option } = this.props

    return (
      <div className="panel-menu-option" onClick={this.handleClick}>
        {option.label}
      </div>
    )
  }
}
