import React from 'react'
import {connect} from 'react-redux'

import {loadComponent} from 'ducks/apps/actions'
import {getCurrentAppId} from 'ducks/editor/objects'
import {requestLibrary} from 'ducks/editor/libraries'
import {getCurrentUser} from 'ducks/users'

import {getAuthToken} from 'utils/auth'
import {scaleValue} from 'utils/zoom'

import Loading from 'components/Shared/Loading'

import BaseObject from '../BaseObject'
import LibraryComponentSub from './LibraryComponentSub'

class LibraryComponent extends BaseObject {
  state = {
    isLoading: false,
  }

  handleLibraryUpdate = async name => {
    const { libraryName, libraryVersion, requestLibrary } = this.props

    if (libraryName !== name) return null
    this.setState({ isLoading: true })

    await requestLibrary(libraryName, libraryVersion)

    this.setState({ isLoading: false })
  }

  librarySocket = () => {
    const sessionToken = getAuthToken()
    const socket = sessionToken && librarySocket(sessionToken)

    return socket
  }

  componentDidMount() {
    const { currentUser } = this.props

    if (currentUser?.developer) {
      const socket = this.librarySocket()
      if (socket) socket.on('libraryUpdates', this.handleLibraryUpdate)
    }
  }

  componentWillUnmount() {
    const { currentUser } = this.props

    if (currentUser?.developer) {
      const socket = this.librarySocket()
      if (socket) socket.off('libraryUpdates', this.handleLibraryUpdate)
    }
  }

  renderLoading = () => {
    const { xScaled, yScaled, zoom, object } = this.props
    const { height, width } = object

    const boundingWidth = scaleValue(width + 40, zoom)
    const boundingHeight = scaleValue(height + 40, zoom)
    const offsetX = xScaled - scaleValue(20, zoom)
    const offsetY = yScaled - scaleValue(20, zoom)

    return (
      <foreignObject
        x={offsetX}
        y={offsetY}
        width={boundingWidth}
        height={boundingHeight}
        pointerEvents="none"
      >
        <Loading small expanded />
      </foreignObject>
    )
  }

  render() {
    let { xScaled, yScaled, zoom, libraryVersion, object } = this.props
    const { isLoading } = this.state

    let childProps = object

    return (
      <g
        onMouseDown={this.handleMouseDown}
        onDoubleClick={this.handleDoubleClick}
        onTouchStart={this.handleMouseDown}
      >
        {isLoading ? (
          this.renderLoading()
        ) : (
          <LibraryComponentSub
            object={childProps}
            libraryVersion={libraryVersion}
            zoom={zoom}
            x={xScaled}
            y={yScaled}
            isLoading={isLoading}
          />
        )}
      </g>
    )
  }
}

const mapStateToProps = (state, { id, libraryName }) => {
  let appId = getCurrentAppId(state)
  const currentUser = getCurrentUser(state)

  return {
    currentUser,
  }
}

export default connect(mapStateToProps, { loadComponent, requestLibrary })(
  LibraryComponent
)
