import update from 'immutability-helper'

const showConnectionsString = window.localStorage.getItem('showConnections')

const showConnections = showConnectionsString
  ? JSON.parse(showConnectionsString)
  : true

const initialState = {
  showConnections,
}

// TYPES
const TOGGLE_CONNECTIONS = Symbol('TOGGLE_CONNECTIONS')

// REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_CONNECTIONS: {
      const showConnections = !state.showConnections

      // update localStorage with the new value
      window.localStorage.setItem('showConnections', showConnections)

      return update(state, { $merge: { showConnections } })
    }

    default:
      return state
  }
}

// ACTIONS
export const toggleConnections = () => ({ type: TOGGLE_CONNECTIONS })

// SELECTORS
export const getShowConnections = state => {
  return state.settings.showConnections
}
