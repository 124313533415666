// templateActions.js


export const SET_OUTER_RULE       = 'SET_OUTER_RULE'
export const SET_PARTS            = 'SET_PARTS'
export const SET_IN_CASE_SHAPES   = 'SET_IN_CASE_SHAPES'



export const SET_TEMPLATE         = 'SET_TEMPLATE'
export const SET_POINTS           = 'SET_POINTS'
export const SET_TEMPLATE_SETTING = 'SET_TEMPLATE_SETTING'

export const setTemplateSetting = (setting) => ({
  type: SET_TEMPLATE_SETTING,
  payload: setting,
})

export const setTemplate = (template) => ({
  type: SET_TEMPLATE,
  payload: template,
})

export const setOuterRule = (outerRule) => ({
  type: SET_OUTER_RULE,
  payload: outerRule,
})

export const setParts = (parts) => ({
  type: SET_PARTS,
  payload: parts,
})

export const setInCaseShapes = (inCaseShapes) => ({
  type: SET_IN_CASE_SHAPES,
  payload: inCaseShapes,
})
