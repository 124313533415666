import React from "react";
import Button from "components/Shared/Button";
import cn from 'classnames';
import styles from './index.module.scss';
import { getApp } from "ducks/apps/selectors";
import {getCurrentAppId} from "ducks/editor/objects";
import {connect} from "react-redux";
import {setTool} from "ducks/editor/tools";
import {getImage, getPath, getSize, updatePath, updateSize, uploadImage} from "ducks/trace";

export class Panel extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            depth: props.depth
        }
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }


    handleChangeSize = (data) => {

        const {updateSize} = this.props;

        this.setState((prevState) => {
            return {
                ...prevState,
                ...data
            }
        }, () => {
            updateSize(data)
        })

    }


    render() {

        let { activeLayer, depth, onSplit, onM} = this.props

        if (!activeLayer) return null
        return (
            <div className={cn(styles.component)}>
                <div className={cn(styles.wrapper)}>

                    <div className={cn(styles.content)}>


                       {/* {activeLayer && <>
                            <div className={cn(styles.row)}>
                                <div className="panel-section">

                                    <div className="panel-section-header">
                                        <h4>Размер</h4>
                                    </div>

                                    <div
                                        className={classNames('panel-section-body')}
                                    >

                                        <SingleNumberControl

                                            label="Глубина"
                                            name="depth"
                                            value={depth}
                                            onChange={this.handleChangeSize}
                                        />
                                    </div>
                                </div>

                            </div>
                        </>}*/}
                        {activeLayer && <>
                            <div className={cn(styles.row)}>
                                <div className={cn(styles.item, styles.x6)}>
                                    <Button
                                        view="primary"
                                        onClick={() => {
                                            onSplit()
                                        }}
                                        /*disabled={disabled}*/
                                    >
                                        Разделить
                                    </Button>
                                </div>
                                {/*<div className={cn(styles.item, styles.x6)}>
                                    <Button
                                        view="tertiary"
                                        onClick={() => {

                                        }}
                                    >
                                        Объеденить
                                    </Button>
                                </div>*/}
                            </div>
                        </>
                        }
                    </div>
                </div>
            </div>

        );
    }
}


export const mapStateToProps = (state, {appId}) => {

    const app = getApp(state, getCurrentAppId(state))
    const image = getImage(state)
    const path = getPath(state)
    const size = getSize(state)
    return {
        app,
        image,
        path,
        size

    }
}


export default connect(mapStateToProps, {updatePath, uploadImage, updateSize, setTool})(Panel)
