import { SET_ORIENTATION } from './orintationActions'
import { initializeStateOrintation } from './initialState'


const orintationDetailsReducer = (state = initializeStateOrintation(), action) => {
    switch (action.type) {
        case SET_ORIENTATION:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

export default orintationDetailsReducer
