import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import Icon from '../../Shared/Icon'

class CloseButton extends Component {



  render() {


    const {onClose} = this.props
    return (
      <div onClick={onClose} className="editor-trace-close">
        <Icon type="close" />
      </div>
    )
  }
}

export default withRouter(CloseButton)
