// Импорт функции инициализации начального состояния
import { templatesInitState } from './initialState';



export const getApps            = state => state.apps.list;
export const getAllDrafts       = state => state.apps.all;
export const getLastApp         = state => state.apps.lastApp || state.apps.list[0]?.id;
export const getAppFromList     = (state, appId) => state.apps.list.find(({ id }) => id === appId);




export const getApp             = (state, appId) => {
    return state.apps?.apps?.[appId]
}



export const getAppsLoaded = state => state.apps.loaded;

export const canEditDraft = (state, draftId) => {
    const app = getApp(state, draftId);
    return app?.status !== "checkout";
};

export const getDatasourceType = (state, appId) => {
    const app = getApp(state, appId);
    return app ? 'apto-backend' : undefined;
};

const getComponents = (state, appId) => {
    const app = state.apps.apps[appId];
    if (!app) return [];
    return sort(Object.keys(app.components).map(id => ({ ...app.components[id], id })), c => c.name);
};

export const getScreens = (state, appId) => getComponents(state, appId).filter(c => !c.reusableComponent);
export const getReusableComponents = (state, appId) => getComponents(state, appId).filter(c => c.reusableComponent);
export const getComponent = (state, appId, componentId) => state.apps.apps[appId]?.components?.[componentId];

export const getNestedComponentsMap = (state, appId, component) => {
    let map = {};
    if (!component || !component.objects) return map;
    traverse(component.objects, obj => {
        if (obj.type === COMPONENT_INSTANCE) {
            const newComponent = getComponent(state, appId, obj.componentId);
            map[obj.componentId] = newComponent;
            Object.assign(map, getNestedComponentsMap(state, appId, newComponent));
        }
    });
    return map;
};


export const getAuthComponentId = (state, appId) => state.apps.apps[appId]?.authComponentId;
export const getAppPlatform = (state, appId) => state.apps.apps[appId]?.primaryPlatform || 'mobile';
export const getTemplatesState = state => state.apps.templates || templatesInitState();

export const getTemplateReduce = (state, appId) => {
    
    const templates = getTemplatesState(state);

    return templates?.list?.reduce((acc, curr) => {
        if (!acc[curr.template_type_id]) acc[curr.template_type_id] = [];
        acc[curr.template_type_id].push(curr);
        return acc;
    }, {});
};

