const END_EDIT = Symbol('END_EDIT')
const BEGIN_EDIT = Symbol('BEGIN_EDIT')

const END_PATH_EDIT = Symbol('END_PATH_EDIT')
const SET_EDITING_PATH = Symbol('SET_EDITING_PATH')

const SET_DRAGGING_OUT = Symbol('SET_DRAGGING_OUT')
const SET_DRAGGING_IN = Symbol('SET_DRAGGING_IN')
const END_DRAG = Symbol('END_DRAGGING')
const SET_SELECTED_POINT = Symbol('SET_SELECTED_POINT')
const SET_DRAGGING_SELECTED_POINT = Symbol('SET_DRAGGING_SELECTED_POINT')

export default (state, action) => {
  if (action.type === BEGIN_EDIT) {
    return {
      ...state,
      shapeEditing: action.id,
    }
  }

  if (action.type === SET_EDITING_PATH) {
    let { pathIndex } = action

    return {
      ...state,
      pathIndex: pathIndex
    }
  }

  if (action.type === END_EDIT) {
    return {
      ...state,
      shapeEditing: null,
    }
  }

  if (action.type === SET_DRAGGING_OUT) {
    return {
      ...state,
      draggingOutControl: true,
      draggingInControl: false,
    }
  }

  if (action.type === SET_DRAGGING_IN) {
    return {
      ...state,
      draggingInControl: true,
      draggingOutControl: false,
    }
  }

  if (action.type === END_DRAG) {
    return {
      ...state,
      draggingInControl: false,
      draggingOutControl: false,
    }
  }

  if (action.type === SET_SELECTED_POINT) {
    let { selectedPoint, draggingSelectedPoint } = action

    return {
      ...state,
      selectedPoint,
      draggingSelectedPoint,
    }
  }

  if (action.type === SET_DRAGGING_SELECTED_POINT) {
    let { draggingSelectedPoint } = action

    return {
      ...state,
      draggingSelectedPoint,
    }
  }

  return state
}

// Actions

export const setEditingShape = id => {
  if (id) {
    return {
      type: BEGIN_EDIT,
      id,
    }
  }

  return { type: END_EDIT }
}
export const setEditingPath = (pathIndex) => ({
  type: SET_EDITING_PATH,
  pathIndex,

})

export const setDraggingOutControl = () => ({
  type: SET_DRAGGING_OUT,
})

export const setDraggingInControl = () => ({
  type: SET_DRAGGING_IN,
})

export const endDrag = () => ({
  type: END_DRAG,
})

export const setSelectedPoint = (selectedPoint, dragging = false) => ({
  type: SET_SELECTED_POINT,
  selectedPoint,
  draggingSelectedPoint: dragging,
})

export const setDraggingSelectedPoint = draggingSelectedPoint => ({
  type: SET_DRAGGING_SELECTED_POINT,
  draggingSelectedPoint,
})

// Selectors

export const getEditingShape = state => {
  return state.editor.objects.present.shapeEditing
}
export const getEditingPath = state => {
  return state.editor.objects.present.pathIndex
}

export const getDraggingOutControl = state => {
  return state.editor.objects.present.draggingOutControl
}

export const getDraggingInControl = state => {
  return state.editor.objects.present.draggingInControl
}

export const getSelectedPoint = state => {
  return state.editor.objects.present.selectedPoint
}

export const getDraggingSelectedPoint = state => {
  return state.editor.objects.present.draggingSelectedPoint
}
