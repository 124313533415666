import React, {Component} from 'react'

import TextControl from './TextControl'

export default class SingleNumberControl extends Component {

  render() {

    let {
      name,
      value,
      defaultValue,
      onChange,
      onFocus,
      onBlur,
      label,
      disabled,
      placeholder,
      allowEmpty,
      minValue,
      maxValue,
      suffix,
      gray,
      converted
    } = this.props

    if (value === undefined) {
      value = defaultValue
    }


    return (
      <div className="single-number-control">
        
        {label ? (
          <label className="single-number-control-label">{label}</label>
        ) : null}

        <TextControl
          gray={gray}
          allowEmpty={allowEmpty}
          disabled={disabled}
          type="number"
          name={name}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          minValue={minValue}
          maxValue={maxValue}
          suffix={suffix}
          converted = {converted}
        />

      </div>
    )
  }
}
