import React, {Component} from 'react'
import {connect} from 'react-redux'
import QS from 'qs'


import {getApps, getAppsLoaded, getLastApp} from '../../ducks/apps/selectors'
import {loadDrafts, requestApps} from '../../ducks/apps/actions'


import EmptyState from '../Shared/EmptyState'
import Loading from '../Shared/Loading'
import DraftList from './List'
import 'styles/Apps.css'
import ModalWrapper from "./ModalWrapper";

class Apps extends Component {
  componentDidMount() {

    let { loadDrafts } = this.props
    loadDrafts()
  }

  getParams = () => {
    let {
      location: { search },
    } = this.props

    let params = QS.parse(search || '', { ignoreQueryPrefix: true })

    return params
  }

  render() {

    let {  children, modalProps, appsLoaded } = this.props

    return (
      <div
        className="apps-page"
        title="Чертежи"
        // baseURL="/"
      >
        {!appsLoaded && <EmptyState greedy>
          <Loading large />
        </EmptyState>}
        {appsLoaded && <DraftList/> }
        <ModalWrapper/>
      </div>
    )
  }
}

const mapStateToProps = state => ({

  lastApp: getLastApp(state),
  appsLoaded: getAppsLoaded(state),


})

export default connect(mapStateToProps, { requestApps, loadDrafts, getApps })(Apps)