import React from 'react'
import {Spacer} from '../App/Navbar'


import 'styles/Nav.css'
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {isAdmin} from "../../ducks/users";


const NavAdmin = props => {
    return (
        <>
            <Spacer/>
            {
                (
                    props.isAdmin && 
                    <div className={'navbar-admin'}>
                        <Link to={"/admin/orders"}>Управление</Link>
                    </div>
                )
            }
        </>
    )
}

const mapStateToProps = (state, {location}) => {
    return {
        isAdmin: isAdmin(state),
    }
}

export default withRouter(
    connect(mapStateToProps, {})(NavAdmin)
)


