
const getDist = (point1, point2) => {

    if (!point1 || !point2) {
        return NaN
    }

    let [x1, y1] = point1
    let [x2, y2] = point2

    return Math.hypot(x2 - x1, y2 - y1)
}

const pathLength = (path) => {

    if (!path) {
        return 0
    }

    return path.split('.').length
}

const subPath = (path, length) => {
    return (path || '').split('.').slice(0, length).join('.')
}

const getObject = (objects, path) => {

  if (!objects || !path || path.length === 0) {
    return null
  }

  if (typeof path === 'string') {
    path = path.split('.')
  }

  if (path.length === 1) {
    return objects[path[0]]
  }

  const newObject = objects[path[0]]

  return newObject && getObject(newObject.children, path.slice(1)) || null
}

const removeChildren = (paths) => {

    const map = {}

    paths.forEach(function (path) {
        map[path] = true
    })

    paths.forEach(function (path) {
        for (let l = 0; l <= pathLength(path) - 1; l += 1) {
            if (map[subPath(path, l)]) {
                delete map[path]; break
            }
        }
    })

    return paths.filter(function (path) {
        return map[path]
    })

}

export function remove_children(positioningObjects, list, map, selection, position, positioningStartPoint){

    if (!positioningObjects && getDist(position, positioningStartPoint) >= 5) {

        positioningObjects = {}

        let paths = removeChildren(selection.map(id => map[id]))

        for (let path of paths) {

            let obj = getObject(list, path)

            if (obj && paths.includes(path)) {
                positioningObjects[obj.id] = [obj.x, obj.y]
            }

        }
    
    }

    return positioningObjects

}