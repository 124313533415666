/* eslint no-multi-spaces: 0 */

import {getObjectsInRect} from 'common/utils'

import {scale, unScaleValue} from './zoom'

export const getPathData = (obj1, obj2, zoom) => {
  // If overlapping, return empty
  if (!obj1 || !obj2 || getObjectsInRect([obj1], obj2).length !== 0) {
    return ''
  }

  let orientation = getOrientation(obj1, obj2)
  let data

  let handleDist =
    Math.max(Math.abs(orientation[1]), Math.abs(orientation[2])) / 3

  let arrowSize = unScaleValue(6, zoom)

  switch (orientation[0]) {
    case 'down':
      data = [
        [centerX(obj1), endY(obj1)],
        [centerX(obj1), endY(obj1) + handleDist],
        [centerX(obj2), obj2.y - handleDist - arrowSize],
        [centerX(obj2), obj2.y - unScaleValue(6, zoom)],
      ]

      break

    case 'up':
      data = [
        [centerX(obj1), obj1.y],
        [centerX(obj1), obj1.y - handleDist],
        [centerX(obj2), endY(obj2) + handleDist + arrowSize],
        [centerX(obj2), endY(obj2) + unScaleValue(6, zoom)],
      ]

      break

    case 'right':
      data = [
        [endX(obj1), centerY(obj1)],
        [endX(obj1) + handleDist, centerY(obj1)],
        [obj2.x - handleDist - arrowSize, centerY(obj2)],
        [obj2.x - arrowSize, centerY(obj2)],
      ]

      break

    case 'left':
      data = [
        [obj1.x, centerY(obj1)],
        [obj1.x - handleDist, centerY(obj1)],
        [endX(obj2) + handleDist + arrowSize, centerY(obj2)],
        [endX(obj2) + arrowSize, centerY(obj2)],
      ]

      break
  }

  data = data.map(itm => scale(itm, zoom))

  let startPoint = data[0]
  let rest = data.slice(1)
  let curveString = rest.map(itm => itm.join(' ')).join(', ')

  return `M ${startPoint.join(' ')} C ${curveString}`
}

export const getOrientation = (obj1, obj2) => {
  let separations = [
    ['right', obj2.x - (obj1.x + obj1.width), centerY(obj1) - centerY(obj2)],
    ['left', obj1.x - (obj2.x + obj2.width), centerY(obj1) - centerY(obj2)],
    ['down', obj2.y - (obj1.y + obj1.height), centerX(obj1) - centerX(obj2)],
    ['up', obj1.y - (obj2.y + obj2.height), centerX(obj1) - centerX(obj2)],
  ]

  let max = separations[0]

  for (let itm of separations) {
    if (itm[1] > max[1]) {
      max = itm
    }
  }

  return max
}

export const getArrowPath = (obj1, obj2, zoom) => {
  if (!obj1 || !obj2 || getObjectsInRect([obj1], obj2).length !== 0) {
    return ''
  }

  let orientation = getOrientation(obj1, obj2)

  let points = []
  let anchor

  switch (orientation[0]) {
    case 'right':
      anchor = scale([obj2.x, centerY(obj2)], zoom)

      points = [
        anchor,
        [anchor[0] - 6, anchor[1] - 3.5],
        [anchor[0] - 6, anchor[1] + 3.5],
      ]

      break

    case 'left':
      anchor = scale([endX(obj2), centerY(obj2)], zoom)

      points = [
        anchor,
        [anchor[0] + 6, anchor[1] - 3.5],
        [anchor[0] + 6, anchor[1] + 3.5],
      ]

      break

    case 'down':
      anchor = scale([centerX(obj2), obj2.y], zoom)

      points = [
        anchor,
        [anchor[0] - 3.5, anchor[1] - 6],
        [anchor[0] + 3.5, anchor[1] - 6],
      ]

      break

    case 'up':
      anchor = scale([centerX(obj2), endY(obj2)], zoom)

      points = [
        anchor,
        [anchor[0] - 3.5, anchor[1] + 6],
        [anchor[0] + 3.5, anchor[1] + 6],
      ]

      break
  }

  return `M ${points.map(p => p.join(' ')).join(' L ')} Z`
}

export const centerX = obj => obj.x + obj.width / 2
export const centerY = obj => obj.y + obj.height / 2
export const endX = obj => obj.x + obj.width
export const endY = obj => obj.y + obj.height
