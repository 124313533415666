import React, {Component} from 'react'
import classNames from 'classnames'
import Dropzone from 'react-dropzone'

import {uploadImage, uploadURL} from '../../../utils/uploads'
import Loading from '../Loading'
import {IconButton} from '../Icon'

import 'styles/FileInput.css'

export default class FileInput extends Component {
  static defaultProps = {
    uploadFunc: uploadImage,
    filenameFunc: value => value.filename,
    uploadURLFunc: value => uploadURL(value.url),
    acceptedMimeTypes: [
      'image/jpeg',
      'image/png',
      'image/gif',
      'video/mp4',
      'video/avi',
      'video/mpeg',
      'video/quicktime',
      'video/x-msvideo',
      'video/x-ms-wmv',
      'video/webm',
      'video/ogg',
      'audio/mpeg',
      'audio/mp4',
      'application/pdf',
      'application/x-compressed',
      'application/x-zip-compressed',
      'application/zip',
      'application/x-zip',
    ],
  }

  state = {
    uploadingFile: null,
  }

  handleDrop = acceptedFiles => {
    let {
      uploadFunc,
      input: { onChange },
    } = this.props

    let file = acceptedFiles[0]

    if (!file) {
      return this.errorMessage('No valid file')
    }

    let filename = file.name

    let reader = new FileReader()

    reader.onload = async () => {
      this.setState({ uploadingFile: reader.result })

      try {
        let url = await uploadFunc(reader.result, filename)
        onChange(url)
      } catch (err) {
        console.error('ERROR UPLOADING IMAGE:', err)
        this.errorMessage()
      }

      this.setState({ uploadingFile: null })
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  errorMessage = (msg = null) => {
    if (msg) {
      console.error(msg)
    }

    alert(
      'An error occurred while uploading your image. Only .jpg, .png, & .gif images are allowed.'
    )
  }

  getFileURL = () => {
    let { uploadingFile } = this.state

    let {
      uploadURLFunc,
      input: { value },
    } = this.props

    if (uploadingFile) {
      return null
    } else if (value) {
      return uploadURLFunc(value)
    }

    return null
  }

  handleRemove = () => {
    let {
      input: { onChange },
    } = this.props

    onChange(null)
  }

  render() {
    let { input, filenameFunc, acceptedMimeTypes } = this.props
    let { uploadingFile } = this.state
    let url = this.getFileURL()
    let value = input.value
    let filename = value ? filenameFunc(value) || 'Untitled' : 'No File'

    let mimetypes = acceptedMimeTypes.join(',')

    return (
      <div className="file-input-wrapper">
        {value ? (
          <div className="file-input-value">
            <div className="file-input-filename">
              <a
                className="deemphasize"
                href={url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {filename}
              </a>
            </div>
            <div className="file-input-links">
              <IconButton type="trash-small" onClick={this.handleRemove} />
            </div>
          </div>
        ) : (
          <Dropzone
            className={classNames('file-input', !url && 'file-input-empty')}
            accept={mimetypes}
            onDrop={this.handleDrop}
          >
            <div className="file-input-sub">
              {url ? (
                <div
                  style={{ backgroundImage: `url('${url}')` }}
                  className="file-input-filename"
                >
                  {filename}
                </div>
              ) : null}
              <div className="file-input-select">
                {uploadingFile ? <Loading small expanded /> : null}
                Choose File
              </div>
            </div>
          </Dropzone>
        )}
      </div>
    )
  }
}
