import { SET_DRAFT_INIT_CUSTOM_SIZE } from './actions'

const initialState = {
    "sizes": "custom",
    "width": 0,
    "height": 0,
    "depth": 0,
    "name": "",
    "template_id": ""
}

const draftInitCustomSizesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DRAFT_INIT_CUSTOM_SIZE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}

export default draftInitCustomSizesReducer
