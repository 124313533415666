import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

import {getBestParent} from 'utils/geometry'
import {scale, scaleValue, unScale} from 'utils/zoom'
import {calculateSnappedPosition, getLibrary} from 'utils/libraries'
import {getSnapValue} from 'utils/snapping'

import {createObject, selectObjects} from 'ducks/editor/objects'

import {getXGrid, getYGrid, resetSnaps, setXSnap, setYSnap,} from 'ducks/editor/snapping'

import {getStartPosition, resetTool} from 'ducks/editor/tools'
import LibraryComponent from './LibraryComponent/LibraryComponentSub'
import 'styles/AddLibraryComponent.css'

const MIN_RADIUS_SQUARED = 20 ** 2

class AddComponentInstance extends Component {
  constructor(props) {
    super(props)

    let startPosition = props.startPosition
      ? unScale(props.startPosition, props.zoom)
      : [0, 0]

    let [x, y] = startPosition
    let { width, height } = props.options

    x -= Math.floor(width / 2)
    y -= Math.floor(height / 2)

    this.state = {
      x,
      y,
      mouseOver: !!props.startPosition,
      xSnapped: x,
      ySnapped: y,
      widthSnapped: 0,
      heightSnapped: 0,
    }
  }

  handleMouseUp = e => {
    let { startPosition } = this.props
    let [x, y] = startPosition
    let distSquared = (e.clientX - x) ** 2 + (e.clientY - y) ** 2

    if (distSquared < MIN_RADIUS_SQUARED) {
      return
    }

    this.handleMouseDown(e)
  }

  handleMouseDown = e => {
    let { xSnapped, ySnapped, widthSnapped, heightSnapped } = this.state

    let { createObject, resetTool, resetSnaps, options } = this.props

    let { libraryName, libraryVersion, componentName } = options

    let library = getLibrary(libraryName, libraryVersion)

    let component = library.config.components.filter(
      c => c.name === componentName
    )[0]

    createObject({
      ...options,
      x: xSnapped,
      y: ySnapped,
      width: widthSnapped || options.width,
      height: heightSnapped || options.height,
      name: component.displayName,
    })

    resetTool()
    resetSnaps()
  }

  handleMouseMove = e => {
    let { zoom, options } = this.props

    let [x, y] = unScale([e.clientX, e.clientY], zoom)
    x = Math.round(x - options.width / 2)
    y = Math.round(y - options.height / 2)

    let parent = this.getBestParent(x, y)

    let snap = this.calculateSnappedPosition(x, y, parent)
    let [xSnapped, ySnapped, widthSnapped, heightSnapped] = snap

    this.setState({
      mouseOver: true,
      x,
      y,
      xSnapped,
      ySnapped,
      widthSnapped,
      heightSnapped,
    })
  }

  handleMouseLeave = () => {
    this.setState({ mouseOver: false })
  }

  getBestParent = (xArg, yArg) => {
    let { x, y } = this.state
    x = xArg || x
    y = yArg || y

    let { options, screens } = this.props

    let obj = { ...options, x, y }
    let parentId = getBestParent(obj, screens)

    return screens.filter(s => s.id === parentId)[0]
  }

  calculateGridSnapPosition = (x, y) => {
    let { options, xGrid, yGrid, setXSnap, setYSnap, zoom } = this.props
    let { width, height } = options

    if (!xGrid || !yGrid) {
      return [x, y, width, height]
    }

    let xDiff = 0
    let yDiff = 0

    let xCoords = {
      left: x,
      center: x + width / 2,
      right: x + width,
    }

    let yCoords = {
      top: y,
      center: y + height / 2,
      bottom: y + height,
    }

    let xSnap = getSnapValue(xGrid, xCoords, zoom)
    let ySnap = getSnapValue(yGrid, yCoords, zoom)

    if (xSnap) {
      let key = Object.keys(xSnap)[0]
      xDiff = xSnap[key] - xCoords[key]
      setXSnap(xSnap[key])
    } else {
      setXSnap(null)
    }

    if (ySnap) {
      let key = Object.keys(ySnap)[0]
      yDiff = ySnap[key] - yCoords[key]
      setYSnap(ySnap[key])
    } else {
      setYSnap(null)
    }

    return [Math.round(x + xDiff), Math.round(y + yDiff), width, height]
  }

  calculateSnappedPosition = (x, y, parent) => {
    let { options } = this.props

    if (!options.snappingRules) {
      return this.calculateGridSnapPosition(x, y)
    }

    let pos = calculateSnappedPosition({ ...options, x, y }, parent)

    return [pos.x, pos.y, pos.width, pos.height]
  }

  renderComponent = () => {
    let { zoom, options, libraryVersion } = this.props
    let { xSnapped, ySnapped, widthSnapped, heightSnapped } = this.state
    let [x, y] = scale([xSnapped, ySnapped], zoom)

    let { width, height } = options

    let unScaledWidth = widthSnapped || width
    let unScaledHeight = heightSnapped || height

    width = scaleValue(unScaledWidth, zoom)
    height = scaleValue(unScaledHeight, zoom)

    let object = { ...options, width: unScaledWidth, height: unScaledHeight }

    let newZoom = {
      ...zoom,
      offset: [0, 0],
    }

    return (
      <g opacity={0.5}>
        <LibraryComponent
          object={object}
          zoom={newZoom}
          libraryVersion={libraryVersion}
          x={x}
          y={y}
        />
        <g transform={`translate(${x}, ${y})`}>
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            className="add-component-outline"
          />
        </g>
      </g>
    )
  }

  render() {
    let { mouseOver } = this.state

    let width = window.innerWidth
    let height = window.innerHeight

    return (
      <g className="add-component-instance">
        {mouseOver ? this.renderComponent() : null}
        <rect
          onMouseDown={this.handleMouseDown}
          onMouseUp={this.handleMouseUp}
          onMouseMove={this.handleMouseMove}
          onMouseLeave={this.handleMouseLeave}
          onMouseEnter={this.handleMouseMove}
          x={0}
          y={0}
          width={width}
          height={height}
          className="add-component-backdrop"
        />
      </g>
    )
  }
}

const mapStateToProps = (state, { match, options }) => ({
  screens: selectObjects(state),
  xGrid: getXGrid(state),
  yGrid: getYGrid(state),
  startPosition: getStartPosition(state),

})

export default withRouter(
  connect(mapStateToProps, {
    createObject,
    resetTool,
    setXSnap,
    setYSnap,
    resetSnaps,
  })(AddComponentInstance)
)
