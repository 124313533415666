const OPEN = Symbol('OPEN')
const CLOSE = Symbol('CLOSE')
const TOGGLE = Symbol('TOGGLE')

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
  if (action.type === OPEN) {
    let { groupId, instanceId } = action

    return {
      ...state,
      [groupId]: instanceId,
    }
  }

  if (action.type === CLOSE) {
    let { groupId } = action

    return {
      ...state,
      [groupId]: null,
    }
  }

  if (action.type === TOGGLE) {
    const { groupId, instanceId } = action

    return {
      ...state,
      [groupId]: state[groupId] === instanceId ? null : instanceId,
    }
  }

  return state
}

// Actions

export const openAccordion = (groupId, instanceId) => ({
  type: OPEN,
  groupId,
  instanceId,
})

export const closeAccordion = groupId => ({
  type: CLOSE,
  groupId,
})

export const toggleAccordion = (groupId, instanceId) => ({
  type: TOGGLE,
  groupId,
  instanceId,
})

// Selectors

export const getAccordionState = (state, groupId) => {
  return state.accordions[groupId]
}
