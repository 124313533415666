import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notify } from 'ducks/notifications';
import { withRouter } from 'react-router-dom';

import { setTemplate } from 'components/Admin/template/store/templateActions';
import { createTemplate } from 'components/Admin/template/apiUtils';
import NumberInput from 'components/Admin/template/NumberInput';

import { getApp } from 'ducks/apps/selectors';
import { 
    updateAppWidth, 
    updateAppHeight, 
    updateAppDepth, 
    updateAppOuterRule, 
    updateAppName,
    updateAppLowerWidth,
    updateAppLowerHeight,
    updateAppBorderWidth,
    updateAppBorderRadius,
} from 'ducks/apps/actions';

import { setData } from 'ducks/editor/objects';

class TemplateDetail extends Component {

    handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const parsedValue = type === 'checkbox' ? checked : type === 'number' ? Number(value) : value;
        const { app, updateWidth, updateHeight, updateDepth, updateOuterRule, updateName, updateLowerWidth, updateLowerHeight, updateBorderWidth, updateBorderRadius, setData } = this.props;

        this.props.setTemplate({
            ...this.props.template,
            [name]: parsedValue,
        });

        // Обновляем соответствующее свойство
        if (name === 'width') {
            updateWidth(app.id, parsedValue);
        } else if (name === 'height') {
            updateHeight(app.id, parsedValue);
        } else if (name === 'depth') {
            updateDepth(app.id, parsedValue);
        } else if (name === 'outer_rule') {
            updateOuterRule(app.id, parsedValue);
        } else if (name === 'name') {
            updateName(app.id, parsedValue);
        } else if (name === 'lower_width') {
            updateLowerWidth(app.id, parsedValue);
        } else if (name === 'lower_height') {
            updateLowerHeight(app.id, parsedValue);
        } else if (name === 'border_width') {
            updateBorderWidth(app.id, parsedValue);
        } else if (name === 'border_radius') {
            updateBorderRadius(app.id, parsedValue);
        }

        // Обновляем данные компонента
        setData(app.id, app.components, app);
    };

    handleFormSubmit = async (e) => {
        e.preventDefault();

        const { history, notify, template, outer_rule, parts, in_case_shapes } = this.props;

        try {
            await createTemplate(history, {
                ...template,
                outer_rule: outer_rule,
                parts: parts,
                in_case_shapes: in_case_shapes,
            });
        } catch (error) {
            notify(`Error: ${error.message}`);
        }
    };

    render() {
        const { template } = this.props;

        return (
            <form onSubmit={this.handleFormSubmit} className="space-y-6 bg-white p-6 shadow-md rounded-md">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Название
                </label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={template.name}
                    onChange={this.handleInputChange}
                    placeholder="Название"
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    required
                />

                <NumberInput code={'width'} name={'Ширина'} value={template.width} onChange={this.handleInputChange} />

                <NumberInput code={'height'} name={'Высота'} value={template.height} onChange={this.handleInputChange} />

                <NumberInput code={'depth'} name={'Глубина'} value={template.depth} onChange={this.handleInputChange} />

                <NumberInput
                    code={'lower_width'}
                    name={'Ширина нижней части'}
                    value={template.lower_width}
                    onChange={this.handleInputChange}
                />

                <NumberInput
                    code={'lower_height'}
                    name={'Высота нижней части'}
                    value={template.lower_height}
                    onChange={this.handleInputChange}
                />

                <NumberInput
                    code={'border_radius'}
                    name={'Радиус скругления'}
                    value={template.border_radius}
                    onChange={this.handleInputChange}
                />

                <NumberInput
                    code={'border_width'}
                    name={'Толщина рамки'}
                    value={template.border_width}
                    onChange={this.handleInputChange}
                />

                <label htmlFor="label" className="block text-sm font-medium text-gray-700">
                    Метка
                </label>
                <input
                    type="text"
                    id="label"
                    name="label"
                    value={template.label}
                    onChange={this.handleInputChange}
                    placeholder="Метка"
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />

                <div className="flex items-center">
                    <input
                        type="checkbox"
                        name="available"
                        checked={template.available}
                        onChange={this.handleInputChange}
                        className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <label htmlFor="available" className="ml-2 block text-sm font-medium text-gray-700">
                        Доступен
                    </label>
                </div>

                <label htmlFor="iconUrl" className="block text-sm font-medium text-gray-700">
                    URL иконки
                </label>
                <textarea
                    id="iconUrl"
                    name="iconUrl"
                    value={template.iconUrl}
                    onChange={this.handleInputChange}
                    placeholder="URL иконки"
                    rows="4"
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />

                <label htmlFor="bid" className="block text-sm font-medium text-gray-700">
                    BID
                </label>
                <input
                    type="text"
                    id="bid"
                    name="bid"
                    value={template.bid}
                    onChange={this.handleInputChange}
                    placeholder="BID"
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />

                <button
                    type="submit"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    Добавить шаблон
                </button>
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    const app = getApp(state, 'n581kk19ktbw9cctimjy5rykhe');

    return {
        app: app,
        template: state.admin.template.details,
        outer_rule: state.admin.template.outer_rule,
        points: state.admin.template.points,
        parts: state.admin.template.parts,
        in_case_shapes: state.admin.template.in_case_shapes,
    };
};

const mapDispatchToProps = (dispatch) => ({
    notify: (message) => dispatch(notify(message)),
    setTemplate: (template) => dispatch(setTemplate(template)),
    
    updateWidth: (appId, width) => dispatch(updateAppWidth(appId, width)),
    updateHeight: (appId, height) => dispatch(updateAppHeight(appId, height)),
    updateDepth: (appId, depth) => dispatch(updateAppDepth(appId, depth)),
    updateOuterRule: (appId, outerRule) => dispatch(updateAppOuterRule(appId, outerRule)),
    updateName: (appId, name) => dispatch(updateAppName(appId, name)),
    updateLowerWidth: (appId, lowerWidth) => dispatch(updateAppLowerWidth(appId, lowerWidth)),
    updateLowerHeight: (appId, lowerHeight) => dispatch(updateAppLowerHeight(appId, lowerHeight)),
    updateBorderWidth: (appId, borderWidth) => dispatch(updateAppBorderWidth(appId, borderWidth)),
    updateBorderRadius: (appId, borderRadius) => dispatch(updateAppBorderRadius(appId, borderRadius)),
    setData: (app_id, app_components, app) => dispatch(setData(app_id, app_components, app)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TemplateDetail));
