import Base from './base'
import {Numerical} from "./core";
import {Formatter} from "./formatter";

export class Size extends Base {

  constructor(arg0, arg1) {
    super(...arguments)
    this._readIndex = true
  }
  initialize(arg0, arg1) {
    var type = typeof arg0,
      reading = this.__read,
      read = 0;
    if (type === 'number') {
      var hasHeight = typeof arg1 === 'number';
      this._set(arg0, hasHeight ? arg1 : arg0);
      if (reading)
        read = hasHeight ? 2 : 1;
    } else if (type === 'undefined' || arg0 === null) {
      this._set(0, 0);
      if (reading)
        read = arg0 === null ? 1 : 0;
    } else {
      var obj = type === 'string' ? arg0.split(/[\s,]+/) || [] : arg0;
      read = 1;
      if (Array.isArray(obj)) {
        this._set(+obj[0], +(obj.length > 1 ? obj[1] : obj[0]));
      } else if ('width' in obj) {
        this._set(obj.width || 0, obj.height || 0);
      } else if ('x' in obj) {
        this._set(obj.x || 0, obj.y || 0);
      } else {
        this._set(0, 0);
        read = 0;
      }
    }
    if (reading)
      this.__read = read;
    return this;
  }

  _set(width, height) {
    this.width = width;
    this.height = height;
    return this;
  }


  equals(size) {
    return size === this || size && (this.width === size.width
      && this.height === size.height
      || Array.isArray(size) && this.width === size[0]
      && this.height === size[1]) || false;
  }


  clone() {
    return new Size(this.width, this.height);
  }

  /**
   * @return {String} a string representation of the size
   */
  toString() {
    var f = Formatter.instance;
    return '{ width: ' + f.number(this.width)
      + ', height: ' + f.number(this.height) + ' }';
  }

  _serialize(options) {
    var f = options.formatter;
    // See Point#_serialize()
    return [f.number(this.width),
      f.number(this.height)];
  }

  add( size) {
    var size = Size.read(arguments)
    return new Size(this.width + size.width, this.height + size.height);
  }

  subtract( size) {
    var size = Size.read(arguments)
    return new Size(this.width - size.width, this.height - size.height);
  }


  multiply( size) {

    var size = Size.read(arguments)
    return new Size(this.width * size.width, this.height * size.height);
  }

  divide( size ) {
    var size = Size.read(arguments)
    return new Size(this.width / size.width, this.height / size.height);
  }

  modulo( size ) {
    var size = Size.read(arguments)
    return new Size(this.width % size.width, this.height % size.height);
  }

  negate() {

    return new Size(-this.width, -this.height);
  }


  isZero() {
    var isZero = Numerical.isZero;
    return isZero(this.width) && isZero(this.height);
  }

  isNaN() {
    return isNaN(this.width) || isNaN(this.height);
  }


  static min(size1, size2) {
    return new Size(
      Math.min(size1.width, size2.width),
      Math.min(size1.height, size2.height));
  }

  static max(size1, size2) {
    return new Size(
      Math.max(size1.width, size2.width),
      Math.max(size1.height, size2.height));
  }


  static random() {
    return new Size(Math.random(), Math.random());
  }

  round() {
    return new Size(op(this.width), op(this.height));
  }

  ceil() {
    return new Size(op(this.width), op(this.height));
  }

  floor() {
    return new Size(op(this.width), op(this.height));
  }

  abs() {
    return new Size(op(this.width), op(this.height));
  }
}
export  class LinkedSize extends Size {
  constructor(arg0, arg1) {
    super(...arguments)
  }
  // Have LinkedSize appear as a normal Size in debugging
  initialize(width, height, owner, setter) {
    this._width = width;
    this._height = height;
    this._owner = owner;
    this._setter = setter;
  }

  // See Point#_set() for an explanation of #_set():
  _set(width, height, _dontNotify) {
    this._width = width;
    this._height = height;
    if (!_dontNotify)
      this._owner[this._setter](this);
    return this;
  }

  getWidth() {
    return this._width;
  }

  setWidth(width) {
    this._width = width;
    this._owner[this._setter](this);
  }

  getHeight() {
    return this._height;
  }

  setHeight(height) {
    this._height = height;
    this._owner[this._setter](this);
  }
}
