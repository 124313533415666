
import axios from "axios";

import store from 'redux-store'

export const BASE_URL           = process.env.REACT_APP_WEB_BASE_URL;

// define store
let reduxStore                  = null
const saveQueue                 = {}
export const DEFAULT_PER_PAGE   = 25
export const baseURL            = BASE_URL
export const apiURL             = `${BASE_URL}/api/v4`


export const saveLayer = async (appId, data) => {
    let endpointURL = `${apiURL}/drafts/${appId}/upset`
    return axios.put(endpointURL, data, {headers: {'X-Requested-With': 'XMLHttpRequest'}})
}

export const deleteLayer = async (appId, layerId) => {

    let endpointURL = `${apiURL}/layers/${layerId}`
    return axios.delete(endpointURL, {headers: {'X-Requested-With': 'XMLHttpRequest'}})

}

export const fetchComponents = async (libraryId, pagination = {}) => {

    return axios
        .get(`${apiURL}/components`, {
            params: {
                page: pagination.page || 0,
                per_page: pagination.perPage || 60,
                library_id: libraryId,
                // is_asc: false,
            }
        })
        .then(response => {
            return response.data
        })
        .catch(err => {
            console.error('GET COMPONENT FAILED!', err)
        })
}

export const fetchTemplates = async (typeId, pagination = {}) => {

    return axios
        .get(`${apiURL}/templates`, {
            params: {
                page: pagination.page || 0,
                per_page: pagination.perPage || 60,
                template_type_id: typeId
            }
        })
        .then(response => {
            return response.data
        })
        .catch(err => {
            console.error('GET COMPONENT FAILED!', err)
        })
}

export const fetchTemplateTypes = async (pagination = {}) => {

    return axios
        .get(`${apiURL}/template_types`, {params: {page: pagination.page || 0, per_page: pagination.perPage || 60}})
        .then(response => {
            return response.data
        })
        .catch(err => {
            console.error('GET COMPONENT FAILED!', err)
        })
}

export const searchTemplates = async (term = "", pagination = {}) => {

    return axios
        .get(`${apiURL}/templates`, {params: {q: term, page: pagination.page || 0, per_page: pagination.perPage || 60}})
        .then(response => {
            return response.data
        })
        .catch(err => {
            console.error('GET COMPONENT FAILED!', err)
        })
}

// Change Queue
window.setInterval(() => {

    // Save all pending & clear
    for (let appId of Object.keys(saveQueue)) {

        const changes = saveQueue[appId]

        // Clear out previous values
        delete saveQueue[appId]

        const { components, calc } = changes

        saveLayer(appId, {components: {...components}, calc: {...calc}}).then(() => { 

            // loadDraft(appId).then((response) => {

            //     const app = response.data;
                
            //     store.dispatch(updateApp(app.id, app))

            //     store.dispatch(setData(app.id, app.components, app))
    
            // })

        })

    }

}, 2000)




const addSavesToQueue = (appId, components, calc) => {

    saveQueue[appId] = {
        components: {
            ...saveQueue[appId]?.components,
            ...components,
        },
        calc
    }
}

export const saveComponents = (appId, components, calc) => {
    //socket.emit('saveComponent', { appId, components, libraryGlobals })

    addSavesToQueue(appId, components, calc)
}

export const deleteComponent = (appId, componentId) => {
    deleteLayer(appId, componentId)
}