import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import Page from 'components/Shared/Page'
import GridTable from "@nadavshaar/react-grid-table"
import Menu from "./Menu";
import { apiURL, BASE_URL } from '../../utils/io'


class Components extends Component {

    constructor(props) {

        super(props)

        this.state = {

            library: { 
                id: 0, 
                name: '' 
            },

            columns: [
                {
                    id: 'thumb',
                    field: 'thumb',
                    label: 'Изображение',
                    cellRenderer: ({ data }) => (
                        <div>
                            <img src={`${BASE_URL + '/media' + data.thumb}`} width='100' height='100'/>
                            <input type="file" onChange={(e) => this.handleImageChange(e, data.id)} style={{ display: 'block', marginTop: '10px' }} />
                        </div>

                    ),
                    width: "200px"
                },

                {
                    id: 'Name',
                    field: 'name',
                    label: 'Название',
                    cellRenderer: ({ data }) => (
                        <input
                            type="text"
                            name="name"
                            value={data.name}
                            onChange={(e) => this.handleChange(e, data)}
                            style={{ width: "100%" }}
                        />
                    ),
                    editable: true,
                    width: "200px"
                },

                {
                    id: 'counter',
                    field: 'counter',
                    label: 'counter',
                    cellRenderer: ({ data }) => (
                        <input
                            type="number"
                            step="any"
                            name="counter"
                            value={data.counter}
                            onChange={(e) => this.handleChange(e, data)}
                            style={{ width: "100%" }}
                        />
                    ),
                    editable: false,
                    width: "100px"
                },

                {
                    id: 'value',
                    field: 'value',
                    label: 'value',
                    cellRenderer: ({ data }) => (
                        <input
                            type="text"
                            step="any"
                            name="value"
                            value={data.value}
                            onChange={(e) => this.handleChange(e, data)}
                            style={{ width: "100%" }}
                        />
                    ),
                    editable: false,
                    width: "150px"
                },

                {
                    id: 'Delete',
                    label: '',
                    width: "100px",
                    cellRenderer: ({ data }) => (
                        <button onClick={() => this.Delete(data.id)}>Удалить</button>
                    )
                },

                {
                    id: 'Перейти',
                    label: '',
                    width: "100px",
                    cellRenderer: ({ data }) => (
                        <Link to={`/admin/components/${data.id}/orintations`} className="text-blue-500 underline">Ориентации</Link>
                    )
                },

            ],

            rows: [],

            totalRows: 0,

            isLoading: true,

            form: {

                library_id: 0,
                counter: 0,
                name: "",
                iconUrl: "",
                value: "shapeComponent",

            },

        }

    }


    handleImageChange = async (e, id) => {

        this.setState({ isLoading: true })

        const file = e.target.files[0]

        const formData = new FormData()

        formData.append('image', file)

        try {

            const response = await fetch(`${apiURL}/components/${id}/image`, {
                method: 'PUT',
                body: formData
            })

            if (response.ok) {
                await this.All(this.props.match.params.id)
            } else {
                throw new Error('Network response was not ok.')
            }

        } catch (error) {
            console.error('Ошибка при загрузке изображения:', error)
        }

    }

    handleChange = (e, data) => {

        const target = e.target
        const type = target.type
        let value = type === 'checkbox' ? target.checked : target.value
        const name = target.name
        
        let row_update = null

        this.setState(prevState => ({

            rows: prevState.rows.map(row => {

                if(row.id === data.id){

                    if(type === 'number'){
                        value = parseFloat(value)
                    }

                    row_update = { ...row, [name]: value }

                    return row_update
                }

                return row
            })

        }), async () => await this.Update(row_update))

    }

    Update = async (data) => {

        try {

            const response = await fetch(`${apiURL}/components`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })

            if (!response.ok) {
                throw new Error('Failed to update');
            }

        } catch (error) {
            console.error('Error updating:', error);
        }
    }

    componentDidMount() {
        this.All(this.props.match.params.id)
        this.LibraryGetById(this.props.match.params.id)
    }

    LibraryGetById = async (id) => {

        this.setState({ isLoading: true })
    
        try {
            const response = await fetch(`${apiURL}/libraries/${id}`)
            if (response.ok) {

                const data = await response.json()

                this.setState(prevState => ({
                    library: data,
                    isLoading: false,
                    form: {
                        ...prevState.form,
                        library_id: data.id,
                    }
                }))

            } else {
                throw new Error('Network response was not ok.')
            }
        } catch (error) {
            console.error('Error fetching:', error)
        }finally{
            this.setState({ isLoading: false })
        }

    }

    All = async (id) => {

        this.setState({ isLoading: true })
    
        try {
            const response = await fetch(`${apiURL}/libraries/${id}/components`)
            if (response.ok) {
                const data = await response.json()
                this.setState({
                    rows: data,
                    totalRows: data.length,
                    isLoading: false
                })
            } else {
                throw new Error('Network response was not ok.')
            }
        } catch (error) {
            console.error('Error fetching:', error)
        }finally{
            this.setState({ isLoading: false })
        }

    }
    
    New = async (e) => {

        e.preventDefault()

        const { form } = this.state

    
        this.setState({ isLoading: true })
    
        try {

            const response = await fetch(`${apiURL}/components`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(form)
            })
    
            if (response.ok) {
                const addedCategory = await response.json();
                this.setState(prevState => ({
                    rows: [...prevState.rows, addedCategory],
                    totalRows: prevState.totalRows + 1,
                }))
            } else {
                throw new Error('Failed to create')
            }
        } catch (error) {
            console.error('Error adding:', error)
        } finally {
            this.setState({ isLoading: false })
        }

    }

    Delete = async (id) => {

        this.setState({ isLoading: true })

        try {
            const response = await fetch(`${apiURL}/components/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                this.setState(prevState => ({
                    rows: prevState.rows.filter(o => o.id !== id),
                    totalRows: prevState.totalRows - 1
                }));
            } else {
                throw new Error('Failed to delete')
            }
        } catch (error) {
            console.error('Error deleting:', error)
        } finally {
            this.setState({ isLoading: false })
        }
    }

    formHandleChange = (e) => {

        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState(prevState => ({
            form: {
                ...prevState.form,
                [name]: value
            }
        }))

    }

    render() {

        const { columns, rows, totalRows, isLoading, form, library } = this.state

        return (
            <Page className="admin-users-page" title="Компоненты">

                <Menu />

                <section className="users-header">
                    <h1>Компоненты - {library.name}</h1>
                </section>

                <form onSubmit={this.New}>

                    <input 
                        type="text" 
                        name="name" 
                        value={this.state.form.name} 
                        onChange={e => this.setState(prevState => ({
                            form: {
                                ...prevState.form,
                                name: e.target.value
                            }
                        }))}
                        placeholder="Название" 
                    />

                    <input 
                        type="number" 
                        step="any"
                        name="counter" 
                        value={this.state.form.counter} 
                        onChange={e => this.setState(prevState => ({
                            form: {
                                ...prevState.form,
                                counter: parseFloat(e.target.value) 
                            }
                        }))}
                        placeholder="counter" 
                    />

                    <input 
                        type="text" 
                        name="value" 
                        value={this.state.form.value} 
                        onChange={e => this.setState(prevState => ({
                            form: {
                                ...prevState.form,
                                value: e.target.value
                            }
                        }))}
                        placeholder="value" 
                    />

                    <input 
                        type="text" 
                        name="iconUrl" 
                        value={this.state.form.iconUrl} 
                        onChange={e => this.setState(prevState => ({
                            form: {
                                ...prevState.form,
                                iconUrl: e.target.value
                            }
                        }))}
                        placeholder="URL иконки" 
                    />

                    <button type="submit">Добавить Компонент</button>

                </form>

                <div className="grid-content">
                    {isLoading ? (
                        <div>Loading...</div>  // Индикатор загрузки
                    ) : (
                        <GridTable
                            showSearch={true}
                            showColumnVisibilityManager={true}
                            columns={columns}
                            rows={rows}
                            totalRows={totalRows}
                            texts={{
                                search: 'Поиск:',
                                totalRows: 'Общее кол-во:',
                                rows: 'Показано:',
                                selected: 'Выбрано',
                                rowsPerPage: 'Элементов на странице:',
                                page: 'Страница:',
                                of: 'из',
                                prev: 'Пред.',
                                next: 'След.',
                                columnVisibility: 'Колонки'
                            }}
                        />
                    )}
                </div>
            </Page>
        )
    }

}

export default withRouter(Components)