import React, {Component} from 'react'
import {connect} from 'react-redux'

import {getComponentId, getCurrentAppId,} from '../../../../ducks/editor/objects'


import MenuControl from './MenuControl'

class AutosaveInputControl extends Component {
  handleChange = wrappedVal => {
    let { name, onChange } = this.props

    let val = wrappedVal[name]

    if (!val.value) {
      val.value = null
    }

    onChange({ [name]: val })
  }

  render() {
    let { options } = this.props

    return (
      <MenuControl
        {...this.props}
        options={options}
        childWidth={320}
        getLabel={()=>{}}
        onChange={this.handleChange}
      />
    )
  }
}

const mapStateToProps = (state, opts) => {
  let { objectId, reference, dataType } = opts
  let appId = getCurrentAppId(state)
  let componentId = getComponentId(state, objectId)

  return {
    options: [],
    getLabel: opt => getLabel(state, opt, appId, componentId, true),
  }
}

export default connect(mapStateToProps)(AutosaveInputControl)
