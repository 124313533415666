import React, { Component } from 'react'
import { connect } from 'react-redux'

import { setDraft } from 'ducks/apps/actions'
import { getApp } from 'ducks/apps/selectors'

import { requestData, setData } from 'ducks/editor/objects'

import EmptyState from 'components/Shared/EmptyState'

import Canvas from './Canvas'
import './Editor.scss'

class Editor extends Component {

  state = {
    error: null,
  }

  handleWheel = e => {
    if (e.ctrlKey) {
      e.preventDefault()
    }
  }

  componentDidMount() {
    document.body.classList.add('editor-body')
  }

  componentWillUnmount() {
    document.body.classList.remove('editor-body')
    document.body.classList.remove('editor-app-unpaid')
  }

  componentDidCatch(error) {
    this.setState({ error })
  }

  render() {
    const { error } = this.state;
    const { children, app } = this.props;

    if (!app || !app.id) {
      return (
        <div className="editor-error">
          <EmptyState greedy>
            <h2>Ошибка!</h2>
            <p>Не удалось загрузить данные приложения. Пожалуйста, попробуйте позже.</p>
          </EmptyState>
          {children}
        </div>
      );
    }

    if (error) {
      return (
        <div className="editor-error">
          <EmptyState greedy>
            <h2>Ошибка!</h2>
            <p>Пожалуйста, обновите страницу и попробуйте еще раз.</p>
          </EmptyState>
          {children}
        </div>
      );
    }

    return (
      <>
        <Canvas key={`canvas_${app.id}`} appId={app.id} />
      </>
    );
  }

}

const mapStateToProps = (state) => {
  
  const app = getApp(state, "n581kk19ktbw9cctimjy5rykhe")

  return {
    app,
  };
}

export default connect(mapStateToProps, {
  requestData,
  setDraft,
  setData,
})(Editor)
