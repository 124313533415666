import React, { useEffect } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import Modal from './Modal';
import 'styles/Page.css';

const Page = ({ baseURL, history, children, className, modalContent, modalProps, form, title }) => {
  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  const handleModalClose = () => {
    if (!baseURL) {
      return;
    }
    history.push(baseURL);
  };

  return (
    <div className={classNames('page', className, { 'form-page': form })}>
      {children}
      {modalContent && (
        <Modal {...modalProps} onClose={handleModalClose}>
          {modalContent}
        </Modal>
      )}
    </div>
  );
};

export default withRouter(Page);



// import React, {Component} from 'react'
// import classNames from 'classnames'
// import { withRouter } from 'react-router-dom'
// import DocumentTitle from 'react-document-title'

// import Modal from './Modal'
// import 'styles/Page.css'

// class Page extends Component {
//   handleModalClose = () => {
//     let { baseURL, history } = this.props

//     if (!baseURL) {
//       return
//     }

//     history.push(baseURL)
//   }

//   render() {
//     let {
//       children,
//       className,
//       modalContent,
//       modalProps,
//       form,
//       title,
//     } = this.props

//     return (
//       <div className={classNames('page', className, { 'form-page': form })}>
//         {title && <DocumentTitle title={title} />}
//         {children}
//         {modalContent && (
//           <Modal {...modalProps} onClose={this.handleModalClose}>
//             {modalContent}
//           </Modal>
//         )}
//       </div>
//     )
//   }
// }

// export default withRouter(Page)
