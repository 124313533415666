import update from 'immutability-helper'

const MODAL = 'EDITOR_MODAL'

const INITIAL_STATE = []

export const NEW_SCREEN_MODAL = 'newScreen'
export const COPY_APP = 'copyApp'
export const DELETE_APP = 'deleteApp'
export const RENAME_DRAFT = 'renameDraft'
export const INVITE_DRAFT = 'inviteDraft'
export const CHECKOUT_MODAL = 'checkoutModal'

export default (state = INITIAL_STATE, action) => {
  if (action.type === `${MODAL}_PENDING`) {
    let newState = update(state, { $push: [action.meta] })

    return newState
  }

  if (
    action.type === `${MODAL}_FULFILLED` ||
    action.type === `${MODAL}_REJECTED`
  ) {
    const index = state.indexOf(action.meta)
    const newState = update(state, { $splice: [[index, 1]] })

    return newState
  }

  return state
}

// Actions

export const showModal = (modalType, opts = {}) => {
  let resolve
  let reject

  let promise = new Promise((resolvePromise, rejectPromise) => {
    resolve = resolvePromise
    reject = rejectPromise
  })

  return {
    type: MODAL,
    meta: { modalType, resolve, reject, opts },
    payload: promise,
  }
}

// Selectors

export const getActiveModal = state => {
  const current = state.editor.modals.length - 1
  return state.editor.modals[current]
}

export const getModals = state => {
  return state.editor.modals
}
