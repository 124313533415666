import React from 'react';

const NumberInput = ({ code, name, value, onChange,  onFocus = () => {}}) => {

    // Конвертация из пикселей в миллиметры
    const widthMm = (value / 96) * 25.4;

    // Конвертация из пикселей в точки
    const widthPts = value * 0.75;

    // Конвертация из пикселей в дюймы
    const widthInches = value / 96;

    return (
        <div className="mb-4 p-4 border border-gray-300 rounded-lg bg-gray-50 shadow-sm w-full">
            <label htmlFor={code} className="block mb-1 font-bold text-gray-700">{name}</label>
            <input
                type="number"
                id={code}
                step="1"
                name={code}
                value={value}
                min="0"
                onFocus={onFocus}
                onChange={onChange}
                placeholder={name}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />

            <div className="grid grid-cols-4 gap-2 mt-2 text-sm text-gray-600">
                <span>PX</span>
                <span>MM</span>
                <span>PT</span>
                <span>IN</span>
            </div>

            <div className="grid grid-cols-4 gap-2 mt-2 text-sm text-gray-600">
                <span>{value}</span>
                <span>{widthMm.toFixed(2)}</span>
                <span>{widthPts.toFixed(2)}</span>
                <span>{widthInches.toFixed(2)}</span>
            </div>

        </div>
    );
};

export default NumberInput;
