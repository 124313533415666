import {traverse} from 'common/utils'

import {ACTION_NAMES, COMPONENT, FILE_UPLOAD, IMAGE_UPLOAD, LABEL, SECTION,} from 'common/constants'

import {capitalize} from './type'

export const getObjectName = object => {
  if (!object) {
    return '[Deleted]'
  }

  if (object.name === 'Select Menu') {
    return 'Dropdown Menu'
  }

  return object.name || abbreviate(object.text, 20) || capitalize(object.type)
}

export const abbreviate = (text, length) => {
  text = text || ''

  if (typeof text === 'object' && text.length) {
    text = text[0]
  }

  if (text.length > length) {
    return `${text.substring(0, length - 3)}...`
  }

  return text
}

export const getTypeName = type => {
  switch (type) {
    case IMAGE_UPLOAD:
      return 'image-picker'
    case FILE_UPLOAD:
      return 'file-picker'
    case SECTION:
      return 'rectangle'
    case COMPONENT:
      return 'screen'
    case LABEL:
      return null
    default:
      return type || 'untitled'
  }
}

export const isDefaultName = (typeName, name) => {
  return !name || name?.includes(typeName)
}

export const createName = (type, name, list) => {
  let largestSuffix = 0

  if (!type) {
    return 'Untitled'
  }

  let typeName = getTypeName(type, name)

  if (!isDefaultName(typeName, name)) {
    typeName = name
  }

  let baseName = null

  if (typeName) {
    let nameParts = typeName.split(/[^a-zA-Z]+/g)
    baseName = nameParts.map(p => capitalize(p)).join(' ')
  }

  if (getTypeName(type, name) === 'screen' && name) {
    baseName = capitalize(name)
  }

  traverse(list, obj => {
    const lowerBaseName = baseName?.toLowerCase()

    if (obj.name?.toLowerCase().startsWith(lowerBaseName)) {
      let match = obj.name.match(/\s(\d+)$/)

      if (match) {
        largestSuffix = Math.max(largestSuffix, match[1])
      } else {
        largestSuffix = Math.max(largestSuffix, 1)
      }
    }
  })

  if (largestSuffix > 0) {
    return `${baseName} ${largestSuffix + 1}`
  }

  return baseName
}

export const actionName = action => {
  return ACTION_NAMES[action.actionType] || 'New Action'
}
