import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {shallowEqual} from 'common/utils'

import {computeLayout} from '../../../../utils/flexbox'
import {getComponent, getNestedComponentsMap} from 'ducks/apps/selectors'
import {updateObject} from '../../../../ducks/editor/objects'
import BaseObject from '../BaseObject'
import ComponentRenderer from './ComponentRenderer'

class ComponentInstance extends BaseObject {

  static contextTypes = {
    editable: PropTypes.bool,
    getSelection: PropTypes.func,
    isNestedComponentInstance: PropTypes.bool,
  }

  static childContextTypes = {
    isNestedComponentInstance: PropTypes.bool,
  }

  getChildContext = () => ({
    isNestedComponentInstance: true,
  })

  shouldComponentUpdate(newProps) {
    let props = [
      'component',
      'hideShadows',
      'xScaled',
      'yScaled',
      'width',
      'height',
      'otherComponents',
    ]

    for (let prop of props) {
      if (!shallowEqual(newProps[prop], this.props[prop])) {
        return true
      }
    }

    return false
  }

  renderObjects() {
    let {
      id,
      component,
      width,
      height,
      hideShadows,
      otherComponents,
      updateObject,
    } = this.props

    if (!component) {
      return null
    }

    let computedLayout = computeLayout(
      component,
      width,
      height,
      otherComponents
    )

    if (!computedLayout) {
      return null
    }

    let [layout, computedHeight] = computedLayout

    if (!this.context.isNestedComponentInstance) {
      if (computedHeight !== height) {
        window.setTimeout(() => {
          updateObject(id, { height: computedHeight })
        }, 0)
      }
    }

    let wrapper = (component.layout.body || [])[0]
    let objects = (wrapper && wrapper.children) || []

    return (
      <g className="component-instance-objects">
        {objects.map(obj => (
          <ComponentRenderer
            key={obj.id}
            object={obj}
            hideShadows={hideShadows}
            layout={layout}
          />
        ))}
      </g>
    )
  }

  render() {
    let { xScaled, yScaled, width, height, zoom } = this.props
    let transform = `translate(${xScaled}, ${yScaled}) scale(${zoom.scale})`

    return (
      <g className="component-instance" transform={transform}>
        {this.renderObjects()}
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill="transparent"
          onMouseDown={this.handleMouseDown}
          onTouchStart={this.handleMouseDown}
          onDoubleClick={this.handleDoubleClick}
        />
      </g>
    )
  }
}

const mapStateToProps = (state, { match, componentId }) => {
  let { appId } = match.params
  let component = getComponent(state, appId, componentId)

  return {
    component,
    otherComponents: getNestedComponentsMap(state, appId, component),
  }
}

export default withRouter(
  connect(mapStateToProps, { updateObject })(ComponentInstance)
)
