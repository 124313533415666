const UPLOAD_IMAGE = Symbol('UPLOAD_IMAGE')
const UPDATE_PATH = Symbol('UPDATE_PATH')
const UPDATE_SIZE = Symbol('UPDATE_SIZE')
const SET_MODE = Symbol('SET_MODE')
const SET_EDITING = Symbol('SET_EDITING')
const RESET_TRACE = Symbol('RESET_TRACE')

const INITIAL_STATE = {
    image: null,
    path: null,
    loading: false,
    mode: 'auto',
    editing: false,
    size: {
        width: 0,
        height: 0,
        depth: 0
    }

}

export default (state = INITIAL_STATE, action) => {
    if (action.type === UPLOAD_IMAGE) {

        return {
            ...state,
            image: action.image,
            loading: true,
        }
    }
    if (action.type === RESET_TRACE) {
        return {
            ...state,
            ...INITIAL_STATE

        }
    }

    if (action.type === UPDATE_PATH) {
        return {
            ...state,
            path: action.path,

        }
    }
    if (action.type === SET_MODE) {
        return {
            ...state,
            mode: action.data,
            path: null

        }
    }
    if (action.type === SET_EDITING) {
        return {
            ...state,
            editing: action.data,

        }
    }
    if (action.type === UPDATE_SIZE) {

        return {
            ...state,
            size: {
                ...state.size,
                ...action.size
            }

        }
    }
    return state
}

// Actions

export const uploadImage = (data) => dispatch => {

    dispatch({
        type: UPLOAD_IMAGE,
        image: data,
    })
}
export const updatePath = (data) => dispatch => {
    dispatch({
        type: UPDATE_PATH,
        path: data,
    })
}
export const setMode = (data) => dispatch => {
    dispatch({
        type: SET_MODE,
        data,
    })
}
export const setEditing = (data) => dispatch => {
    dispatch({
        type: SET_EDITING,
        data,
    })
}
export const updateSize = (data) => dispatch => {
    dispatch({
        type: UPDATE_SIZE,
        size: data,
    })
}
export const resetTrace = (data) => dispatch => {
    dispatch({
        type: RESET_TRACE,

    })
}
// Selectors

export const getImage = state => {
    if (!state.trace.image && !state.trace.loading) {
        return null
    }

    return state.trace.image
}
export const getSize = (state) => {
    return state.trace.size
}
export const getMode = (state) => {
    return state.trace.mode
}
export const getEditing = (state) => {
    return state.trace.editing
}
export const getPath = (state) => {
    return state.trace.path
}
