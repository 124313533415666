import React, {Component} from 'react'
import {connect} from 'react-redux'
import DocumentEvents from 'react-document-events'
import {ActionCreators} from 'redux-undo'

import {
    ARROW_KEY_OFFSETS,
    ARROW_KEYS,
    BACKSPACE,
    DELETE,
    DOWN_ARROW,
    G,
    L,
    MINUS_ALL,
    ONE,
    PLUS_ALL,
    SPACEBAR,
    UP_ARROW,
    Z,
    ZERO,
} from 'utils/keyboard'

import {hotkeyMapping} from 'utils/hotkeys'
import {commandKeyPressed} from 'utils/system'

import {
    deleteObject,
    getComponentId,
    groupObjects,
    positionObjects,
    reorderObjectsMoveDown,
    reorderObjectsMoveFirst,
    reorderObjectsMoveLast,
    reorderObjectsMoveUp,
    resetZoom,
    selectObject,
    setPan,
    setZoom,
    ungroupObjects,
    updateObject
} from 'ducks/editor/objects'

import {setTool} from 'ducks/editor/tools'
import {getSelection} from 'ducks/editor/selection'
import {getEditingText} from 'ducks/editor/textEditing'
import {getEditingShape, getSelectedPoint} from 'ducks/editor/shapeEditing'
import {toggleConnections} from 'ducks/settings'

const hotkeyReverseMap = {}

for (let type of Object.keys(hotkeyMapping)) {
  let key = hotkeyMapping[type]
  let code = key.toUpperCase().charCodeAt(0)

  hotkeyReverseMap[code] = type
}

class KeyboardEvents extends Component {
  handleKeyDown = e => {
    let key = e.which

    let { editing, setZoom, resetZoom } = this.props

    if (commandKeyPressed(e)) {
      let { undo, redo } = this.props

      if (key === Z && e.shiftKey) {
        e.preventDefault()
        redo()
      } else if (key === Z) {
        e.preventDefault()
        undo()
      }

      if (key === G) {
        e.preventDefault()

        let { groupObjects, ungroupObjects, selection } = this.props

        if (e.shiftKey) {
          ungroupObjects(selection)
        } else {
          groupObjects(selection)
        }
      }

      if (key === UP_ARROW) {
        e.preventDefault()

        let {
          reorderObjectsMoveFirst,
          reorderObjectsMoveUp,
          selection,
        } = this.props

        if (e.shiftKey) {
          reorderObjectsMoveFirst(selection)
        } else {
          reorderObjectsMoveUp(selection)
        }
      }

      if (key === DOWN_ARROW) {
        e.preventDefault()

        let {
          reorderObjectsMoveLast,
          reorderObjectsMoveDown,
          selection,
        } = this.props

        if (e.shiftKey) {
          reorderObjectsMoveLast(selection)
        } else {
          reorderObjectsMoveDown(selection)
        }
      }

      if (PLUS_ALL.includes(key)) {
        e.preventDefault()
        setZoom(null, null, Math.sqrt(2))
      }

      if (MINUS_ALL.includes(key)) {
        e.preventDefault()
        setZoom(null, null, 1 / Math.sqrt(2))
      }

      if (key === ZERO) {
        e.preventDefault()
        setZoom(1)
      } else if (key === ONE) {
        e.preventDefault()
        resetZoom()
      }
    } else if (e.target === document.body) {
      let {
        deleteObject,
        positionObjects,
        selection,
        setTool,
        selectedPoint,
        toggleConnections,
        editingObject,
        editingComponent,
      } = this.props

      let offset = e.shiftKey ? 10 : 1

      if (!editing) {
        if (ARROW_KEYS.includes(key)) {
          let [x, y] = ARROW_KEY_OFFSETS[key]

          if (selection.length > 0) {
            // Positioning objects
            positionObjects(
              selection,
              { x: offset * x, y: offset * y },
              false,
              false
            )
          } else {
            // Scrolling canvas
            setZoom(null, null, null, [x * -100, y * -100])
          }
        }

        if (key === DELETE || key === BACKSPACE) {
          deleteObject(selection)
          e.preventDefault()
        }
      } else {
        if (key === DELETE ) {
          if (editingObject && selectedPoint !== null) {
            let points = [...editingObject.points.slice(0, selectedPoint), ...editingObject.points.slice(selectedPoint + 1)]

            points.slice(selectedPoint, 1)


            this.updateObject({ points  }, `removePoint-${+new Date()}`)
          }

          e.preventDefault()
        }
      }

      if (key === SPACEBAR) {
        e.preventDefault()
        const { setPan } = this.props
        setPan(true)
      }

      if (e.shiftKey && key === L) toggleConnections()

      let type = hotkeyReverseMap[key]

      if (type) {
        setTool(type, {})
      }
    }
  }

  updateObject = (changes, undoKey) => {

    let { editingObject, updateObject } = this.props
    let newObj = { ...editingObject, ...changes }

    if (undoKey) {
      this._lastUndoKey = undoKey
    }

    updateObject(editingObject.id, newObj, this._lastUndoKey || 'vector')
  }

  handleKeyUp = e => {
    let key = e.which

    if (e.target === document.body) {
      if (key === SPACEBAR) {
        e.preventDefault()
        const { setPan } = this.props
        setPan(false)
      }

      if (ARROW_KEYS.includes(key)) {
        let { positionObjects, selection } = this.props

        if (selection.length > 0) {
          positionObjects(selection, { x: 0, y: 0 })
        }
      }
    }
  }

  render() {
    return (
      <DocumentEvents
        onKeyDown={this.handleKeyDown}
        onKeyUp={this.handleKeyUp}
      />
    )
  }
}

const mapStateToProps = state => ({
  selection: getSelection(state),
  selectedPoint: getSelectedPoint(state),
  editingShape: getEditingShape(state),
  editingObject: selectObject(state, getEditingShape(state)),
  editingComponent: selectObject(state, getComponentId(state, getEditingShape(state))),
  editing: !!(getEditingShape(state) || getEditingText(state)),
})

export default connect(mapStateToProps, {
  deleteObject,
  groupObjects,
  ungroupObjects,
  positionObjects,
  setTool,
  setZoom,
  setPan,
  resetZoom,
  updateObject,
  undo: ActionCreators.undo,
  redo: ActionCreators.redo,
  toggleConnections,
  reorderObjectsMoveFirst,
  reorderObjectsMoveLast,
  reorderObjectsMoveUp,
  reorderObjectsMoveDown,
})(KeyboardEvents)
