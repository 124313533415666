// initialState.js


export const copyWithApp = (existingApp, newAppData) => {

    const updatedApp = {
        ...existingApp,
        ...newAppData,
        parts: newAppData.parts || existingApp.parts,
        components: newAppData.components || existingApp.components,
        calc: newAppData.calc || existingApp.calc,
    };

    return updatedApp;
};


export const appInitDefault = () => ({
    ...initializeState(),
    templates: {
        ...templatesInitState()
    }
})

export const templatesInitState = () => ({
    list: [],
    loading: false,
    loaded: false,
})

export const initializeState = () => ({
    apps: {
        "n581kk19ktbw9cctimjy5rykhe": {
            "id": "n581kk19ktbw9cctimjy5rykhe",
            "user_id": "h4gjd16b7bnyzjrcj8nqoshcmc",
            "method_cut": "",
            "template_id": "5c263b07ec0e6153783071c700",
            "outer_rule": "",
            "parts": [
                {
                    "_id": "5c263b07ec0e6153783071c8",
                    "name": "Apache 1800",
                    "case": "5c263b07ec0e6153783071c7"
                }
            ],
            "in_case_shapes": [],
            "name": "asd",
            "width": 804,
            "height": 564,
            "lower_width": 540.48,
            "lower_height": 780.48,
            "depth": 288,
            "border_width": 24,
            "border_radius": 12,
            "launch_component_id": "",
            "calc": {
                "": []
            },
            components: {
                "n581kk19ktbw9cctimjy5rykhe": {
                    "id": "n581kk19ktbw9cctimjy5rykhe",
                    "draft_id": "kwfkpfa5efbpmkyab6ih4rwyta",
                    "order": 1,
                    "x": 0,
                    "y": 0,
                    "name": "asd",
                    "type": "component",
                    "width": 804,
                    "height": 564,
                    "depth": 288,
                    "hidden": false,
                    "objects": [],
                    "opacity": 1,
                    "positioning": null,
                    "background_color": "@background"
                }
            },
            "status": "draft",
            "status_update_at": 0,
            "comment": "",
            "checkout_phone": "",
            "checkout_email": "",
            "data": null
        }
    },
    list: [],
    all: [],
    loading: false,
    loaded: false,
    uploadInProgress: false,
    templates: null,
})
