
export const onOuterRuleChange = (
    index, 
    e, 
    outer_rule, 
    setOuterRule
) => {

    const { name, value, type, checked } = e.target;
    const parsedValue = type === 'checkbox' ? checked : (type === 'number' ? Number(value) : value);

    const updatedOuterRule = outer_rule.map((rule, i) => {
        if (i === index) {
            return { ...rule, [name]: parsedValue };
        }
        return rule;
    });

    setOuterRule(updatedOuterRule);

};

export const onAddOuterRule = (
    outer_rule, 
    setOuterRule, 
    setTemplateSetting,
) => {

    const newRule = {
        x: 0,
        y: 0,
        shape: "POLYGON",
        width: 7.5,
        height: 5,
        rotation: 0,
        depth: 1,
        name: "untitled",
        groups: [],
        isFingerNotch: false,
        points: [[0.0000, 0.0000], [1.0000, 1.0000]],
    };

    const updatedOuterRule = outer_rule ? [...outer_rule, newRule] : [newRule];

    setOuterRule(updatedOuterRule);

    setTemplateSetting({ activeRuleIndex: updatedOuterRule.length - 1 });
};

export const onRemoveOuterRule = (index, outer_rule, setOuterRule, setting, setTemplateSetting) => {
    const updatedOuterRule = outer_rule.filter((_, i) => i !== index);

    setOuterRule(updatedOuterRule);

    const newActiveIndex = setting.activeRuleIndex === index 
        ? null 
        : setting.activeRuleIndex > index 
            ? setting.activeRuleIndex - 1 
            : setting.activeRuleIndex;

    setTemplateSetting({ activeRuleIndex: newActiveIndex });
};

export const setActiveRule = (index, setTemplateSetting) => {
    setTemplateSetting({ activeRuleIndex: index });
};
