import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import Page from 'components/Shared/Page'
import GridTable from "@nadavshaar/react-grid-table"
import Menu from "./Menu";
import { apiURL, BASE_URL } from '../../utils/io'


class Orientations extends Component {

    constructor(props) {

        super(props)

        this.state = {

            component: { 
                id: 0, 
                name: '' 
            },

            columns: [

                {
                    id: 'iconUrl',
                    label: 'Изображение',
                    width: "150px",
                    cellRenderer: ({ data }) => (
                        <img src={data.iconUrl} alt={data.name} style={{ width: '100px', height: 'auto', padding: '10px' }} />
                    )
                },

                {
                    id: 'Перейти',
                    label: '',
                    width: "100px",
                    cellRenderer: ({ data }) => (
                        <Link to={`/admin/orintations/${data.id}`} className="text-blue-500 underline">{ data.name }</Link>
                    )
                },

                {
                    id: 'Delete',
                    label: '',
                    width: "100px",
                    cellRenderer: ({ data }) => (
                        <button onClick={() => this.Delete(data.id)} className="text-red-500">Удалить</button>
                    )
                },

            ],

            rows: [],

            totalRows: 0,

            isLoading: true,

        }

    }

    componentDidMount() {
        this.All(this.props.match.params.id); this.ComponentGetById(this.props.match.params.id)
    }

    ComponentGetById = async (id) => {

        this.setState({ isLoading: true })
    
        try {
            const response = await fetch(`${apiURL}/components/${id}`)
            if (response.ok) {

                const data = await response.json()

                this.setState(prevState => ({
                    component: data,
                    isLoading: false,
                }))

            } else {
                throw new Error('Network response was not ok.')
            }
        } catch (error) {
            console.error('Error fetching:', error)
        }finally{
            this.setState({ isLoading: false })
        }

    }

    All = async (id) => {

        this.setState({ isLoading: true })

        try {
            const response = await fetch(`${apiURL}/components/${id}/orientations`)
            if (response.ok) {
                const data = await response.json()
                this.setState({
                    rows: data,
                    totalRows: data.length,
                    isLoading: false
                })
            } else {
                throw new Error('Network response was not ok.')
            }
        } catch (error) {
            console.error('Error fetching:', error)
        }finally{
            this.setState({ isLoading: false })
        }

    }

    
    Delete = async (id) => {
        this.setState({ isLoading: true });

        try {

            const response = await fetch(`${apiURL}/orientations/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            
            if (response.ok) {
                this.setState(prevState => ({
                    rows: prevState.rows.filter(o => o.id !== id),
                    totalRows: prevState.totalRows - 1
                }));
            } else {
                throw new Error('Failed to delete the orientation');
            }

        } catch (error) {
            console.error('Error deleting orientation:', error);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    render() {

        const { columns, rows, totalRows, isLoading, component } = this.state

        return (
            <Page className="admin-users-page" title="Компоненты">

                <Menu />

                <section className="users-header">
                    <h1>Компонент - {component.name} / Ориентации</h1>
                </section>

                <div className="grid-content">
                    {isLoading ? (
                        <div>Loading...</div>  // Индикатор загрузки
                    ) : (
                        <GridTable
                            showSearch={true}
                            showColumnVisibilityManager={true}
                            columns={columns}
                            rows={rows}
                            totalRows={totalRows}
                            texts={{
                                search: 'Поиск:',
                                totalRows: 'Общее кол-во:',
                                rows: 'Показано:',
                                selected: 'Выбрано',
                                rowsPerPage: 'Элементов на странице:',
                                page: 'Страница:',
                                of: 'из',
                                prev: 'Пред.',
                                next: 'След.',
                                columnVisibility: 'Колонки'
                            }}
                        />
                    )}
                </div>
            </Page>
        )
    }

}

export default withRouter(Orientations)