import React, {Component} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import classNames from 'classnames'

import {COMPONENT, ELLIPSE, LIBRARY_COMPONENT, LINE, SECTION, TYPE_OPTIONS,} from 'common/constants'

import {getObjectName} from 'utils/naming'
import {getAppComponent} from 'utils/libraries'

import { getAuthComponentId } from 'ducks/apps/selectors'
import {getComponentId, getParentComponent, selectObjects,} from 'ducks/editor/objects'
import {getSelection, resetSelection, setSelection,} from 'ducks/editor/selection'
import {getActiveTab, setActiveTab} from 'ducks/editor/tabs'

import {PanelItem} from 'components/Shared/Panel'
import ErrorBoundary from 'components/Shared/ErrorBoundary'
import Icon from 'components/Shared/Icon'
import ScreenName from 'components/Editor/RightPanel/ScreenName'

import InspectPanel from '../Inspect'
import LibraryInspect from '../Inspect/Libraries'

import ScreenControl from '../Inspect/ScreenControl'
import OverflowMenu from './OverflowMenu'

import './RightPanel.scss'
import Scrollbar from "../../Shared/Scrollbar";

export const TYPE_NAMES = TYPE_OPTIONS.reduce(
  (acc, option) => ({
    ...acc,
    [option.value]: option.label,
  }),
  {}
)

TYPE_NAMES[SECTION] = 'Группа'
TYPE_NAMES[LINE]    = 'Линия'
TYPE_NAMES[ELLIPSE] = 'Круг'

class RightPanel extends Component {

  state = {
    screenTemplateExpanded: false,
  }

  showScreenTemplates = () => {
    this.setState({ screenTemplateExpanded: true })
  }

  handleBack = () => {
    let {
      componentParent,
      setActiveTab,
      setSelection,
      resetSelection,
    } = this.props

    if (!componentParent) {
      resetSelection()

      return setActiveTab('screens')
    }

    const { id } = componentParent

    return setSelection(id, false)
  }

  getObject = (_objects = null) => {

    let { objects } = this.props

    objects = _objects || objects

    const object = objects.length === 1 ? objects[0] : null

    return object

  }

  collapseVisibility = () => {
    this.setState({ visibilityExpanded: false })
  }

  collapseScreenTemplate = () => {
    this.setState({ screenTemplateExpanded: false })
  }

  componentDidUpdate(oldProps) {

    let { screenTemplateExpanded } = this.state
    let oldObject = this.getObject(oldProps.objects)
    let newObject = this.getObject()

    if (screenTemplateExpanded && (!oldObject || !newObject || oldObject.id !== newObject.id)) {
      this.collapseScreenTemplate()
    }

  }

  renderContent() {
    const { match } = this.props
    const { screenTemplateExpanded } = this.state
    const { appId } = match.params

    let object = this.getObject()

    if (object?.type === LIBRARY_COMPONENT) {
      return <LibraryInspect object={object} />
    }

    if (object?.type === COMPONENT && !object.reusableComponent) {
      return (
        <Scrollbar>
          <ScreenControl
            screenTemplateExpanded={screenTemplateExpanded}
            appId={appId}
            object={object}
            onCollapseScreenTemplate={this.collapseScreenTemplate}
            onShowScreenTemplates={this.showScreenTemplates}
          />
        </Scrollbar>
      )
    }

    return <Scrollbar><InspectPanel /></Scrollbar>
  }

  renderTypeHeader() {

    let object = this.getObject()

    if (!object) return null

    const { type, componentName, libraryName } = object

    switch (type) {
      case LIBRARY_COMPONENT: {
        const component = getAppComponent(null, libraryName, componentName)

        return component?.displayName
      }
    }

    if (!object) return null

    return TYPE_NAMES[object.type]
  }



  renderBack = () => {
    const { componentParent } = this.props

    return (
      <div className="right-panel-back" onClick={this.handleBack}>
        <Icon type="keyboard-back" />
        {componentParent ? getObjectName(componentParent) : 'Слои'}
      </div>
    )
  }

  renderActions = object => {

    const { match } = this.props
    const { appId } = match.params

    if (!object) return null

    return (
      <OverflowMenu
        appId={appId}
        object={object}
        onShowVisibility={this.showVisibility}
        onShowScreenTemplates={this.showScreenTemplates}
      />
    )

  }

  render() {

    let { objects, componentId, match } = this.props

    let { appId } = match.params

    let object = null

    if (!objects || objects.length === 0) return null

    if (objects.length === 1) object = objects[0]

    let name = getObjectName(object)

    let subtitle = () => {
      if (object.type !== COMPONENT) return this.renderTypeHeader(); return 'Слой'
    }

    return (
      <>

        { this.renderBack() }

        <div className={classNames({ 'multiple-objects-header': !object })}>

          {object ? (

            <>

              <OverflowMenu
                appId={appId}
                object={object}
                onShowScreenTemplates={this.showScreenTemplates}
              />

              <PanelItem
                title={name}
                subtitle={subtitle()}
                renderActions={() => this.renderActions(object)}
                group={object.id}
                fluid
                header
                boxed
              >
                <ScreenName object={object} />
              </PanelItem>

            </>

          ) : (
            <span className="right-panel-selected-object-name">
              <span>{objects.length} компонентов выбрано</span>
            </span>
          )}

        </div>

        <ErrorBoundary>{this.renderContent()}</ErrorBoundary>

      </>
    )
  }
}

const mapStateToProps = (state, { match }) => {

  const { appId }   = match.params
  let selection     = getSelection(state)
  let componentId   = null

  if (selection.length === 0) {
    return {}
  }

  if (selection.length === 1) {
    componentId = getComponentId(state, selection[0])
  }

  return {
    componentId,
    componentParent: getParentComponent(state, selection?.[0]),
    activeTab: getActiveTab(state),
    objects: selectObjects(state, selection),
    isAuthComponent: componentId === getAuthComponentId(state, appId),
  }
}

export default withRouter(
  connect(mapStateToProps, { setActiveTab, setSelection, resetSelection })(
    RightPanel
  )
)