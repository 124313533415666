import {borderStyle} from 'common/constants'
import BaseObject from './BaseObject'

import {scaleValue} from 'utils/zoom'

export default class BaseShape extends BaseObject {
  getBorderWidth() {
    let { borderWidth, zoom } = this.props
    borderWidth = 1
    return scaleValue(borderWidth, zoom)
  }

  getBorderProps() {
    let { borderColor, borderPosition, ...styles } = this.props

    if (!styles.borderStyle || styles.borderStyle === borderStyle.NONE) {
      return {
        strokeWidth: 1,
        stroke: 'transparent',
      }
    }

    let strokeWidth = this.getBorderWidth()

    let borderProps = {
      strokeWidth,
      stroke: this.getColor(borderColor),
    }



    return borderProps
  }

  getBackgroundColor() {


    return  (this.props.error || this.props.fake )
      ? '#f58a8a'
      : '#ddd'
  }

  getShadowProps() {
    let {
      xScaled,
      yScaled,
      widthScaled,
      heightScaled,
      shadow,
      zoom,
    } = this.props

    let border = this.getBorderProps()

    shadow = {
      ...shadow,
      color: this.getColor(shadow.color),
    }

    return {
      shadow,
      zoom,
      border,
      x: xScaled,
      y: yScaled,
      width: widthScaled,
      height: heightScaled,
    }
  }
}
