import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import {ModalFooter, PrimaryButton, SecondaryButton,} from '../../../Shared/NewModal'
import WrappedInput from '../../../Shared/Forms/WrappedInput'

const FORM_NAME = 'NewScreenCategory'
class NewCategoryForm extends Component {
  render() {
    const { name, onClose, handleSubmit } = this.props

    return (
      <>
        <h1>New Category</h1>
        <form onSubmit={handleSubmit} id="new-category-form">
          <Field
            autoFocus
            name="name"
            label="Name"
            component={WrappedInput}
            large
          />
        </form>
        <ModalFooter>
          <SecondaryButton alignRight onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton form="new-category-form" disabled={!name}>
            Create
          </PrimaryButton>
        </ModalFooter>
      </>
    )
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector(FORM_NAME)
  const name = selector(state, 'name')

  return { name }
}

export default reduxForm({
  form: FORM_NAME,
})(connect(mapStateToProps)(NewCategoryForm))
