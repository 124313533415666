import React from "react";
import cn from 'classnames';
import classNames from 'classnames';
import styles from './index.module.scss';
import {Field, reduxForm} from "redux-form";
import Button from "../../../Shared/Button";
import WrappedNumberInput from "../../../Shared/Forms/WrappedNumberInput";


class TraceForm extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            tolerance: 15,
            selected: false,
            points: [],
            size: {width: 0, height: 0, depth: 0}
        }
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }


    render() {
        let {...props} = this.props;
        let {
            handleSubmit,
            submitSucceeded,
            submitting,
            error
        } = this.props


        let {size} = this.state
        const {invalid} = this.props

        return (
            <form onSubmit={handleSubmit} className="trace-size-form"  autoComplete="off">
                <div className={cn(styles.row)}>
                    <div className="panel-section">

                        <div className="panel-section-header">
                            <h4>Размер</h4>
                        </div>

                        <div
                            className={classNames('panel-section-body')}
                        >
                            <Field
                                autoFocus={true}
                                type="number"
                                label="Ширина"
                                name="width"
                                converted = {true}

                                suffix={'mm'}
                                component={WrappedNumberInput}
                            />

                            <Field

                                type="number"
                                label="Высота"
                                name="height"
                                converted = {true}

                                suffix={'mm'}
                                component={WrappedNumberInput}
                            />
                            <Field

                                type="number"
                                label="Глубина"
                                name="depth"
                                converted = {true}

                                suffix={'mm'}
                                component={WrappedNumberInput}
                            />

                        </div>
                    </div>

                </div>
                <div className={cn(styles.row)}>
                    <div className={cn(styles.item, styles.x6)}>
                        <Button
                            primary
                            fluid
                            disabled={invalid}
                        >
                            Использовать
                        </Button>
                    </div>
                    <div className={cn(styles.item, styles.x6)}>
                        <Button
                            secondary
                            fluid
                            onClick={() => {
                                this.setState({selected: false})
                            }}
                        >
                            Очистить
                        </Button>
                    </div>
                </div>
            </form>
        );
    }
}


const FORM_NAME = 'traceForm'
export const validate = data => {
    let errors = {}
    if (!data.height) {
        errors.height = 'Укажите глубину слоя'
    }
    if (!data.width) {
        errors.width = 'Укажите глубину слоя'
    }
    if (!data.depth) {
        errors.depth = 'Укажите глубину слоя'
    }

    return errors
}
export default reduxForm({
    form: FORM_NAME,
    validate,
})(TraceForm)
/*
export default withRouter(connect(mapStateToProps, {updatePath, uploadImage, updateSize, setTool})(
    reduxForm({
        initialValues: {
            template: 'blank',
        },
        validate,
        form: FORM_NAME,
    })(TraceForm)
))*/
