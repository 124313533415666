import React, {Component} from 'react'
import {getSymbolLabel} from '../../../utils/formulas'

import 'styles/SymbolEntity.css'

export default class SymbolEntity extends Component {
  render() {
    let { entity } = this.props

    return (
      <span className="symbol-entity">
        <span className="symbol-entity-sub">{getSymbolLabel(entity)}</span>
      </span>
    )
  }
}
