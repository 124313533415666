import React, {Component} from 'react'

import Color from 'color'

import {scaleValue} from '../../../utils/zoom'

export default class Shadow extends Component {
  constructor() {
    super()
    this._id = Math.round(Math.random() * 10000).toFixed(0)
    this._filterId = `filter-${this._id}`
    this._maskId = `mask-${this._id}`
  }

  getBlurRadius() {
    let { shadow, zoom } = this.props
    shadow = shadow || {}

    let size = shadow.size || 0

    return scaleValue(size / 2, zoom)
  }

  getBorderWidth() {
    let { border, zoom } = this.props

    return scaleValue((border.strokeWidth || 0) / 2, zoom)
  }

  getShadowPosition() {
    let { shadow, zoom } = this.props
    shadow = shadow || {}
    let x = shadow.x || 0
    let y = shadow.y || 0

    return [scaleValue(x, zoom), scaleValue(y, zoom)]
  }

  getColor() {
    let { shadow } = this.props
    shadow = shadow || {}
    let color = Color(shadow.color)

    return {
      r: color.red() / 255,
      g: color.green() / 255,
      b: color.blue() / 255,
      a: color.alpha(),
    }
  }

  render() {
    let { width, height, children } = this.props

    let maskChild = React.cloneElement(children, {
      fill: '#000',
      stroke: '#000',
    })

    let border = this.getBorderWidth()

    let mainChild = React.cloneElement(maskChild, {
      filter: `url(#${this._filterId})`,
      mask: `url(#${this._maskId})`,
    })

    let stdDeviation = this.getBlurRadius()
    let color = this.getColor()

    let [shadowX, shadowY] = this.getShadowPosition()

    let maskX = -3 * stdDeviation + shadowX - border
    let maskY = -3 * stdDeviation + shadowY - border
    let maskWidth = width + 6 * stdDeviation + border * 2
    let maskHeight = height + 6 * stdDeviation + border * 2

    return (
      <g>
        <filter
          id={this._filterId}
          width={width * 2}
          height={height * 2}
          x={-width / 2}
          y={-height / 2}
        >
          <feOffset
            result="offsetOut"
            in="SourceAlpha"
            dx={shadowX}
            dy={shadowY}
          />
          <feComponentTransfer result="alphaOut" in="offsetOut">
            <feFuncR type="linear" slope="0" intercept={color.r} />
            <feFuncG type="linear" slope="0" intercept={color.g} />
            <feFuncB type="linear" slope="0" intercept={color.b} />
            <feFuncA type="linear" slope={color.a} intercept="0" />
          </feComponentTransfer>
          <feGaussianBlur in="alphaOut" stdDeviation={stdDeviation} />
        </filter>
        <mask
          id={this._maskId}
          maskUnits="userSpaceOnUse"
          x={maskX}
          y={maskY}
          width={maskWidth}
          height={maskHeight}
        >
          <rect
            x={maskX}
            y={maskY}
            width={maskWidth}
            height={maskHeight}
            fill="#fff"
          />
          {maskChild}
        </mask>
        {mainChild}
      </g>
    )
  }
}
