import React, { Component } from 'react';
import Stripe from '../../Shared/Stripe';
import 'styles/Navbar.css';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from 'ducks/users';

class Navbar extends Component {
  
  handleLogout = () => {
    this.props.logout();
  };

  render() {
    let { children } = this.props;

    return (
      <div className="app-navbar">
        <Stripe className="app-navbar-stripe" />
        <Link to={'/'} className="app-navbar-brand">TotalShapes</Link>
        {children}

        <div className="logout" onClick={this.handleLogout}>
          <div className="left-nav-avatar">
            <div className="left-nav-avatar__placeholder">Вых</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(null, { logout })(Navbar));

export const Spacer = () => <div className="navbar-spacer" />;
