import React, { Component } from 'react'
import classNames from 'classnames'

import './Panel.scss'

export { default as PanelMenu } from './Menu'
export { default as PanelItem } from './Item'



export class PanelSection extends Component {

  render() {

    let { children, noPad, title, headerAction } = this.props

    return (
      <div className="panel-section">

        {title && (
          <div className="panel-section-header">
            <h4>{title}</h4>
            {headerAction ? (
              <div className="panel-section-header-action">{headerAction}</div>
            ) : null}
          </div>
        )}

        <div className={classNames('panel-section-body', {
            'panel-section-body-nopad': noPad,
          })}
        >
          {children}

        </div>
      </div>
    )
  }
}

export class Panel extends Component {

  render() {

    let { children, className, headerContent, footer } = this.props

    return (
      <div className={classNames('panel', className)}>

        {headerContent && (
          <div className="panel-header-content">{headerContent}</div>
        )}

        <div className="panel-body">{children}</div>

        {footer ? <div className="panel-footer">{footer}</div> : null}

      </div>
    )
  }
}
