export const ESC = 27
export const RETURN = 13
export const SHIFT = 16

export const G = 71
export const Z = 90
export const L = 76

export const LEFT_ARROW = 37
export const UP_ARROW = 38
export const RIGHT_ARROW = 39
export const DOWN_ARROW = 40

export const BACKSPACE = 8
export const DELETE = 46
export const SPACEBAR = 32

export const PLUS = 187
export const MINUS = 189
export const ZERO = 48
export const ONE = 49

export const PLUS_FIREFOX = 61
export const MINUS_FIREFOX = 173

export const PLUS_ALL = [PLUS, PLUS_FIREFOX]
export const MINUS_ALL = [MINUS, MINUS_FIREFOX]

export const ARROW_KEYS = [LEFT_ARROW, UP_ARROW, RIGHT_ARROW, DOWN_ARROW]

export const ARROW_KEY_OFFSETS = {
  [LEFT_ARROW]: [-1, 0],
  [UP_ARROW]: [0, -1],
  [RIGHT_ARROW]: [1, 0],
  [DOWN_ARROW]: [0, 1],
}
