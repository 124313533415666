import React, {forwardRef, useCallback, useRef,} from 'react';
import cn from 'classnames';
import {mergeRefs} from 'utils/mergerefs';
import {useFocus} from 'hooks/useFocus';

import styles from './index.module.scss';
import {throttle} from "underscore";

export const Slider = forwardRef(
    (
        {
            min = 0,
            max = 100,
            step = 1,
            value = 0,
            steps,
            className,
            onChange,
            dataTestId,
            ...restProps
        },
        ref,
    ) => {
        const inputRef = useRef(null);

        const [focused] = useFocus(inputRef, 'keyboard');

        const range = max - min;
        const dividedWithoutRemainder = range % step === 0;
        const validValue = Math.max(min, Math.min(value, max));

        const rangeProps = {
            className: cn(styles.range, {[styles.focused]: focused}),
            type: 'range',
            min,
            max,
            value: validValue,
            step: dividedWithoutRemainder ? step : undefined,
        };

        const progressProps = {
            className: styles.progress,
            max: range,
            value: validValue - min,
        };

        const handleInputChange = useCallback(
            throttle((event) => {
                if (onChange) {
                    onChange(event, {value: +event.target.value});
                }
            }, 1000),

            [onChange],
        );

        return (
            <div className={cn(styles.component, className)} data-test-id={dataTestId}>
                <div className={styles.rangeWrapper}>
                    <input
                        {...rangeProps}
                        {...restProps}
                        ref={mergeRefs([ref, inputRef])}
                        onChange={handleInputChange}
                    />
                    {steps}
                </div>
                <progress {...progressProps} />
            </div>
        );
    },
);

/**
 * Для отображения в сторибуке
 */
Slider.defaultProps = {
    min: 0,
    max: 100,
    step: 1,
    value: 0,
};
