import React, {Component} from 'react'
import {connect} from 'react-redux'
import {CSSTransition, TransitionGroup} from 'react-transition-group'

import {getNotifications, hideNotification} from 'ducks/notifications'

import Snackbar from 'components/Shared/Snackbar'

import 'styles/Notifications.css'

class Notification extends Component {

  handleHide = () => {
    let { notification, hideNotification } = this.props

    hideNotification(notification.id)
  }

  render() {
    let { notification } = this.props

    return (
      <div className="notification-item">
        <Snackbar onDismiss={this.handleHide}>{notification.message}</Snackbar>
      </div>
    )
  }
}

class Notifications extends Component {

  render() {

    let { notifications, hideNotification } = this.props

    return (
      <TransitionGroup className="notifications">
        {notifications.map(notif => (
          <CSSTransition
            key={notif.id}
            timeout={400}
            classNames="notification-item"
          >
            <Notification
              notification={notif}
              hideNotification={hideNotification}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    )
    
  }
}

const mapStateToProps = state => ({
  notifications: getNotifications(state),
})

export default connect(mapStateToProps, { hideNotification })(Notifications)
