export const convertToBlocks = value => {
  let blocks = [[]]
  let currentBlock = 0

  if (typeof value === 'string' || typeof value === 'number') {
    value = [String(value)]
  } else if (!Array.isArray(value)) {
    value = ['', value, '']
  }

  for (let itm of value) {
    if (typeof itm !== 'string') {
      blocks[currentBlock].push(itm)

      continue
    }

    let pieces = itm.split(/\n/)

    for (let i = 0; i < pieces.length; i += 1) {
      let piece = pieces[i]

      if (i > 0) {
        currentBlock += 1
      }

      if (!blocks[currentBlock]) {
        blocks[currentBlock] = []
      }

      blocks[currentBlock].push(piece)
    }
  }

  if (blocks.length === 0) {
    return [['']]
  }

  for (let block of blocks) {
    if (block.length === 0) {
      block.push('')
    }
  }

  return blocks
}

const lastItem = arr => arr[arr.length - 1]

export const convertToSimple = blocks => {
  let result = []

  for (let block of blocks) {
    // Avoid mutating block
    block = block.slice()

    if (result.length === 0) {
      result = result.concat(block)
    } else {
      if (typeof lastItem(result) !== 'string') {
        result.push('')
      }

      if (typeof block[0] !== 'string') {
        block.unshift('')
      }

      result[result.length - 1] = `${lastItem(result)}\n${block[0]}`
      result = result.concat(block.slice(1))
    }
  }

  // Stick with basic strings if possible
  if (result.length === 1 && typeof result[0] === 'string') {
    return result[0]
  }

  return result
}
