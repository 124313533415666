import { combineReducers } from 'redux';

import templateDetailsReducer from './templateDetailReducer';
import outerRuleReducer from './outerRuleReducer';
import partsReducer from './partsReducer';
import inCaseShapesReducer from './inCaseShapesReducer';
import templateSettingReducer from './templateSettingReducer';

const templateCombineReducers = combineReducers({
    details: templateDetailsReducer,
    outer_rule: outerRuleReducer,
    parts: partsReducer,
    in_case_shapes: inCaseShapesReducer,
    setting: templateSettingReducer,
});

export default templateCombineReducers;
