const initialState = [];

const inCaseShapesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_IN_CASE_SHAPES':
            return action.payload;
        default:
            return state;
    }
};

export default inCaseShapesReducer;
