import React, {Component} from 'react'
import {connect} from 'react-redux'

import {getObjectPosition, getYOffset, getZoom, selectObject,} from 'ducks/editor/objects'

import {getHoverSelection, getSelection} from 'ducks/editor/selection'
import {getMarquee} from 'ducks/editor/marquee'

import Box from './Box'

import 'styles/HoverSelection.css'

class HoverSelection extends Component {
  render() {
    let { marquee, objects, zoom, yOffset } = this.props

    if (marquee || objects.length === 0) {
      return null
    }

    return (
      <g>
        {objects.map(
          obj =>
            obj && (
              <Box
                isHover
                key={obj.id}
                object={obj}
                zoom={zoom}
                yOffset={yOffset}
              />
            )
        )}
      </g>
    )
  }
}

const mapStateToProps = state => {
  let hoverSelection = getHoverSelection(state)

  hoverSelection = hoverSelection.filter(
    id => getSelection(state).indexOf(id) === -1
  )

  let objects = hoverSelection.map(id => ({
    ...selectObject(state, id),
    ...getObjectPosition(state, id),
  }))

  return {
    objects,
    zoom: getZoom(state),
    marquee: getMarquee(state),
    yOffset: getYOffset(state),
  }
}

export default connect(mapStateToProps)(HoverSelection)
