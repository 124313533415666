import React, {Component} from 'react'
import {connect} from 'react-redux'

import {COPY_APP, DELETE_APP, getActiveModal, INVITE_DRAFT, RENAME_DRAFT,} from 'ducks/editor/modals'

import Modal from 'components/Shared/Modal'

import Copy from "../../Copy";
import Delete from "../../Delete";
import RenameDraft from "../../Rename";
import InviteDraft from "../../Invite";

class ModalWrapper extends Component {
    handleSuccess = value => {
        let {activeModal} = this.props
        activeModal.resolve(value)
    }

    handleReject = err => {
        let {activeModal} = this.props
        activeModal.reject(err)
    }

    renderModal = (children, modalProps) => {
        return (
            <div className="modal-wrapper-wrapper">
                <Modal {...modalProps} scrolling onClose={this.handleReject}>
                    {children}
                </Modal>
            </div>
        )
    }

    render() {
        let {activeModal} = this.props

        if (!activeModal) {
            return null
        }

        if (activeModal.modalType === COPY_APP) {
            return this.renderModal(
                <Copy
                    opts={activeModal.opts}
                    onSuccess={this.handleSuccess}
                    onCancel={this.handleReject}
                />,
                {className: 'copy-modal'}
            )
        }

        if (activeModal.modalType === DELETE_APP) {
            return this.renderModal(
                <Delete
                    opts={activeModal.opts}
                    onSuccess={this.handleSuccess}
                    onCancel={this.handleReject}
                />,
                {className: 'copy-modal'}
            )
        }
        if (activeModal.modalType === RENAME_DRAFT) {
            return this.renderModal(
                <RenameDraft
                    opts={activeModal.opts}
                    onSuccess={this.handleSuccess}
                    onCancel={this.handleReject}
                />,
                {className: 'rename-modal'}
            )
        }
        if (activeModal.modalType === INVITE_DRAFT) {
            return this.renderModal(
                <InviteDraft
                    opts={activeModal.opts}
                    onSuccess={this.handleSuccess}
                    onCancel={this.handleReject}
                />,
                {className: 'rename-modal'}
            )
        }
        return null
    }
}

const mapStateToProps = state => ({
    activeModal: getActiveModal(state),
})

export default connect(mapStateToProps)(ModalWrapper)
