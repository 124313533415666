import {getObject, pathLength, sortPaths, subPath, uniqueElements,} from 'common/utils'

export const commonBase = paths => {
  let base = paths[0]

  paths.forEach(path => {
    let length = pathLength(base)

    if (subPath(path, length) !== base) {
      base = commonBase([subPath(base, length - 1), subPath(path, length - 1)])
    }
  })

  return base || ''
}

export const isSelectable = (selection, path) => {
  let basePath = commonBase(selection)

  let commonPath = commonBase([basePath, path])

  return pathLength(commonPath) >= pathLength(path) - 1
}

export const isExpandSelectable = (selection, path) => {
  return isSelectable(selection, subPath(path, pathLength(path) - 1))
}

export const getSelectableObject = (selection, path, minLength = 1) => {
  if (selection.length === 0) {
    return subPath(path, minLength)
  }

  let maxLength = pathLength(path)

  let commonLength = 1

  for (let itm of selection) {
    let common = commonBase([itm, path])
    let length = pathLength(common)

    // If selection is a parent of path, don't auto-expand
    if (pathLength(itm) > length) {
      length += 1
    }

    if (length >= maxLength) {
      return path
    }

    if (length > commonLength) {
      commonLength = length
    }
  }

  commonLength = Math.max(minLength, commonLength)

  return subPath(path, commonLength)
}

export const getDoubleClickSelection = (selection, path) => {
  let parentPath = getSelectableObject(selection, path)

  return subPath(path, pathLength(parentPath) + 1)
}

export const sortSelection = (selection, list, map) => {
  let paths = uniqueElements(selection.map(id => map[id])).filter(p => p)

  let ids = sortPaths(paths)
    .map(p => getObject(list, p)?.id)
    .filter(id => id)

  return ids
}
