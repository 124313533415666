import React from 'react'
import SliderControl from './Libraries/SliderControl'

export function getMaxBorderRadius(object) {
  const { width = 100, height = 100 } = object

  return Math.floor(Math.min(width, height) / 2)
}

const BorderRadiusControl = props => <SliderControl {...props} />

export default BorderRadiusControl
