import React, {Component} from 'react'
import classNames from 'classnames'
import Dropzone from 'react-dropzone'

import {getFilename, uploadImage, uploadURL} from '../../../utils/uploads'
import {assetURL} from '../../../utils/assets'
//import { uploadImage as uploadAssetImage } from '../../../utils/io'
import Loading from '../Loading'

import 'styles/ImageInput.css'

export default class ImageInput extends Component {
  static defaultProps = {
    buttons: ['view', 'remove'],
  }

  state = {
    uploadingImage: null,
  }

  handleChange = () => {
    if (this.dropzone) {
      this.dropzone.open()
    }
  }

  handleDrop = (acceptedFiles, rejectedFiles) => {
    let { isAsset, input } = this.props
    let { onChange } = input

    let file = acceptedFiles.concat(rejectedFiles)[0]

    if (!file) {
      return this.errorMessage('No valid file')
    }

    let reader = new FileReader()

    reader.onload = async evt => {
      this.setState({ uploadingImage: reader.result })

      let uploadFunc = this.uploadImage

      try {
        let url = await uploadFunc(reader.result)
        onChange(url)
      } catch (err) {
        console.error('ERROR UPLOADING IMAGE:', err)
        this.errorMessage()
      }

      this.setState({ uploadingImage: null })
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  uploadImage = async dataURL => {
    let { isAsset } = this.props
    let result = await uploadImage(dataURL)

    return isAsset ? result.url : result
  }

  uploadAssetImage = async dataURL => {
    let { appId } = this.props
    let filename = getFilename(dataURL)
    let file = dataURL.split(',')[1]

    return //await uploadAssetImage(appId, file, filename)
  }

  errorMessage = (msg = null) => {
    if (msg) {
      console.error(msg)
    }

    // eslint-disable-next-line no-alert
    alert(
      'An error occurred while uploading your image. Only .jpg, .png, & .gif images are allowed.'
    )
  }

  getImageURL = () => {
    let { uploadingImage } = this.state

    let {
      isAsset,
      input: { value },
    } = this.props

    if (uploadingImage) {
      return uploadingImage
    } else if (value) {
      return isAsset ? assetURL(value) : uploadURL(value)
    }

    return null
  }

  handleRemove = () => {
    let {
      input: { onChange },
    } = this.props

    onChange(null)
  }

  renderLinks(url) {
    const {
      input: { value },
      buttons,
    } = this.props

    if (!value) {
      return null
    }

    return (
      <div className="image-input-links">
        {buttons.map(button => {
          if (button === 'view') {
            return (
              <a
                key="view"
                href={url}
                target="_blank"
                className="deemphasize"
                rel="noopener noreferrer"
              >
                View
              </a>
            )
          } else if (button === 'change') {
            return (
              <a
                key="change"
                className="deemphasize image-input-change"
                onClick={this.handleChange}
              >
                Change
              </a>
            )
          } else if (button === 'remove') {
            return (
              <a
                key="remove"
                className="deemphasize image-input-remove"
                onClick={this.handleRemove}
              >
                Remove
              </a>
            )
          }
        })}
      </div>
    )
  }

  setDropzoneRef = zone => {
    this.dropzone = zone
  }

  render() {

    let { uploadingImage } = this.state
    let { displayName } = this.props
    let url = this.getImageURL()

    return (
      <div className="image-input-wrapper">
        {displayName ? <p>{displayName}</p> : null}
        <Dropzone

          accept="image/jpeg,image/png,image/gif"
          onDrop={this.handleDrop}
          ref={this.setDropzoneRef}
        >
          {({getRootProps, getInputProps}) => (
<div {...getRootProps({className: classNames('image-input', !url && 'image-input-empty')})}>
              <div className="image-input-sub">
                {url ? (
                  <div
                    style={{ backgroundImage: `url('${url}')` }}
                    className="image-input-preview"
                  />
                ) : null}
                {uploadingImage ? <Loading expanded /> : null}
                <input {...getInputProps()} />
                <div className="image-input-select">Выберите изображение</div>
              </div>
</div>
           )}

        </Dropzone>
        {this.renderLinks(url)}
      </div>
    )
  }
}
