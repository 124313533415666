


export const filterByQuery = (list, query, accessor) => {
  let values = accessor ? list.map(v => accessor(v)) : list
  query = query.trim()

  // skip single pass in empty case
  if (!query) {
    return values
  }

  let terms = query.split(/\s+/)

  for (let term of terms) {
    values = values.filter(itm => itm.includes(term))
  }

  return values
}

