import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import classNames from 'classnames'


//import { VelocityTransitionGroup } from 'velocity-react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'



import {getUID} from 'utils/uid'
import {colors} from 'utils/colors'

import {closeAccordion, getAccordionState, openAccordion, toggleAccordion,} from 'ducks/accordions'

import Icon, {IconButton} from './Icon'
import Button from './Button'

import './Accordion.scss'
import './Accordion.css';



export default class Accordion extends Component {
  static propTypes = {
    color: PropTypes.oneOf(colors),
    titleComponent: PropTypes.node,
  }

  static defaultProps = {
    titleComponent: 'div',
  }

  constructor(props) {
    super(props)

    this.state = {
      expanded: Boolean(props.defaultExpanded),
    }
  }

  handleToggle = () => {
    let expanded = this.getExpanded()

    if (expanded) {
      this.closeSub()
    } else {
      this.openSub()
    }
  }

  // Ref-accessible function
  handleOpen = () => {
    this.openSub()
  }

  // Ref-accessible function
  handleClose = () => {
    this.closeSub()
  }

  getExpanded() {
    let { expanded } = this.state

    return expanded
  }

  openSub() {
    this.setState({ expanded: true })
  }

  closeSub() {
    this.setState({ expanded: false })
  }

  handleChildrenMouseDown = e => {
    e.stopPropagation()
  }

  handleToggleAdvanced = () => {
    const { group, toggleAccordion, itemId } = this.props
    const advancedGroup = `${group}-advanced-${itemId}`

    if (!toggleAccordion) return null

    return toggleAccordion(advancedGroup, itemId)
  }

  renderChildren = () => {
    let {
      advancedShown,
      renderChildren,
      renderAdvanced,
      boxed,
      color,
      group,
      itemId,
      isEditable,
      editAction,
    } = this.props

    if (!renderChildren) {
      return null
    }

    const advancedGroup = `${group}-advanced-${itemId}`

    const colorProp = color ? { [color]: true } : {}

    return (
      renderChildren && (
        <div className="accordion-children">
          {renderChildren()}
          {renderAdvanced ? (
            <GroupedAccordion
              className="accordion-children-advanced-options"
              color={color}
              renderChildren={renderAdvanced}
              group={advancedGroup}
              itemId={itemId}
              hideCarret
            />
          ) : null}
          {boxed ? (
            <div
              className={classNames('accordion-boxed-done', {
                'is-editable': isEditable,
              })}
            >
              {isEditable ? (
                <IconButton type="pencil-small" onClick={editAction} />
              ) : null}
              {renderAdvanced ? (
                <Button
                  {...colorProp}
                  text
                  small
                  square
                  type="button"
                  onClick={this.handleToggleAdvanced}
                >
                  {advancedShown ? 'Hide' : 'Show'} Advanced
                </Button>
              ) : null}
              <Button
                {...colorProp}
                small
                square
                type="button"
                onClick={this.handleClose}
              >
                Применить
              </Button>
            </div>
          ) : null}
        </div>
      )
    )
  }

  renderHeader = () => {
    let {
      boxed,
      hideCarret,
      title,
      icon,
      collapsedTitle,
      expandedTitle,
      renderHeader,
      isAfterTrial,
    } = this.props

    let expanded = this.getExpanded()

    collapsedTitle = collapsedTitle || title
    expandedTitle = expandedTitle || title
    let currentTitle = expanded ? expandedTitle : collapsedTitle

    if (renderHeader) return renderHeader(expanded)

    const expandedClass = isAfterTrial ? 'expand after-trial-icon' : 'expand'

    return (
      <>
        {!boxed && !hideCarret ? (
          <Icon type={expanded ? 'expand-vertical' : expandedClass} />
        ) : null}
        {icon && <Icon className="accordion-icon" type={icon} />}
        {currentTitle}
      </>
    )
  }

  render() {
    let expanded = this.getExpanded()

    let {
      titleComponent,
      className,
      hideOnly,
      boxed,
      color,
      isAfterTrial,
    } = this.props

    if (color === 'darkPink') color = 'dark-pink'

    let TitleComponent = titleComponent

    return (
      <div
        className={classNames('accordion', className, {
          'accordion-expanded': expanded,
          'accordion-boxed': boxed,
          [`accordion-boxed-${color}`]: color,
          'after-trial': isAfterTrial,
        })}
      >
        <TitleComponent className="accordion-title" onClick={this.handleToggle}>
          {this.renderHeader()}
        </TitleComponent>
        {hideOnly ? (
          this.renderChildren()
        ) : (
          // <VelocityTransitionGroup
          //   enter={{ animation: 'slideDown' }}
          //   leave={{ animation: 'slideUp' }}
          //   speed={3}
          // >
          //   {expanded ? this.renderChildren() : null}
          // </VelocityTransitionGroup>

          <TransitionGroup>
            {expanded && (
              <CSSTransition classNames="accordion-animation" timeout={300}>
                <div>
                  {this.renderChildren()}
                </div>
              </CSSTransition>
            )}
          </TransitionGroup>
        )}
      </div>
    )
  }
}

// Grouped Accordion

class GroupedAccordionSub extends Accordion {
  constructor(props) {
    super(props)

    let { itemId } = props

    this.id = itemId || getUID()
  }

  getExpanded() {
    return this.props.expandedId === this.id
  }

  openSub() {
    let { openAccordion, group, isAfterTrial } = this.props

    if (!isAfterTrial) {
      openAccordion(group, this.id)
    }
  }

  closeSub() {
    let { closeAccordion, group } = this.props

    closeAccordion(group)
  }

  componentDidMount() {
    let { defaultExpanded } = this.props

    if (defaultExpanded) {
      this.openSub()
    }
  }
}

const mapStateToProps = (state, { group, itemId, appId, isAPI }) => {


  return {
    expandedId: getAccordionState(state, group),
    advancedShown: getAccordionState(state, `${group}-advanced-${itemId}`),

  }
}

export const GroupedAccordion = connect(mapStateToProps, {
  openAccordion,
  closeAccordion,
  toggleAccordion,
})(GroupedAccordionSub)
