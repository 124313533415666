import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'

import 'styles/AddObject.css'
import { getApp } from "ducks/apps/selectors";
import {getCurrentUser} from "ducks/users";
import {connect} from "react-redux";
import {setTool} from "ducks/editor/tools";
import {getComponentIcon} from "utils/libraries";
import {SHAPE_COMPONENT} from "../../../common/constants";


import Tab from "./Tab";
import {ComponentItem} from "./ComponentsAccordion";
import {requestComponent, requestComponents} from "ducks/components";
import axios from "axios";
import {apiURL, DEFAULT_PER_PAGE, fetchComponents} from 'utils/io'

function getIconStyles(value) {
  if (value?.options) {
    const {libraryName, libraryVersion, componentName} = value.options
    if (!libraryName) return null

    const iconURL = getComponentIcon(libraryName, libraryVersion, componentName)

    return {
      backgroundImage: `url(${iconURL})`,
    }
  }
}

const categorized = [

]


class AddPanel extends Component {
  shouldComponentUpdate(nextProps) {
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.match.params.appId !== nextProps.match.params.appId
  }

  renderItem = ({id, label, value, icon, ...otherProps}) => {

    return (
      <ComponentItem
        key={id}
        name={otherProps ? otherProps.name : label}
        iconClassName={icon && `editor-add-component-icon-${icon}`}
        iconStyles={!otherProps.iconUrl ? getIconStyles(value) :
          {
            backgroundImage: `url(${otherProps.iconUrl})`,
            backgroundSize: 'contain'
          }}
        onMouseDown={e => {
          this.handleSelect(id, value, [e.clientX, e.clientY])
        }}
      />
    )
  }

  loadChildrenPaginated = async (node, page = 0 , pageLimit = DEFAULT_PER_PAGE) => {

    let { loaded } = this.props

    const data  = await fetchComponents(node.id, {page: page, perPage : pageLimit })


    let children = [];
    if (data) {

      children = data.map((item) => (
        {
          id: item.id,
          name: `${item.name}`,
          description: '',
          children: [],
          counter: 0,
          thumb: item.thumb || "",

          type: item.value,
          page: node.page || 0,
          expanded: false,
          selected: false,
        }
      ))
    }

    return children;
  };

  handleSelect = async (e, node, parent) => {

    if (!node) {
      return
    }


    const {setTool, requestComponent} = this.props


    let  options = {}


    if (node.type === SHAPE_COMPONENT || node.value === SHAPE_COMPONENT) {
      const componentURL = `${apiURL}/components/${node.id}`
      const { data } = await axios.get(componentURL)

      function b64DecodeUnicode(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
      }
      if (data.orientations)  {
        data.orientations.map((m)=> {
          m.data = JSON.parse(b64DecodeUnicode(m.data))
          return m
        })

        options.orientations = data.orientations
      }
    }

    setTool(node.type || node.value, options, [e.clientX, e.clientY])


  }

  render() {
    const {
      match: {
        params: {appId},
      },
    } = this.props

    const {options} = this.props


    let sections = []

    return (
      <Tab
        defaultOpen={['Популярные']}
        searchPlaceholder="Поиск по базе оборудования..."
        emptyMessage={
          <>
            Пусто

          </>
        }
        loadChildrenPaginated = {this.loadChildrenPaginated}
        selectCallback = {this.handleSelect}
        renderItem={this.renderItem}
        options={options}
        sections={sections}
      />
    )

  }
}

const mapStateToProps = (state, {appId}) => {
  const app = getApp(state, appId)
  const user = getCurrentUser(state)

  return {
    app,
    options: [...categorized.map(category => ({
      label: category.label,
      children:category.components,
      loaded: true,
      counter: 3
    }))],
    user,
  }
}

export default withRouter(connect(mapStateToProps, {setTool, requestComponent, requestComponents})(AddPanel))
//export default withRouter(AddPanel)
