import React from 'react'
import BaseShape from './BaseShape'

import {PathItem, Point as PathPoint, Rectangle as PathRectangle, Segment, Size} from "../../../utils/vector";

export default class Rectangle extends BaseShape {

    getPathData() {
        this.dimension = []


        const kappa = 4 * (Math.sqrt(2) - 1) / 3
        const {
            width, height, points, compound, isClosed, zoom, x, y, borderRadius, angle, xScaled,
            yScaled,
            widthScaled,
            heightScaled
        } = this.props
        this.path = PathItem.create()

        if (compound && compound.length > 0) {
            this.path = PathItem.create(compound)
        } else if (points && points.length > 0) {


            this.path = PathItem.create(points)
        } else {
            const rect = new PathRectangle(x, y, width, height);
            let r = new PathPoint(borderRadius ? [borderRadius, borderRadius] : [0, 0])

            const bl = rect.getBottomLeft(true),
                tl = rect.getTopLeft(true),
                tr = rect.getTopRight(true),
                br = rect.getBottomRight(true)
            ;

            let segments = [];
            if (!r || r.isZero()) {
                segments = [
                    new Segment(bl),
                    new Segment(tl),
                    new Segment(tr),
                    new Segment(br)
                ];
            } else {

                r = Size.min(new Size(borderRadius, borderRadius), rect.getSize(true).divide(2));
                const rx = r.width,
                    ry = r.height,
                    hx = rx * kappa,
                    hy = ry * kappa;
                segments = [
                    new Segment(bl.add(rx, 0), null, [-hx, 0]),
                    new Segment(bl.subtract(0, ry), [0, hy]),
                    new Segment(tl.add(0, ry), null, [0, -hy]),
                    new Segment(tl.add(rx, 0), [-hx, 0], null),
                    new Segment(tr.subtract(rx, 0), null, [hx, 0]),
                    new Segment(tr.add(0, ry), [0, -hy], null),
                    new Segment(br.subtract(0, ry), null, [0, hy]),
                    new Segment(br.subtract(rx, 0), [hx, 0])
                ];
            }

            this.path = PathItem.create(segments.map(({point, handleIn, handleOut}) => {
                return new Segment(point, handleIn, handleOut)
            }))
        }
        let unit = window.devicePixelRatio === 2 ? 2 : 1
        this.path.setClosed(isClosed)
        this.path.scale(zoom.scale )


        this.path.adjust(x * zoom.scale, y* zoom.scale)

        // this.path.rotate(angle || 0, this.path.getRotatePoint(x/unit, y/unit));

        return this.path.getPathData()
    }

    doubleClickHandler() {
        if (this.isSelected()) {
            return this.props.onEdit()
        }

        return super.doubleClickHandler()
    }

    renderDimesion() {


    }

    renderPath() {
        
        return (
            <path
                {...this.getBorderProps()}
                fill={this.getBackgroundColor()}
                d={this.getPathData()}
            />
        )
    }

    render() {
        let {
            xScaled,
            yScaled,
            points,
            zoom,
            hideShadows,
            shadow,

            x,
            y,
            width,
            height,

            ...styles
        } = this.props


        return (
            <g className="shape">
                <g
                    className="shape"
                    onMouseDown={this.handleMouseDown}
                    onDoubleClick={this.handleDoubleClick}
                    onTouchStart={this.handleMouseDown}
                    style={styles}
                    /*transform={`translate(${xScaled} ${yScaled})`}*/
                >

                    {this.renderPath()}

                </g>
                {this.dimension && (<g className="shape-editor-size 2">
                    {this.dimension.map((pt, i) => (

                        <g>
                            <line className="shape-editor-line-angle" x1={pt[0]} y1={pt[1]} x2={pt[2]} y2={pt[3]}/>
                        </g>
                    ))}
                </g>)}</g>
        )
    }
}
