import axios from 'axios'
import qs from 'qs'

const extensions = {
  'image/jpeg': 'jpg',
  'image/jpg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
}

const imageTypes = new Set(Object.values(extensions))

export const isImage = key => {
  key = String(key)

  let parts = key.split('.')
  let extension = parts[parts.length - 1]

  return imageTypes.has(extension)
}

export const uploadURL = (key, height = null) => {
  if (!key) return null

  if (key && typeof key === 'object') {
    key = key.url
  }

  if (!isImage(key)) {
    return `${process.env.REACT_APP_FILE_UPLOADS_URL}/${key}`
  }

  let params = { orient: '', auto: 'compress' }

  if (height !== null) {
    params.h = height
  }

  let baseURL = process.env.REACT_APP_IMAGE_UPLOADS_URL

  return `${baseURL}/${key}?${qs.stringify(params)}`
}

export const getFilename = dataURL => {
  let splitData = dataURL.split(',')
  let match = splitData[0].match(/^data:([\w\/]+);.*$/i)

  if (!match) {
    throw new Error('Malformatted dataURL')
  }

  let mimetype = match[1]
  let extension = extensions[mimetype.toLowerCase()]

  if (!extension) {
    throw new Error(`Invalid file type: ${mimetype}`)
  }

  return `file.${extension}`
}

export const uploadImage = async (dataURL, filename = null) => {
  let data = dataURL.split(',')[1]

  if (!filename) {
    filename = getFilename(dataURL)
  }

  let endpointURL = `${process.env.REACT_APP_DATABASE_URL}/uploads`

  let result = await axios.post(endpointURL, { filename, data })

  return result.data
}
