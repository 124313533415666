import React, {Component} from 'react'
import {Field, reduxForm} from 'redux-form'
import Button from '../Shared/Button'
import ReactCodeInput from '../Shared/Forms/Pin'
import AuthPageLogo from './Logo'

const FORM_NAME = 'VerifyForm'

export const validate = data => {
  let errors = {}

  if (!data.code || data.code.length < 6) {
    errors.code = 'Password must be at least 6 characters'
  }

  return errors
}

class VerifyForm extends Component {
  render() {
    let { handleSubmit, error } = this.props

    return (
      <form onSubmit={handleSubmit} className="auth-form">
        <AuthPageLogo />
        <h1>Завершение регистрации</h1>
        <p>Откройте входящее сообщение, полученное на адрес указанной вами электронной почты, и перейдите по ссылке для завершения регистрации.
          или введите код из сообщения</p>
        <Field
          autoFocus
          name="code"
          placeholder=""
          type="text"
          label="Код подтверждения"
          component={ReactCodeInput}
        />
        <div className="auth-form-submission-row">
          <Button large teal type={'submit'}>
            Отправить
          </Button>
        </div>
        {error && <div className="auth-form-error">{error}</div>}
      </form>
    )
  }
}

export default reduxForm({
  form: FORM_NAME,
  validate,
})(VerifyForm)
