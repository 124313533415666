// WrappedNumberInput.js
import React from 'react';
import classNames from 'classnames';

import 'styles/WrappedInput.css';

import Icon from '../../../Shared/Icon'; // Убедитесь, что путь к Icon корректен

const WrappedNumberInput = ({
  label,
  name,
  value,
  onChange,
  className,
  suffix,
  placeholder,
  disabled,
  minValue = 0,
  ...props
}) => {

  const handleChange = (e) => {

    let val = e.target.value;

    // Преобразуем значение в число и гарантируем, что оно не меньше minValue
    let numericValue = Math.max(minValue, parseFloat(val) || 0)

    onChange(numericValue)

  }

  const handleKeyDown = (e) => {
    const increment = e.shiftKey ? 10 : 1;
    let newValue = parseFloat(value) || 0;

    if (e.keyCode === 38) { // Стрелка вверх
      e.preventDefault();
      onChange(String(newValue + increment));
    } else if (e.keyCode === 40) { // Стрелка вниз
      e.preventDefault();
      onChange(String(Math.max(minValue, newValue - increment)));
    } else if (e.keyCode === 13) { // Enter
      e.currentTarget.blur();
    }
  };

  const incrementValue = (delta) => {

    let newValue = parseFloat(value) || 0
    newValue = newValue + delta
    newValue = Math.max(minValue, newValue)


    onChange(newValue)

  }

  return (
    <div className={classNames('single-number-control', className)}>

      {label && (
        <label className="single-number-control-label">{label}</label>
      )}

      <div className={classNames('text-control', { disabled })}>
        <div className="text-control-sub">

          <input
            type="number"
            name={name}
            value={value}
            min={minValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            autoComplete="off"
            placeholder={placeholder}
            disabled={disabled}
            {...props}
          />

          <div className="incrementer">
            <div
              className="incrementer-btn incrementer-increase"
              onMouseDown={(e) => {
                e.preventDefault();
                incrementValue(e.shiftKey ? 10 : 1);
              }}
            >
              <Icon type="up-arrow-small" />
            </div>
            <div
              className="incrementer-btn incrementer-decrease"
              onMouseDown={(e) => {
                e.preventDefault();
                incrementValue(e.shiftKey ? -10 : -1);
              }}
            >
              <Icon type="down-arrow-small" />
            </div>
          </div>

          {suffix && <div className="text-control-suffix">{suffix}</div>}

        </div>
      </div>
    </div>
  );
};

export default WrappedNumberInput;
