import React, {useMemo} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import ToggleButton from '../../Shared/Forms/ToggleButton'
import 'styles/GenericRow.css'

const GenericInspectRow = ({
  children,
  className: classNameProp,
  onToggle,
  title,
  togglable,
  toggled,
}) => {
  const className = useMemo(
    () => classNames('inspect-generic-row', classNameProp),
    [classNameProp]
  )

  const bodyClassName = useMemo(
    () => classNames('inspect-generic-row-body', toggled || 'disabled'),
    [toggled]
  )

  return (
    <div className={className}>
      <div className="inspect-generic-row-title">
        {title}
        {togglable ? (
          <ToggleButton value={toggled} onChange={onToggle} />
        ) : null}
      </div>
      <div className={bodyClassName}>{children}</div>
    </div>
  )
}

GenericInspectRow.propTypes = {
  title: PropTypes.string.isRequired,
  togglable: PropTypes.bool,
  toggled: PropTypes.bool,
  onToggle: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

GenericInspectRow.defaultProps = {
  togglable: false,
  toggled: true,
  onToggle: () => null,
}

export default GenericInspectRow
