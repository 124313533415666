import React from 'react'
import classNames from 'classnames'
import 'styles/Stripe.css'

export default ({ className }) => (
  <div className={classNames('color-stripe', className)}>
{/*    <StripeBar color="#3e87f8" />
    <StripeBar color="#ffc00e" />
    <StripeBar color="#ef4c30" />*/}
  </div>
)

export const StripeBar = ({ color }) => (
  <div className="color-stripe-bar" style={{ backgroundColor: color }} />
)
