// layerUtils.js

import * as BABYLON from 'babylonjs';
import { PathItem, Point as PathPoint } from "../../../../utils/vector";
import { GROUP, SHAPE } from 'common/constants';
import { getId } from "../../../../common/utils";


export function getOverflowsUtils(components, componentId) {

    const arr = Object.values(components || {}).sort((a, b) => a.order - b.order);

    function flattenLayer(objects, flatten = {}) {
        if (!Array.isArray(objects)) {
            objects = [objects];
        }

        objects.forEach((item) => {
            if (item.type === GROUP) {
                item.children?.forEach((obj) => flattenLayer(obj, flatten));
                return;
            }

            if (item.compound && item.compound.length > 0) {
                item.compound?.forEach((obj) => flattenLayer(obj, flatten));
                return;
            }

            if (item.points && item.points.length > 0) {
                let p = PathItem.create(item.points);
                const bounds = p.bounds;

                item = Object.assign({}, {
                    id: getId(),
                    isClosed: true,
                    points: item.points,
                    x: bounds.x,
                    y: bounds.y,
                    width: bounds.width,
                    height: bounds.height,
                    depth: item.depth,
                    type: SHAPE
                });
            }

            flatten[item.id] = item;
        });

        return flatten;
    }

    let overflow = {};
    let elements = [];

    arr.forEach((component) => {
        const flatten = flattenLayer(component.objects);
        overflow[component.id] = elements;
        elements = [];

        overflow[component.id].forEach((obj) => {
            if (obj.depth - component.depth > 0) {
                obj.depth = obj.depth - component.depth;
                elements.push(obj);
            }
        });

        Object.values(flatten).forEach((obj) => {
            if (obj.depth - component.depth > 0) {
                obj.depth = obj.depth - component.depth;
                elements.push(obj);
            }
        });
    });

    return overflow[componentId];
}



export function drawLayerUtils(scene, app, components, activeLayer, cameraSetTarget) {

    
    let layer = components[activeLayer];
    if (!layer) {
      layer = Object.values(components)[0];
    }

    new BABYLON.HemisphericLight("light", new BABYLON.Vector3(1, 1, 0), scene);
    new BABYLON.HemisphericLight("light", new BABYLON.Vector3(1, 1, 0), scene);

    let boxMesh;

    if (app && app.outer_rule && app.outer_rule.length > 0 && Array.isArray(app.outer_rule)) {
      app.outer_rule.forEach((outer) => {
        const path = PathItem.create(outer.points);
        path.adjust(0, 0);
        path.setClosed(true);
        path.scale(new PathPoint(1, -1));
        path.reorient(true, true);
        let rulePoints = path.segments.map(s => new BABYLON.Vector3(s.point.x, s.point.y, 0));
        const [end] = rulePoints;
        rulePoints = [...rulePoints, end].reverse();
        const extrudeRulePath = [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 0, layer.depth / 100)];
        const cap = rulePoints.map(p => new BABYLON.Vector3(p.x, 0, p.y));

        const box = BABYLON.MeshBuilder.ExtrudeShapeCustom("box", {
          shape: rulePoints,
          path: extrudeRulePath,
          sideOrientation: BABYLON.Mesh.DOUBLESIDE,
          updatable: true
        }, scene);
        box.position = new BABYLON.Vector3(0, 0, 0);

        const capFront = BABYLON.MeshBuilder.CreatePolygon("polygon", {
          shape: cap,
          sideOrientation: BABYLON.Mesh.FRONTSIDE
        }, scene);
        capFront.rotation.x = -Math.PI / 2;

        const capBack = BABYLON.MeshBuilder.CreatePolygon("polygon", {
          shape: cap,
          sideOrientation: BABYLON.Mesh.BACKSIDE
        }, scene);
        capBack.rotation.x = -Math.PI / 2;
        capBack.position.z = layer.depth / 100;

        boxMesh = BABYLON.Mesh.MergeMeshes([capFront, box, capBack]);
      });
    } else {
      boxMesh = BABYLON.MeshBuilder.CreateBox("box", {
        width: app.width / 100,
        height: app.height / 100,
        depth: layer.depth / 100
      }, scene);
      boxMesh.position = new BABYLON.Vector3((app.width / 2) / 100, (app.height / 2) / 100, (layer.depth / 2) / 100);
    }

    const plate = BABYLON.CSG.FromMesh(boxMesh);
    const multiMat = new BABYLON.MultiMaterial("multiMat", scene);

    cameraSetTarget(new BABYLON.Vector3((app.width / 2) / 100, (app.height / 2) / 100, (layer.depth / 2) / 100));

    const boxMat = new BABYLON.StandardMaterial("mat0", scene);
    boxMat.diffuseColor = BABYLON.Color3.FromHexString("#0ea7e2");
    boxMat.backFaceCulling = !0;
    multiMat.subMaterials.push(boxMat);

    const draw = (shapeObject) => {
      const { width, height, depth, x, y, points, compound, id } = shapeObject;
      let path = PathItem.create();

      if (shapeObject.children && shapeObject.type === GROUP) {
        shapeObject.children.forEach(child => draw(child));
        return;
      }

      if (compound && compound.length > 0) {
        path = PathItem.create(compound);
        path.children.forEach(child => {
          const bounds = child.bounds;
          draw({ ...child, points: child.points, x: bounds.x, y: bounds.y, width: bounds.width, height: bounds.height, depth: child.depth });
        });
        return;
      }

      if (points && points.length > 0) {
        path = PathItem.create(points);
      }

      path.setClosed(true);
      path.reorient(true, true);
      path.scale(new PathPoint(1, -1));

      if (!path.segments) {
        return;
      }

      path.flatten();
      path.adjust(0, 0);

      let shapePoints = path.segments.map(s => new BABYLON.Vector3((s.point.x) / 100, (s.point.y) / 100, 0));
      const [end] = shapePoints;
      shapePoints = [...shapePoints, end].reverse();
      const extrudePath = [new BABYLON.Vector3(0, 0, 0), new BABYLON.Vector3(0, 0, depth / 100)];
      const cap = shapePoints.map(p => new BABYLON.Vector3(p.x, 0, p.y));

      const extrusion = BABYLON.MeshBuilder.ExtrudeShapeCustom(id, {
        shape: shapePoints,
        path: extrudePath,
        sideOrientation: BABYLON.Mesh.DOUBLESIDE,
        updatable: true
      }, scene);
      extrusion.position = new BABYLON.Vector3(0, 0, 0);

      const capFront = BABYLON.MeshBuilder.CreatePolygon("polygon", {
        shape: cap,
        sideOrientation: BABYLON.Mesh.FRONTSIDE
      }, scene);
      capFront.rotation.x = -Math.PI / 2;

      const capBack = BABYLON.MeshBuilder.CreatePolygon("polygon", {
        shape: cap,
        sideOrientation: BABYLON.Mesh.BACKSIDE
      }, scene);
      capBack.rotation.x = -Math.PI / 2;
      capBack.position.z = depth / 100;

      const model = BABYLON.Mesh.MergeMeshes([capFront, extrusion, capBack]);
      model.position = new BABYLON.Vector3(x / 100, (app.height - y - height) / 100, (layer.depth - depth) / 100 + 0.05);

      const ex = BABYLON.CSG.FromMesh(model);
      const Z = new BABYLON.StandardMaterial("mat1", scene);
      Z.diffuseColor = BABYLON.Color3.FromHexString("#fadd34");
      Z.backFaceCulling = true;
      multiMat.subMaterials.push(Z);

      plate.subtract(ex);
      model.dispose();
    };

    layer.objects && Array.isArray(layer.objects) && layer.objects.forEach(draw);
    getOverflowsUtils(components, layer.id)?.forEach(draw);

    boxMesh.dispose();
    plate.toMesh("csg4", multiMat, scene, true);
    
}
