import React, {Component} from 'react'
import {SubmissionError} from 'redux-form'
import QS from 'qs'
import {verify} from '../../utils/auth'
import AuthPage from './AuthPage'
import 'styles/Auth.css'
import Loading from "../Shared/Loading";
import EmptyState from "../Shared/EmptyState";
import {connect} from 'react-redux'
import {setCurrentUserToken} from '../../ducks/users'
import VerifyForm from "./VerifyForm";

class Verify extends Component {
    constructor(props) {
        super(props)

        let {location} = props
        let {token, email, code} = QS.parse(location.search, {
            ignoreQueryPrefix: true,
        })
        let shouldVerify = null

        if (token && email) {
            shouldVerify = true
        }

        this.state = {
            token,
            email,
            shouldVerify
        }
    }

    componentDidMount() {
        const {shouldVerify, token, email} = this.state;
        if (shouldVerify) {
            this.verifyToken({
                token, email
            })
        }
    }

    verifyToken = async (data) => {

        const {shouldVerify, token, email} = this.state;
        let {history, setCurrentUserToken} = this.props
        data = {
            ...data,
            token,
            email
        }

        return verify(data).then((response) => {
            let {headers: {token}} = response

            if (token) {

                setCurrentUserToken(token)
            }

            history.push('/')

        }).catch((err) => {

            const data = err.response?.data
            if (data) {
                throw new SubmissionError({_error: data.message})
            }

            throw new SubmissionError({_error: 'error'})
        })
    }

    render() {
        let {error, shouldVerify} = this.state

        return (
            <AuthPage className="verify-page" error={error}>
                {/*<Form onSubmit={this.handleUpdate} />*/}
                {shouldVerify && <EmptyState greedy>
                    <Loading large/>
                </EmptyState>}
                {!shouldVerify &&

                    <VerifyForm
                        onSubmit={this.verifyToken}

                    />}


            </AuthPage>
        )
    }

}

export default connect(null, {
        setCurrentUserToken
    }
)(Verify)
