import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {COMPONENT} from 'common/constants'

import {createName} from 'utils/naming'

import { getApp } from "ducks/apps/selectors";
import {createObject, selectObjects, updateObjects} from 'ducks/editor/objects'

import Form from './Form'

/*
import Shapes from "../../../../output_lib.json";
*/

class NewScreenModal extends Component {
  handleSubmit = async values => {

    let {createObject, updateObjects,onSuccess, opts, app} = this.props
    let baseObject
    const name = values.name || this.getDefaultName()

    let depth = app.depth
    const recalculate = []



    Object.values(app.components).forEach(screen => {
        if (screen.depth !== app.depth) {
          depth -= screen.depth
        } else {
          if (screen.objects.length > 0) {
            let maxX = 0
            screen.objects.forEach(obj => {
              if (obj.depth > maxX) {
                maxX = obj.depth
              }
            })
            recalculate.push({

              depth: maxX,
              id: screen.id,
            })
            depth -= maxX
          } else {
            const d = app.depth / 2
            recalculate.push({

              depth: d,
              id: screen.id,
            })
            depth = d
          }
        }
      }
    )

    baseObject = {
      width: app.width,
      height: app.height,
      depth: depth,
      backgroundColor: '@background',
    }


    let newObject = createObject(
      {
        ...baseObject,
        name,
        type: COMPONENT,
        x: undefined,
        y: undefined,
      },
      null,
      !opts.zoom
    )
    recalculate.length && updateObjects(recalculate)
    onSuccess(newObject.id)
  }

  getDefaultName = () => {
    let {list} = this.props

    return createName(COMPONENT, null, list)
  }

  render() {
    let {app, onCancel, opts} = this.props
    let name = this.getDefaultName()

    return (
      <Form
        onSubmit={this.handleSubmit}
        onCancel={onCancel}
        defaultName={name}
      />
    )
  }
}

const mapStateToProps = (state, {match}) => ({
  list: selectObjects(state),
  app: getApp(state, match.params.appId),
})

export default withRouter(
  connect(mapStateToProps, {createObject, updateObjects})(NewScreenModal)
)
