import {ALIGN_CENTER, ALIGN_RIGHT, LABEL} from 'common/constants'

// utils
import {getFontFamily} from './type'

export const getHeight = (fontSize, lineCount) => {
  return lineCount * Math.ceil(1.15 * fontSize)
}

export const measureLabel = label => {
  let lines = getValueParagraphs(label.text)

  let maxWidth = 0
  let height = getHeight(label.fontSize, lines.length)

  for (let line of lines) {
    let width = measureFragment(label, line)

    if (width > maxWidth) {
      maxWidth = width
    }
  }

  maxWidth = Math.ceil(maxWidth / 2) * 2

  return { width: maxWidth, height }
}

// Join last / first paragraph as one
export const joinParagraphs = (paragraphs, newParagraphs) => {
  paragraphs = paragraphs.slice()

  let firstNew =
    typeof newParagraphs === 'string'
      ? newParagraphs
      : (newParagraphs && newParagraphs[0]) || ''

  let rest = typeof newParagraphs === 'string' ? [] : newParagraphs.slice(1)

  if (firstNew) {
    if (paragraphs.length === 0) {
      paragraphs.push(firstNew)
    } else {
      paragraphs[paragraphs.length - 1] = `${
        paragraphs[paragraphs.length - 1]
      }${firstNew}`
    }
  }

  return [...paragraphs, ...rest]
}

export const getValueParagraphs = value => {
  let paragraphs = []

  if (typeof value === 'object') {
    value = value.filter(v => v !== '')

    value.forEach(t => {
      if (typeof t === 'string') {
        const array = t.split(/\n/g)
        paragraphs = joinParagraphs(paragraphs, array)
      } else {
        paragraphs = joinParagraphs(paragraphs, t.label || 'lorem ipsum')
      }
    })
  } else {
    paragraphs = (value || '').split(/\n/g)
  }

  return paragraphs
}

export const getLines = label => {
  let lines = []

  if (label.multiline === false) {
    return [getAbbreviation(label)]
  }

  let paragraphs = getValueParagraphs(label.text)

  if (label.autoWidth) {
    return paragraphs
  }

  let currentLine = 0

  for (let paragraph of paragraphs) {
    let regex = /\s+/g
    let match
    let lastIndex = 0
    let done = false

    let newParagraph

    if (typeof paragraph === 'object') {
      newParagraph = paragraph.label || 'lorem ipsum'
    } else {
      newParagraph = paragraph
    }

    while (!done) {
      match = regex.exec(newParagraph)

      let word

      if (!match) {
        done = true
        word = newParagraph.substring(lastIndex)
      } else {
        word = newParagraph.substring(lastIndex, match.index)
        lastIndex = match.index
      }

      // If line is empty, always put word there.
      if (!lines[currentLine]) {
        lines[currentLine] = word
      } else {
        let combined = lines[currentLine] + word
        let combinedWidth = measureFragment(label, combined)

        if (combinedWidth > label.width) {
          currentLine += 1
          lines[currentLine] = word
        } else {
          lines[currentLine] += word
        }
      }
    }

    currentLine += 1
  }

  return lines
}

export const getAbbreviation = label => {
  let paragraphs = getValueParagraphs(label.text).map(paragraph => {
    if (typeof paragraph === 'object') {
      return paragraph.label || 'lorem ipsum'
    }

    return paragraph
  })

  let text = paragraphs.join(' ').replace(/[\s\n\r]+/g, ' ')

  if (label.autoWidth) {
    return text
  }

  if (measureFragment(label, text) <= label.width) {
    return text
  }

  for (let i = 1; i < text.length - 3; i += 1) {
    let textFragment = `${text.substring(0, i)}...`

    if (measureFragment(label, textFragment) > label.width) {
      return `${text.substring(0, i - 1)}...`
    }
  }

  return '...'
}

export const measureFragment = (label, text) => {
  if (process.env.NODE_ENV === 'test') {
    return text.length * label.fontSize
  }

  let svg = document.createElement('svg')
  svg.style.zIndex = 20000
  svg.style.position = 'fixed'
  svg.style.left = 0
  svg.style.top = 0
  svg.style.opacity = 0
  svg.style.pointerEvents = 'none'
  svg.width = '1000px'
  svg.height = '200px'

  let textNode = document.createElement('text')
  textNode.x = 0
  textNode.y = '200px'
  textNode.style.fontFamily = getFontFamily(label)
  textNode.style.fontSize = `${label.fontSize}px`
  textNode.style.fontWeight = label.fontWeight || 400
  textNode.innerHTML = text

  document.body.appendChild(svg)
  svg.appendChild(textNode)

  let rect = textNode.getBoundingClientRect()

  window.setTimeout(() => {
    document.body.removeChild(svg)
  }, 0)

  return rect.width
}

export const updateObjectWidth = object => {
  return object
}
