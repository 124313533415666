import React from 'react';

import PreviewButton from '../Editor/PreviewButton';
import CheckoutButton from '../Editor/CheckoutButton';
import { Spacer } from '../App/Navbar';
import AppSwitcher from './AppSwitcher';

import 'styles/Nav.css';
import ShareButton from '../Editor/ShareButton';
import { isAdmin } from '../../ducks/users';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const EditorNav = (props) => {

  return (
    <>
      <AppSwitcher />

      <Spacer />

      <ShareButton />

      <PreviewButton />

      <CheckoutButton />

    </>
  );
};

const mapStateToProps = (state, { location }) => {
  return {
    isAdmin: isAdmin(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(EditorNav)
);