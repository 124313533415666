import React, {Component} from 'react'

import {getObjectAttributes, resetTool} from '../../../ducks/editor/tools'

import {getXGrid, getYGrid, resetSnaps, setXSnap, setYSnap,} from '../../../ducks/editor/snapping'

import {createRect} from '../../../utils/geometry'
import {getSnapValue} from '../../../utils/snapping'
import {scaleRect, unScale} from '../../../utils/zoom'

const ESC = 27

export const initialState = {
  startPoint: null,
  currentPoint: null,
  shiftPressed: false,
  altPressed: false,
}

export default class CreateGenericRect extends Component {
  state = { ...initialState }

  handleKeyDown = e => {

    if (e.which === ESC) {
      const { resetTool } = this.props
      resetTool()
    }
  }

  getPoint = e => {
    let { zoom, xGrid, yGrid, setXSnap, setYSnap } = this.props
    let point = unScale([e.clientX, e.clientY], zoom)

    if (!xGrid || !yGrid) {
      return point
    }

    let xSnapped = getSnapValue(xGrid, { x: point[0] }, zoom)
    let ySnapped = getSnapValue(yGrid, { y: point[1] }, zoom)

    setXSnap(xSnapped && xSnapped.x)
    setYSnap(ySnapped && ySnapped.y)

    return [xSnapped ? xSnapped.x : point[0], ySnapped ? ySnapped.y : point[1]]
  }

  handleMouseDown = e =>
    this.setState({
      startPoint: this.getPoint(e),
    })

  handleMouseMove = e =>
    this.setState({
      currentPoint: this.getPoint(e),
      shiftPressed: e.shiftKey,
      altPressed: e.altKey,
    })

  handleMouseUp = e => {
    const { startPoint } = this.state

    if (!startPoint) {
      return
    }

    const { resetTool, objectAttributes } = this.props
    resetTool()

    console.log(5)

    this.createObject(objectAttributes, this.getRect())
  }

  getRect = () => {
    let { startPoint, currentPoint, shiftPressed, altPressed } = this.state

    if (
      !startPoint ||
      !currentPoint ||
      (startPoint[0] === currentPoint[0] && startPoint[1] === currentPoint[1])
    ) {
      return null
    }

    let diffX = currentPoint[0] - startPoint[0]
    let diffY = currentPoint[1] - startPoint[1]

    let xSign = diffX ? diffX / Math.abs(diffX) : 1
    let ySign = diffY ? diffY / Math.abs(diffY) : 1

    if (shiftPressed) {
      let dist = Math.max(Math.abs(diffX), Math.abs(diffY))

      currentPoint = [
        startPoint[0] + xSign * dist,
        startPoint[1] + ySign * dist,
      ]
    }

    if (altPressed) {
      startPoint = [
        startPoint[0] - (currentPoint[0] - startPoint[0]),
        startPoint[1] - (currentPoint[1] - startPoint[1]),
      ]
    }

    return createRect(startPoint, currentPoint, true)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    let { resetSnaps } = this.props

    document.removeEventListener('keydown', this.handleKeyDown)
    resetSnaps()
  }

  createObject() {
    throw new Error('createObject should be overriden in subclass')
  }

  render() {
    const { zoom } = this.props
    let rect = scaleRect(this.getRect(), zoom)

    let borderRadius = 0

    return (
      <g
        onMouseDown={this.handleMouseDown}
        onMouseMove={this.handleMouseMove}
        onMouseUp={this.handleMouseUp}
        pointerEvents="all"
      >
        <rect
          x={0}
          y={0}
          width={window.innerWidth}
          height={window.innerHeight}
          style={{ fill: 'none' }}
        />
        {rect && (
          <rect
            x={rect.x - 0.5}
            y={rect.y - 0.5}
            width={rect.width + 1}
            height={rect.height + 1}
            rx={borderRadius}
            ry={borderRadius}
            style={{ fill: 'none', stroke: '#808080', strokeWidth: 0.5 }}
          />
        )}
      </g>
    )
  }
}

export const mapStateToProps = state => ({
  objectAttributes: getObjectAttributes(state),
  xGrid: getXGrid(state),
  yGrid: getYGrid(state),
})

export const mapDispatchToProps = {
  resetTool,
  setXSnap,
  setYSnap,
  resetSnaps,
}
