import React, {Component} from 'react'
import {connect} from 'react-redux'
import {imageResize, imageTypes} from 'common/constants'


import {getComponent, getCurrentAppId, selectObjects,} from 'ducks/editor/objects'


import {getSelection} from 'ducks/editor/selection'

import IconOption from 'components/Shared/IconOption'
import ImageInput from 'components/Shared/Forms/ImageInput'
import BindableTextControl from 'components/Shared/BindableTextControl'

import MenuControl from './MenuControl'

import 'styles/ImageControl.css'

import SlideControl from "../SlideControl";

const imageResizeOptions = [
  { label: 'Crop Image to Fill Space', value: imageResize.COVER },
  { label: 'Show Full Image (Don’t Crop)', value: imageResize.CONTAIN },
]

const imagePlaceholderOptions = [
  { label: "Don't show anything", value: false },
  { label: 'Show a placeholder image', value: true },
]


class ImageControl extends Component {
  getLabel = () => {
    let { label, value } = this.props

    if (this.isLibraryComponent() && value?.imageType === 'url') {
      return <IconOption icon="link" label="URL" />
    }

    return label
  }

  isLibraryComponent = () => {
    const { object } = this.props

    return object.type === 'libraryComponent'
  }

  getMenuOptions = () => {
    let { options, canBeUploaded } = this.props

    if (canBeUploaded) {
      return [
        {
          label: <IconOption icon="uploaded-image" label="Upload" />,
          value: 'uploaded',
        },
        {
          label: <IconOption icon="link" label="URL" />,
          value: 'url',
        },
      ]
    }

    if (this.isLibraryComponent()) {
      return [
        {
          label: (
            <IconOption
              icon="dynamic-image"
              label="Database"
              className="image-control-dynamic-image-option"
            />
          ),
          children: options,
        },
        {
          label: <IconOption icon="link" label="URL" />,
          value: imageTypes.URL,
        },
      ]
    }

    return options
  }

  getType = () => {
    const { role } = this.props

    return role && role === 'listItem'
  }

  getTarget = () => {
    const { namespace, object } = this.props

    if (object?.type === 'libraryComponent') {
      if (namespace) {
        return object.attributes[namespace]
      } else {
        return object.attributes
      }
    } else {
      return object
    }
  }

  getImageKey = () => {
    const { object, name } = this.props
    if (name && object.type !== 'image') return name
    else return 'imageBinding'
  }

  getImageBinding = () => {
    const target = this.getTarget()
    const key = this.getImageKey()

    if (!target) return null

    return target?.[key]
  }

  getImageOptions = () => {
    const object = this.getImageBinding()

    return object?.options
  }

  getPlaceholderStatus = () => {
    let status = ''
    const options = this.getImageOptions()

    if (options && 'placeholderImageEnabled' in options) {
      const { placeholderImageEnabled } = options

      if (typeof placeholderImageEnabled !== 'undefined') {
        status = placeholderImageEnabled
      }
    }

    return status
  }

  getImageFileName = () => {
    const { object } = this.props
    const options = this.getImageOptions()
    if (options?.placeholderImage) return options.placeholderImage
    if (object?.imageType === 'uploaded') return object.filename1x

    return null
  }

  handleChange = newValues => {

    const { onChange } = this.props
    const key = this.getImageKey()

    // check if the newValue contains one of these properties
    const filename1x = 'filename1x' in newValues
    const imageResize = 'imageResize' in newValues

    // if the new value is for cropping or a static image we want to
    // update the root of the object instead of where the binding takes place
    if (imageResize || filename1x) return onChange(newValues)

    return onChange({ [key]: newValues })
  }

  handleImageChange = value => {
    const { object } = this.props
    const { imageType } = object
    const target = this.getImageBinding()

    switch (imageType) {
      case 'uploaded':
        return this.handleChange({ filename1x: value })
      default:
        return this.handleChange({
          ...target,
          options: { ...target?.options, placeholderImage: value },
        })
    }
  }

  handlePlaceholderControllerChange = value => {
    const target = this.getImageBinding()
    this.handleChange({ ...target, options: { ...target?.options, ...value } })
  }

  renderPlaceholderController = () => {
    let value = this.getPlaceholderStatus()

    return (
      <MenuControl
        displayName="If there's no image..."
        name="placeholderImageEnabled"
        value={value}
        onChange={this.handlePlaceholderControllerChange}
        options={imagePlaceholderOptions}
      />
    )
  }

  renderImageInput = () => {
    const { appId, role } = this.props
    const object = this.getTarget()
    const { imageType } = object

    let displayName = ''
    let value = this.getImageFileName()
    let name = ''

    if (imageType === 'dynamic' || (role && role === 'listItem')) {
      displayName = 'Placeholder Image'
    }

    return (
      <ImageInput
        isAsset
        displayName={displayName}
        buttons={['view', 'remove']}
        appId={appId}
        input={{
          value,
          name,
          onChange: this.handleImageChange,
        }}
      />
    )
  }

  handleImageURLChange = ({ url: imageURL }) => {
    const { onChange, value: oldValue, name } = this.props

    if (this.isLibraryComponent()) {
      let newVal = { ...oldValue, binding: imageURL }

      return onChange({ [name]: newVal })
    } else {
      return onChange({ imageURL })
    }
  }

  renderLibraryImageURLInput = () => {
    const { object, role, reference, value } = this.props
    const menuValue = value?.binding

    return (
      <BindableTextControl
        displayName="URL"
        name="url"
        onChange={this.handleImageURLChange}
        value={menuValue}
        objectId={object?.id}
        placeholder="https://example.com/image.jpg"
        role={role}
        reference={reference}
      />
    )
  }

  renderImageURLInput = () => {
    const { object } = this.props

    return (
      <BindableTextControl
        displayName="URL"
        name="url"
        onChange={this.handleImageURLChange}
        value={object?.imageURL || ''}
        objectId={object?.id}
        placeholder="https://example.com/image.jpg"
      />
    )
  }
  handleTurdsizeInput = ({turdsize}) => {
    const { onChange, value: oldValue, name } = this.props

    return onChange({ ['turdsize']: turdsize })
  }
  renderTurdsizeInput= () => {
    const { object } = this.props

    const value = object?.turdsize || 0

    return (
      <SlideControl
        name="turdsize"
        value={value}
        defaultValue={2}
        onChange={this.handleTurdsizeInput}
        min={0}
        max={10}
      />
    )
  }
  handleToleranceInput = ({tolerance}) => {
    const { onChange, value: oldValue, name } = this.props

    return onChange({ ['tolerance']: tolerance })
  }
  renderToleranceInput= () => {
    const { object } = this.props

    const value = object?.tolerance || 200

    return (

      <SlideControl
        name="tolerance"
        value={value}
        defaultValue={255}
        onChange={this.handleToleranceInput}
        min={0}
        max={255}
      />

    )
  }

  renderImageCropping = () => {
    const { object } = this.props

    const value = object?.imageResize || ''

    return (
      <MenuControl
        autoSelect
        displayName="Image Cropping"
        name="imageResize"
        value={value}
        onChange={this.handleChange}
        options={imageResizeOptions}
      />
    )
  }


  renderImageTracing = () => {
    const { object } = this.props

    const value = object?.imageResize || ''

    return (
      <span rel="noopener noreferrer" className="editor-image-trace" onClick={this.traceImage}>Трассировка</span>
    )
  }

  handleImageSourceChange = val => {
    const { onChange, name, value: oldValue } = this.props

    if (this.isLibraryComponent()) {
      let newVal

      if (val[name] === imageTypes.URL) {
        if (oldValue?.imageType) {
          newVal = {
            binding: oldValue?.binding,
            imageType: imageTypes.URL,
            type: 'imageBinding',
          }
        } else {
          newVal = {
            binding: oldValue,
            imageType: imageTypes.URL,
            type: 'imageBinding',
          }
        }
      } else {
        newVal = {
          binding: val[name],
          imageType: imageTypes.INTERNAL,
          type: 'imageBinding',
        }
      }

      return onChange({ [name]: newVal })
    } else {
      return onChange(val)
    }
  }

  render() {
    let { name, displayName, value, object, canBeUploaded, role } = this.props

    const { imageType, filename1x } = object
    const options = this.getMenuOptions()
    const status = this.getPlaceholderStatus()

    let rowHeight = canBeUploaded ? 40 : undefined

    const canCrop = imageType === 'dynamic' || filename1x || imageType === 'url'

    const hasImage =
      imageType === 'dynamic' ||
      imageType === 'url' ||
      (role && role === 'listItem')

    let menuValue = value?.type === 'imageBinding' ? value.binding : value

    if (value?.imageType === imageTypes.URL) {
      menuValue = imageTypes.URL
    }

    if (
      menuValue &&
      Object.keys(menuValue).length === 0 &&
      menuValue.constructor === Object
    ) {
      menuValue = null
    }

    return (
      <div className="library-image-control">
        <MenuControl
          options={options}
          name={name}
          displayName={displayName}
          value={menuValue}
          onChange={this.handleImageSourceChange}
          getLabel={this.getLabel}
          rowHeight={rowHeight}
        />

        {imageType === 'url' && this.renderImageURLInput()}
        {value?.imageType === imageTypes.URL &&
          this.renderLibraryImageURLInput()}
        {hasImage ? this.renderPlaceholderController() : null}
        {status || imageType === 'uploaded' ? this.renderImageInput() : null}
        {canCrop && role !== 'listItem'? this.renderTurdsizeInput() : null}
        {canCrop && role !== 'listItem' ? this.renderToleranceInput() : null}

       {/* {canCrop && role !== 'listItem' ? this.renderImageCropping() : null}*/}
        {canCrop && role !== 'listItem' ? this.renderImageTracing() : null}
      </div>
    )
  }
}

const mapStateToProps = (
  state,
  { objectId, value, role, reference, canBeUploaded }
) => {
  let appId = getCurrentAppId(state)
  let componentId = getComponent(state, objectId).id
  let label


  if (canBeUploaded && value === 'uploaded') {
    label = 'Сохраненное изображение'
  }

  if (value === 'url') {
    label = 'URL'
  }

  let options = []

  const selection = getSelection(state)
  const objects = selectObjects(state, selection)

  return {
    appId,
    componentId,
    label,
    options,
    object: objects.length === 1 ? objects[0] : null,
  }
}

export default connect(mapStateToProps)(ImageControl)
