import {Component} from 'react'
import PropTypes from 'prop-types'
import {normalizeColor} from '../../../utils/colors'

export default class BaseObject extends Component {
  static contextTypes = {
    editable: PropTypes.bool,
    getSelection: PropTypes.func,
  }

  cancelEvent = e => {
    e.stopPropagation()
  }

  handleSelect = (e = {}) => {
    let { onSelect } = this.props

    onSelect(e.shiftKey, e.metaKey)
  }

  isSelected = () => {
    let { id } = this.props
    let { getSelection } = this.context

    let selection = getSelection()

    return selection.indexOf(id) !== -1
  }

  handleMouseDown = e => {
    let { onPosition } = this.props

    const clientX = e.clientX || (e.touches && e.touches[0].clientX) || undefined ;
    const clientY = e.clientY || (e.touches &&  e.touches[0].clientY) || undefined ;

    this.handleSelect(e)

    let point = [clientX, clientY]
    onPosition(point)
  }

  handleDoubleClick = e => {
    let { editable } = this.context

    if (!editable) {
      return
    }

    e.stopPropagation()
    this.doubleClickHandler()
  }

  doubleClickHandler() {
    let { onExpand } = this.props

    onExpand()
  }

  getColor(color) {
    let { branding } = this.props

    return normalizeColor(color, branding)
  }




}
