import svgPathParser from 'svg-path-parser';
import deepEqual from 'deep-equal';
import {Bezier} from 'bezier-js';
import {flatten} from './arrays';

const _slicedToArray = function () {
  function sliceIterator(arr, i) {
    const _arr = [];
    let _n = true;
    let _d = false;
    let _e = undefined;

    try {
      for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
        _arr.push(_s.value);

        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i["return"]) _i["return"]();
      } finally {
        if (_d) throw _e;
      }
    }

    return _arr;
  }

  return function (arr, i) {
    if (Array.isArray(arr)) {
      return arr;
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i);
    } else {
      throw new TypeError("Invalid attempt to destructure non-iterable instance");
    }
  };
}();
// POINTS has the following format:
//  [
//    {
//      point: [x, y],
//      inControl: [x, y] || null,
//      outControl: [x, y] || null
//    }
//  ]


export const createPath = (points, isClosed, transform) => {
  if (points.length === 0) {
    return '';
  }

  points = transformPath(points, transform);
  const firstPoint = points[0];
  let commands = ['M ' + firstPoint.point.join(' ')];
  commands = commands.concat(points.slice(0, points.length - 1).map(function (pt, i) {
    return getSegment(points, i);
  }));

  if (isClosed) {
    commands.push(getSegment(points, points.length - 1));
    commands.push(' Z');
  }

  return commands.join('');
};

export const  transformPath = (points, transform) => {
  if (!transform) {
    return points;
  }

  return points.map(function (_ref) {
    const point = _ref.point,
      inControl = _ref.inControl,
      outControl = _ref.outControl;
    return {
      point: transform(point),
      inControl: inControl && transform(inControl),
      outControl: outControl && transform(outControl)
    };
  });
};

export const  getSegment = (points, i) => {
  const startPoint = getPoint(points, i);
  const endPoint = getPoint(points, i + 1);
  const startControl = startPoint.outControl || startPoint.point;
  const endControl = endPoint.inControl || endPoint.point;
  const data = [startControl, endControl, endPoint.point];
  return ' C ' + data.map(function (d) {
    return d.join(' ');
  }).join(', ');
};

export const  getPoint = (points, i) => {
  i = (i + points.length) % points.length;
  return points[i];
};

export const  parseSVGPath = (svgPath) => {
  const path = svgPathParser(svgPath || '');
  let points = [];
  let isClosed = false;

  if (path.length === 0) {
    return {
      points: points,
      isClosed: isClosed
    };
  }

  for (let i = 0; i < path.length; i += 1) {
    const piece = path[i];
    const command = piece.command,
      x = piece.x,
      y = piece.y,
      x1 = piece.x1,
      y1 = piece.y1,
      x2 = piece.x2,
      y2 = piece.y2;

    switch (command) {
      case 'moveto':
      case 'lineto':
        points.push({
          point: [x, y]
        });
        break;

      case 'curveto':
        // Add outControl to prev point
        if (points.length > 0) {
          points[points.length - 1].outControl = [x1, y1];
        } // Push new point with inControl


        points.push({
          point: [x, y],
          inControl: [x2, y2]
        });
        break;

      case 'closepath':
        if (points.length > 1 && deepEqual(points[0].point, points[points.length - 1].point)) {
          points[0].inControl = points[points.length - 1].inControl;
          points = points.slice(0, points.length - 1);
        }

        isClosed = true;
        break;
    }
  }

  return {
    points: points,
    isClosed: isClosed
  };
};

export const  getPathBbox = (points, isClosed) => {
  const length = isClosed ? points.length : points.length - 1;
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  if (points.length === 1 && length === 0) {
    const _points$0$point = _slicedToArray(points[0].point, 2),
      x = _points$0$point[0],
      y = _points$0$point[1];

    return {
      x: x,
      y: y,
      width: 0,
      height: 0
    };
  }

  for (let i = 0; i < length; i += 1) {
    const start = points[i];
    const end = points[(i + 1) % points.length];
    const data = flatten([start.point, start.outControl || start.point, end.inControl || end.point, end.point]);
    const segment = new Bezier(data);
    const bbox = segment.bbox();

    if (bbox.x.min < minX) {
      minX = bbox.x.min;
    }

    if (bbox.y.min < minY) {
      minY = bbox.y.min;
    }

    if (bbox.x.max > maxX) {
      maxX = bbox.x.max;
    }

    if (bbox.y.max > maxY) {
      maxY = bbox.y.max;
    }
  }

  if (isNaN(minX) || isNaN(minY) || isNaN(maxX) || isNaN(maxY)) {
    return {
      x: 0,
      y: 0,
      width: 0,
      height: 0
    };
  }

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY
  };
};



